var UpliftUtil = {
  loadB2B: function(code) {
    if (!window.Uplift && code) {
      console.log("load uplift b2b");

      (function(u, p, l, i, f, t, o, b, j) {
        u["UpLiftPlatformObject"] = f;
        (u[f] =
          u[f] ||
          function() {
            (u[f].q = u[f].q || []).push(arguments);
          }),
          (u[f].l = 1 * new Date());
        (b = p.createElement(l)), (j = p.getElementsByTagName(l)[0]);
        b.async = 1;
        b.src = i + "?id=" + t;
        j.parentNode.insertBefore(b, j);
        o = u.location.host.match(/[\w-]+\.\w{2,3}(:\d+)?$/);
        if (o) {
          o = o[0];
        }
        u[f]("create", t, o);
      })(
        window,
        document,
        "script",
        "https://cdn.uplift-platform.com/a/up.js",
        "up",
        "UP-" + code
      );
    }
  },

  loadB2C: function(code) {
    if (!window.Uplift && code) {
      console.log("load uplift b2c");

      (function(u, p, l, i, f, t, o, b, j) {
        u["UpLiftPlatformObject"] = f;
        (u[f] =
          u[f] ||
          function() {
            (u[f].q = u[f].q || []).push(arguments);
          }),
          (u[f].l = 1 * new Date());
        (b = p.createElement(l)), (j = p.getElementsByTagName(l)[0]);
        b.async = 1;
        b.src = i + "?upcode=" + t;
        j.parentNode.insertBefore(b, j);
        o = u.location.host.match(/[\w-]+\.\w{2,3}(:\d+)?$/);
        if (o) o = o[0];
        u[f]("create", t, o);
      })(
        window,
        document,
        "script",
        "https://tag.uplift.com/external/up.js",
        "up",
        "UP-" + code
      );
    }
  },

  initB2B: function(settings, isCheckout) {
    console.log("util init b2b");
    var UpliftObj = window.Uplift.Agent;

    var options = settings.agentInfo;

    options.checkout = isCheckout || false;

    UpliftObj.init(options);

    return UpliftObj;
  },

  initB2C: function(settings, isCheckout) {
    console.log("util init b2c");

    var UpliftObj = window.Uplift.Payments;

    var options = {
      apiKey: settings.key,
      locale: settings.agentInfo.locale,
      currency: settings.agentInfo.currency
    };

    if (isCheckout) {
      options.checkout = true;
      options.container = settings.agentInfo.payMonthlyContainer;
      options.onChange = settings.agentInfo.onChange;
    }

    UpliftObj.init(options);

    return UpliftObj;
  },

  getTripId: function(id) {
    var upliftData =
      JSON.parse(window.sessionStorage.getItem("uplift-" + id)) || {};

    if (upliftData.tripId) {
      console.log("return id");
      return upliftData.tripId;
    }

    return null;
  },

  removeTripId: function(id) {
    console.log("remove tripid");
    window.sessionStorage.removeItem("uplift-" + id);
  },

  processSessionTokenCallback: function(data) {
    console.log("callback");
    console.log(data);
    return "asdf";
  }
};

module.exports = UpliftUtil;
