var $ = require("jquery");
var CreditCardView = require("./_payment.credit-card");
var CreditCardFeeView = require("./_payment.credit-card-fee");
var AddressView = require("./_payment.address");
var ccTypes = require("./../../utilities/libraries/credit-card-types");

var PaymentView = Backbone.View.extend({
  /**
   * The internal event listeners for the view.
   */
  events: {
    "keyup [data-payment-amount-input]": "processPaymentAmountInputChange",
    "click [data-payment-process]": "processPaymentProcessSelection",
    "change [data-payment-process]": "processPaymentProcessSelection",
    "change [data-payment-credit-card-type]": "processCreditCardTypeSelection",
    "disableOn [data-payment-amount-input]": "clearPaymentAmountInput",
    "disableOff [data-payment-amount-input]": "defaultPaymentAmountInput",
    "disableOn [data-payment-clear-on-disable]": "clearCheckedRadio",
    "click [data-payment-option]": "processPaymentOptionChange",
    "click [data-payment-method-type-label]": "externalAddressPopulate",
    "states-updated [data-country-state-select]": "externalCountryPopulate",
    "keyup [data-points-to-redeem]": "redeemInputLimitTimer",

    // @TODO: feel free to create a module just for this
    "click [data-split-payment-button]": "splitPaymentCalculator"
  },

  // @todo delete after is done via configuration

  // This method populates and clears the billing address section if a externalBillingAddress Object exists

  externalAddressPopulate: function(endpointCall) {
    // As the logic from the external address needs to meet how much money is owed, it will only work when the remainingCost is = 0
    let remainingCost = this.currentRemainingCost;

    let addressBlock = REVELEX.externalBillingAddress || {};
    let paymentAddressBlock = this.$("[data-address-block='billing']") || "";

    paymentAddressBlock.removeClass("corporate_credit_card_enabled");
    if (
      (this.$("[data-payment-method-type-label='corporate_credit_card']").is(
        ":checked"
      ) ||
        endpointCall === true) &&
      remainingCost === 0 &&
      Object.keys(addressBlock).length
    ) {
      paymentAddressBlock.addClass("corporate_credit_card_enabled");
      paymentAddressBlock.find("[data-address-1]").val(addressBlock.address1);
      paymentAddressBlock.find("[data-address-2]").val(addressBlock.address2);
      paymentAddressBlock.find("[data-city]").val(addressBlock.city);

      this.externalCountryPopulate(addressBlock.country);

      paymentAddressBlock.find("[data-state]").val(addressBlock.state);

      // Doing this to remove any bad format that comes from the zip code
      paymentAddressBlock
        .find("[data-postal-code]")
        .val(addressBlock.postalCode)
        .focus()
        .blur();
      this.settings.hasAddress = false;
    } else if (Object.keys(addressBlock).length && !this.settings.hasAddress) {
      paymentAddressBlock.find("[data-address-1]").val("");
      paymentAddressBlock.find("[data-address-2]").val("");
      paymentAddressBlock.find("[data-city]").val("");
      paymentAddressBlock.find("[data-state]").val("");
      paymentAddressBlock.find("[data-postal-code]").val("");
    }
  },

  // Country State widget works independently we need to trigger a change when the address get prepopulated
  externalCountryPopulate: function(country) {
    let paymentAddressBlock = this.$("[data-address-block='billing']") || "";
    let paymentAddressCountry = paymentAddressBlock.find("[data-country]");

    if (
      typeof country === "string" &&
      paymentAddressCountry.val() !== country
    ) {
      paymentAddressCountry.val(country).trigger("change");
    }
  },

  // Controlling the points limit and giving a 2 second gap per keystroke
  redeemInputLimitTimer: function() {
    clearTimeout(this.redeemInputTimer);

    this.redeemInputTimer = setTimeout(() => {
      this.redeemInputLimit();
    }, 2000);
  },

  // Validating the max for the points to redeem input

  redeemInputLimit: function() {
    let currentInput = this.$("[data-points-to-redeem]");

    if (currentInput) {
      let currentValue = parseFloat(currentInput.val()).toFixed(2);
      let currentMax = parseFloat(currentInput.attr("max")).toFixed(2);

      currentInput.val(currentValue);

      if (parseFloat(currentValue) <= 0) {
        currentInput.val(0);
      } else if (parseFloat(currentValue) >= parseFloat(currentMax)) {
        currentInput.val(currentMax);
      }
    }
  },

  // split paymeny calculator logic

  splitPaymentCalculator: function() {
    this.redeemInputLimit();
    // Checking if there's an amount to be paid by the client
    let amountToPaid =
      this.$("[data-payment-option-amount]:checked").attr(
        "data-payment-option-amount"
      ) || 0;

    let corporateCardButton = this.$(
      "[data-payment-method-type-label='corporate_credit_card']"
    );

    let creditCardButton = this.$(
      "[data-payment-method-type-label='credit_card']"
    );

    let corporateBlock = this.$("[data-payment-corporate-block]");

    // Guaranteed comes with the amount of 1 and it that payment method doesnt allow split payment or point redemption
    if (amountToPaid > 1) {
      let url = this.settings.calculatorURL;
      let calculatorWrapper = this.$("[data-split-payment-calculator-wrapper]");
      let pointsToRedeem =
        calculatorWrapper.find("[data-points-to-redeem]").val() ||
        this.$("[data-available-points]").val();

      let errorDialog = this.$("[data-dialog-error-calculator]").component();

      const reward_data = {
        "reward_data[amount]": pointsToRedeem,
        "reward_data[total]": amountToPaid
      };

      // send request
      $.ajax({
        type: "GET",
        dataType: "json",
        cache: false,
        url: url,
        data: $.param(reward_data),
        statusCode: {
          500: function() {
            errorDialog.open();
          }
        },
        beforeSend: () => {
          this.$("[data-split-payment-calculator]").addClass("is-loading");
          this.$("[data-split-payment-button]").attr("disabled", true);
        },
        success: response => {
          if (response.success) {
            response.data.AvailablePoints = parseFloat(
              response.data.AvailablePoints
            );
            response.data.MarkupPercent = parseFloat(
              response.data.MarkupPercent
            );
            response.data.PointsRemaining = parseFloat(
              response.data.PointsRemaining
            );
            response.data.RemainingCost = parseFloat(
              response.data.RemainingCost
            );
            response.data.RemainingCostWithMarkup = parseFloat(
              response.data.RemainingCostWithMarkup
            );

            response.data.SurchargeFee = parseFloat(response.data.SurchargeFee);

            response.data.total = parseFloat(amountToPaid);

            response.data.usablePoints = parseFloat(pointsToRedeem);

            // generating the max for the calculator

            if (response.data.total >= response.data.AvailablePoints) {
              response.data.currentMax = response.data.AvailablePoints;
            } else {
              response.data.currentMax = response.data.total;
            }

            if (response.data.usablePoints >= response.data.currentMax) {
              response.data.usablePoints = response.data.currentMax;
            }

            this.currentRemainingCost = response.data.RemainingCost;

            if (corporateCardButton.is(":checked")) {
              this.externalAddressPopulate(true);
            }

            this.$("[data-split-payment-calculator-wrapper]").html(
              this.splitTemplate(response.data)
            );

            this.paymentFormWrapper.component().reflow();

            this.$el.loadComponents();
            this.$el.loadModules();

            let splitPaymentBlock = this.$("[data-split-payment-card-block]");

            if (response.data.RemainingCost > 0) {
              splitPaymentBlock.component().open();
            } else {
              splitPaymentBlock.component().close();
            }

            // if the corporateBlock is closed,
            // disable all forms field even the credit card ones

            if (!corporateBlock.component().isOpen()) {
              splitPaymentBlock.component().disableFormFieldsOnClose();
            }
          } else {
            this.splitPaymentErrorMessages(response);
            corporateCardButton.attr("disabled", true);
          }

          this.$("[data-split-payment-button]").removeAttr("disabled");
          this.$("[data-split-payment-calculator]").removeClass("is-loading");
          if (response.data.usablePoints === 0) {
            creditCardButton.trigger("click");
          }
        },
        error: response => {
          this.splitPaymentErrorMessages(response);
          this.$("[data-split-payment-calculator]").removeClass("is-loading");
          this.$("[data-split-payment-calculator]").removeClass("disabled");
          corporateCardButton.attr("disabled", true);
        }
      });
    } else {
      corporateCardButton.attr("disabled", true);
    }
  },

  splitPaymentErrorMessages: function(response) {
    let errorDialog = this.$("[data-dialog-error-calculator]").component();
    let errorDialogBody = this.$("[data-dialog-error-calculator-body]");

    let errors = "";

    if (response && response.messages.fault.length) {
      response.messages.fault.forEach(
        element => (errors = errors.concat("<p>" + element + "</p>"))
      );
    }
    if (response && response.messages.general.length) {
      response.messages.general.forEach(
        element => (errors = errors.concat("<p>" + element + "</p>"))
      );
    }
    errorDialogBody.html(errors);
    errorDialog.reflow().open();
  },

  /**
   * This method processes the change of a payment amount input
   *
   * @param event
   */
  processPaymentAmountInputChange: function(event) {
    this.processPaymentAmount(parseFloat($(event.currentTarget).val()));
  },

  // This method process if there's any amount attached to the Payment Amount selected

  processPaymentAmount: function(amount) {
    if (amount > 0) {
      this.$("[data-payment-input-amount-yes]").trigger(
        "click"
      )[0].checked = true;
      this.autoSelectPaymentProcess(true);
    } else {
      this.$("[data-payment-input-amount-no]").trigger(
        "click"
      )[0].checked = true;
      this.autoSelectPaymentProcess(false);
    }

    this.creditCardFeeView.setAmount(amount);
  },

  /**
   * This method processes the change of payment option.
   *
   * @param event
   */
  processPaymentOptionChange: function(event, autoSelect) {
    this.currentAmount = parseFloat(
      $(event.currentTarget).data("payment-option-amount")
    );

    this.processPaymentAmount(this.currentAmount);

    // find the selected payment option
    var paymentOption = this.paymentOptionCollection.findWhere({
      component_id: $(event.currentTarget).val()
    });
    this.displayPaymentOptionMessage(paymentOption);

    // trigger the custom event with the selected payment option as available data to let all views know the
    // payment option has changed
    this.dispatcher.trigger("paymentOption:changed", {
      paymentOption: paymentOption ? paymentOption.toJSON() : {}
    });

    if (this.settings.externalWallet && !autoSelect) {
      this.splitPaymentCalculator();
    }
  },

  displayPaymentOptionMessage: function(paymentOption) {
    var option = paymentOption ? paymentOption.toJSON() : false;

    this.$("[data-payment-option-message]").addClass("is-hidden");

    if (option) {
      switch (option.payment_option_type.name) {
        case "NO_PAYMENT":
          this.$('[data-payment-option-message="no-payment"]').removeClass(
            "is-hidden"
          );
          break;

        case "DEPOSIT":
          this.$('[data-payment-option-message="deposit"]').removeClass(
            "is-hidden"
          );
          break;

        case "FULL_PAYMENT":
          this.$('[data-payment-option-message="full"]').removeClass(
            "is-hidden"
          );
          break;
      }
    }
  },

  /**
   * This method clears out the previously entered payment amount.
   *
   * @param event
   */
  clearPaymentAmountInput: function(event) {
    $(event.currentTarget).val(null);
  },

  /**
   * This method defaults the payment amount input if the payment option has a payment amount.
   *
   * @param event
   */
  defaultPaymentAmountInput: function(event) {
    $(event.currentTarget)
      .val($(event.currentTarget).data("payment-amount-default"))
      .trigger("keyup");
  },

  /**
   * This method unchecks the radio that was previously selected.
   *
   * @param event
   */
  clearCheckedRadio: function(event) {
    $(event.currentTarget)
      .prop("checked", false)
      .trigger("change");
  },

  autoSelectPaymentOption: function() {
    if (this.$("[data-payment-option]").length === 1) {
      this.$("[data-payment-option]").trigger("click", true);
    } else if (this.$("[data-payment-option]:checked").length === 1) {
      this.$("[data-payment-option]:checked").trigger("click", true);
      var paymentOption = this.paymentOptionCollection.findWhere({
        component_id: this.$("[data-payment-option]:checked").val()
      });

      this.displayPaymentOptionMessage(paymentOption);
      this.dispatcher.trigger("paymentOption:changed", {
        paymentOption: paymentOption ? paymentOption.toJSON() : {}
      });
    }
  },

  /**
   * This method will auto check the payment process if there is one and the check flag is true
   *
   * @param check
   */
  autoSelectPaymentProcess: function(check) {
    if (this.$("[data-payment-process]").length === 1) {
      this.$("[data-payment-process]")[0].checked = check;
      this.$("[data-payment-process]").trigger("change");
    }
  },

  /**
   * This method will auto check the payment method if there is only one payment method for the selected payment
   * process.
   *
   * @param process
   */
  autoSelectPaymentMethodType: function(process) {
    var type = process == 1 ? "electronic" : "manual";

    if (
      this.$("[data-payment-method-type-" + type + "]").length === 1 &&
      this.$("[data-payment-process-type-" + type + "]").is(":checked")
    ) {
      this.$("[data-payment-method-type-" + type + "]")[0].checked = true;
      this.$("[data-payment-method-type-" + type + "]")
        .trigger("click")
        .trigger("change");
    }
  },

  /**
   * This method proceses the selection of payment process
   *
   * @param event
   */
  processPaymentProcessSelection: function(event) {
    // get the selected payment process and fire the toggle
    this.togglePaymentMethodTypes(
      $(event.currentTarget).val(),
      $(event.currentTarget).is(":checked")
    );

    this.$("[data-payment-process-manual-message]")[
      this.$("[data-payment-process-type-manual]").is(":checked")
        ? "removeClass"
        : "addClass"
    ]("is-hidden");
  },

  /**
   * This method toggles the payment method types based on the selected payment process
   *
   * @param process
   */
  togglePaymentMethodTypes: function(process, isChecked) {
    // determine what payment process should be processed
    if (process == 1 && isChecked) {
      // deselect the manual credit card option that was selected
      this.$(
        "[data-payment-credit-card-option][data-payment-method-manual]"
      ).removeAttr("selected");

      // hide all of the manual credit card options
      this.$("[data-payment-method-manual]").addClass("is-hidden");
      this.$(
        "[data-payment-method-type-manual]:not([data-payment-method-type-electronic])"
      ).attr("disabled", true);

      // show all of the electronic credit card options
      this.$("[data-payment-method-electronic]").removeClass("is-hidden");
      this.$(
        "[data-payment-method-type-electronic]:not([data-method-disabled])"
      ).removeAttr("disabled");
    } else if (isChecked) {
      // deselect the electronic credit card option that was selected
      this.$(
        "[data-payment-credit-card-option][data-payment-method-electronic]"
      ).removeAttr("selected");

      // show all of the manual credit card options
      this.$("[data-payment-method-manual]").removeClass("is-hidden");
      this.$(
        "[data-payment-method-type-manual]:not([data-method-disabled])"
      ).removeAttr("disabled");

      // hide all of the electronic credit card options
      this.$("[data-payment-method-electronic]").addClass("is-hidden");
      this.$(
        "[data-payment-method-type-electronic]:not([data-payment-method-type-manual])"
      ).attr("disabled", true);
    } else {
      this.$("[data-payment-method-type-manual]").attr("disabled", true);
      this.$("[data-payment-method-type-electronic]").attr("disabled", true);
      this.$("[data-payment-method-type]")
        .prop("checked", false)
        .trigger("change");
    }

    // this.externalWalletAction();

    this.autoSelectPaymentMethodType(process);
  },

  /**
   * This method processes the selection of credit card type
   *
   * @param event
   */
  processCreditCardTypeSelection: function(event) {
    var selection = $(event.currentTarget).find("option:selected");

    // find the credit card type that was selected and fire the toggle
    this.toggleCreditCardFields(selection);
    this.creditCardFeeView.setCreditCard(selection.data("payment-credit-card"));
  },

  /**
   * This method handles the toggling of credit card cvv and issue date
   *
   * @param element
   */
  toggleCreditCardFields: function(element) {
    // display the credit card issue date if the selected credit card option has the appropriate data
    this.$("[data-payment-cc-issue]")[
      element.data("payment-credit-card-issue") ? "removeClass" : "addClass"
    ]("is-hidden");

    // display the credit card cvv if the selected credit card option has the appropriate data
    this.$("[data-payment-cc-cvv]")[
      element.data("payment-credit-card-cvv") ? "removeClass" : "addClass"
    ]("is-hidden");

    var ccCode = element.data("payment-credit-card-code") || "none";

    this.$("[data-payment-credit-card-number]").attr(
      "maxlength",
      ccTypes[ccCode].maxlength || ccTypes["none"].maxlength
    );
    this.$("[data-payment-credit-card-number]").attr(
      "pattern",
      ccTypes[ccCode].pattern || ccTypes["none"].pattern
    );

    if (this.$("[data-payment-credit-card-cvv]").length) {
      this.$("[data-payment-credit-card-cvv]").attr(
        "maxlength",
        ccTypes[ccCode].cvvlength || ccTypes["none"].cvvlength
      );
    }
  },

  initAddresses: function() {
    var selectedOption = this.$("[data-payment-option]:checked").val();

    // find the selected payment option
    var paymentOption = this.paymentOptionCollection.findWhere({
      component_id: selectedOption
    });

    // trigger the custom event with the selected payment option as available data to let all views know the
    // payment option has changed
    this.dispatcher.trigger("paymentOption:changed", {
      paymentOption: paymentOption ? paymentOption.toJSON() : {}
    });
  },

  /**
   * This method performs the initialization of the view.
   */
  initialize: function() {
    this.settings = {};

    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
    }

    this.dispatcher = this;

    var self = this;

    if (
      this.settings.externalWallet &&
      !this.settings.disableCalculator &&
      this.$("[data-template='corporate-card-wrapper']").length
    ) {
      this.redeemInputTimer;
      this.paymentFormWrapper = this.$el.closest("form");

      this.splitTemplate = Handlebars.compile(
        this.$("[data-template='corporate-card-wrapper']").html()
      );

      this.splitPaymentCalculator();
    }

    this.paymentOptionCollection = new Backbone.Collection(
      this.settings.paymentOptions
    );

    this.creditCardFeeView = new CreditCardFeeView({
      el: this.$("[data-credit-card-fee]")
        ? this.$("[data-credit-card-fee]")
        : "<div></div>",
      currency: this.settings.currency
    });

    this.$("[data-address-block]").each(function() {
      new AddressView({
        el: this,
        dispatcher: self.dispatcher
      });
    });

    this.$("[data-credit-card-block]").each(function() {
      new CreditCardView({
        el: this,
        dispatcher: self.dispatcher,
        settings: self.settings
      });
    });

    this.autoSelectPaymentOption();
    this.initAddresses();

    if (this.$("[data-payment-process]:checked").length) {
      this.togglePaymentMethodTypes(
        this.$("[data-payment-process]:checked").val(),
        true
      );
    }

    this.toggleCreditCardFields(
      this.$("[data-payment-credit-card-type] > option:selected")
    );

    this.$el.loadComponents();
    this.$el.loadModules();
  }
});

module.exports = PaymentView;
