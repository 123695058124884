/**
 *  * Scrollto component
 *  */
var ScrollTo = Backbone.View.extend({
  events: { click: "scroll" },
  initialize: function() {
    // init stuff done in reflow for flexibility
    this.$el.attr("tabindex", 0);

    this.reflow();
  },
  reflow: function() {
    // fallback to body and 0 in case of settings not present
    this.container = $(this.$el.data("scroll-to-container") || "html,body");
    this.target = this.$el.data("scroll-to-target") || 0;
    this.framed = this.$el.data("scroll-to-framed") || false;
    this.duration = this.$el.data("scroll-to-duration") || 1000;
    this.hide = this.$el.data("scroll-hide") || false;
    this.hideScrollDown = this.$el.data("hide-on-scroll-down") || false;
    this.offset = this.$el.data("scroll-offset") || 0;
    this.wait = this.$el.data("scroll-wait") || 0;

    this.container.attr("tabindex", 0);

    if (this.hideScrollDown) {
      //init onScrollUp when scrolling
      $(window).on("scroll." + this.cid, this.onScrollUp.bind(this));
      this.cachedScroll = 0;
    }
    return this;
  },
  getOffset: function() {
    // returns amount in pixels to scroll
    if (!_.isNumber(this.target)) {
      return this.framed
        ? $(this.target).position().top - this.offset
        : $(this.target).offset().top - this.offset;
    }
    return this.target;
  },
  scroll: function() {
    // scroll happily

    setTimeout(
      function() {
        this.container.animate({ scrollTop: this.getOffset() }, this.duration);
        this.container.trigger("focus");
      }.bind(this),
      this.wait
    );

    //if hide is true fade-out on click
    if (this.hide) {
      this.$el.removeClass("fade-in").addClass("fade-out");
    }
  },
  onScrollUp: function() {
    var currentScroll = $(window).scrollTop();
    //if scroll up fade-in
    if (currentScroll < this.cachedScroll && currentScroll > 100) {
      this.$el.removeClass("fade-out").addClass("fade-in");
    } else {
      //else fade-out
      this.$el.removeClass("fade-in").addClass("fade-out");
    }
    this.cachedScroll = currentScroll;
  }
});
module.exports = ScrollTo;
