var $ = require("jquery");
var checkboxTemplate =
  '<input class="multiselect-choice-target" type="checkbox" id="{{key}}" name="{{label}}" value="{{key}}" data-multiselect-selection="{{uid}}">';
var pilowTemplate = [
  "{{#each this}}",
  '<div class="pilow"  data-pillow-value="{{key}}" data-multiselect-pilow>{{label}}',
  '<a data-multiselect-pilow-close href="javascript:void(0)">X</a>',
  "</div>",
  "{{/each}}"
].join("\n");
var pilowContainerTemplate =
  '<div class="multiselect-pilow-container" data-multiselect-pilow-container></div>';
var dropdownTemplate = [
  "{{#each this}}",
  '<li class="multiselect-overlay-list">',
  '<label class="multiselect-overlay-list-choice"> {{> checkbox}} {{label}}</label>',
  "</li>",
  "{{/each}}"
].join("\n");

var Option = Backbone.Model.extend({
  defaults: {
    key: "",
    label: ""
  },
  initialize: function() {
    // Create a unique id
    this.set("uid", _.uniqueId("list-option-"));
  }
});
var Options = Backbone.Collection.extend({
  model: Option,

  export: function() {
    return this.toJSON();
  },
  filterOptions: function(pattern) {
    return _.filter(
      this.export(),
      function(option) {
        var str = option.label;
        return str.search(pattern) != -1;
      }.bind(this)
    );
  }
});
var Results = Backbone.Collection.extend({
  model: Option,
  export: function() {
    return this.toJSON();
  }
});
var PillowsView = Backbone.View.extend({
  initialize: function(settings) {
    this.list = settings.list;
    this.settings = settings.settings;
    this.pillows = Handlebars.compile(pilowTemplate);
    this.render();
  },
  render: function() {
    this.$el.html(this.pillows(this.list.toJSON()));
  }
});
var MultiselectView = Backbone.View.extend({
  events: {
    "click [data-multiselect-trigger]": "showOverlay",
    "click [data-multiselect-selection]": "updateData",
    "click [data-multiselect-pilow-close]": "removePilow",
    "keyup [data-multiselect-trigger]": "doAutocomplete"
  },
  initialize: function(data) {
    //Compiling commun templates
    this.dropDownCheckbox = Handlebars.compile(checkboxTemplate);
    //Commun data
    this.trigger = this.$("[data-multiselect-trigger]");
    this.$overlay = this.$("[data-multiselect-options]");
    this.count = 0;
    this.clicked = false;
    this.selections = new Results();

    //Importing settings
    this.reflow();
    //Rendering depending of the source

    this.dropDownList = Handlebars.compile(dropdownTemplate);
    Handlebars.registerPartial("checkbox", this.dropDownCheckbox);
    switch (this.source) {
      case "list":
        this.createLists(this.formatOptions());
        this.render();
        break;
      case "dom":
        this.multiselectList = this.$("[data-multiselect-list]");
        this.createLists(this.formatStaticOption());
        this.render();
        break;
    }
    this.loadCheckedOptions();
    this.checkLengthList();

    //If pillows add conditional for this and create the pillow view passing the el
    if (this.hasPillows) {
      this.multiselectPilows = Handlebars.compile(pilowContainerTemplate);
      this.$el.append(this.multiselectPilows);
      this.multiselectPilows = this.$el.find(
        "[data-multiselect-pilow-container]"
      );
      this.pillows = new PillowsView({
        el: this.multiselectPilows,
        list: this.selections
      });
      this.listenTo(
        this.selections,
        "add remove",
        this.pillows.render.bind(this.pillows)
      );
    }
    if (this.hasFilter) {
      this.listenTo(this.results, "reset", function() {
        this.render();
        this.loadSelectedOptionsFilter();
      });
    }
  },
  reflow: function() {
    var data = this.$el.data();

    //Import filter option, setting default to false
    this.hasFilter = data.multiselectFilter ? data.multiselectFilter : false;

    //Import number of inputs before triggering the search and result render, setting default to 3
    this.counterFilter = data.multiselectCounterFilter
      ? data.multiselectCounterFilter
      : 3;

    //Import pillows option, setting default to false
    this.hasPillows = data.multiselectPillows ? data.multiselectPillows : false;

    // Import name to display in input after more that 1 selection
    this.overallName = data.multiselectOverallName
      ? data.multiselectOverallName
      : "Selected";

    // Import selected elements if passed in.
    this.selected = data.multiselectSelected ? data.multiselectSelected : null;

    // Import data source.
    this.source = data.multiselectSource ? data.multiselectSource : "dom";

    // Import data source.
    this.maxSelection = data.multiselectMaxSelection
      ? data.multiselectMaxSelection
      : 200;

    if (this.source == "list") {
      // Import options if passed in.
      this.multiselectList = data.multiselectList ? data.multiselectList : null;

      // Import key element if passed in.
      this.key = data.multiselectKey ? data.multiselectKey : null;

      // Import label element if passed in.
      this.label = data.multiselectLabel ? data.multiselectLabel : null;

      //Import limit of elements to show before adding the scroll, setting defaul to 5
      this.optionsNumber = data.multiselectOptionsnNo
        ? data.multiselectOptionsnNo
        : 5;
    }
    return this;
  },
  render: function() {
    this.$overlay.html(this.dropDownList(this.results.export()));
    return this;
  },
  formatOptions: function() {
    var options = [];
    _.each(
      this.multiselectList,
      function(option) {
        options.push(
          new Option({
            label: option[this.label],
            key: option[this.key]
          })
        );
      }.bind(this)
    );
    return options;
  },
  formatStaticOption: function() {
    var options = [];
    _.each(
      this.multiselectList,
      function(option) {
        $option = $(option);
        var data = $option.data();
        options.push(
          new Option({
            label: data.multiselectLabel,
            key: data.multiselectValue.toString()
          })
        );
      }.bind(this)
    );
    this.$overlay.empty();
    return options;
  },
  loadSelectedOptionsFilter: function() {
    if (this.selections.export().length) {
      _.each(
        this.selections.export(),
        function(selection) {
          var currentSelection = this.$overlay.find(
            "#" + selection.key.toString() + ""
          );
          $(currentSelection).prop("checked", true);
          currentSelection.parent().addClass("is-selected");
        }.bind(this)
      );
    }
    return this;
  },
  loadCheckedOptions: function() {
    if (this.selected && this.selected.length <= this.maxSelection) {
      _.each(
        this.selected,
        function(selection) {
          var currentSelection = this.$overlay.find(
            "#" + selection.toString() + ""
          );
          this.selections.add(
            this.options.findWhere({ key: selection.toString() })
          );
          $(currentSelection).trigger("click");
        }.bind(this)
      );
    }
    return this;
  },
  createLists: function(options) {
    this.options = new Options(options);
    this.results = new Results(this.options.export());
  },
  checkLengthSelections: function() {
    if (this.selections.export().length >= this.maxSelection) {
      this.$el.addClass("is-complete");
      return true;
    } else {
      this.$el.removeClass("is-complete");
      return false;
    }
  },
  checkLengthList: function() {
    if (this.$overlay.children().length > this.optionsNumber) {
      this.$overlay.addClass("overlay-scroll");
      var overlayHeight = parseInt(this.optionsNumber) * 40;
      this.$overlay.css({
        "max-height": overlayHeight + "px",
        "overflow-y": "scroll"
      });
    }
  },
  showOverlay: function(e) {
    $(document).on(
      "click.dropdownClose." + this.cid,
      this.closeOverlayOutClick.bind(this)
    );
    if (this.$overlay.length && !this.clicked) {
      this.$el.addClass("arrow-up");
      this.$overlay.addClass("is-visible");
      this.clicked = true;
    } else {
      this.hideOverlay(e);
    }
  },
  hideOverlay: function() {
    this.$overlay.removeClass("is-visible");
    this.$el.removeClass("arrow-up");
    this.clicked = false;
  },
  updateData: function(e) {
    var selection = e.target;
    if (selection.checked) {
      this.count++;
      data = {
        key: selection.value,
        label: selection.name
      };
      this.selections.add(
        this.options.findWhere({ key: selection.value.toString() })
      );
      $(selection)
        .parent()
        .addClass("is-selected");
    } else {
      this.count--;
      this.selections.remove(
        this.options.findWhere({ key: selection.value.toString() })
      );
      $(selection)
        .parent()
        .removeClass("is-selected");
    }
    if (this.count > 1) {
      var inputValue = this.count + " " + this.overallName;
      this.trigger.attr("placeholder", inputValue);
    } else if (this.count === 1) {
      var selectedElement = this.selections.export();
      this.trigger.attr("placeholder", selectedElement[0].label);
    } else {
      this.trigger.attr("placeholder", "");
    }
    this.trigger.val("");

    if (this.hasFilter) {
      this.hideOverlay(e);
      this.results.reset(this.options.export());
    }
    this.checkLengthSelections();
    return this;
  },
  removePilow: function(e) {
    var option = $(e.target)
      .parent()
      .data("pillowValue")
      .toString();
    this.$overlay.find("[value=" + option + "]").trigger("click");
  },
  doAutocomplete: function() {
    var inputValue = this.trigger.val();
    if (inputValue != "") {
      var pattern = new RegExp(inputValue, "gi");
      if (inputValue.toString().length > this.counterFilter) {
        this.results.reset(this.options.filterOptions(pattern));
      }
    } else {
      this.results.reset(this.options.export());
    }
  },
  closeOverlayOutClick: function(e) {
    if (!this.$el.find(e.target).length) {
      $(document).off("click.dropdownClose." + this.cid);
      this.hideOverlay(e);
    }
  }
});
module.exports = MultiselectView;
