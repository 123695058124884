var transpiler = Backbone.View.extend({
  initialize: function(e) {
    // Select all code and transpile it into the code tab
    $("[data-tabs-content]").each(
      function(index, section) {
        let currentSection = $(section);
        if (currentSection.parents("[data-tabs-content]").length) {
          console.warn(
            "nested tabs, skipping transpiling as it's suspected to be a demo"
          );

          return;
        }
        let example = currentSection.find('[data-tabs-tab="example"]');
        let code = currentSection.find('[data-tabs-tab="code"]');
        let output = '<pre><code class="html">';
        let baseCode = code.html().trim().length ? code : example;

        output += this.transpile(baseCode.html());
        output += "</code></pre>";

        // Print transpiled code into code tab
        code.html(output);
      }.bind(this)
    );
  },

  transpile: function(code) {
    return code
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/\"/g, "&quot;");
  }
});

module.exports = transpiler;
