var $ = require("jquery");

var CreditCardView = require("./_payment.credit-card");
var CreditCardFeeView = require("./_payment.credit-card-fee");

const PaymentProcessView = require("./_payment.payment-process");
const PaymentMethodTypeView = require("./_payment.payment-method-type");

var creditCardStandaloneView = Backbone.View.extend({
  initialize: function() {
    var self = this;

    this.dispatcher = this;
    this.settings = {};

    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
      this.$el.removeAttr("data-settings");
    }

    this.paymentAmount = 0;

    new PaymentProcessView({
      el: this.$el,
      paymentMethodTypes: this.settings.paymentMethodTypes,
      dispatcher: self.dispatcher
    });

    new PaymentMethodTypeView({
      el: self.$("[data-payment-method-type-container]"),
      paymentMethodTypes: this.settings.paymentMethodTypes,
      dispatcher: self.dispatcher
    });

    var PaymentMethodTypes = new Backbone.Collection(
      this.settings.paymentMethodTypes
    );

    let creditCardContainer = self.$('[data-payment-method-row="credit_card"]');
    let creditCardCollapser = creditCardContainer.component();

    var options = {
      el: creditCardContainer,
      paymentMethodType: PaymentMethodTypes.find({
        type_label: "credit_card"
      }),
      paymentMethods: this.settings.paymentMethods["credit_card"],
      dispatcher: self.dispatcher,
      settings: self.settings
    };

    options.initDates = self.settings.initDates;

    new CreditCardView(options);

    // checking if credit card collapser is closed or open,
    // depending on it runs logic to disable or enable fields
    creditCardCollapser.isOpen()
      ? creditCardCollapser.open()
      : creditCardCollapser.close();

    this.$el.loadComponents();
    this.$el.loadModules();
  }
});

module.exports = creditCardStandaloneView;
