var GoogleAnalytics = require("../../utilities/analytics/google_analytics");
var GoogleTagManager = require("../../utilities/analytics/google_tag_manager");
var GoogleAnalyticsGTag = require("../../utilities/analytics/google_analytics_gtag");
var Bazaarvoice = require("../../utilities/analytics/bazaarvoice");

var Analytics = Backbone.View.extend({
  initialize: function() {
    $("[data-analytics-service]").each(function() {
      if ($(this).data("settings")) {
        var settings = $(this).data("settings");

        switch (settings.type) {
          case "google_analytics":
            new GoogleAnalytics(settings);
            break;

          case "google_tag_manager":
            new GoogleTagManager(settings);
            break;

          case "google_analytics_gtag":
            new GoogleAnalyticsGTag(settings);
            break;

          case "bazaarvoice":
            new Bazaarvoice(settings);
            break;
        }
      }
    });
  }
});

module.exports = Analytics;
