var PaymentOption = Backbone.Model.extend({
  idAttribute: "component_id",

  initialize: function(attributes) {
    this.set({
      type_id: attributes.payment_option_type.id,
      name: attributes.payment_option_type.type_label,
      allow_zero_amount: attributes.allow_zero_amount
    });
  }
});

var PaymentMethodAssociation = Backbone.Model.extend({
  idAttribute: "payment_method_type_id"
});

var PaymentMethodAssociationCollection = Backbone.Collection.extend({
  model: PaymentMethodAssociation
});

var PaymentMethodType = Backbone.Model.extend({ idAttribute: "id" });

var PaymentMethodTypeCollection = Backbone.Collection.extend({
  model: PaymentMethodType
});

var PaymentMethod = Backbone.Model.extend({
  idAttribute: "component_id",

  initialize: function(attributes) {
    if (attributes && attributes.payment_method_type) {
      this.set({
        type_id: attributes.payment_method_type.id,
        name: attributes.payment_method_type.type_label
      });
    }
  }
});

var PaymentMethodCollection = Backbone.Collection.extend({
  model: PaymentMethod
});

var PaymentUtility = {
  PaymentOption: PaymentOption,
  PaymentMethodAssociation: PaymentMethodAssociation,
  PaymentMethodAssociationCollection: PaymentMethodAssociationCollection,
  PaymentMethodType: PaymentMethodType,
  PaymentMethodTypeCollection: PaymentMethodTypeCollection,
  PaymentMethod: PaymentMethod,
  PaymentMethodCollection: PaymentMethodCollection,

  buildPaymentOptionChangeData: function(
    paymentOption,
    amount,
    name,
    allowZero
  ) {
    return {
      paymentOption: paymentOption,
      amount: parseFloat(amount || 0),
      ignoreAmount: name === "no_payment",
      allowZero: allowZero
    };
  },

  buildPaymentOptionChangeDataFromBillingInfo: function(billingInfo) {
    return this.buildPaymentOptionChangeData(
      new PaymentOption(billingInfo.payment_option),
      billingInfo.other_payment_amount || billingInfo.payment_option.amount,
      billingInfo.payment_option.payment_option_type.type_label,
      billingInfo.allow_zero_amount ||
        billingInfo.payment_option.allow_zero_amount
    );
  },

  buildPaymentProcessChangeData: function(paymentOption, process) {
    var processType = this.determinePaymentProcessType(process);

    return {
      paymentOption: paymentOption,
      processType: processType
    };
  },

  buildPaymentProcessChangeDataFromBillingInfo: function(billingInfo) {
    return this.buildPaymentProcessChangeData(
      new PaymentOption(billingInfo.payment_option),
      billingInfo.is_manual ? 0 : 1
    );
  },

  determinePaymentProcessType: function(process) {
    var processType = null;

    if (process === 1 || process === true) {
      processType = "electronic";
    } else if (process === 0 || process === false) {
      processType = "manual";
    }

    return processType;
  },

  buildPaymentMethodTypeChangeData: function(
    paymentOption,
    process,
    paymentMethodType
  ) {
    return {
      paymentOption: paymentOption,
      processType: this.determinePaymentProcessType(process),
      paymentMethodType: paymentMethodType
    };
  },

  buildPaymentMethodTypeChangeDataFromBillingInfo: function(billingInfo) {
    return this.buildPaymentMethodTypeChangeData(
      new PaymentOption(billingInfo.payment_option),
      billingInfo.is_manual ? 0 : 1,
      new PaymentMethodType(billingInfo.payment_method.payment_method_type)
    );
  },

  buildPaymentMethodChangeData: function(paymentMethod) {
    return {
      paymentMethod: paymentMethod
    };
  },

  buildPaymentMethodChangeDataFromBillingInfo: function(billingInfo) {
    return this.buildPaymentMethodChangeData(
      new PaymentMethod(billingInfo.payment_method)
    );
  }
};

module.exports = PaymentUtility;
