var $ = require("jquery");

var AsyncInput = Backbone.View.extend({
  getOptions: function() {
    var data = {};

    $('[data-async-input-field="' + this.id + '"]').each(function() {
      // make sure to only send data for radios and checkboxes that are checked
      if (
        ($(this).attr("type") === "radio" ||
          $(this).attr("type") === "checkbox") &&
        !$(this).is(":checked")
      ) {
        return;
      }

      data[$(this).data("async-input-field-name")] = $(this).val();

      // add data parameters into request
      if ($(this).data("async-input-field-data-params")) {
        let dataParams = $(this).data("async-input-field-data-params");

        _.each(dataParams, function(value, key) {
          data[key] = value;
        });
      }
    });

    return data;
  },

  sendRequest: function() {
    $.ajax({
      type: "GET",
      dataType: "json",
      cache: true,
      url: this.url,
      data: this.getOptions(),

      beforeSend: () => {
        this.render({ isLoading: true }, false);
        this.$el.addClass("is-loading");
        this.templateOutput.addClass("loading-text");
      },

      success: response => {
        var enable = true;

        var responseLength =
          response.data.length || Object.keys(response.data).length;

        if (!responseLength) {
          response.data = null;
          enable = false;
        }

        this.render(response, enable);
        this.$el.removeClass("is-loading");
        this.templateOutput.removeClass("loading-text");
      },

      error: () => {
        this.render();
        this.$el.removeClass("is-loading");
        this.templateOutput.removeClass("loading-text");
      }
    });
  },

  render: function(data, enable) {
    this.templateOutput.empty().html(this.template(data));
  },

  initialize: function() {
    this.id = this.$el.data("async-input-id");
    this.url = this.$el.data("async-input-url");
    this.template = Handlebars.compile(this.$("[data-template]").html() || "");
    this.templateOutput = this.$("[data-async-input-output]");

    $(document).on(
      "change",
      '[data-async-input-field="' + this.id + '"]',
      this.sendRequest.bind(this)
    );

    if (this.$el.data("async-input-auto-fetch")) {
      this.sendRequest();
    }
  }
});

module.exports = AsyncInput;
