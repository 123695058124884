var $ = require("jquery");

var PaymentUtility = require("./_payment.utility");

var PaymentOptionView = Backbone.View.extend({
  events: {
    "click [data-payment-option]": "processSelection",
    "keyup [data-payment-amount-input]": "processPaymentAmountInputChange"
  },

  initialize: function(options) {
    this.dispatcher = options.dispatcher;
    this.paymentOption = new PaymentUtility.PaymentOption(
      options.paymentOption
    );
    this.name = options.paymentOption.payment_option_type.type_label;

    this.dispatcher.on(
      "paymentOption:changed",
      this.processPaymentOptionChange.bind(this)
    );

    this.dispatcher.on(
      "initFromBillingInfo",
      this.initFromBillingInfo.bind(this)
    );
  },

  initFromBillingInfo: function(data) {
    if (
      data.billingInfo.payment_option.payment_option_type.type_label ===
      this.name
    ) {
      this.$("[data-payment-option]").attr("checked", true);

      this.$("[data-payment-amount-input]").attr("disabled", false);
    } else {
      this.$("[data-payment-option]").attr("checked", false);

      this.$("[data-payment-amount-input]").attr("disabled", true);
    }
  },

  processSelection: function() {
    this.$("[data-payment-amount-input]")
      .removeAttr("disabled")
      .val(
        this.paymentOption.get("amount")
          ? this.paymentOption.get("amount").toFixed(2)
          : this.paymentOption.get("allow_zero_amount")
            ? 0
            : null
      );

    this.announceChange();
  },

  processPaymentOptionChange: function(data) {
    if (data.paymentOption.get("name") !== this.name) {
      this.$("[data-payment-amount-input]")
        .attr("disabled", true)
        .val(null);

      this.$("[data-payment-option-message]").addClass("is-hidden");
    }
  },

  processPaymentAmountInputChange: function() {
    this.announceChange();
  },

  announceChange: function() {
    this.dispatcher.trigger(
      "paymentOption:changed",
      PaymentUtility.buildPaymentOptionChangeData(
        this.paymentOption,
        this.$("[data-payment-amount-input]").length
          ? this.$("[data-payment-amount-input]").val()
          : this.paymentOption.get("amount"),
        this.name,
        this.paymentOption.get("allow_zero_amount")
      )
    );
  }
});

module.exports = PaymentOptionView;
