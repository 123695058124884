// Read all our JS files in /components/ folder.
function requireAll(requireContext) {
  let name;
  let names = _.map(requireContext.keys(), function(component) {
    name = component.split("/")[1].split(".js")[0];
    return name;
  });

  var files = _.object(names, requireContext.keys().map(requireContext));

  // Happily removes all files prefixed with underscores
  _.each(
    requireContext.keys(),
    function(file, i) {
      if (file.substring(0, 3) === "./_") {
        delete files[file.split("/")[1].split(".js")[0]];
      }
    }.bind(this)
  );

  return files;
}

module.exports = requireAll;
