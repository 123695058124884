/*
  This component us to pull from an endpoint and print the response in its container
  it also allows us to fetch json data and build a template without needing a unique component

  Example of settings structure in template

  {
    "endPoint":{
      "base":"{{app_url workflow="car_search_utilities" action="fetch_car_makes" display_mode="JSON"}}", only pass queries
      "queries": ["name_of_query_to_pass_to_endPoint=value_to_pass"], can pass as many as needed
      "revelex" : [{"name_of_query_to_pass_to_endPoint":"name_of_property_in_REVELX"}] can pass as many as needed
    },
    "loadIfVisible": boolean,
    "loaderId": "identification_value",
    "hideSiblings": boolean //hide any div with the same loaderId,
    "fetchDataAsLoaded" : boolean , "it will load the data as soon as component loaded , irrespective of the component is visible or not"
    "updateEndPoint" : "[data-target-fields]" "pass the selector to grab all the field's value to generate the end point",
    "dataType" : "json" pass this only if you need to render json data
    "initialData": {} // initial component data, will trigger a render using the data
    "clearDataOnError: boolean // component data will be clear if there is any error, removing any previous data set
  }
*/

//Main View Container
var mainView = Backbone.View.extend({
  events: {
    "click [data-content-loader-reset]": "clearComponentData"
  },

  initialize: function() {
    // Capture settings data
    this.settings = this.$el.data("settings") || {};

    // Building model in init to prevent overwrites when more then one component sibling
    // can change this if new backbone version corrects this issue
    var ComponentData = Backbone.Model.extend({
      defaults: {
        // data is empty until fetch is complete
      }
    });

    // init data model
    this.componentData = new ComponentData(this.settings.initialData || {});

    // Listens for any change in the data model and re-renders
    this.componentData.on("change", this.$el.renderItems.bind(this));

    // Check if a value for loadIfVisible has been passed
    // If so, we use that value
    this.loadIfVisible =
      typeof this.settings.loadIfVisible != "undefined"
        ? this.settings.loadIfVisible
        : true;

    // identification for component
    this.loaderId = this.settings.loaderId ? this.settings.loaderId : false;

    // identification for component
    this.hideSiblings = this.settings.hideSiblings
      ? this.settings.hideSiblings
      : false;

    this.updateEndPoint = this.settings.updateEndPoint;

    // Get setting for type of data requesting "json or text"
    this.dataType = this.settings.dataType ? this.settings.dataType : false;

    // Get endPoint object from settings
    let endPointSettings = this.settings.endPoint || {};

    // Build end point
    this.endPoint = endPointSettings.base || "";

    // Queries built in smarty
    let queries = endPointSettings.queries ? endPointSettings.queries : false;

    // Array of objects containing parameters to get from the REVELEX global variable
    let revelexQueries = endPointSettings.revelex
      ? endPointSettings.revelex
      : false;

    // Create query string from queries array and concatenate with endPoint
    if (queries) {
      queries.forEach((query, index) => {
        let start = index > 0 ? "&" : "?";
        this.endPoint += `${start}${query}`;
      });
    }

    // Create query string from REVELEX
    if (revelexQueries) {
      // Loop through each object to build string
      revelexQueries.forEach((token, index) => {
        // If queries array is empty or is undefined, and first index
        let start = !queries && index === 0 ? "?" : "&";

        // Gets property name from object
        let query = Object.keys(token)[0];

        // Gets property value from Revelex object
        let value = REVELEX;

        // we split the value to build the full path
        token[query].split(".").forEach(key => {
          value = value[key];
        });

        // Concatenates new query to endpoint
        this.endPoint += `${start}${query}=${value}`;
      });
    }

    // Listen for click event in DOM from trigger with same loaderId value
    if (this.loaderId) {
      $(document).on(
        "click.content-loader." + this.cid,
        '[data-content-loader-trigger="' +
          this.loaderId +
          '"], [data-content-loader-trigger*="' +
          this.loaderId +
          '"]:not(.loading-content)',
        this.loadContent.bind(this)
      );
    }
    if (this.settings.fetchDataAsLoaded) {
      this.loadContent();
    }

    // If LoadIfVisible is true we check visibility on init otherwise we wait for a trigger
    if (this.loadIfVisible) {
      // Only bind the scroll event if the item is not visible.
      if (!this.checkIfVisible()) {
        // Bind namespaced scroll event so we can unbind once it loads
        $(document).on(
          "scroll.content-loader." + this.cid,
          this.checkIfVisible.bind(this)
        );
      }
    }

    // If data type is not json, load components
    // json data will be using the template render
    else if (this.dataType !== "json") {
      this.$el.loadComponents();
      this.$el.loadModules();
    }

    // render templates using initialData
    if (this.settings.initialData) {
      this.$el.loadTemplates({ data: this.componentData });
    }
  },

  createEndPoint: function(e) {
    // Build new end point
    this.endPoint = this.settings.endPoint.base || "";
    this.updateEndPointData(e);
  },

  updateEndPointData: function(e) {
    let updateEndPointEl = $(this.updateEndPoint);

    // search for the nearest [updateEndPoint] element
    if (e && e.type) {
      const parentEl = $(e.currentTarget).closest(this.updateEndPoint);
      if (parentEl.length) {
        updateEndPointEl = parentEl;
      }
    }

    let allFields = updateEndPointEl.find("input , select , textarea");
    let queryData = decodeURIComponent($(allFields).serialize());

    const tree = updateEndPointEl.find('[data-component="tree"]');
    if (tree.length) {
      queryData += queryData.length ? "&" : "" + tree.component().serialize();
    }

    this.endPoint =
      this.endPoint.indexOf("?") === -1
        ? `${this.endPoint}?${queryData}`
        : `${this.endPoint}&${queryData}`;
  },

  // Checks if component is in window view
  checkIfVisible: function() {
    if (
      this.$el.isInViewport().length &&
      !$('[data-content-loader-trigger="' + this.loaderId + '"]').hasClass(
        "content-loaded"
      )
    ) {
      // Fetch data from endPoint
      this.dataType === "json" ? this.queueFetch() : this.loadContent();

      // Remove binding from document
      $(document).off("scroll.content-loader." + this.cid);
    }

    return this.$el.isInViewport().length;
  },

  // uses async-limiter which is declared on jquery.bundle
  // global[Keys] are declared as global variables
  queueFetch: function() {
    this.$el.addClass("is-loading");
    $('[data-content-loader-trigger="' + this.loaderId + '"]').addClass(
      "loading-content"
    );

    globalQueue.push(cb => {
      globalQueueResults.push(this.fetch(cb));
    });
  },

  // Fetch data
  fetch: function(cb) {
    if (this.hideSiblings) {
      $('[data-content-loader-sibling="' + this.loaderId + '"]').removeClass(
        "is-hidden"
      );
    }
    let currentResponse = {
      endpoint: this.endPoint,
      currentLoaderId: this.loaderId
    };

    if (this.dataType !== "json") {
      // set loading state
      this.$el.addClass("is-loading");

      $('[data-content-loader-trigger="' + this.loaderId + '"]').addClass(
        "loading-content"
      );
    }

    try {
    } catch (e) {}

    fetch(this.endPoint, { credentials: "same-origin" })
      .then(response => {
        if (!response.ok) {
          // If the response status is not OK, throw an error to be caught in the catch block
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return this.dataType === "json" ? response.json() : response.text();
      })
      .then(data => {
        // add the response
        currentResponse.success = true;
        currentResponse.response = data;

        $.rvlx.cache = $.rvlx.cache || {};
        $.rvlx.cache["content-loader"] = $.rvlx.cache["content-loader"] || [];
        $.rvlx.cache["content-loader"].push(currentResponse);

        this.render(currentResponse);

        if (this.hideSiblings && !REVELEX[this.loaderId]) {
          $('[data-content-loader-sibling="' + this.loaderId + '"]').addClass(
            "is-hidden"
          );
        }

        $('[data-content-loader-trigger="' + this.loaderId + '"]')
          .addClass("is-loaded")
          .removeClass("loading-content");

        // Remove binding from document
        $(document).off("scroll.content-loader." + this.cid);

        cb();
      })
      .catch(error => {
        // report request failed
        currentResponse.success = false;
        currentResponse.response = "Unable to retrieve requested data.";
        currentResponse.responseMessages = {};
        currentResponse.responseMessages.fault =
          "Unable to retrieve requested data.";
        currentResponse.hasErrors = true;

        this.render(currentResponse);

        if (this.hideSiblings) {
          $('[data-content-loader-sibling="' + this.loaderId + '"]').addClass(
            "is-hidden"
          );
        }

        $('[data-content-loader-trigger="' + this.loaderId + '"]')
          .removeClass("loading-content")
          .addClass("content-loaded");

        cb();
      });
  },

  loadContent: function(e) {
    $.rvlx.cache = $.rvlx.cache || {};
    $.rvlx.cache["content-loader"] = $.rvlx.cache["content-loader"] || [];

    // If triggered by an event and the trigger has a validate form flag, validate form first
    if (
      e &&
      e.type &&
      _.has($(e.currentTarget).data(), "contentLoaderValidateForm")
    ) {
      const parentForm = $(e.currentTarget).parents("form");
      if (parentForm.length) {
        if (!parentForm.component().validate({ reset: false })) {
          return this;
        }
      }
    }

    // If triggered by an event and the trigger has a refresh flag, store flag
    if (
      e &&
      e.type &&
      _.has($(e.currentTarget).data(), "contentLoaderRefresh")
    ) {
      this.enforceRefresh = true;
    } else if (e && e.type && this.updateEndPoint) {
      this.createEndPoint(e);
      this.enforceRefresh = true;
    } else if (e) {
      this.enforceRefresh = false;
    }
    if (
      e &&
      e.type &&
      _.has($(e.currentTarget).data(), "contentLoaderTriggerUrl")
    ) {
      this.endPoint = $(e.currentTarget).data("contentLoaderTriggerUrl");
      this.updateEndPointData(e);
    }

    // Try to pull response from cache only if refresh is not enforced
    var content = this.enforceRefresh
      ? false
      : _.findWhere($.rvlx.cache["content-loader"], {
          endpoint: this.endPoint
        });

    if (content) {
      this.render(content);
    } else {
      this.queueFetch();
    }

    return this;
  },

  render: function(data) {
    switch (this.dataType) {
      case "json":
        // We wait until we get a response to loads Templates
        // in order to avoid re-rendering
        this.$el.loadTemplates({
          data: this.componentData
        });

        this.$el.removeClass("is-loading");

        // sadly handling messages was an afterthought
        // this will take care of any messages

        if (data.response.messages) {
          data.response.data.hasErrors = !!data.response.messages.fault.length;
          data.response.data.hasMessages = !!data.response.messages.general
            .length;
          data.response.data.responseMessages = data.response.messages;
        }

        // clear previous data from componentData if there is an error
        if (this.settings.clearDataOnError && data.response.data.hasErrors) {
          this.componentData.clear().set(this.settings.initialData || {});
        }

        // Setting response data to the data model
        this.componentData.set(data.response.data);
        break;

      default:
        this.$el.html(data.response).removeClass("is-loading");

        // Trigger event after rendering
        this.$el.trigger("content-loaded");
        break;
    }

    // Initialized Modules and Components after population
    this.$el.loadComponents();
    this.$el.loadModules();

    if (this.settings.scrollTop) {
      this.handleScroll();
    }

    if (this.$el.parents("form").length) {
      let parentForm = this.$el.parents("form").component();
      parentForm.reflow();
    }

    return this;
  },

  handleScroll: function() {
    if (_.isNumber(this.scrollTop)) {
      offset = this.scrollTop;
    } else {
      offset = this.$el.offset().top - 20;
    }

    $("html,body").animate(
      {
        scrollTop: offset
      },
      600
    );
  },

  clearComponentData: function() {
    this.componentData.clear().set(this.settings.initialData || {});
  }
});

module.exports = mainView;
