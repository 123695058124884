var $ = require("jquery");

var FCCView = require("./_payment.future-cruise-credit");
var VoucherView = require("./_payment.voucher");
var CheckView = require("./_payment.check");
var CreditCardView = require("./_payment.credit-card");
var CreditCardFeeView = require("./_payment.credit-card-fee");
var AddressView = require("./_payment.address");

var PaymentOptionView = require("./_payment.payment-option");
var PaymentProcessView = require("./_payment.payment-process");
var PaymentMethodTypeView = require("./_payment.payment-method-type");
var PaymentMethodView = require("./_payment.payment-method");

var ExternalHackView = require("./_payment.external-hack");

var PaymentUtility = require("./_payment.utility");

var UpliftView = require("./_payment.uplift");

var BillingInfo = Backbone.Model.extend({
  defaults: {
    payment_option: {
      payment_option_type: {
        id: null,
        type_label: null
      }
    },
    payment_method: {
      payment_method_type: {
        id: null,
        type_label: null
      },
      payment_method_associations: []
    },
    billing_address: {}
  }
});

var PaymentView = Backbone.View.extend({
  initialize: function() {
    var self = this;

    this.dispatcher = this;
    this.settings = {};

    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
    }

    this.paymentAmount = 0;

    _.each(this.settings.paymentOptions, function(option) {
      new PaymentOptionView({
        el: self.$(
          '[data-payment-option-container="' + option.component_id + '"]'
        ),
        paymentOption: option,
        billingInfo: self.settings.billingInfo,
        dispatcher: self.dispatcher
      });
    });

    new PaymentProcessView({
      el: this.$el,
      paymentMethodTypes: this.settings.paymentMethodTypes,
      dispatcher: self.dispatcher
    });

    new PaymentMethodTypeView({
      el: self.$("[data-payment-method-type-container]"),
      paymentMethodTypes: this.settings.paymentMethodTypes,
      dispatcher: self.dispatcher
    });

    var PaymentMethodTypes = new Backbone.Collection(
      this.settings.paymentMethodTypes
    );

    _.each(this.settings.paymentMethods, function(methods, type) {
      var options = {
        el: self.$('[data-payment-method-row="' + type + '"]'),
        paymentMethodType: PaymentMethodTypes.find({ type_label: type }),
        paymentMethods: methods,
        dispatcher: self.dispatcher
      };

      if (type === "check") {
        new CheckView(options);
      } else if (type === "credit_card") {
        options.initDates = self.settings.initDates;

        new CreditCardView(options);
      } else if (type === "uplift") {
        new UpliftView(options);
      } else if (type === "future_cruise_credit") {
        new FCCView(options);
      } else if (type === "voucher") {
        new VoucherView(options);
      } else {
        new PaymentMethodView(options);
      }
    });

    new CreditCardFeeView({
      el: self.$("[data-credit-card-fee]"),
      currency: self.settings.currency,
      dispatcher: self.dispatcher
    });

    this.$("[data-address-block]").each(function() {
      new AddressView({
        el: this,
        dispatcher: self.dispatcher
      });
    });

    if (this.settings.externalWallet && !this.settings.disableCalculator) {
      new ExternalHackView({
        el: this.$el,
        settings: this.settings,
        dispatcher: self.dispatcher
      });
    }

    this.$el.loadComponents();
    this.$el.loadModules();

    this.dispatcher.on(
      "paymentOption:changed",
      this.processPaymentOptionChange.bind(this)
    );

    this.dispatcher.on(
      "paymentProcess:changed",
      this.processPaymentProcessChange.bind(this)
    );

    this.dispatcher.on(
      "initFromBillingInfo",
      this.initFromBillingInfo.bind(this)
    );

    if (this.settings.billingInfo && this.settings.billingInfo.payment_option) {
      var billingInfo = new BillingInfo(this.settings.billingInfo);

      this.dispatcher.trigger("initFromBillingInfo", {
        billingInfo: billingInfo.toJSON()
      });
    } else {
      this.$("[data-payment-option]:checked").trigger("click");
    }
  },

  initFromBillingInfo: function(data) {
    this.processPaymentOptionChange(
      PaymentUtility.buildPaymentOptionChangeDataFromBillingInfo(
        data.billingInfo
      )
    );

    this.processPaymentProcessChange(
      PaymentUtility.buildPaymentProcessChangeDataFromBillingInfo(
        data.billingInfo
      )
    );

    this.processPaymentMethodTypeChange(
      PaymentUtility.buildPaymentMethodTypeChangeDataFromBillingInfo(
        data.billingInfo
      )
    );
  },

  processPaymentOptionChange: function(data) {
    this.$("[data-payment-option-message]").addClass("is-hidden");

    if (data.paymentOption) {
      this.$(
        '[data-payment-option-message="' + data.paymentOption.get("name") + '"]'
      ).removeClass("is-hidden");
    }
  },

  processPaymentProcessChange: function(data) {
    this.$("[data-payment-process-manual-message]").addClass("is-hidden");

    if (
      data.processType === "manual" &&
      data.paymentOption.get("name") !== "no_payment"
    ) {
      this.$("[data-payment-process-manual-message]").removeClass("is-hidden");
    }
  },

  processPaymentMethodTypeChange: function(data) {
    this.$("[data-payment-method-container]").addClass("is-hidden");

    if (data.paymentMethodType) {
      this.$("[data-payment-method-container]").removeClass("is-hidden");
    }
  }
});

module.exports = PaymentView;
