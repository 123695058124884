/**
 * Field Masks Library
 * description  : Meant to work with jquery.inputmask
 *
 * Documentation regarding creating custom masks can be found here:
 * https://github.com/RobinHerbots/jquery.inputmask
 *
 * Basic Masking:
 *  - 9 : numeric
 *  - a : alphabetical
 *  - * : alphanumeric
 *
 * Once declared, custom masks can be invoked using data attributes inside
 * form components. IE:
 *
 *  <input type="text" data-mask="phone-us" required />
 */

var MasksLibrary = {
  "phone-us": "(999) 999 9999", // Phone US Validator pattern
  "alphanumeric-only": {
    mask: "[*]{0,999}",
    greedy: false
  },
  "alpha-only": {
    mask: "[a]{0,999}",
    greedy: false
  },
  "numbers-only": {
    mask: "[9]{0,999}",
    greedy: false
  },
  decimal: {
    alias: "numeric",
    placeholder: "0",
    autoGroup: !0,
    digits: 2,
    digitsOptional: !1,
    clearMaskOnLostFocus: !1,
    rightAlign: !1
  }
};

module.exports = MasksLibrary;
