// this is used if there's any mention of a
// point widget on the search results page

// setting up the default for the point Widget
var Points = Backbone.Model.extend({
  defaults: {
    currentValue: 0,
    min: 0,
    max: 0,
    step: 0
  }
});

var SearchResultsPointForm = Backbone.View.extend({
  /**
   * maxInputValue will trigger current Value to set itself to the max
   */

  maxInputValue: function() {
    this.pointsModel
      .set({ currentValue: this.pointSettings.maxAllowed })
      .trigger("change");
  },

  validateInput: function(e) {
    let currentValue = e.jquery ? $(e).val() : $(e.currentTarget).val();

    // removing any unwanted character
    currentValue = currentValue.replace(/\D/g, "");

    return this.roundValue(currentValue, "currentValue");
  },

  roundValue: function(currentValue, key) {
    // if there are no value on input after replacing
    if (currentValue === "") {
      currentValue = 0;
    }

    let formattedValue = parseInt(currentValue);

    // determining if is part of the steps
    let absValue = Math.abs(formattedValue);
    let step = Math.abs(this.pointSettings.step);

    // Determining the numbers on either side of value
    let low = absValue - (absValue % step);
    let high = low + step;

    // Return the closest one, multiplied by -1 if value < 0
    // formattedValue = absValue - low < high - absValue ? low : high;
    formattedValue = low;

    if (formattedValue > this.pointSettings.max) {
      formattedValue = this.pointSettings.max;
    } else if (formattedValue < this.pointSettings.min) {
      formattedValue = this.pointSettings.min;
    }

    this.pointsModel.set(key, formattedValue).trigger("change");
    return formattedValue;
  },

  /**
   * Method to change value on point Input
   * Uses validateInput to check for limits
   * @param e
   * @param isKey
   */

  amountSelector: function(e, isKey) {
    //getting values
    let currentStepper = $(e.currentTarget);
    let currentStepperValue = currentStepper.attr(
      "data-points-stepper-trigger"
    );

    //checking if it was a key press on the input
    // and determine if its between bounderies

    if (isKey) {
      currentStepperValue = e.keyCode;
      if (currentStepperValue === 40 || currentStepperValue === 38) {
        this.validateInput(e);
      }
    } else {
      // if it's a click on the stepper arrows
      // make the command to turn into a number

      currentStepperValue = parseInt(currentStepperValue);
    }

    // get the target and the value
    let currentTarget = this.currentContainer.find("[data-points-amount]");
    let currentValue = currentTarget.val();

    // take the display out of the number for manipulation
    currentValue = parseInt(currentValue.replace(/,/g, ""));

    if (currentStepperValue === 40) {
      currentTarget.val(currentValue - this.pointSettings.step);
    } else if (currentStepperValue === 38) {
      currentTarget.val(currentValue + this.pointSettings.step);
    }

    // validate again that the final number is still
    // accurate and in between boundaries

    if (isKey) {
      if (currentStepperValue === 40 || currentStepperValue === 38) {
        this.validateInput(currentTarget);
        this.currentForm.find("[data-points-amount]").trigger("focus");
      }
    } else {
      this.validateInput(currentTarget);
    }
  },

  initialize: function(options) {
    let currentElement = options.el;
    this.currentContainer = options.container;

    this.currentForm = this.currentContainer.find("[data-points-input-form]");

    this.pointSettings = this.currentContainer.data("settings");

    // set Default Data plus set new data from settings
    this.pointsModel = new Points().set(this.pointSettings);

    this.isValid = false;

    //Loads Templates
    currentElement.$el.loadTemplates({
      data: this.pointsModel
    });

    this.pointsModel.on(
      "change",
      currentElement.$el.renderItems.bind(currentElement)
    );

    this.pointSettings.maxAllowed = this.roundValue(
      this.pointSettings.maxAllowed,
      "maxAllowed"
    );

    this.pointsModel.set({ maxAllowed: this.pointSettings.maxAllowed });

    this.currentContainer.on("click", "[data-input-max-trigger]", e => {
      this.maxInputValue(this.pointSettings);
    });

    this.currentContainer.on("blur focusout", "[data-points-amount]", e => {
      this.validateInput(e);
    });

    this.currentContainer.on("keydown", "[data-points-amount]", e => {
      this.amountSelector(e, true);
    });

    this.currentContainer.on("click", "[data-points-stepper-trigger]", e => {
      this.amountSelector(e);
    });
  }
});

module.exports = SearchResultsPointForm;
