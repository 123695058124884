var $ = require("jquery");
var UpliftUtil = require("../../utilities/uplift");

var PaymentMethodView = require("./_payment.payment-method");

var UpliftView = PaymentMethodView.extend({
  initialize: function(options) {
    PaymentMethodView.prototype.initialize.call(this, options);

    console.log("init uplift");

    this.tokenTimeout = null;

    this.settings = {
      agentInfo: {
        onChange: this.processOnChange.bind(this),
        sessionTokenCallback: UpliftUtil.processSessionTokenCallback.bind(this)
      }
    };

    if (this.$("[data-uplift-container]").data("settings")) {
      this.settings = $.extend(
        true,
        this.settings,
        this.$("[data-uplift-container]").data("settings")
      );
    }

    console.log("init settings");
    console.log(this.settings);

    this.orderAmount = this.settings.tripInfo.order_amount;

    if (this.settings.isB2B) {
      UpliftUtil.loadB2B(this.settings.code);

      this.initB2B();
    } else {
      UpliftUtil.loadB2C(this.settings.code);

      this.initB2C();
    }

    this.dispatcher.on(
      "paymentOption:changed",
      this.processPaymentOptionChange.bind(this)
    );

    window.onunload = function() {
      if (this.UpliftObj) {
        console.log("onunload deselect");
        this.UpliftObj.deselect();
      }
    }.bind(this);
  },

  processPaymentOptionChange: function(data) {
    console.log("process payment option change");

    var paymentAmount = data.amount * 100;

    console.log("trip info " + this.settings.tripInfo.order_amount);
    console.log("payment option " + paymentAmount);

    if (
      this.settings.isB2B &&
      this.UpliftObj &&
      paymentAmount > 0 &&
      paymentAmount !== this.orderAmount
    ) {
      console.log("payment option change update amount");
      this.settings.tripInfo.order_amount = paymentAmount;
      this.orderAmount = paymentAmount;

      console.log(this.settings.tripInfo);

      const tripId = this.getTripId();

      console.log(tripId);

      if (tripId) {
        console.log("update trip");
        this.UpliftObj.updateTrip(tripId, this.settings.tripInfo);
      } else {
        console.log("create trip");
        this.UpliftObj.createTrip(this.settings.tripInfo);
      }
    }
  },

  processPaymentMethodTypeChange: function(data) {
    console.log("process payment method type change");
    console.log(data);
    PaymentMethodView.prototype.processPaymentMethodTypeChange.call(this, data);

    if (
      data.paymentMethodType.get("type_label") !==
      this.paymentMethodType.get("type_label")
    ) {
      this.$("[data-payment-uplift-trip]").attr("disabled", true);
      this.$("[data-payment-uplift-token]").attr("disabled", true);
      if (this.UpliftObj) {
        console.log("deselect");
        this.UpliftObj.deselect(data.paymentMethodType.get("name"));
      }
    } else {
      if (this.UpliftObj) {
        console.log("select");
        this.UpliftObj.select();
      }
    }
  },

  processSelection: function(event) {
    console.log("process selection");
    console.log(event);
    console.log(this.UpliftObj);

    PaymentMethodView.prototype.processSelection.call(this, event);

    if (this.currentStatus !== "SERVICE_UNAVAILABLE") {
      console.log("unhide and enable");
      this.$el.removeClass("is-hidden");
      this.$("[data-payment-uplift-trip]").attr("disabled", false);
      this.$("[data-payment-uplift-token]").attr("disabled", false);
    }

    if (this.UpliftObj) {
      console.log("select");
      this.UpliftObj.select();
    }
  },

  initB2B: function() {
    console.log("b2b init");

    window.upReady = function() {
      console.log("b2b upready");
      console.log(this.settings.agentInfo);

      this.UpliftObj = UpliftUtil.initB2B(this.settings, true);

      const tripId = this.getTripId();

      if (tripId) {
        console.log("update trip");
        this.UpliftObj.updateTrip(tripId, this.settings.tripInfo);
      } else {
        console.log("create trip");
        this.UpliftObj.createTrip(this.settings.tripInfo);
        console.log("load");
        this.UpliftObj.load(this.settings.tripInfo);
      }
    }.bind(this);
  },

  initB2C: function() {
    console.log("b2c init");

    window.upReady = function() {
      console.log("b2c upready");
      this.UpliftObj = UpliftUtil.initB2C(this.settings, true);

      this.UpliftObj.load(this.settings.tripInfo);
    }.bind(this);
  },

  processOnChange: function(data) {
    console.log("onchange");
    console.log(data);

    var self = this;

    this.currentStatus = data.status;

    if (data.tripId) {
      console.log("store trip id");
      this.$("[data-payment-uplift-trip]").val(data.tripId);
      window.sessionStorage.setItem(
        "uplift-" + this.settings.id,
        JSON.stringify({ tripId: data.tripId })
      );
    }

    switch (data.status) {
      case "OFFER_AVAILABLE":
        this.dispatcher.trigger("paymentMethodType:available", {
          paymentMethodType: this.paymentMethodType
        });

        break;

      case "OFFER_UNAVAILABLE":
        this.dispatcher.trigger("paymentMethodType:available", {
          paymentMethodType: this.paymentMethodType
        });

        this.dispatcher.trigger("paymentMethodType:disable", {
          paymentMethodType: this.paymentMethodType
        });

        $("[data-uplift-from-price]").css({ display: "none" });
        $("[data-uplift-unavailable]").css({ display: "block" });

        break;

      case "TOKEN_AVAILABLE":
        console.log("get token");
        this.UpliftObj.getToken();

        // add a timeout to check that the token has been retrieved and retry if not
        this.tokenTimeout = setTimeout(function() {
          if (!self.$("[data-payment-uplift-token]").val().length) {
            self.UpliftObj.select();
          }
        }, 5000);
        break;

      case "TOKEN_RETRIEVED":
        console.log("store token");
        this.$("[data-payment-uplift-token]").val(JSON.stringify(data.token));

        clearTimeout(this.tokenTimeout);
        break;

      case "SERVICE_UNAVAILABLE":
        this.dispatcher.trigger("paymentMethodType:unavailable", {
          paymentMethodType: this.paymentMethodType
        });
        break;
    }
  },

  getTripId: function() {
    console.log("get trip id " + this.settings.id);

    var tripId = UpliftUtil.getTripId(this.settings.id);

    console.log(tripId);

    if (tripId) {
      console.log("return id");
      if (!this.$("[data-payment-uplift-trip]").val()) {
        this.$("[data-payment-uplift-trip]").val(tripId);
      }
      return tripId;
    }

    console.log("return null");
    return null;
  }
});

module.exports = UpliftView;
