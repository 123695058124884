var CalendarDropdown = Backbone.View.extend({
  events: {
    "change [data-calendar-dropdown-month] [data-dropdown-selection]":
      "updateMonth",
    "change [data-calendar-dropdown-year] [data-dropdown-selection]":
      "updateYear",
    "change [data-calendar-dropdown-day] [data-dropdown-selection]": "updateDay"
  },

  initialize: function() {
    this.settings = {
      format: "MM/DD/YYYY",
      locale: "en-US",
      startDate: null,
      endDate: null
    };

    this.id = this.$el.data("dropdownCalendarId");

    // Set moment locale
    moment.locale(this.settings.locale);
    moment.locale(this.settings.format);
    this.localeData = moment.localeData();

    this.dayDropdown = this.$el.find("[data-calendar-dropdown-day]");
    this.yearDropdown = this.$el.find("[data-calendar-dropdown-year]");
    this.monthDropdown = this.$el.find("[data-calendar-dropdown-month]");

    this.reflow();

    this.monthValue = parseInt(this.initialDate.format("M"));
    this.dayValue = parseInt(this.initialDate.format("D"));
    this.yearValue = this.initialDate.get("y");

    //Render Day
    this.dayDropdownView = this.renderDate(
      this.dayDropdown,
      1,
      31,
      false,
      this.dayValue
    ).component();

    //Render Year
    this.yearDropdownView = this.renderDate(
      this.yearDropdown,
      this.settings.startDate.get("y"),
      this.settings.endDate.get("y"),
      false,
      this.yearValue
    ).component();

    //Render Month
    this.monthDropdownView = this.renderDate(
      this.monthDropdown,
      0,
      11,
      true,
      this.monthValue
    ).component();

    //Render hidden input
    this.render();
    this.prepopulateCalendar();
  },
  reflow: function() {
    this.defineSettings();

    //taking the name of the hidden input
    this.calendarName = this.$el.data("dropdown-calendar-name");
    return this;
  },
  defineSettings: function() {
    //Getting the data attributes fron the calendar dropdown component
    var data = this.$el.data();

    //Defining settings
    this.settings.format = data.dropdownCalendarFormat || this.settings.format;
    this.settings.locale = data.dropdownCalendarLocale || this.settings.locale;
    this.settings.startDate =
      moment(data.dropdownCalendarStartDate, this.settings.format) ||
      moment()
        .startOf("year")
        .subtract(5, "y");
    this.settings.endDate =
      moment(data.dropdownCalendarEndDate, this.settings.format) ||
      moment()
        .endOf("year")
        .add(5, "y");
    this.initialDate = moment(data.dropdownCalendarValue, this.settings.format);
  },

  render: function() {
    //Adding the hidden input and defining the name
    this.$el.append("<input hidden data-calendar-dropdown>");
    this.inputCalendar = this.$("[data-calendar-dropdown]");
    this.inputCalendar.attr("name", this.calendarName);
    var inputAttributes = {
      "data-validate-group": this.calendarName
    };

    //Add attributes
    this.$("[data-dropdown-selection]").attr(inputAttributes);

    return this;
  },
  prepopulateCalendar: function() {
    if (this.initialDate.isValid()) {
      this.createDate(this.initialDate, true);
    }
  },
  formatDate: function(init, end) {
    //Format year and day to create the list of objects to initialize the dropdown component
    var itemArray = [];
    for (var i = init; i <= end; i++) {
      itemArray.push({ value: i, label: i });
    }
    return itemArray;
  },
  formatMonth: function(init, end) {
    //Format month to create the list of objects to initialize the dropdown component
    var monthArray = moment.months();
    var itemArray = [];
    for (var i = init; i <= end; i++) {
      itemArray.push({ value: i + 1, label: monthArray[i] });
    }
    return itemArray;
  },
  renderDate: function(element, start, end, isMonth, isSelected) {
    //Initialize the dropdown with the data attributes needed.
    var itemArray = isMonth
      ? this.formatMonth(start, end)
      : this.formatDate(start, end);
    var calendarElement = element.data({
      dropdownList: itemArray,
      dropdownKey: "value",
      dropdownLabel: "label",
      dropdownSelected: isSelected
    });
    return calendarElement;
  },
  isInRange: function(dateToCheck) {
    //Check if a passed date is between the start date and end date defined in the data attributes
    return dateToCheck.isValid()
      ? dateToCheck.isBetween(
          this.settings.startDate,
          this.settings.endDate,
          null,
          "[]"
        )
      : false;
  },
  getRange: function() {
    //Return and object when the year selected is the start year or end year and create the object
    //passing the information needed for each one of the cases
    var currentYear = this.yearDropdown.find("[data-dropdown-selection]").val();
    if (
      currentYear != "" &&
      currentYear == this.settings.startDate.get("year")
    ) {
      return {
        month: this.settings.startDate.get("month"), //Display the month needed for the logic (Getting the range or order to render and generate the list)
        start: this.settings.startDate.get("month"), //Start month for rendering
        end: 11, //End month for rendering
        startDay: this.settings.startDate.get("date"), //Start day dor rendering the day dropdown
        endDay: moment(this.settings.startDate)
          .endOf("month")
          .get("D"), //End day dor rendering the day dropdown
        update: true, //If month and day dropdown need to be updated
        isStart: true, //If the year selected is the start year
        isValidMonth: function(currentMonth) {
          //Return if the month selected is valid by comparing with the start month
          return moment(currentMonth - 1).isSameOrAfter(
            this.settings.startDate.get("month")
          );
        }.bind(this)
      };
    } else if (
      currentYear != "" &&
      currentYear == this.settings.endDate.get("year")
    ) {
      return {
        month: this.settings.endDate.get("month"),
        start: 0,
        end: this.settings.endDate.get("month"),
        startDay: 1,
        endDay: this.settings.endDate.get("date"),
        update: true,
        isValidMonth: function(currentMonth) {
          return moment(currentMonth - 1).isSameOrBefore(
            this.settings.endDate.get("month")
          );
        }.bind(this)
      };
    } else {
      return {
        update: false
      };
    }
  },
  updateMonth: function(e) {
    var currentMonth = this.monthDropdown
      .find("[data-dropdown-selection]")
      .val();

    if (
      this.monthDropdownView &&
      this.monthDropdownView.selected != currentMonth
    ) {
      this.updateDay(e);
    }
  },
  updateDay: function(e) {
    //If we clicked on the dropdown to open it, we let dropdown.js highlight it (focus) without triggering updateDay
    if (
      e &&
      this.dayDropdownView &&
      $(e.target).val() == this.dayDropdownView.selected
    ) {
      return false;
    }

    //Getting data if the year selected is in the limits(start or end)
    var monthValue = this.getRange();

    //Getting the input values for month, day and year
    var currentMonth = this.monthDropdown
      .find("[data-dropdown-selection]")
      .val();
    var currentDay = this.dayDropdown.find("[data-dropdown-selection]").val();
    var currentYear = this.yearDropdown.find("[data-dropdown-selection]").val();

    //Updating day dropdown (list and value)
    if (currentMonth) {
      //checking if the month selected is the limit, if it is update the day dropdown list.
      if (monthValue.update && currentMonth - 1 == monthValue.month) {
        validDay = monthValue.endDay || validDay;
        this.renderDate(this.dayDropdown, monthValue.startDay, validDay);
        //checking if the day selected is valid, if not update the day to a valid one
        if (
          currentDay != "" &&
          (validDay < currentDay ||
            (monthValue.update &&
              monthValue.isStart &&
              currentDay < monthValue.startDay))
        ) {
          this.createDate(null);
        }
      } else {
        //Getting the maximal valid value for the day depending of the month and the year.
        var validDay =
          currentYear != ""
            ? parseInt(
                moment()
                  .year(currentYear)
                  .month(currentMonth - 1)
                  .endOf("month")
                  .format("D")
              )
            : parseInt(
                moment()
                  .month(currentMonth - 1)
                  .endOf("month")
                  .format("D")
              );
        this.renderDate(this.dayDropdown, 1, validDay);
      }
      // Getting dropdown ready to populate new options
      if (this.dayDropdownView.$el.find("[data-dropdown-options-wrapper]")) {
        // Deleting old dropdown options
        this.dayDropdownView.$el
          .find("[data-dropdown-options-wrapper]")
          .empty();
      }
      // Populating new options, reflow dropdown component
      this.dayDropdownView.reflow();

      //Create date if all inputs are filled
      if (currentDay != "" && currentYear != "") {
        var currentDate = currentMonth + "/" + currentDay + "/" + currentYear;
        this.createDate(currentDate);
      }
    }
  },
  updateYear: function(e) {
    var currentYear = this.yearDropdown.find("[data-dropdown-selection]").val(),
      currentDay = this.dayDropdown.find("[data-dropdown-selection]").val();

    if (
      this.yearDropdown.find("[data-dropdown-selection]").val().length == 4 &&
      (this.yearDropdownView && this.yearDropdownView.selected != currentYear)
    ) {
      //Getting data if the year selected is in the limits(start or end)
      var monthValue = this.getRange();

      //Getting the input value for month
      var currentMonth = this.monthDropdown
        .find("[data-dropdown-selection]")
        .val();
      //Update the month if the year selected is in the limits(start or end)
      if (monthValue.update) {
        this.renderDate(
          this.monthDropdown,
          monthValue.start,
          monthValue.end,
          true
        );
        this.monthDropdownView.reflow();

        //checking if the month selected is invalid delete the inputCaledar
        if (currentMonth != "" && !monthValue.isValidMonth(currentMonth)) {
          this.createDate();
        } else if (
          currentMonth != "" &&
          monthValue.isValidMonth(currentMonth)
        ) {
          //Else it's valid now check if the day is valid
          var currentDate = currentMonth + "/" + currentDay + "/" + currentYear;
          this.createDate(currentDate);
        }
      } else {
        //Render the month dropdown to the original list (without restrictions)
        this.renderDate(this.monthDropdown, 0, 11, true);
        if (this.monthDropdownView) {
          this.monthDropdownView.reflow();
        }

        //Update day dropdown
        this.updateDay();
      }
    }
  },
  createDate: function(currentDate, overrideValidation) {
    var currentDate = moment(currentDate, this.settings.format);

    //Check the date is between the range and filled the hidden input with the date using the specified format
    if (this.isInRange(currentDate)) {
      this.inputCalendar.val(currentDate.format(this.settings.format));
      this.$el.attr("data-is-valid", true);

      //Add attributes to validate only if all fields were selected
      this.$("[data-dropdown-selection]").each(
        function(i, select) {
          select.dataset["validateDataNames"] = "validDateOfBirth" + this.id;
          select.dataset["validDateOfBirth" + this.id] = true;
        }.bind(this)
      );
    } else {
      this.inputCalendar.val("");
      this.$el.attr("data-is-valid", false);

      this.$("[data-dropdown-selection]").each(
        function(i, select) {
          select.dataset["validateDataNames"] = "validDateOfBirth" + this.id;
          select.dataset["validDateOfBirth" + this.id] = false;
        }.bind(this)
      );
    }

    //Validate current calendar block
    //By default the form validation is going to reset and clear out all the errors on all other calendar blocks that might be invalid
    if (!overrideValidation) {
      this.$el
        .parents("form")
        .component()
        .validate({ group: this.inputCalendar.attr("name") });
    }

    //If there are still calendar blocks that are invalid we find them and validate them one by one without resetting the form validation
    var calendarGroups = [];
    $('[data-is-valid="false"]').each(
      function(index, group) {
        this.$el
          .parents("form")
          .component()
          .validate({
            reset: false,
            group: $(group).data("dropdownCalendarName")
          });
      }.bind(this)
    );

    return this;
  }
});
module.exports = CalendarDropdown;
