var $ = require("jquery");

var Swapper = Backbone.View.extend({
  events: {
    "click [data-swapper-attr-swapper]": "swapAttributes",
    "click [data-swapper-content-swapper]": "swapContent",
    "change [data-swapper-content-select]": "swapContent"
  },

  attrSwappee: null,
  contentSwappee: null,

  swapAttributes: function(event) {
    var swapper = $(event.currentTarget);

    if (swapper.is("select")) {
      this.attrSwappee.attr(
        swapper.find("option:selected").data("swapper-attr-swapper")
      );
    } else {
      this.attrSwappee.attr(swapper.data("swapper-attr-swapper"));
    }

    this.$("[data-swapper-attr-swapper].is-active")
      .not(swapper)
      .removeClass("is-active");
    swapper.addClass("is-active");
  },

  swapContent: function(event) {
    var swapper = $(event.currentTarget);

    if (swapper.is("select")) {
      this.contentSwappee.html(
        swapper.find("option:selected").data("swapper-content-swapper")
      );
      this.swapAttributes(event);
    } else {
      this.contentSwappee.html(swapper.data("swapper-content-swapper"));
    }

    this.$("[data-swapper-content-swapper].is-active")
      .not(swapper)
      .removeClass("is-active");
    swapper.addClass("is-active");
  },

  initialize: function() {
    this.attrSwappee = this.$("[data-swapper-attr-swappee]");
    this.contentSwappee = this.$("[data-swapper-content-swappee]");

    this.$("[data-component]").each(function() {
      $(this).component();
    });
  }
});

module.exports = Swapper;
