// templates are moved to a different js
// to separate logic from templates

const NotificationDialogTemplates = require("./_notification-dialog.template");

const NotificationDialog = Backbone.View.extend({
  initTemplates: function() {
    this.templates = {
      confirmationContent: Handlebars.compile(
        NotificationDialogTemplates.confirmationDialog
      )
    };

    this.$el.append(
      Handlebars.compile(NotificationDialogTemplates.confirmationDialogWrapper)
    );
  },

  open: function(messages) {
    let confirmationDialog = this.$("[data-confirmation-modal]")
      .html(this.templates.confirmationContent(messages))
      .component();

    // open confirmation dialog
    confirmationDialog.reflow().open();

    confirmationDialog.currentModal.on("click", "[data-dialog-action]", () => {
      // redirect if there is a redirectUrl set
      if (this.settings.redirectUrl) {
        $(location).attr("href", this.settings.redirectUrl);

        // display loading splash when doing redirect
        Backbone.Events.trigger("loadingSplashOn");
      } else {
        confirmationDialog.close();
      }
    });
  },

  initialize: function(options) {
    this.settings = options.settings;

    if (this.$el.data("settings")) {
      this.settings = $.extend(
        true,
        this.settings,
        eval(this.$el.data("settings"))
      );
    }

    this.initTemplates();
  }
});

module.exports = NotificationDialog;
