/**
 *  Modify search widget Common functionality
 *  Be careful as this functionality helps to filter vendors and ships depending on the type
 *  Plus is being used as parent view on other modules
 */

let stateModel = Backbone.Model.extend({});

/**
 * @description View that controls modify-search behavior,
 * Settings structure expected if some component data will change depending on interactions with the UI:
 * (To be reviewed)
 *   data-settings = '{
          "filterParams": {                               @description Object that contains key elements to let the module know what and when to filter
          "event":"change",                               @description Event we will listen to in order to render module components using filtered data
          "target":"[data-search-type]",                  @description DOM element on which the event will be triggered
          "value":"cruise_tour",                          @description Value expected to use filtered data
          "lists": "data-param-vendors,data-param-ships", @description list of selectors to grab selection-list components which data will be filtered
          "criteria" : {                                  @description Object with a specific list of Model attributes keys to be filtered and value to filter by
            "key": "has_tours",
            "value": true
          }
        },
        "data": {                                         @description Data used to create Model attributes
          "marketingCode":{{$search_params.marketing_code|json_encode}},
          "planCode":{{$search_params.plan_codes|json_encode}}
        }
      }'
 */

let SearchController = Backbone.View.extend({
  events: {
    "click [data-reset]": "reset",
    "click [data-reset-all]": "resetAll",
    "click [data-search-type-trigger]": "filterTypeState"
  },
  initialize: function(options) {
    // Stores data from module DOM

    this.settings = {
      filterParams: {
        hasDefaultCriteria: false
      },
      data: []
    };

    this.toggleModifyView();

    // resize event to control filter behavior
    $(window).on("resizeView", this.toggleModifyView.bind(this));
    $(window).on("orientationchange", this.toggleModifyView.bind(this));

    this.isApple = /(macintosh|ipod|iphone|ipad)/.test(navigator.userAgent);

    const data = this.$el.data();

    if (data.settings) {
      this.settings = $.extend(true, this.settings, data.settings);
    } else {
      return "Module settings not found";
    }

    if (this.settings.cruise_tour_type) {
      this.$("[data-search-type-tour]").trigger("click");
    }

    // Search Model
    this.searchState = new stateModel(this.settings.data);

    if (this.settings.filterParams.target) {
      this.searchState.set(
        "target",
        this.el.querySelector(`${this.settings.filterParams.target}:checked`)
          .value
      );
    }

    // Removes settings from DOM
    this.el.removeAttribute("data-settings");

    //If we have filter data
    if (this.settings.filterParams) {
      // Adds event we will listen to for filtering
      let currentEvent = `${this.settings.filterParams.event} ${
        this.settings.filterParams.target
      }`;

      if (typeof this.events !== "function") {
        this.events[currentEvent] = "filterParams";
      } else {
        $(document).on(
          `${this.settings.filterParams.event}`,
          `${this.settings.filterParams.target}`,
          this.filterParams.bind(this)
        );
      }

      // Resetting events delegation
      this.delegateEvents();
    }

    // Saves all components in module for future use
    this.components = Array.prototype.slice
      .call(
        this.el.querySelectorAll(
          "[data-component]:not([data-component='collapser'])"
        )
      )
      .map(node => $(node).component());

    // Saves native elements marked with `data-search-native-element` in the module
    this.nativeElem = this.el.querySelectorAll("[data-search-native-element]");

    // Saves lists that depend on external events for data filtering
    if (this.settings.filterParams.lists) {
      const targetSelectors = this.settings.filterParams.lists.split(",");
      let componentLists = targetSelectors.map(selector => {
        let match = null;
        this.components.forEach(component => {
          if (component.el.hasAttribute(selector)) {
            match = component;
            return;
          }
        });
        return match;
      });
      this.searchState.set("listComponents", componentLists);

      // if there's a default criteria, sort for it
      if (this.settings.filterParams.hasDefaultCriteria) {
        this.changeData(this.searchState.get("listComponents"), false, true);
      }
    }

    // Filter specified lists when default selected search_type is cruise_tour
    if (this.searchState.get("target") === this.settings.filterParams.value) {
      // @params: list of elements and `clearSelections` flag
      this.changeData(this.searchState.get("listComponents"), false);
    }
  },

  toggleModifyView: function() {
    if ($('[data-collapser-id="modify-search-collapser"]').length) {
      let modifyCollapser = $(
        this.el.closest('[data-collapser-id="modify-search-collapser"]')
      );

      let currentViewport = window.screen.width;

      if (window.orientation === -90 || window.orientation === 90) {
        currentViewport = window.screen.height;
      }

      if (currentViewport <= 767) {
        // B2C modify search should be closed by default
        modifyCollapser.component().close();
        modifyCollapser.removeClass("is-expanded");
      } else if (!Boolean(modifyCollapser.attr("data-modify-widget-hidden"))) {
        modifyCollapser.component().open();
        this.$el.removeAttr("style").removeClass("is-hidden");
        modifyCollapser.addClass("is-expanded");
      }
    }
  },

  /**
   * Function that handles when to use filtered data or not
   * @param {Event} e
   * @returns {this}
   */
  filterParams: function(e) {
    // Filter lists records when selected target value is equal to expected criteria value
    // @params lists of targets, clear selections
    if (e?.currentTarget?.value === this.settings.filterParams.value) {
      this.changeData(this.searchState.get("listComponents"), true);
    } else {
      // Reset records to initial status
      // @params lists of targets, clear selections, reset to initial
      this.changeData(this.searchState.get("listComponents"), true, true);
    }
  },

  /**
   * Function that manages when to show filtered selection lists data or default data
   * @param {String} newTargetValue
   */
  changeData: function(lists = [], clear = true, reset = false) {
    lists.forEach(component => {
      if (reset) {
        // Reset original list records
        // @params: key, value, clear selections, reset to initial
        component.selectionsData.filterRecordsByExternalController(
          null,
          null,
          clear,
          reset
        );

        if (component.settings.defaultCriteria?.key) {
          component.selectionsData.filterRecordsByExternalController(
            component.settings.defaultCriteria.key,
            component.settings.defaultCriteria.value,
            false,
            false
          );
        }
      } else {
        // Filters list records
        // @params: key, value, clear selections, reset to initial
        component.selectionsData.filterRecordsByExternalController(
          this.settings.filterParams.criteria.key,
          this.settings.filterParams.criteria.value,
          clear,
          reset
        );
      }
    });
    return this;
  },

  /**
   * @description Calls Reset function in the component targeted
   * E.g. Stepper does not have a reset trigger built in its template
   */
  reset: function(e) {
    let target = e.currentTarget.getAttribute("data-reset");
    $(target)
      .component()
      .reset();
  },

  /**
   * @description Each component has its own Reset function
   * resetAll will call reset function on every component and clear native elements value
   */
  resetAll: function() {
    //Reset all component found in this module
    this.components.forEach(component => {
      component.reset();
    });

    // Clear native elements found in the module
    this.nativeElem.forEach(element => {
      if (typeof element !== "undefined") {
        // Reset select tags
        if (element.tagName === "SELECT") {
          element.selectedIndex = 0;
          $(element).trigger("change");
        } else if (element.type === "checkbox" || element.type === "radio") {
          // Un-check checkboxes and radios
          element.checked = false;
        } else {
          // Clear other elements
          element.value = "";
        }
      }
    });
  },

  /**
   * Function that handles active state of search-filter-type trigger
   * @param {Event} e
   * @returns {this}
   */
  filterTypeState: function(e) {
    this.$("[data-search-type-trigger]").removeClass("is-active");
    $(e.currentTarget).addClass("is-active");
  }
});

module.exports = SearchController;
