var GoogleTagManager = Backbone.View.extend({
  initialize: function(options) {
    this.settings = options;

    Backbone.Events.on("publishAnalyticsData", this.publishData.bind(this));

    if (this.settings.send_events) {
      $(document).on("click", "[data-analytics-id]", this.sendEvent.bind(this));
    }
  },

  publishData: function(data) {
    if (data.google_tag_manager) {
      if (data.google_tag_manager.page_data.dataAsDomObj) {
        window[data.google_tag_manager.page_data.dataAsDomObj] =
          data.google_tag_manager.page_data.data;
      } else {
        (
          window[data.google_tag_manager.account_name || "dataLayer"] ||
          (window[data.google_tag_manager.account_name || "dataLayer"] = [])
        ).push(data.google_tag_manager.page_data.data);
      }
    }
  },

  sendEvent: function(event) {
    var data = {
      label: $(event.currentTarget).data("analyticsId"),
      category: this.settings.event_category || this.settings.page,
      action: this.settings.event_action || "click",
      event: this.settings.event_event || "click"
    };

    (
      window[this.settings.account_name || "dataLayer"] ||
      (window[this.settings.account_name || "dataLayer"] = [])
    ).push(data);
  }
});

module.exports = GoogleTagManager;
