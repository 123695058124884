var $ = require("jquery");

var DataDuplicator = Backbone.View.extend({
  /**
   * The internal event listeners for the view.
   */
  events: {
    "click [data-duplicator-apply-all]": "duplicateSelections"
  },

  duplicateSelections: function(e) {
    var self = this;
    var group = $(e.currentTarget).attr("data-duplicator-apply-all");
    var duplicateData = self.$('[data-duplicator="' + group + '"]');

    _.each(duplicateData, function(field) {
      self.duplicateField(field);
    });
  },

  duplicateField: function(field) {
    var self = this;
    var duplicateName = $(field).attr("data-duplicator-name");
    var els = self
      .$('[data-duplicator-name="' + duplicateName + '"]')
      .not(field);
    var duplicateDependant = $(field).attr("data-duplicator-slave");

    switch ($(field).attr("data-duplicator-type")) {
      case "value":
        els.val($(field).val()).trigger("change");

        break;

      case "select":
        let duplicateValue = $(field).val();

        let currentOption = els.find("option[value=" + duplicateValue + "]");
        els.find("option").removeAttr("selected");

        if (currentOption.length) {
          currentOption.prop("selected", true).attr("selected", true);
        }

        els.val(duplicateValue).trigger("change");

        break;

      case "checkbox":
        if ($(field).is(":checked")) {
          els.prop("checked", true).trigger("change");
        } else {
          els.prop("checked", false).trigger("change");
        }
        break;

      case "radio":
      case "radioClick":
        let currentValue = $(field).val();

        if ($(field).is(":checked")) {
          // in order to check a radio group
          // a search needs to be made to match values between duplicated areas
          els.each(function(index, item) {
            let $item = $(item);
            if ($item.val() === currentValue) {
              $item.prop("checked", true).trigger("change");

              return false;
            }
          });
        }
        break;

      case "link":
        // Checking if the parent is field replicator
        let isFieldReplicator = els.parents(
          "[data-component='field-replicator']"
        );
        let hasExternalTrigger;

        // Checking if there are externalTriggers
        if (
          isFieldReplicator.length &&
          isFieldReplicator.component().settings &&
          isFieldReplicator.component().settings.hasExternalTrigger
        ) {
          hasExternalTrigger = true;
        }

        // If there are externalTriggers is present avoid mimicking clicks on adds or removals
        if (!hasExternalTrigger) {
          for (var x = 1; x <= $(field).attr("data-duplicator-count"); x++) {
            els.click();
          }
        }
        break;

      case "component":
        var component = $(field).component();
        var duplicateData = component.getDuplicateData();

        els.each(function() {
          var elComponent = $(this).component();

          elComponent.setDuplicateData(duplicateData);
        });

        break;
    }

    if (duplicateDependant) {
      _.each(
        self.$('[data-duplicator-dependant="' + duplicateDependant + '"]'),
        function(slave) {
          self.duplicateField(slave);
        }
      );
    }
    //blur each duplicated field
    els.blur();
  },
  initialize: function() {
    this.$el.loadComponents();
    this.$el.loadModules();
  }
});

module.exports = DataDuplicator;
