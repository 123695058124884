/**
 * Date and time picker
 * @date 9/24/15
 *
 * Updates for CNM only
 * @date 03/12/19
 */

var OutputView = require("../../utilities/output-view");

var momentMasks = require("./../../utilities/libraries/moment-locale");

var OutputViewCalendar = OutputView.extend({
  onComponentDetach: function() {
    this.component.closeCalendar();
  },
  onComponentAttach: function() {
    this.component.openCalendar();
  }
});

/**
 * Calendar module
 * This module is encompassed in one view
 */
var CalendarView = Backbone.View.extend({
  /**
   * Default element is an empty div
   */
  el: "<div></div>",

  /**
   * Initialization
   * Loads default settings, and merges with passed in `options.settings`
   * Sets moment locale
   * @param Object options
   */
  initialize: function(args) {
    // click events not recognized on some iphone versions
    this.clickHandler = "click";

    var options = {},
      data = this.$el.data();

    //Model created on initialize to avoid sharing data through model prototype
    var CalendarStatus = Backbone.Model.extend({
      defaults: {
        selected_date: null,
        current_month: null,
        current_year: null,
        is_month_select: false,
        is_year_select: false,
        months: [],
        select_months: {},
        select_years: {},
        has_error: false,
        settings: {
          // locale for calendar (Webpack uses currentLocale Camelcase)
          locale:
            REVELEX && REVELEX.settings && REVELEX.settings.currentLocale
              ? REVELEX.settings.currentLocale.replace("_", "-")
              : "en-US",

          // type (single, start, end, linked)
          type: "single",

          // ordinal for linked calendars
          ordinal: false,

          // name for use in global namespace, pass custom name for start/end calendars
          name: null,

          // highlight content of the input field when focus
          select_on_focus: true,

          // enable, false to disable
          is_enabled: true,

          //Month selection control
          search_by_month: false,

          //Maximum months range selectable
          month_selection_max: 6,

          // datepicker
          date: false,
          show_date: true,
          show_calendars: 1,
          show_calendars_mobile: 1,
          show_year_select: true,
          date_min: false,
          date_max: false,
          date_display_format: "L",
          date_input_format: "MM/DD/YYYY",
          date_output_format: "MM/DD/YYYY",
          date_input_mask: true,
          date_auto_render: true,
          date_year_select_range: 12,
          date_template_selector: "[data-item]",
          date_trigger_selector: "[data-calendar-trigger]",
          date_trigger_active_class: "is-active",
          date_calendar_error_active: "has-error",
          date_calendar_target: "[data-calendar-target]",
          date_input_target: "[data-calendar-input]",
          date_output_target: "[data-calendar-output]",
          date_render: false,
          date_difference: {
            amount: 2,
            max: "6",
            max_amount: "months",
            unit: "days"
          },
          allow_primary_offset: false
        }
      }
    });

    this.calendarStatus = new CalendarStatus();

    data.settings
      ? _.extend(options, data.settings)
      : (options = args.settings);

    // Set calendar instance settings, using clone to avoid modifying default settings
    this.settings = this.calendarStatus.get("settings");
    _.extend(this.settings, options);

    // Save active view in session or retrieve it if already exists
    // 0 -> Month selection view, 1 -> Day selection view
    if (sessionStorage.getItem("default-calendar-active-view") !== null) {
      this.settings.default_view_active = parseInt(
        sessionStorage.getItem("default-calendar-active-view")
      );
    } else {
      sessionStorage.setItem(
        "default-calendar-active-view",
        this.settings.default_view_active
      );
    }

    //Activating search by month on calendar status
    if (this.settings.search_by_month && !this.settings.default_view_active) {
      this.calendarStatus.set("is_month_select", true);
    }

    // Set moment locale
    moment.locale(momentMasks(this.settings.locale));

    // Set state
    this.state = {
      selected_date: null,
      current_month: null,
      current_year: null,
      is_month_select: false,
      is_year_select: false,
      months: [],
      select_months: {},
      select_years: {}
    };

    // Prepare global namespace
    window.REVELEX = window.REVELEX || {};
    window.REVELEX.Calendars = window.REVELEX.Calendars || {};

    // Set into global namespace
    if (this.settings.name) {
      window.REVELEX.Calendars[this.settings.name] =
        window.REVELEX.Calendars[this.settings.name] || {};
      if (this.settings.type === "single") {
        window.REVELEX.Calendars[this.settings.name] = this;
      } else if (this.settings.type === "linked" && this.settings.ordinal) {
        window.REVELEX.Calendars[this.settings.name][
          this.settings.ordinal
        ] = this;
      } else if (this.settings.type === "start") {
        window.REVELEX.Calendars[this.settings.name].start = this;
      } else if (this.settings.type === "end") {
        window.REVELEX.Calendars[this.settings.name].end = this;
      }
    } else {
      this.settings.name = this.generateName();
      while (typeof window.REVELEX.Calendars[this.settings.name] === "object") {
        this.settings.name = this.generateName();
      }
      window.REVELEX.Calendars[this.settings.name] = this;
    }

    // Compile templates, set state
    this.setElements().setState();

    //Mobile lazy loading controls
    if (window.screen.width <= 767) {
      //Loads more months when scroll reaches bottom or top
      this.lazyLoaderActive = true;
      //adding 2 calendars to actual amount to display
      this.settings.show_calendars_mobile += 2;
    }

    //If anything other than a number was passed on show_months, we use 1 instead
    this.settings.show_calendars =
      typeof this.settings.show_calendars !== "number"
        ? 1
        : this.settings.show_calendars;

    // If it's iPhone or Android, set show_months to show_calendars_mobile
    if (
      navigator.userAgent.match(/Android/i) != null ||
      navigator.userAgent.match(/iPhone/i) != null ||
      window.screen.width <= 1023
    ) {
      this.settings.show_calendars = this.settings.show_calendars_mobile;
    }

    //if search_by_month and is_month_select are active, render months instead of weeks
    this.settings.search_by_month && this.calendarStatus.get("is_month_select")
      ? this.setSelectMonths()
      : this.setMonths();

    //Load templates
    this.$el.loadTemplates({ data: this.calendarStatus, cid: this.cid });

    //Bind events
    this.bindEvents();

    //Android keyboard input bug fix
    var ua = navigator.userAgent,
      chrome = /chrome/i.test(ua),
      android = /android/i.test(ua);

    if (chrome && android) {
      this.$el.find('input[type="text"]').removeAttr("maxlength");
      this.$el
        .find('input[type="text"]')
        .inputmask(this.settings.date_input_mask, { reverse: true });
    }
    //End of Android fix//
  },

  updateSettings: function(newSettings) {
    this.settings = _.extend(this.settings, newSettings);

    this.setState();
    this.renderDate();
    if (this.elements.$date_input) {
      this.elements.$date_input.trigger("keyup");
    }
    return this;
  },

  /**
   * Default event handlers
   */
  events: {
    "click [data-next-month]": "handleNextMonth",
    "click [data-prev-month]": "handlePrevMonth",
    "click [data-next-year]": "handleNextYear",
    "click [data-prev-year]": "handlePrevYear",
    "click [data-next-year-range]": "handleNextYearRange",
    "click [data-prev-year-range]": "handlePrevYearRange",
    "click [data-day]": "handleSelectDay",
    "click [data-select-day]": "handleSelectDaily",
    "click [data-select-month]": "handleSelectMonth",
    "click [data-select-year]": "handleSelectYear",
    "click [data-month]": "handleMonth",
    "click [data-year]": "handleYear",
    "click [data-calendar-close]": "close",
    scroll: "calendarLazyLoader"
  },

  reset: function() {
    //Setting default date if it's found in settings else null
    let defaultDate = this.settings.reset_date
      ? moment(this.settings.reset_date, this.settings.date_input_format)
      : null;

    this.calendarStatus.set({
      selected_date: defaultDate,
      current_month:
        defaultDate && moment.isMoment(defaultDate)
          ? moment([defaultDate.year(), defaultDate.month()])
          : moment(),
      current_year:
        defaultDate && moment.isMoment(defaultDate)
          ? moment([defaultDate.year()])
          : moment(),
      selected_range: {}
    });

    this.setDate(defaultDate, false, true, null, true);

    //Sets ranges to default
    if (this.settings.type === "start" || this.settings.type === "end") {
      this.setSelectedRanges();
    }
  },

  close: function(e) {
    if (this.settings.outputView) {
      //Output view function that controls visibility and attach/detach events
      this.elements.$date_calendar.toggleOutput(e);
      this.isOpen = false;
    } else {
      this.closeCalendar();
    }
  },

  /**
   * Generates a random name for the calendar, if not set
   * @returns {string} name
   */
  generateName: function() {
    return Math.random()
      .toString(36)
      .slice(2);
  },

  /**
   * Bind base events
   * @returns {Calendar} this
   */
  bindEvents: function() {
    // Render when month or day is changed
    this.calendarStatus.on({
      "change:current_month": this.handleChangeMonth.bind(this),
      "change:selected_date": this.handleChangeDate.bind(this),
      "change:months change:select_months change:select_years": this.$el.renderItems.bind(
        this
      )
    });

    // Render when select view is changed
    this.on({
      "change:calendar_select": this.$el.renderItems.bind(this)
    });

    //If settings are updated by another component or module, reset component
    this.calendarStatus.on({ "changed:settings": this.reset.bind(this) });
    if (this.elements.$date_trigger) {
      this.bindTrigger();
    }
    if (this.elements.$date_input) {
      this.bindInput();
    }
    return this;
  },

  /**
   * Method to be overridden to attach custom events
   * Need to be called directly after overriding
   * @returns {Calendar} this
   */
  bindAdditionalEvents: function() {
    return this;
  },

  openCalendar: function(e) {
    //For a calendar with start and end date, if the start date is empty, and the user clicks on the end date,
    // the focus will be sent by default to the start date.
    if (
      this.settings.type == "end" &&
      window.REVELEX.Calendars[
        this.settings.name
      ].start.elements.$date_trigger.val() == ""
    ) {
      var linked_calendar = window.REVELEX.Calendars[this.settings.name].start;
      if (this.settings.outputView) {
        linked_calendar.triggerElement.click();
      } else {
        linked_calendar.elements.$date_trigger.focus();
        linked_calendar.openCalendar();
      }
      return false;
    }
    if (!this.isOpen) {
      if (this.elements.$date_calendar) {
        if (this.settings.outputView) {
          this.renderDate();
        } else {
          this.elements.$date_calendar.addClass(
            this.settings.date_trigger_active_class
          );
        }
      }
      this.isOpen = true;
      var focusOn = this.elements.$date_input || this.elements.$date_trigger;
      if (focusOn.length && !this.settings.outputView) {
        focusOn.focus();
      }
    }
    return this;
  },

  closeCalendar: function(e) {
    if (this.isOpen && !this.settings.outputView) {
      this.elements.$date_calendar.removeClass(
        this.settings.date_trigger_active_class
      );
      this.isOpen = false;
    } else if (this.settings.outputView) {
      this.isOpen = false;
    }
    return this;
  },

  /**
   * Bind calendar triggers
   * @returns {Calendar} this
   */
  bindTrigger: function() {
    if (this.elements.$date_trigger && this.elements.$date_calendar) {
      // Bind trigger
      this.elements.$date_trigger.on(
        "focus click",
        function(e) {
          if (this.settings.is_enabled) {
            this.openCalendar(e);
          }
        }.bind(this)
      );

      // Bind hide calendar
      if (!this.settings.outputView) {
        this.$el.on(
          "focusout",
          function(e) {
            if (this.settings.is_enabled) {
              // Wrap in timeout for browser behavior differences
              setTimeout(
                function() {
                  if (this.$el.find(":focus").length === 0) {
                    // Trigger blur event on input field so it gets validated when calendar is focused out.
                    this.closeCalendar();
                  }
                }.bind(this),
                0
              );
            }
          }.bind(this)
        );
      }

      if (this.settings.hide_on_mouseleave) {
        this.$el.on(
          "mouseleave",
          function(e) {
            //hide calendar on mouseleave if hide_on_mouseleave option is true
            this.closeCalendar();
          }.bind(this)
        );
      }
    }

    return this;
  },

  /**
   * Bind date input
   * @returns {Calendar} this
   */
  bindInput: function() {
    if (this.elements.$date_input && this.settings.date_input_mask) {
      this.elements.$date_input.on("keypress", this.handleInput.bind(this));
      this.elements.$date_input.on("keyup", this.handleInputEnd.bind(this));
    }

    return this;
  },

  /**
   * Handles date input
   * @param e
   * @returns {bool}
   */
  handleInput: function(e) {
    var $control = $(e.target);
    var position = false;

    // Get input cursor position
    if (typeof $control[0].selectionStart !== "undefined") {
      position = $control[0].selectionStart;
    } else if (window.document.selection) {
      var range = window.document.selection.createRange();
      range.moveStart("character", -$control[0].value.length);
      position = range.text.length;
    }

    return true;
  },

  /**
   * Handles end of input
   * @param e
   * @returns {boolean}
   */
  handleInputEnd: function(e) {
    //No keyboard interaction when month selection is active
    if (
      this.settings.search_by_month &&
      this.calendarStatus.get("is_month_select")
    ) {
      return false;
    }

    var $control = $(e.target),
      // replace everything is not 0-9 , - , . or /
      currentValue = e.target.value.replace(/[^0-9\-\.|\/]/g, ""),
      rawValue = e.target.value.replace(/[^0-9]/g, ""),
      mask = this.settings.date_input_format,
      selectionSpot = $control[0].selectionStart,
      currentDate = this.calendarStatus.get("selected_date")
        ? this.calendarStatus
            .get("selected_date")
            .format(this.settings.date_input_format)
        : false,
      changed = false;

    if (currentValue == currentDate) {
      return true;
    }

    // process calendar output when the date is null or when the date matches the format
    if (
      !currentValue ||
      currentValue.length == this.settings.date_input_format.length
    ) {
      var r = this.setDate(currentValue, true, false, true);

      if (r) {
        changed = true;
      } else {
        $control.focus();
      }
    }

    if (changed) {
      this.closeCalendar();

      // If it's a linked calendar and it's start, open end
      if (this.settings.type == "start") {
        var linked_calendar = window.REVELEX.Calendars[this.settings.name].end;
        if (linked_calendar) {
          linked_calendar.openCalendar();
        }
      }
    }

    // Force position to be at the end by clearing the field and adding the value again (hack for android)
    if (currentValue.length != this.settings.date_input_format.length) {
      // if position is already at the end do not assign the the rawValue to the field
      $control[0].value = "";
      $control[0].value = rawValue;
    }

    return true;
  },

  /**
   * Sets jQuery elements from settings so that we do not have to do a check every time
   * @returns {Calendar} this
   */
  setElements: function() {
    this.elements = {};

    // Date template
    if (
      this.settings.date_template_selector &&
      this.$(this.settings.date_template_selector).length > 0
    ) {
      this.elements.$date_template = this.$(
        this.settings.date_template_selector
      );
    }

    // Date trigger
    if (
      this.settings.date_trigger_selector &&
      this.$(this.settings.date_trigger_selector).length > 0
    ) {
      this.elements.$date_trigger = this.$(this.settings.date_trigger_selector);
    }

    // Calendar display
    if (
      this.settings.date_calendar_target &&
      this.$(this.settings.date_calendar_target).length > 0
    ) {
      if (this.settings.outputView) {
        this.settings.id = this.settings.name + "-" + this.settings.type;
        this.settings.trigger =
          '[data-calendar-trigger="' + this.settings.id + '"]';

        this.settings.hideCloseOutputTrigger = true;

        this.elements.$date_calendar = new OutputViewCalendar({
          component: this,
          settings: this.settings
        });
      } else {
        this.elements.$date_calendar = this.$(
          this.settings.date_calendar_target
        );
      }
    }

    // Date input
    if (
      this.settings.date_input_target &&
      this.$(this.settings.date_input_target).length > 0
    ) {
      this.elements.$date_input = this.$(this.settings.date_input_target);
    } else if (
      this.settings.date_input_target &&
      $('[data-calendar-input="' + this.settings.date_input_target + '"]')
        .length
    ) {
      this.elements.$date_input = $(
        '[data-calendar-input="' + this.settings.date_input_target + '"]'
      );
    }

    // Date output
    if (
      this.settings.date_output_target &&
      this.$(this.settings.date_output_target).length > 0
    ) {
      this.elements.$date_output = this.$(this.settings.date_output_target);
    } else if (
      this.settings.date_output_target &&
      $('[data-calendar-output="' + this.settings.date_output_target + '"]')
        .length
    ) {
      this.elements.$date_output = $(
        '[data-calendar-output="' + this.settings.date_output_target + '"]'
      );
    }

    // Add jquery mask
    if (
      this.elements.$date_input &&
      this.settings.date_input_mask &&
      !this.settings.search_by_month
    ) {
      var format = this.settings.date_input_format;
      var mask = this.settings.date_input_format.replace(/\w/g, 9);
      this.elements.$date_input.inputmask(mask, { placeholder: format });
    }

    return this;
  },

  /**
   * Handles when month is changed
   * @returns {Calendar} this
   */
  handleChangeMonth: function() {
    this.render();
    return this;
  },

  /**
   * Handles when date is changed
   * If calendar is part of start/end/linked calendars, update as appropriate
   * @returns {Calendar} this
   */
  handleChangeDate: function(data) {
    // Flags for if we need to update a linked calendar
    var has_linked_calendar = false;
    var linked_calendar = null;

    //Set global selected day timestamp
    if (this.settings.type === "single") {
      this.calendarStatus.set(
        "day_selected",
        moment(this.calendarStatus.get("selected_date")).unix()
      );
    }

    // If is a start calendar, check if it has an end calendar
    if (
      this.settings.name &&
      this.settings.type === "start" &&
      window.REVELEX.Calendars[this.settings.name] &&
      window.REVELEX.Calendars[this.settings.name].end
    ) {
      if (!this.calendarStatus.get("selected_date")) {
        this.render();
        return this;
      }

      let endCalendar = window.REVELEX.Calendars[this.settings.name].end;

      has_linked_calendar = true;
      linked_calendar = endCalendar;

      //If selected start date is greater than selected end date
      //move end date to match start date
      if (
        moment(endCalendar.calendarStatus.get("selected_date")).isBefore(
          this.calendarStatus.get("selected_date")
        )
      ) {
        endCalendar.calendarStatus.set({
          selected_date: moment(this.calendarStatus.get("selected_date")).endOf(
            "day"
          ),
          silent: true
        });
      }

      //END Calendar range
      let range = {
        start: moment(this.calendarStatus.get("selected_date")).unix(),
        end: endCalendar.calendarStatus.get("selected_date")
          ? moment(endCalendar.calendarStatus.get("selected_date")).unix()
          : -1
      };

      //Keeps calendars in syncrony
      this.calendarStatus.set("selected_range", range);
      endCalendar.calendarStatus.set("selected_range", range);

      // Setting end calendar current month to match start calendar
      // and render same months grid for both
      if (
        this.calendarStatus.get("current_month") !==
        endCalendar.calendarStatus.get("current_month")
      ) {
        endCalendar.calendarStatus.set({
          current_month: this.calendarStatus.get("current_month"),
          silent: true
        });
      }
      //render items
      endCalendar.setMonths();
    }

    // If has linked calendar, check it and push back start date if needed (and current calendar has a date)
    if (
      has_linked_calendar &&
      linked_calendar &&
      (this.state.selected_date || this.settings.date_min)
    ) {
      // Update linked calendar minimum date
      linked_calendar.settings.date_min = this.settings.allow_primary_offset
        ? moment(this.settings.date_min).add(
            this.settings.date_difference.amount,
            this.settings.date_difference.unit
          )
        : moment(this.settings.date_min);

      // If linked calendar date is before this calendar date, set linked calendar date to
      //  `date_difference` amount of days after this calendar date
      if (linked_calendar.settings.fill_linked_date) {
        var new_date = moment(this.settings.date_min).add(
          linked_calendar.settings.date_difference.amount,
          linked_calendar.settings.date_difference.unit
        );

        if (linked_calendar.settings.date_difference.max) {
          // temporary max date
          var linked_calendar_max_temp = moment(this.settings.date_min).add(
            linked_calendar.settings.date_difference.max,
            linked_calendar.settings.date_difference.maxUnit
          );

          linked_calendar.settings.date_max = linked_calendar_max_temp.isBefore(
            this.settings.date_max
          )
            ? linked_calendar_max_temp
            : linked_calendar.settings.date_max;
        }

        // If new end date isn't selectable (i.e. goes past maximum date), try to set to maximum date and fall back to minimum date
        if (linked_calendar.isSelectable(new_date)) {
          linked_calendar.state.selected_date = new_date;
        } else {
          if (
            linked_calendar.settings.date_max &&
            linked_calendar.isSelectable(linked_calendar.settings.date_max)
          ) {
            linked_calendar.state.selected_date = moment(
              linked_calendar.settings.date_max
            );
          } else {
            linked_calendar.state.selected_date = moment(
              this.settings.date_min
            );
          }
        }
      } else {
        linked_calendar.state.selected_date = null;
      }

      // This will to support the max gap between two linked calendars if set by offset_max
      linked_calendar.settings.date_max =
        this.settings.date_difference.max && this.state.selected_date
          ? moment(this.state.selected_date)
              .add(1, "day")
              .add(this.settings.date_difference.max, "months")
              .startOf("day")
          : this.settings.date_max;

      if (
        linked_calendar.settings.date_max.isAfter(
          moment(this.settings.date_max).startOf("day")
        )
      ) {
        linked_calendar.settings.date_max = this.settings.date_max;
      }

      // Change end calendar current month
      linked_calendar.state.current_month = linked_calendar.state.selected_date
        ? moment(linked_calendar.state.selected_date).startOf("month")
        : moment(linked_calendar.settings.date_min).startOf("month");
      linked_calendar.state.current_year = linked_calendar.state.selected_date
        ? moment(linked_calendar.state.selected_date).startOf("year")
        : moment(linked_calendar.settings.date_min).startOf("year");

      // Trigger changed date event on end calendar
      linked_calendar.trigger("changed:date", {
        date: linked_calendar.state.selected_date
          ? moment(linked_calendar.state.selected_date).format(
              linked_calendar.settings.date_output_format
            )
          : null
      });
    }

    // If is an end calendar, re-render start calendar
    if (
      this.settings.name &&
      this.settings.type === "end" &&
      window.REVELEX.Calendars[this.settings.name] &&
      window.REVELEX.Calendars[this.settings.name].start
    ) {
      // Get start calendar
      var start_calendar = window.REVELEX.Calendars[this.settings.name].start;
      //END Calendar range
      let range = {
        start: start_calendar.calendarStatus.get("selected_date")
          ? moment(start_calendar.calendarStatus.get("selected_date")).unix()
          : -1,
        end: moment(this.calendarStatus.get("selected_date")).unix()
      };

      //Keeps calendars in syncrony
      this.calendarStatus.set("selected_range", range);
      start_calendar.calendarStatus.set("selected_range", range);

      //Re-render calendar
      start_calendar.setMonths();
    }

    //Month view deactivation
    if (
      !this.settings.search_by_month &&
      this.calendarStatus.get("is_month_select")
    ) {
      this.calendarStatus.set("is_month_select", false);
    }
    this.calendarStatus.set("is_year_select", false);
    this.render();
    return this;
  },

  /**
   * Method to increase current month
   * Triggers `changed:month` event
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleNextMonth: function(e) {
    var month = moment(this.calendarStatus.get("current_month"))
      .endOf("month")
      .add(1, "day")
      .startOf("day");
    if (this.isSelectable(month)) {
      this.calendarStatus.set("current_month", month);
    }
    return this;
  },

  /**
   * Method to decrease current month
   * Triggers `changed:month` event
   * @param {Event} e
   * @returns {Calendar} this
   */
  handlePrevMonth: function(e) {
    var month = moment(this.calendarStatus.get("current_month"))
      .startOf("month")
      .subtract(1, "day")
      .startOf("day");
    if (this.isSelectable(month)) {
      this.calendarStatus.set("current_month", month.startOf("month"));
    }
    return this;
  },

  /**
   * Method to increase current year
   * Triggers `changed:select` event
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleNextYear: function(e) {
    var year = moment(this.calendarStatus.get("current_year")).add(1, "year");
    this.calendarStatus.set("current_year", year);
    this.setSelectMonths();
    return this;
  },

  /**
   * Method to decrease current year
   * Triggers `changed:select` event
   * @param {Event} e
   * @returns {Calendar} this
   */
  handlePrevYear: function(e) {
    var year = moment(this.calendarStatus.get("current_year")).subtract(
      1,
      "year"
    );
    this.calendarStatus.set("current_year", year);
    this.setSelectMonths();
    return this;
  },

  /**
   * Method to increase current year range
   * Triggers `changed:select` event
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleNextYearRange: function(e) {
    var year = moment(this.calendarStatus.get("current_year")).add(
      this.settings.date_year_select_range,
      "year"
    );
    this.calendarStatus.set("current_year", year);
    this.setSelectYears();
    return this;
  },

  /**
   * Method to decrease current year range
   * Triggers `changed:select` event
   * @param {Event} e
   * @returns {Calendar} this
   */
  handlePrevYearRange: function(e) {
    var year = moment(this.calendarStatus.get("current_year")).subtract(
      this.settings.date_year_select_range,
      "year"
    );
    this.calendarStatus.set("current_year", year);
    this.setSelectYears();
    return this;
  },

  /**
   * Method to change selected day, called when day is clicked
   * Passes to setDate()
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleSelectDay: function(e) {
    //Date selected
    var date = moment(
      e.currentTarget.attributes["data-day"].value,
      this.settings.date_output_format
    ).startOf("day");

    //Sets new selected date
    this.setDate(date, false, true);
    //Investigate: I think it add some class for CNM specific styles
    e.stopPropagation();
    return this;
  },

  /**
   * Method to show select month interface, called when month/year from calendar interface is clicked
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleSelectMonth: function(e) {
    var startCalendar = window.REVELEX.Calendars[this.settings.name].start;

    this.calendarStatus.get("selected_date")
      ? startCalendar.triggerElement.trigger(this.clickHandler)
      : false;

    this.calendarStatus.set("is_month_select", true);
    this.calendarStatus.set("is_year_select", false);
    //Reset calendar if selected date exists
    this.calendarStatus.get("selected_date") ? this.reset() : false;
    //Build months calendar
    this.setSelectMonths();
    //Re-render output view() and check if there is an linked calendar

    //Update active view input
    sessionStorage.setItem("default-calendar-active-view", 0);

    if (
      this.settings.outputView &&
      this.settings.search_by_month &&
      this.elements.$date_template
    ) {
      this.calendarStatus.get("selected_date") ? this.renderDateInput() : false;
      //Linked calendar month selection view activation
      if (
        this.settings.type === "start" &&
        window.REVELEX.Calendars[this.settings.name].end &&
        !window.REVELEX.Calendars[this.settings.name].end.calendarStatus.get(
          "is_month_select"
        )
      ) {
        //Set up month selection view for end calendar
        window.REVELEX.Calendars[this.settings.name].end.handleSelectMonth();
        window.REVELEX.Calendars[this.settings.name].end.calendarStatus.get(
          "selected_date"
        )
          ? window.REVELEX.Calendars[
              this.settings.name
            ].end.handleSelectMonthRange(
              window.REVELEX.Calendars[
                this.settings.name
              ].end.calendarStatus.get("selected_date")
            )
          : false;
      }
      //Linked calendar month selection view activation
      if (
        this.settings.type === "end" &&
        window.REVELEX.Calendars[this.settings.name].start &&
        !window.REVELEX.Calendars[this.settings.name].start.calendarStatus.get(
          "is_month_select"
        )
      ) {
        //Set up month selection view for start calendar
        window.REVELEX.Calendars[this.settings.name].start.handleSelectMonth();
        window.REVELEX.Calendars[this.settings.name].start.calendarStatus.get(
          "selected_date"
        )
          ? window.REVELEX.Calendars[
              this.settings.name
            ].start.handleSelectMonthRange(
              window.REVELEX.Calendars[
                this.settings.name
              ].start.calendarStatus.get("selected_date")
            )
          : false;
      }
    }

    return this;
  },

  /**
   * Handle month range selection
   * Keeps start and end month calendar in synchrony using REVELEX variable
   * @param {Moment} month
   */
  handleSelectMonthRange: function(month) {
    if (month.isValid()) {
      //Setting variables for linked calendars
      if (this.settings.type === "start" || this.settings.type === "end") {
        var startCalendar = window.REVELEX.Calendars[this.settings.name].start,
          startCalendarDate = startCalendar.calendarStatus.get("selected_date"),
          endCalendar = window.REVELEX.Calendars[this.settings.name].end,
          endCalendarDate = endCalendar.calendarStatus.get("selected_date");
      }

      /*
       ** Setting selected date
       ** {silent:true} because we don't need to re-render element listening `selected_date` change at this time
       */
      if (
        month.isSame(this.settings.date_min, "month") ||
        month.isAfter(moment(this.settings.date_min))
      ) {
        //Not start/end calendars. Set calendar date to start of month and start of day.
        if (this.settings.type !== "start" && this.settings.type !== "end") {
          this.calendarStatus.set(
            { selected_date: moment(month.startOf("month").startOf("day")) },
            { silent: true }
          );
        }
        //START calendar
        if (this.settings.type === "start") {
          /*If end date exists and the difference between months selected is greater than maximum number of months established
            Using moment().startOf('month') because when End calendar date is saved, date is set to end of month and to get correct values we need both dates start of month
            Since the range includes the same month, we need to rest 1 to the difference obtained
          */
          if (
            endCalendarDate &&
            moment(endCalendarDate)
              .startOf("month")
              .diff(month, "month", true) >
              parseInt(this.settings.month_selection_max) - 1
          ) {
            //Activates error flag, assigning month selected timestamp that will be used to highlight month clicked and show custom error message
            this.calendarStatus.set("has_error", month.unix());
            return false;
          } else {
            //De-activate error flag
            this.calendarStatus.set("has_error", false);
          }
          //Sets start month date to start of month if it's after today's date else uses today's date
          this.calendarStatus.set(
            {
              selected_date: month.startOf("month").isAfter(moment())
                ? moment(month.startOf("month").startOf("day"))
                : moment()
                    .add(1, "day")
                    .startOf("day")
            },
            { silent: true }
          );
          /*
           ** If Start month selected is after End month, set End month date
           ** to same selected for Start but end of the month
           */
          if (month.isAfter(endCalendarDate)) {
            endCalendar.calendarStatus.set(
              "selected_date",
              moment(month).endOf("month")
            );
            endCalendarDate = endCalendar.calendarStatus.get("selected_date");
          }
          //Sets selected ranges
          this.setSelectedRanges(this, endCalendar);
        }

        //END calendar
        if (this.settings.type === "end") {
          //If start date exists and the difference between months selected is greater than maximum number of months established
          if (
            startCalendarDate &&
            month.diff(startCalendarDate, "month", true) >
              parseInt(this.settings.month_selection_max) - 1
          ) {
            //Activates error flag, assigning month selected timestamp that will be used to highlight month clicked and show custom error message
            this.calendarStatus.set("has_error", month.unix());
            return false;
          } else {
            //De-activate error flag
            this.calendarStatus.set("has_error", false);
          }
          //Set End calendar date to end of month and end of day.
          this.calendarStatus.set(
            {
              selected_date: moment(month)
                .endOf("month")
                .endOf("day")
            },
            { silent: true }
          );
          /*
           ** If End month selected is before Start month, set Start month date
           ** to same selected for End but start of the month
           */
          if (month.isBefore(startCalendarDate)) {
            startCalendar.calendarStatus.set(
              "selected_date",
              moment(month).startOf("month")
            );
            startCalendarDate = startCalendar.calendarStatus.get(
              "selected_date"
            );
          }
          //Set selected ranges
          this.setSelectedRanges(startCalendar, this);
        }
      } else {
        //In case month selected is before today's date, fall back to today's date
        this.calendarStatus.set(
          { selected_date: moment(this.settings.date_min) },
          { silent: true }
        );
      }

      //Reseting is_selected flag
      if (this.settings.type !== "start" && this.settings.type !== "end")
        this.calendarStatus.set(
          "month_selected",
          moment([month.year(), month.month()]).unix()
        );

      //Updates current month and updates inputs
      this.calendarStatus.set("current_month", month);

      //If `start` month was selected and `end` calendar exists, update selected range and open it.
      if (this.settings.type === "start" && endCalendar) {
        //Update month range for End calendar
        endCalendar.setSelectMonths();
        //change focus and open calendar
        this.settings.outputView
          ? endCalendar.triggerElement.click()
          : endCalendar.elements.$date_trigger.focus();
      } else if (this.settings.type === "end" && startCalendar) {
        //Updates Start month calendar ranges
        startCalendar.setSelectMonths();
        this.settings.outputView
          ? this.triggerElement.click()
          : this.closeCalendar();
      }
    }
  },
  /**
   * Sets ranges selected for linked (Start/End) calendars
   * @param {Calendar} startCalendar
   * @param {calendar} endCalendar
   */
  setSelectedRanges: function(
    startCalendar = window.REVELEX.Calendars[this.settings.name].start,
    endCalendar = window.REVELEX.Calendars[this.settings.name].end
  ) {
    let startCalendarDate = null,
      endCalendarDate = null;
    if (startCalendar && endCalendar) {
      //Calendars selected dates
      //Checks if we are selecting month ranges because month date is moved to end of month or current date depending on the conditions
      //and timestamp per month is always calculated using start of month
      if (startCalendar.calendarStatus.get("selected_date")) {
        startCalendarDate =
          startCalendar.settings.search_by_month &&
          startCalendar.calendarStatus.get("is_month_select")
            ? moment([
                startCalendar.calendarStatus.get("selected_date").year(),
                startCalendar.calendarStatus.get("selected_date").month()
              ])
            : startCalendar.calendarStatus.get("selected_date");
      }
      if (endCalendar.calendarStatus.get("selected_date")) {
        endCalendarDate =
          endCalendar.settings.search_by_month &&
          endCalendar.calendarStatus.get("is_month_select")
            ? moment([
                endCalendar.calendarStatus.get("selected_date").year(),
                endCalendar.calendarStatus.get("selected_date").month()
              ])
            : endCalendar.calendarStatus.get("selected_date");
      }

      //Setting Range for both calendars using TIMESTAMP
      //Start calendar
      startCalendar.calendarStatus.set("selected_range", {
        start: moment(startCalendarDate).unix(),
        end: endCalendarDate ? moment(endCalendarDate).unix() : -1
      });
      //End calendar
      endCalendar.calendarStatus.set("selected_range", {
        start: moment(startCalendarDate).unix(),
        end: endCalendarDate ? moment(endCalendarDate).unix() : -1
      });
    }
  },

  /**
   * Method to show select year interface, called when year from select month interface is clicked
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleSelectYear: function(e) {
    this.calendarStatus.set("is_month_select", false);
    this.calendarStatus.set("is_year_select", true);
    this.setSelectYears();
    // this.trigger("changed:select");
    return this;
  },

  /**
   * Method to handle daily selection, called when the Daily control is clicked on display options
   * @returns {Calendar} this
   */
  handleSelectDaily: function() {
    var startCalendar = window.REVELEX.Calendars[this.settings.name].start;
    this.calendarStatus.get("selected_date")
      ? startCalendar.triggerElement.trigger(this.clickHandler)
      : false;

    const month = this.calendarStatus.get("current_month");
    if (month && month.isValid()) {
      //Deactivate Month selection
      this.calendarStatus.set("is_month_select", false);
      //Deactivate Year selection
      this.calendarStatus.set("is_year_select", false);

      //if select day exist, reset it
      this.calendarStatus.get("selected_date") ? this.reset() : false;

      //Build calendars
      this.setMonths();

      //Updates active view in session
      sessionStorage.setItem("default-calendar-active-view", 1);

      //Linked calendar day selection view activation
      if (
        this.settings.type === "start" &&
        window.REVELEX.Calendars[this.settings.name].end &&
        window.REVELEX.Calendars[this.settings.name].end.calendarStatus.get(
          "is_month_select"
        )
      ) {
        //Set up day selection view for end calendar
        window.REVELEX.Calendars[this.settings.name].end.handleSelectDaily();
      }
      //Linked calendar day selection view activation
      if (
        this.settings.type === "end" &&
        window.REVELEX.Calendars[this.settings.name].start &&
        window.REVELEX.Calendars[this.settings.name].start.calendarStatus.get(
          "is_month_select"
        )
      ) {
        //Set up day selection view for start calendar
        window.REVELEX.Calendars[this.settings.name].start.handleSelectDaily();
      }
    }
    return this;
  },

  /**
   * Method to handle selection of a month, called when a month is clicked from select month interface
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleMonth: function(e) {
    var $control = $(e.target);
    var month = $control.attr("data-month");

    if (month) {
      month = moment(month, this.settings.date_output_format);

      if (month.isValid()) {
        //Set to false only if search_by_month is not active
        if (!this.settings.search_by_month) {
          this.calendarStatus.set("is_month_select", false);
        } else {
          //Update month range selection data
          this.handleSelectMonthRange(month);
        }
        //Update flag that control if year structure is showed
        this.calendarStatus.set("is_year_select", false);
        //Updates current month and inputs, if no error was thrown
        !this.calendarStatus.get("selected_range")
          ? this.calendarStatus.set("current_month", month)
          : false;

        //Event to re-render items
        this.trigger("change:calendar_select");
      }
    }

    return this;
  },

  /**
   * Method to handle selection of a year, called when a year is clicked from select year interface
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleYear: function(e) {
    var $control = $(e.target);
    var year = $control.attr("data-year");

    if (year) {
      year = moment(year, this.settings.date_output_format);
      if (year.isValid()) {
        this.calendarStatus.set("is_month_select", true);
        this.calendarStatus.set("is_year_select", false);
        this.calendarStatus.set("current_year", year);
        this.calendarStatus.set(
          "current_month",
          moment(this.calendarStatus.get("current_month")).year(year.year())
        );
        this.setSelectMonths();
      }
    }

    return this;
  },

  /**
   * Method to set date, used by handleSelectDay() and can be called from usage in outside JS
   * Triggers `changed:date` event
   * @param {string} date
   * @param {Moment} date
   * @param {bool} update_month
   * @param {bool} close_calendar
   * @returns {Calendar} this
   */
  setDate: function(date, update_month, close_calendar, return_flag, reset) {
    var date = date || false;
    var update_month = update_month || false;
    var close_calendar = close_calendar || false;
    var return_flag = return_flag || false;
    var updated = false;

    if (date) {
      date = moment.isMoment(date)
        ? date
        : moment(date, this.settings.date_input_format).startOf("day");
      if (
        (date.isValid() &&
          this.isSelectable(date) &&
          !date.isSame(this.calendarStatus.get("selected_date"))) ||
        reset
      ) {
        if (update_month) {
          this.calendarStatus.set(
            "current_month",
            moment(date).startOf("month")
          );
          this.calendarStatus.set("current_year", moment(date).startOf("year"));
        }
        //Updates date last to ensure all params have been updated when month builder function executes
        this.state.selected_date = date;
        updated = true;
        this.calendarStatus.set("selected_date", date);
      } else {
        date = this.calendarStatus.get("selected_date");
      }
    } else {
      date = moment().startOf("day");

      if (
        this.settings.date_min &&
        this.settings.date_max &&
        moment(date)
          .endOf("day")
          .isAfter(this.settings.date_min) &&
        date.isBefore(moment(this.settings.date_max).endOf("day"))
      ) {
        date = date;
      } else if (this.settings.date_max) {
        date = this.settings.date_max;
      } else if (this.settings.date_min) {
        date = this.settings.date_min;
      }

      if (update_month) {
        this.calendarStatus.set("current_month", moment(date).startOf("month"));
        this.calendarStatus.set("current_year", moment(date).startOf("year"));
      }

      this.calendarStatus.set("selected_date", null);
    }

    // if reset flag is true, skip these steps
    if (
      close_calendar &&
      this.elements.$date_trigger &&
      this.elements.$date_calendar &&
      !reset
    ) {
      if (this.settings.outputView) {
        this.triggerElement.trigger(this.clickHandler);
      } else {
        this.closeCalendar();
      }
      if (this.settings.type == "start") {
        var linked_calendar = window.REVELEX.Calendars[this.settings.name].end;
        if (linked_calendar) {
          if (this.settings.outputView) {
            linked_calendar.triggerElement.trigger(this.clickHandler);
          } else {
            linked_calendar.openCalendar();
          }
        }
      } else if (this.settings.type == "end" && !this.settings.outputView) {
        var linked_calendar =
          window.REVELEX.Calendars[this.settings.name].start;
      }
    }

    return return_flag ? updated : this;
  },

  /**
   * Sets the initial state
   * @returns {Calendar} this
   */
  setState: function() {
    // Set min and max dates
    if (this.settings.date_min) {
      var date_min = moment.isMoment(this.settings.date_min)
        ? this.settings.date_min
        : moment(this.settings.date_min, this.settings.date_input_format);
      this.settings.date_min = date_min.isValid()
        ? date_min.startOf("day")
        : false;
    }
    if (this.settings.date_max) {
      var date_max = moment.isMoment(this.settings.date_max)
        ? this.settings.date_max
        : moment(this.settings.date_max, this.settings.date_input_format);
      this.settings.date_max = date_max.isValid()
        ? date_max.startOf("day")
        : false;
    }

    // Set current date and current month
    if (this.settings.show_date) {
      var date = moment().startOf("day");
      var initial_date = false;

      if (this.settings.date) {
        date = this.settings.date;
        initial_date = true;
      } else if (
        this.settings.date_min &&
        this.settings.date_max &&
        moment(date)
          .endOf("day")
          .isAfter(this.settings.date_min) &&
        date.isBefore(moment(this.settings.date_max).endOf("day"))
      ) {
        date = date;
      } else if (this.settings.date_max) {
        date = this.settings.date_max;
      } else if (this.settings.date_min) {
        date = this.settings.date_min;
      }

      date = moment.isMoment(date)
        ? date
        : moment(date, this.settings.date_input_format).startOf("day");

      this.calendarStatus.set("current_month", moment(date).startOf("month"));
      this.calendarStatus.set("current_year", moment(date).startOf("year"));

      if (initial_date) {
        this.calendarStatus.set("selected_date", date);
        this.renderDateInput().renderDateOutput();
      }
    }

    // If is an end calendar, rerender start calendar
    if (
      this.settings.name &&
      this.settings.type === "end" &&
      window.REVELEX.Calendars[this.settings.name] &&
      window.REVELEX.Calendars[this.settings.name].start
    ) {
      window.REVELEX.Calendars[this.settings.name].start.render();
    }

    // Set view
    //Search by month active then set flag is_month_select to true
    if (!this.settings.search_by_month) {
      this.calendarStatus.set("is_month_select", false);
    }
    this.calendarStatus.set("is_year_select", false);

    return this;
  },

  /**
   * Builds the months array that will be used to render calendar
   * After finishing, `this.calendarStatus.get('months')` will look like:
   *  [
   *      {
   *          weeks: [
   *              {days: [ {date: ...}, {date: ...}, {date: ...}, {date: ...}, {date: ...}, {date: ...}, {date: ...}
   * ]},
   *              {days: [ {date: ...}, {date: ...}, {date: ...}, ... ]},
   *              {days: [ {date: ...}, {date: ...}, {date: ...}, ... ]},
   *              {days: [ {date: ...}, {date: ...}, {date: ...}, ... ]},
   *          ],
   *          year: 2015,
   *          month: {
   *              full: "January",
   *              short: "Jan"
   *          },
   *          weekdays: {
   *              full: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
   *              short: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
   *              min: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
   *          },
   *          has_previous_month: false,
   *          has_next_month: true
   *      },
   *      {
   *          weeks: [
   *              {days: [ {date: ...}, {date: ...}, {date: ...}, ... ]},
   *              {days: [ {date: ...}, {date: ...}, {date: ...}, ... ]},
   *              ...
   *      },
   *      ...
   *  ]
   * @returns {array} months
   */
  setMonths: function() {
    //Getting values from model
    const current_month = this.calendarStatus.get("current_month"),
      current_year = this.calendarStatus.get("current_year").year(),
      date_selected = this.calendarStatus.get("selected_date");
    //Elements needed when constructing calendar months object
    let built_months = this.calendarStatus.get("months"),
      weekdays = {},
      has_previous_month = this.isSelectable(
        moment(current_month).subtract(1, "day")
      ),
      has_next_month = this.isSelectable(
        moment(current_month)
          .endOf("month")
          .startOf("day")
          .add(1, "day")
      );

    //if 1st month and year corresponds with current, means it's same structure we built and return it
    if (
      built_months.length &&
      built_months[0].year == current_year &&
      built_months[0].month.index == current_month.month()
    ) {
      this.setSelectedRanges();
      this.trigger("change:calendar_select");
      return this;
    }

    /*
     ** moment.localeData().firstDayOfWeek() returns an integer representing the first day of the week
     ** locale EN = 0, first day is Sunday
     ** locale ES = 1, first day is Monday
     */
    if (moment.localeData().firstDayOfWeek() === 1) {
      weekdays = {
        full: [
          moment()
            .isoWeekday(1)
            .format("dddd"),
          moment()
            .isoWeekday(2)
            .format("dddd"),
          moment()
            .isoWeekday(3)
            .format("dddd"),
          moment()
            .isoWeekday(4)
            .format("dddd"),
          moment()
            .isoWeekday(5)
            .format("dddd"),
          moment()
            .isoWeekday(6)
            .format("dddd"),
          moment()
            .isoWeekday(7)
            .format("dddd")
        ],
        short: [
          moment()
            .isoWeekday(1)
            .format("ddd"),
          moment()
            .isoWeekday(2)
            .format("ddd"),
          moment()
            .isoWeekday(3)
            .format("ddd"),
          moment()
            .isoWeekday(4)
            .format("ddd"),
          moment()
            .isoWeekday(5)
            .format("ddd"),
          moment()
            .isoWeekday(6)
            .format("ddd"),
          moment()
            .isoWeekday(7)
            .format("ddd")
        ],
        min: [
          moment()
            .isoWeekday(1)
            .format("dd"),
          moment()
            .isoWeekday(2)
            .format("dd"),
          moment()
            .isoWeekday(3)
            .format("dd"),
          moment()
            .isoWeekday(4)
            .format("dd"),
          moment()
            .isoWeekday(5)
            .format("dd"),
          moment()
            .isoWeekday(6)
            .format("dd"),
          moment()
            .isoWeekday(7)
            .format("dd")
        ]
      };
    } else {
      weekdays = {
        full: moment.weekdays(),
        short: moment.weekdaysShort(),
        min: moment.weekdaysMin()
      };
    }

    let months = [];

    // Create a month object based on how many months to show at a time
    for (var i = 0; i < this.settings.show_calendars; i++) {
      var month = moment(this.calendarStatus.get("current_month")).add(
        i,
        "months"
      );
      var first_day = moment(month).startOf("month");
      var last_day = moment(month)
        .endOf("month")
        .startOf("day");
      var current_day = moment(first_day);
      months[i] = {
        weeks: [],
        weekdays: weekdays,
        month: {
          index: current_day.month(),
          full: moment.months(current_day.month()),
          short: moment.monthsShort(current_day.month())
        },
        months: {
          full: moment.months(),
          short: moment.monthsShort()
        },
        year: current_day.year(),
        has_previous_month: has_previous_month,
        has_next_month: has_next_month
      };

      // Loop through until last day of month
      while (
        last_day.diff(current_day, "days") > 0 ||
        current_day.isSame(last_day)
      ) {
        // If this is the first day, or if this is a different week than the last day, create a new week
        //  inside current month
        if (
          current_day.isSame(first_day) ||
          !current_day.isSame(moment(current_day).subtract(1, "day"), "week")
        ) {
          months[i].weeks.push({ days: [] });
        }

        // If this is the first day and the first day is not the beginning of the week, fill up the week
        //  with dates from previous month
        if (current_day.isSame(first_day) && current_day.weekday() !== 0) {
          var week_beginning = moment(current_day).startOf("week");
          while (
            months[i].weeks[months[i].weeks.length - 1].days.length <
            current_day.weekday()
          ) {
            var filler_week = months[i].weeks[months[i].weeks.length - 1].days;
            var filler_day = moment(week_beginning).add(
              filler_week.length,
              "days"
            );
            filler_week.push({
              date: filler_day,
              date_day: filler_day.date(),
              date_output: filler_day.format(this.settings.date_output_format),
              is_previous_month: true,
              is_selectable: this.isSelectable(filler_day),
              day_timestamp: filler_day.unix()
            });
          }
        }

        // Add day to current week
        months[i].weeks[months[i].weeks.length - 1].days.push({
          date: moment(current_day),
          date_day: current_day.date(),
          date_output: current_day.format(this.settings.date_output_format),
          is_selectable: this.isSelectable(current_day),
          day_timestamp: current_day.unix()
        });

        // If this is the last day and the last day is not the end of the week, fill up the week with dates
        //  from next month
        if (current_day.isSame(last_day) && current_day.weekday() !== 6) {
          var week_beginning = moment(current_day).startOf("week");
          while (months[i].weeks[months[i].weeks.length - 1].days.length < 7) {
            var filler_week = months[i].weeks[months[i].weeks.length - 1].days;
            var filler_day = moment(week_beginning).add(
              filler_week.length,
              "days"
            );
            filler_week.push({
              date: filler_day,
              date_day: filler_day.date(),
              date_output: filler_day.format(this.settings.date_output_format),
              is_next_month: true,
              is_selectable: this.isSelectable(filler_day),
              day_timestamp: filler_day.unix()
            });
          }
        }

        // Increment day
        current_day.add(1, "day");
      }
    }

    //Updating model
    this.calendarStatus.set("months", months);
    if (this.settings.type === "start" || this.settings.type === "end") {
      this.setSelectedRanges();
    }

    return this;
  },

  /**
   * Builds the array of months that will be used to render select month interface
   * @returns {Calendar} this
   */
  setSelectMonths: function() {
    //Declares variables
    const current_year = this.calendarStatus.get("current_year"),
      date_selected = this.calendarStatus.get("selected_date");
    let built_months_keys = Object.keys(
        this.calendarStatus.get("select_months")
      ),
      show_year_select = this.settings.show_year_select,
      years = {};

    // Disable year select if max and min dates are the same year
    if (
      this.settings.date_min &&
      this.settings.date_max &&
      this.settings.date_min.year() === this.settings.date_max.year()
    ) {
      show_year_select = false;
    }

    //If it's the same year that we already have built, trigger select event and return
    //1st element in array will always match current year if year selected is same we have built before
    if (built_months_keys[0] == current_year.year()) {
      // Event to re-render when month object has not changed
      this.setSelectedRanges();
      this.trigger("change:calendar_select");
      return this;
    }

    // Build each month per as many years as the setting show_calendar commands
    for (let y = 0; y < this.settings.show_calendars; y++) {
      let months = [],
        new_year = moment(current_year)
          .add(y, "years")
          .startOf("year")
          .startOf("day");

      for (var i = 0; i < 12; i++) {
        var month = moment([new_year.year(), i, 1]);
        var is_selectable =
          this.isSelectable(month) ||
          this.isSelectable(moment(month).endOf("month"));

        var month_info = {
          full: moment.months(i),
          short: moment.monthsShort(i),
          month_output: month.format(this.settings.date_output_format),
          is_selectable: is_selectable,
          month_timestamp: moment([new_year.year(), month.month()]).unix()
        };

        months.push(month_info);
      }

      years[new_year.year()] = {
        year: new_year.year(),
        months: months,
        show_year_select: show_year_select,
        has_previous_year: this.isSelectable(
          moment(new_year).subtract(1, "day")
        ),
        has_next_year: this.isSelectable(moment(new_year).add(1, "year"))
      };
    }

    //Sets new month object
    this.calendarStatus.set("select_months", years);

    if (this.settings.type === "start" || this.settings.type === "end") {
      this.setSelectedRanges();
    }

    return this;
  },

  /**
   * Builds the array of years that will be used to render select year interface
   * @returns {Calendar} this
   */
  setSelectYears: function() {
    const built_years = this.calendarStatus.get("select_years");
    let current_year = this.calendarStatus.get("current_year").year(),
      date_selected = this.calendarStatus.get("selected_date"),
      range_amount = this.settings.date_year_select_range,
      start_year = current_year - Math.floor(range_amount / 2),
      end_year = start_year + (range_amount - 1),
      years = [];

    //If it's the same year that we already have built, trigger select event and return
    if (
      built_years.start_year === start_year &&
      built_years.end_year === end_year
    ) {
      //Event to re-render when object has not changed
      built_years.years.some(function(item) {
        if (item.year == date_selected.year()) {
          item.is_selected = true;
          return true;
        }
      }, this);
      this.trigger("change:calendar_select");
      return this;
    }

    // Build each year
    for (var i = 0; i < range_amount; i++) {
      var year = moment([
        start_year + i,
        this.calendarStatus.get("current_year").month(),
        1
      ]);
      var is_selectable = this.isSelectableYear(year);
      var year_info = {
        year: year.year(),
        year_output: year.format(this.settings.date_output_format),
        is_selected:
          date_selected && year.year() == date_selected.year() && is_selectable
            ? true
            : false,
        is_selectable: is_selectable
      };
      years.push(year_info);
    }

    this.calendarStatus.set("select_years", {
      start_year: start_year,
      end_year: end_year,
      years: years,
      has_previous_year_range:
        this.settings.date_min && this.settings.date_min.year() >= start_year
          ? false
          : true,
      has_next_year_range:
        this.settings.date_max && this.settings.date_max.year() <= end_year
          ? false
          : true
    });

    return this;
  },

  /**
   * Loads more calendars to show when scroll reaches bottom or top
   * IntersectionObserver could be used but it's not supported by IE
   * @param {Event} e
   */
  calendarLazyLoader: function(e) {
    if (this.lazyLoaderActive && this.settings.outputView) {
      //Controls whether a calendar about to be generated is valid or not
      //start: used when scroll goes up
      //end: used when scroll goes down
      let calendarControl = {
        start: !this.calendarStatus.get("is_month_select")
          ? moment(this.calendarStatus.get("current_month"))
              .startOf("month")
              .subtract(1, "days")
          : moment(this.calendarStatus.get("current_year"))
              .startOf("year")
              .subtract(1, "days"),
        end: !this.calendarStatus.get("is_month_select")
          ? moment(this.calendarStatus.get("current_month")).add(
              this.settings.show_calendars_mobile,
              "months"
            )
          : moment(this.calendarStatus.get("current_year")).add(
              this.settings.show_calendars_mobile,
              "years"
            )
      };
      //Output view container limits
      let target = e.currentTarget,
        calendarHeigth = target.querySelector(".calendar-month").clientHeight;
      //Checks if scroll is close the top of container and previous calendar is valid
      if (target.scrollTop <= 80 && this.isSelectable(calendarControl.start)) {
        //Calls PrevMonth or PrevYear depending on what type of calendar render is being used
        !this.calendarStatus.get("is_month_select")
          ? this.handlePrevMonth()
          : this.handlePrevYear();
        //Returns scroll to previous position (+1 calendar height)
        target.scrollTop += calendarHeigth;
      } else if (
        //Checks if scroll is close the bottom of container and next calendar is valid
        //Adds amount of calendars to show to current because current (month or year) is always the 1st built not the last
        target.scrollTop + target.clientHeight >= target.scrollHeight - 50 &&
        this.isSelectable(calendarControl.end)
      ) {
        //Calls PrevMonth or PrevYear depending on what type of calendar render is being used
        !this.calendarStatus.get("is_month_select")
          ? this.handleNextMonth(e)
          : this.handleNextYear(e);
        //Returns scroll to previous position (-1 calendar height)
        target.scrollTop -= calendarHeigth;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },

  /**
   * Checks if a given moment date object is within calendar's range
   * @param {Moment} date
   */
  isSelectable: function(date) {
    if (
      !(moment.isMoment(date) && date.isValid()) ||
      (this.settings.date_min && date.isBefore(this.settings.date_min)) ||
      (this.settings.date_max && date.isAfter(this.settings.date_max))
    ) {
      return false;
    }

    return true;
  },

  /**
   * Checks if a given date's YEAR is within calendar's range
   * @param {Moment} date
   */
  isSelectableYear: function(date) {
    if (
      !(moment.isMoment(date) && date.isValid()) ||
      (this.settings.date_min && date.year() < this.settings.date_min.year()) ||
      (this.settings.date_max && date.year() > this.settings.date_max.year())
    ) {
      return false;
    }

    return true;
  },

  /**
   * Renders the datepicker calendar
   * If a render target is given, will automatically replace the target's content with rendered content
   * Otherwise will return the HTML of the render result
   * @returns {*} Calendar this / string content
   */
  renderDate: function() {
    this.setMonths();
    if (this.elements.$date_calendar) {
      if (!this.settings.outputView) {
        this.elements.$date_calendar.focus();
      } else {
        return this;
      }
    } else {
      return content;
    }
  },

  /**
   * Renders the date display value
   * @returns {Calendar} this
   */
  renderDateInput: function() {
    const format =
      this.settings.search_by_month &&
      this.calendarStatus.get("is_month_select")
        ? "MMM YYYY"
        : this.settings.date_display_format;

    if (this.elements.$date_input) {
      if (this.calendarStatus.get("selected_date")) {
        this.$el.addClass("calendar-has-date");
        this.elements.$date_input.addClass("calendar-has-date").val(
          moment(this.calendarStatus.get("selected_date"))
            .locale(moment.locale())
            .format(format)
        );
      } else {
        // Change event should not be triggered if no change was made.
        if (this.elements.$date_input.val()) {
          this.$el.removeClass("calendar-has-date");
          this.elements.$date_input
            .val("")
            .removeClass("calendar-has-date")
            .trigger("change");
        }
      }
    }
    return this;
  },

  /**
   * Renders the date output value
   * @returns {Calendar} this
   */
  renderDateOutput: function() {
    if (this.elements.$date_output) {
      if (this.calendarStatus.get("selected_date")) {
        if (this.settings.type == "end") {
          $("[data-dates-range-connector]").addClass("date-selected");
        }
        this.elements.$date_output.val(
          moment(this.calendarStatus.get("selected_date"))
            .locale(moment.locale())
            .format(this.settings.date_output_format)
        );
      } else {
        // Change event should not be triggered if no change was made.
        if (this.elements.$date_output.val()) {
          this.elements.$date_output.val("").trigger("change");
        }
      }
    }
    return this;
  },

  /**
   * Wrapper render function
   * Checks for date/time show settings
   * Checks if render function are passed and will use in place of default
   * @returns {Calendar} this
   */
  render: function() {
    if (this.settings.show_date) {
      if (
        this.calendarStatus.get("is_month_select") &&
        !this.settings.search_by_month
      ) {
        this.setSelectMonths();
      } else if (this.calendarStatus.get("is_year_select")) {
        this.setSelectYears();
      } else {
        // Render calendar
        if (typeof this.settings.date_render === "function") {
          this.setMonths();
          this.settings.date_render.call();
        } else {
          this.renderDate();
        }

        this.renderDateInput().renderDateOutput();
      }
    }

    return this;
  }
});

module.exports = CalendarView;
