/*
    Global Router
    No module/component should interact with the window hash by itself,
    instead the global router should take care of updating,
    triggering and executed the respective callbacks on the
    binded events.

    To enable a component/module to use the Router you need to
    register it using one of the following public methods:

    Router.registerComponent(uniqueId, callback, componentScope);
    Router.registerModule(uniqueId, callback, moduleScope);

    // Revelex Corporation
 */

var Router = Backbone.Router.extend({
  routes: {
    "*path": "_process"
  },
  _process: function(hash) {
    if (hash && hash.length) {
      this._interpret(hash);
    }
  },
  initialize: function() {
    // Create Models to keep track of routes
    this.state = {
      components: new Backbone.Model(),
      modules: new Backbone.Model()
    };

    Backbone.history.start();
  },
  _interpret: function(hash) {
    // Data Structure for each record:
    // {
    // 	$key: $value
    // }
    var Modules = {},
      Components = {};

    _.each(hash.split(/!|\|/), function(key, i) {
      var strippedVar = key.split("="),
        preChar = hash.slice(hash.indexOf(key) - 1, hash.indexOf(key)),
        isModule = preChar == "!" ? true : false,
        isComponent = preChar == "|" ? true : false;

      if (isModule) {
        Modules[strippedVar[0]] = strippedVar[1];
      }

      if (isComponent) {
        Components[strippedVar[0]] = strippedVar[1];
      }
    });

    this.state.modules.clear().set(Modules);
    this.state.components.clear().set(Components);

    return this;
  },
  _reflowHash: function() {
    var components = this.state.components.toJSON();
    var modules = this.state.modules.toJSON();
    var newHash = "#";

    _.each(
      components,
      function(value, key) {
        newHash += "|" + key + "=" + value;
      }.bind(this)
    );

    _.each(
      modules,
      function(value, key) {
        newHash += "!" + key + "=" + value;
      }.bind(this)
    );

    if (window) {
      window.location.hash = newHash;
    }
  },
  registerModule: function(uid, callback, scope) {
    // Bind Callback to Event related to specific UID
    this.state.modules.on("change:" + uid, callback.bind(scope));

    // Check if state is already defined
    if (this.state.modules.has(uid)) {
      // Bind to make sure we run the function in the scope of the component
      var tempCallback = _.bind(callback, scope);

      // Run Callback for first time
      tempCallback(this.state.modules, this.state.modules.get(uid));
    }

    // Return reference of instance of Router
    return this;
  },
  registerComponent: function(uid, callback, scope) {
    // Bind Callback to Event related to specific UID
    this.state.components.on("change:" + uid, callback.bind(scope));

    // Check if state is already defined
    if (this.state.components.has(uid)) {
      // Bind to make sure we run the function in the scope of the component
      var tempCallback = _.bind(callback, scope);

      // Run Callback for first time
      tempCallback(this.state.components, this.state.components.get(uid));
    }

    // Return reference of instance of Router
    return this;
  },
  moduleSwitch: function(uid, value) {
    // Allows module to update their state
    this.state.modules.set(uid, value);

    this._reflowHash();

    return this;
  },
  componentSwitch: function(uid, value) {
    // Allows components to update their state
    this.state.components.set(uid, value);

    this._reflowHash();

    return this;
  },
  ifModulePresent: function(uid) {
    // Returns boolean depending on the presence of the uid in the state model
    return this.state.modules.has(uid);
  },
  ifComponentPresent: function(uid) {
    // Returns boolean depending on the presence of the uid in the state model
    return this.state.components.has(uid);
  },
  unregisterModule: function(uid) {
    // Destroy module registration and unbinds change event for specific key
    if (this.state.modules.has(uid)) {
      this.state.modules.unset(uid).off("change:" + uid);
      this._reflowHash();
    }

    return this;
  },
  unregisterComponent: function(uid) {
    // Destroy component registration unbinds change event for specific key
    if (this.state.components.has(uid)) {
      this.state.components.unset(uid).off("change:" + uid);
      this._reflowHash();
    }

    return this;
  }
});

module.exports = new Router();
