var $ = require("jquery");

var PaymentUtility = require("./_payment.utility");

var PaymentMethodTypeView = Backbone.View.extend({
  events: {
    "click [data-payment-method-type-input]": "processSelection"
  },

  initialize: function(options) {
    this.dispatcher = options.dispatcher;

    this.paymentMethodTypes = new PaymentUtility.PaymentMethodTypeCollection(
      options.paymentMethodTypes
    );

    this.unavailableTypes = new PaymentUtility.PaymentMethodTypeCollection();

    this.dispatcher.on(
      "paymentProcess:changed",
      this.processPaymentProcessChange.bind(this)
    );

    this.dispatcher.on(
      "initFromBillingInfo",
      this.initFromBillingInfo.bind(this)
    );

    this.dispatcher.on(
      "paymentMethodType:available",
      this.typeAvailable.bind(this)
    );

    this.dispatcher.on(
      "paymentMethodType:unavailable",
      this.typeUnavailable.bind(this)
    );

    this.dispatcher.on(
      "paymentMethodType:disable",
      this.typeDisable.bind(this)
    );
  },

  initFromBillingInfo: function(data) {
    this.processPaymentProcessChange(
      PaymentUtility.buildPaymentProcessChangeDataFromBillingInfo(
        data.billingInfo
      ),
      true,
      data.billingInfo
    );
  },

  processPaymentProcessChange: function(data, isInitializing, billingInfo) {
    var self = this;
    var displayCount = 0;
    var defaultType = null;
    var initializing = isInitializing || false;

    this.selectedPaymentOption = data.paymentOption;
    this.selectedPaymentProcess = data.processType;

    var associations = this.selectedPaymentOption.get(
      "payment_method_associations"
    );

    this.$el.addClass("is-hidden");
    this.$("[data-payment-method-type]").addClass("is-hidden");

    _.each(this.$("[data-payment-method-type-input]"), function(el) {
      el.checked = false;
      $(el).attr("disabled", true);
    });

    _.each(associations, function(assoc) {
      var method = self.paymentMethodTypes.find({
        id: assoc.payment_method_type_id
      });

      if (method) {
        if (
          ((data.processType === "electronic" &&
            method.get("allow_electronic")) ||
            (data.processType === "manual" && method.get("allow_manual"))) &&
          !self.unavailableTypes.get({ id: method.get("id") })
        ) {
          self
            .$('[data-payment-method-type="' + method.get("type_label") + '"]')
            .removeClass("is-hidden");
          self
            .$(
              '[data-payment-method-type-input="' +
                method.get("type_label") +
                '"]:not([data-payment-method-disabled])'
            )
            .attr("disabled", false);

          if (!defaultType) {
            defaultType = method.get("type_label");
          }

          displayCount++;
        }
      }
    });

    if (initializing) {
      if (displayCount > 1) {
        this.$el.removeClass("is-hidden");
      }

      if (billingInfo.payment_method.component_id) {
        this.$(
          '[data-payment-method-type-input="' +
            billingInfo.payment_method.payment_method_type.type_label +
            '"]'
        ).prop("checked", true);
      }
    } else {
      let initPaymentMethod = this.$("[data-payment-method-checked]");
      if (displayCount === 0) {
        this.announceChange(new PaymentUtility.PaymentMethodType());
      } else if (displayCount > 1) {
        this.$el.removeClass("is-hidden");
        if (initPaymentMethod.length) {
          initPaymentMethod.trigger("click");
        } else {
          this.announceChange(new PaymentUtility.PaymentMethodType());
        }
      } else {
        this.$('[data-payment-method-type-input="' + defaultType + '"]')
          .prop("checked", true)
          .trigger("click");
      }
    }
  },

  processSelection: function(event) {
    var method =
      this.paymentMethodTypes.find({
        id: parseInt($(event.currentTarget).val())
      }) || new PaymentUtility.PaymentMethodType();

    this.announceChange(method);
  },

  announceChange: function(method) {
    this.dispatcher.trigger(
      "paymentMethodType:changed",
      PaymentUtility.buildPaymentMethodTypeChangeData(
        this.selectedPaymentOption,
        this.selectedPaymentProcess,
        method
      )
    );
  },

  typeAvailable: function(data) {
    console.log("method type available");
    console.log(data);

    this.unavailableTypes.remove(data.paymentMethodType);

    console.log(this.unavailableTypes);
  },

  typeUnavailable: function(data) {
    console.log("method type unavailable");
    console.log(data);

    this.unavailableTypes.add(data.paymentMethodType);

    console.log(this.unavailableTypes);
  },

  typeDisable: function(data) {
    console.log("method type disable");
    console.log(data);

    this.$(
      '[data-payment-method-type-input="' +
        data.paymentMethodType.get("type_label") +
        '"]'
    ).attr("data-payment-method-disabled", true);
  }
});

module.exports = PaymentMethodTypeView;
