// flatten Object
// flatten Object will take any object that is passed to it and doesnt matter the deep of it,
// will bring it to a single level
//
// One scenario that this comes in handy is when flattening an object with search params

// Example :
// If passing an initial prefix, this will add brackets "[]" for any incoming
// (key)
// rooms : {
//   1 : {
//     adults: 1,
//     children: 0
//   }
// }
//
// output:
// search[rooms][1][number_adults]: "1"
// search[rooms][1][number_children]: "0"

var flattenObject = Backbone.View.extend({
  flatten: function(currentObject, prefix = "") {
    // we are returning the full function to have it available in the main component/module
    return Object.keys(currentObject).reduce((newObject, k) => {
      const pre = prefix.length ? prefix + "[" : "";
      const post = prefix.length ? "]" : "";

      if (
        typeof currentObject[k] === "object" &&
        currentObject[k] &&
        Object.keys(currentObject[k]).length
      )
        Object.assign(
          newObject,
          this.flatten(currentObject[k], pre + k + post)
        );
      else newObject[pre + k + post] = currentObject[k];
      return newObject;
    }, {});
  },
  initialize: function(settings) {
    this.settings = settings || {};
  }
});

module.exports = new flattenObject();
