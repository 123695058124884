// Gallery component
// Here we initialize slickJS depending on if images actually exists

require("slick-carousel");

// image checker checks for images
var imageChecker = require("../../commons/components/image-checker");

var Gallery = Backbone.View.extend({
  events: {
    "imageChecker:done": "renderGallery"
  },
  initialize: function() {
    this.imageChecker = new imageChecker();
    let currentId = this.$el.attr("id");
    this.settings = _.extend(this.$el.data(), {
      prevArrow:
        "<button type='button' tabindex='0' class='gallery-prev' aria-controls='" +
        currentId +
        "-items' aria-label='Previous Slide'></button>",
      nextArrow:
        "<button type='button' tabindex='0' class='gallery-next' aria-controls='" +
        currentId +
        "-items' aria-label='Next Slide'></button>",
      dotsClass: "gallery-dots"
    });
    this.render();
  },
  render: function() {
    if (this.settings.settings && this.settings.settings.imageCheck) {
      this.$el.addClass("is-loading");
      this.imageChecker.checkImageStatus(
        this,
        this.settings.settings.imageCheck
      );
    } else {
      this.renderGallery();
    }
  },
  renderGallery: function() {
    this.$el.slick(this.settings);

    // ADA overwrites to gallery
    let availableSlides = this.$(".slick-slide");
    this.$el.attr("role", "group");
    this.$(".slick-list").attr({ "aria-atomic": "false" });
    availableSlides.attr({
      "aria-roledescription": "slide",
      role: "group"
    });

    if (this.settings.ignoreAriaDescribe) {
      availableSlides.removeAttr("aria-describedby");
    }

    this.$(".slick-slide").each((index, el) => {
      let currentSlide = index + 1;

      if (!this.settings.ignoreAriaLabelledby) {
        $(el).attr("aria-labelledby", this.cid + index);
      }

      $(el).append(
        "<div class='is-hidden' id='" +
          this.cid +
          index +
          "'>" +
          currentSlide +
          " of " +
          availableSlides.length +
          "</div>"
      );
    });

    this.$el.removeClass("is-loading");
    return this;
  }
});
module.exports = Gallery;
