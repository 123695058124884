var Bazaarvoice = Backbone.View.extend({
  initialize: function(options) {
    this.settings = options;

    Backbone.Events.on("publishAnalyticsData", this.publishData.bind(this));

    if (this.settings.send_events) {
      $(document).on("click", "[data-analytics-id]", this.sendEvent.bind(this));
    }
  },

  publishData: function(data) {
    if (data.bazaarvoice) {
      if (data.bazaarvoice.page_data.dataAsDomObj) {
        window[data.bazaarvoice.page_data.dataAsDomObj] =
          data.bazaarvoice.page_data.data;
      } else {
        window.bvCallback = function(BV) {
          BV.pixel.trackTransaction(data.bazaarvoice.page_data);
        };
      }
    }
  },

  sendEvent: function(event) {
    var data = {
      type: "click",
      label: $(event.currentTarget).data("analyticsId"),
      value: this.settings.page
    };

    if (this.settings.event_label) {
      data.label = this.settings.event_label;
    }

    if (this.settings.event_value) {
      data.value = this.settings.event_value;
    }

    window.bvCallback = function(BV) {
      BV.pixel.trackConversion(data);
    };
  }
});

module.exports = Bazaarvoice;
