var $ = require("jquery");
const outputView = require("../../utilities/output-view");

let stateModel = Backbone.Model.extend({
  defaults: {
    name: "",
    template: "",
    maxCount: 0,
    currentCount: 0,
    hasExternalTrigger: false,
    processAddCount: 2
  }
});

var FieldReplicator = Backbone.View.extend({
  /**
   * The internal e listeners for the view.
   */
  events: {
    "click [data-field-replicator-add]": "processAdd",
    "click [data-field-replicator-remove]": "processRemove",
    "change [data-field-replicator-maximum-items]": "limitItemsNumber"
  },

  /**
   * Maps to removeFields after checking if event is related with current instance
   * @param e
   */
  processInputChange: function(e) {
    this.inputCurrentValue = $(e.currentTarget).val();
    if (this.isCurrentInstance(e)) {
      this.addRemoveFields(this.inputCurrentValue);
    }
  },

  processAdd: function(e) {
    if (this.isCurrentInstance(e)) {
      this.addField(e);
      this.model.set("processAddCount", this.model.get("processAddCount") + 1);
    }
  },

  /**
   * Process the removal of a Field if a data-field-replicator-remove is clicked
   * @param e
   */
  processRemove: function(e) {
    if (this.isCurrentInstance(e)) {
      this.removeField(e);
    }
  },

  /**
   * This method returns an array with the els that belong to the current instance
   *
   * @param els Array of els.
   * @returns {Array}
   */
  excludeOutScopedElements: function(els) {
    return _.filter(
      els,
      function(el) {
        // If outputView is present, elements that belong to parent field-replicator won't have it as direct parent
        // because it was detached from DOM structure and attached to outputView container
        if (
          this.data.outputView &&
          !$(el).parents('[data-component="field-replicator"]').length
        ) {
          return true;
        } else {
          return (
            $(el)
              .parents('[data-component="field-replicator"]')
              .first()
              .data("field-replicator-uid") === this.cid
          );
        }
      }.bind(this)
    );
  },

  /**
   * Checks if the triggers are part of the same instance
   * @param e
   */
  isCurrentInstance: function(e) {
    // Checks that it's displaying on output view specified container
    if (
      this.data.outputView &&
      $(e.currentTarget).parents(this.data.outputView)
    ) {
      return true;
    }

    if (e.currentTarget.dataset["fieldReplicatorExternalTrigger"]) {
      var parentComp = $('[data-field-replicator-name="' + this.name + '"]')
        .first()
        .data("field-replicator-uid");
    } else {
      var parentComp = $(e.currentTarget)
        .parents('[data-component="field-replicator"]')
        .first()
        .data("field-replicator-uid");
    }

    return parentComp && parentComp === this.cid;
  },

  toggleActionButtons: function() {
    var currentMax = this.$el.data("fieldReplicatorMaxCount"),
      // Setting element container where we will check for Add button
      element = this.data.outputView ? this.fieldContainer : this.$el;

    var currentCount = this.excludeOutScopedElements(
      this.fieldContainer.find("[data-field-replicator-source]")
    ).length;

    // hide the add button if we have reached the maximum fields allowed
    if (currentCount >= currentMax) {
      element.find("[data-field-replicator-add]").addClass("is-disabled");

      // show the add button if we are under the maximum fields allowed
    } else if (currentCount < currentMax) {
      element.find("[data-field-replicator-add]").removeClass("is-disabled");
    }

    return this;
  },

  reset: function() {
    this.removeField({ removeAll: true });
  },

  /**
   * This method adds a field input to the view.
   */
  addField: function() {
    // Increment the counter
    let trip_type = this.$el.data("fieldReplicatorTrip_type");
    let currentCount = ++this.excludeOutScopedElements(
      this.fieldContainer.find("[data-field-replicator-source]")
    ).length;

    this.fieldCounter = this.inputCurrentValue
      ? parseInt(this.inputCurrentValue)
      : currentCount;

    let count =
      trip_type === "MC" || trip_type === "RT"
        ? this.model.get("processAddCount")
        : currentCount;

    // Create new element
    var newField = $(
      this.fieldTemplate({
        count: count,
        ordinal_count: currentCount,
        parent_index: this.getParentId()
      })
    );

    // Add Element
    if (this.data.outputView) {
      this.outputViewInstance.currentContent.append(newField);
    } else {
      this.fieldContainer.append(newField);
    }

    // Store field index
    // we use currentCount as is the iteration count and not the whole
    newField.attr("data-field-replicator-source-index", currentCount);

    // If data source is a component, init
    if ($(newField).data("component") !== undefined) {
      $(newField).component();
    }

    // If data source if a module, init
    if ($(newField).data("module") !== undefined) {
      $(newField).module();
    }

    // Then, cascade init
    let childComponents = $(newField).loadComponents();
    $(newField).loadModules();

    // Save a reference of the child components to have them accessible to unload them
    $(newField).data("childComponents", childComponents);

    // Toggle Action Stuff
    this.toggleActionButtons();

    //Trigger Happy Event
    this.$el.trigger("field-added");

    // renumber the displayed fields
    this.renumberFields();

    // Identify if this component is nested in a form component
    var parentForm = this.$el.parents('[data-component="form"]');

    // If so, reflow the form to have it parse validators and conditions for new elements
    if (parentForm.length) {
      parentForm.component().reflow();
    }
  },

  /**
   * This method removes a field from the view.
   *
   * @param e The click e
   */
  removeField: function(e) {
    let currentFieldContainer = this.fieldContainer.find(
      "[data-field-replicator-source]"
    ).length
      ? this.fieldContainer
      : this.fieldContainerOld;

    if (typeof currentFieldContainer === "undefined") {
      return;
    }

    let fields = this.excludeOutScopedElements(
      currentFieldContainer.find("[data-field-replicator-source]")
    );
    let currentCount = fields.length;

    let currentMinimum = fields.length - this.minimumItems;

    // remove the block if there is more than one block displaying
    if (e && _.has(e, "type")) {
      if (currentCount > this.minimumItems) {
        const field = $(e.currentTarget)
          .parents("[data-field-replicator-source]")
          .first();

        const childComponents = $(field.data("childComponents"));

        // Unload child components
        childComponents.trigger("unload");

        // Remove field
        field.remove();

        currentCount -= 1;
        this.fieldCounter = currentCount;
      }
    } else if (e && _.has(e, "removeAll")) {
      _.each(
        $(fields),
        function(field, index) {
          if (index < currentMinimum && currentMinimum >= this.minimumItems) {
            field = $(field);
            const childComponents = $(field.data("childComponents"));

            // Unload child components
            childComponents.trigger("unload");

            // Remove field
            field.remove();
          }
        }.bind(this)
      );
      this.fieldCounter = this.minimumItems;

      if (this.data.outputView) {
        this.outputViewInstance.render(true, currentFieldContainer);
      }
    } else {
      const field = $(_.last(fields));

      const childComponents = $(field.data("childComponents"));

      // Unload child components
      childComponents.trigger("unload");

      // Remove field
      field.remove();

      this.fieldCounter--;
    }

    this.toggleActionButtons();

    this.$el.trigger("field-removed");

    this.renumberFields();
  },

  addRemoveFields: function(num) {
    let fields = this.excludeOutScopedElements(
      this.fieldContainer.find("[data-field-replicator-source]")
    );

    if (fields.length < num) {
      _.times(num - fields.length, this.addField.bind(this));
    } else if (fields.length > num) {
      _.times(fields.length - num, this.removeField.bind(this));
    } else {
      this.fieldCounter = fields.length;
    }

    this.renumberFields();
  },

  /**
   * This method renumbers the field sections display number.
   */
  renumberFields: function() {
    this.model.set("currentCount", this.fieldCounter);

    if (this.externalTriggers) {
      this.externalTriggers.attr("data-duplicator-count", this.fieldCounter);
      this.externalTriggers.find("option").removeAttr("selected");
      this.externalTriggers
        .find("option[value=" + this.fieldCounter + "]")
        .prop("selected", true)
        .attr("selected", true);
    }

    let sourceElements = this.fieldContainer.find(
      "[data-field-replicator-source]"
    );
    let counterElements = this.data.outputView
      ? this.excludeOutScopedElements(
          this.outputViewInstance.$el.find("[data-field-replicator-counter]")
        )
      : this.excludeOutScopedElements(
          this.$("[data-field-replicator-counter]")
        );

    this.reassignSourceIndexes(sourceElements);

    this.reassignSourceIndexes(counterElements, true);

    // Set 'fieldCounter` value to `counterTotal` target element in DOM
    if (this.counterTotal) {
      this.counterTotal.html(this.fieldCounter);
    }

    return this;
  },

  /**
   * this method reassign attributes and update content to renumberFields method
   */

  reassignSourceIndexes: function(elements, updateContent) {
    let currentCount = 1;

    // Check if elements is a jQuery collection or an array
    let elementsArray = elements instanceof $ ? elements.toArray() : elements;

    if (elementsArray && Array.isArray(elementsArray)) {
      elementsArray?.forEach(function(element) {
        let $element = $(element);

        $element.attr("data-field-replicator-source-index", currentCount);

        if (updateContent) {
          $element.html(currentCount);
        }

        currentCount++;
      });
    }
  },

  getParentId: function() {
    var id = false;

    if (this.parentFieldReplicator) {
      var counter = this.$el.parents("[data-field-replicator-source]");
      id = counter.length ? counter.data("field-replicator-source-index") : id;
    }

    return id;
  },

  /**
   * Optional: This method limits the number of items that can be selected in the TARGET element
   * @data-attributes: data-field-replicator-maximum-items,      value: maximum options number in the TRIGGER element
   *                   data-field-replicator-maximum-trigger-id, value: TRIGGER element identificator
   *                   data-field-replicator-maximum-target,     value: data-field-replicator-maximum-trigger-id value, link between TRIGGER and TARGET elements
   */
  limitItemsNumber: function(e) {
    if (this.maximunItemsNumber) {
      //Getting trigger element
      var trigger_element = e.target;
      //Getting target element
      var target_element = this.$el.find(
        "[data-field-replicator-maximum-target='" +
          $(trigger_element).data("field-replicator-maximum-trigger-id") +
          "']"
      );
      //Number of items allowed before exceding the data-field-replicator-maximum-items value
      var maximumNumberItemsLeft =
        $(trigger_element).data("field-replicator-maximum-items") -
        $(trigger_element).val();

      $(target_element)
        .find("option")
        .each(function() {
          if (this.value > maximumNumberItemsLeft) {
            $(this).prop("disabled", true);
          } else {
            $(this).prop("disabled", false);
          }
        });
      if (
        $(target_element)
          .find(":selected")
          .val() > maximumNumberItemsLeft
      ) {
        $(target_element)
          .find("option")
          .first()
          .prop("selected", true)
          .change();
      }
    }
  },

  /**
   * This method initializes the view
   */
  initialize: function() {
    // field replicator results container
    this.name = this.el.dataset["fieldReplicatorName"];
    this.fieldReplicatorDisableInput = this.el.dataset[
      "fieldReplicatorDisableInput"
    ];
    this.fieldContainer = this.$("[data-field-replicator-results]").first();

    //implementing model for future use
    this.settings = _.extend(this.$el.data("settings"));
    this.model = new stateModel(this.settings);
    this.model.set(this.settings);

    // Items on current field replicator scope
    var currentItems = this.fieldContainer.find(
        "[data-field-replicator-source]"
      ),
      outOfScopeItems = this.fieldContainer
        .find(
          "[data-component='field-replicator'] [data-field-replicator-source]"
        )
        .toArray();

    // If outOfScopeItems has values
    if (outOfScopeItems.length) {
      // Remove `outOfScopeItems` elements from `currentItems` list
      currentItems = currentItems.filter(
        (i, elem) => outOfScopeItems.indexOf(elem) === -1
      );
    }

    // Items count
    this.fieldCounter = currentItems.length;

    // Component Data from element
    this.data = this.$el.data();

    // If we specify a template name use it as id to search for the template.
    var templateSelector = this.data["fieldReplicatorTemplate"],
      innerTemplate = this.$('[data-template="' + templateSelector + '"]');

    // If the template was not found with that id or if the id wasn't specified search for [data-template] only.
    if (!templateSelector || (templateSelector && !innerTemplate.length)) {
      innerTemplate = this.$("[data-template]").first();
    }

    //Remove template from DOM
    this.$('[data-template="' + templateSelector + '"]').remove();

    // If we didn't find any template internally we look in the entire DOM
    this.fieldTemplate = innerTemplate.length
      ? innerTemplate.html()
      : $(
          '[data-field-replicator-external-template="' +
            this.$el.data("field-replicator-template") +
            '"]'
        ).html();

    //Replace custom variable syntax for templates within templates
    this.fieldTemplate = this.fieldTemplate
      ? this.fieldTemplate.replace(/%%(.*?)%%/g, "{{$1}}")
      : "";

    this.fieldTemplate = Handlebars.compile(this.fieldTemplate);

    //Integration with Output View
    if (this.data.outputView) {
      //when control element to print total is required
      this.data.fieldReplicatorTotal
        ? (this.counterTotal = this.$el.find(
            "[data-field-replicator-counter-total]"
          ))
        : false;
      this.outputViewInstance = new outputView({
        component: this,
        settings: this.data
      });
      // Sync component template with outputView template
      this.outputViewInstance.template = this.fieldContainer.clone(true);
      // Hiding container created with smarty from DOM
      // It will be removed with output view 1st render
      this.fieldContainerOld = this.fieldContainer.addClass("is-hidden");
      // Re-assigning container
      this.fieldContainer = this.outputViewInstance.$el;

      // Overwriting output view render function
      this.outputViewInstance.render = function(reset, currentContentReset) {
        // content saved in the output view current instance
        // We will modify the outputViewInstance.currentContent when a field is added/removed
        this.currentContent =
          currentContentReset || this.currentContent || this.template;
        if (reset) {
          this.currentContent = this.template;
          this.$el.loadComponents();
        }

        if (!this.loaded) {
          // @TODO: Check if there is no issue when adding new rooms
          // Attach content
          this.$el.html(this.currentContent);
          // Removing from DOM container created with smarty
          this.component.fieldContainerOld.remove();
          this.loaded = true;
          this.$el.loadComponents();
        }
      };
    }

    // Store Unique ID for current instance of Field Replicator
    this.$el.data("field-replicator-uid", this.cid);

    // Find out if it's a nested Field Replicator
    this.parentFieldReplicator = this.$el.parents(
      '[data-component="field-replicator"]'
    );

    // If it is, store its parent, otherwise store a flag.
    this.parentFieldReplicator = this.parentFieldReplicator.length
      ? this.parentFieldReplicator
      : false;

    // Add indexes to smarty rendered fields
    if (this.fieldCounter) {
      // Make sure that starts from index 0.
      this.fieldCounter = 0;
      currentItems.each(
        function(i, el) {
          if (currentItems.length > i) {
            this.fieldCounter++;
          }
          $(el).attr("data-field-replicator-source-index", this.fieldCounter);
        }.bind(this)
      );
    }

    // Toggle Action Stuff
    this.toggleActionButtons();

    // if instance have external triggers, field replicator input must
    // have the same value as the field replicator instance

    if (!this.fieldReplicatorDisableInput) {
      let fieldReplicatorTriggerType = this.model.get("hasExternalTrigger")
        ? "[data-field-replicator-input=" + this.name + "]"
        : "[data-field-replicator-input]";

      this.externalTriggers = $(document).find(
        "[data-field-replicator-external-trigger=" + this.name + "]"
      );

      // Bind input change to method
      $(document).on(
        "change.fieldReplicator." + this.cid,
        fieldReplicatorTriggerType,
        this.processInputChange.bind(this)
      );
    }

    //Find data-field-replicator-maximum-trigger-id and data-field-replicator-maximum-items attributes, set the flag to true if they are found
    this.maximunItemsNumber = !!this.$el.find(
      "[data-field-replicator-maximum-trigger-id][data-field-replicator-maximum-items]"
    );

    this.minimumItems = this.$el.attr("data-field-replicator-min-items") || 1;

    this.$el.find("[data-field-replicator-input]").trigger("change");

    const childComponents = this.$el.loadComponents();
    this.$el.loadModules();

    // Load product specific component and modules
    _.each(this.$("[data-path]"), component => {
      const loadedComponent = $(component).component();

      if (loadedComponent) {
        childComponents.push(loadedComponent);
      }

      $(component).module();
    });

    // We do this to keep track of the list of components inside each field individually
    // For future handling. We only do this if there are components present, if not we skip the extra processing.
    if (childComponents.length) {
      var fields = this.excludeOutScopedElements(
        this.fieldContainer.find("[data-field-replicator-source]")
      );

      _.each($(fields), function(field) {
        field = $(field);

        // If it's a component of a module load it
        if (field.data("component")) {
          field.component();
        }

        if (field.data("module")) {
          field.module();
        }

        // This doesn't load the components again, it's only meant to retrieve the list
        const childComponents = field.loadComponents();

        // Save a reference of the child components in the field to have them accessible to unload them
        field.data("childComponents", childComponents);
      });
    }
  }
});

module.exports = FieldReplicator;
