/* this file contains nothing but hacks that were implemented for a client. this all goes against how the system was
designed to work and only copied over for backwards compatibility.
* */

var $ = require("jquery");

var PaymentUtility = require("./_payment.utility");

var CCHackView = require("./_payment.credit-card");

var ExternalHackView = Backbone.View.extend({
  events: {
    "click [data-payment-method-type-input]": "externalAddressPopulate",
    "states-updated [data-country-state-select]": "externalCountryPopulate",
    "keyup [data-points-to-redeem]": "redeemInputLimitTimer",

    // @TODO: feel free to create a module just for this
    "click [data-split-payment-button]": "splitPaymentCalculator"
  },

  initialize: function(options) {
    this.settings = options.settings;

    this.settings.hasAddress = options.settings.billingInfo.length
      ? !options.settings.billingInfo.billing_address.length
      : 0;

    this.dispatcher = options.dispatcher;

    this.paymentAmount = 0;
    this.totalAmount = options.settings.totalAmount;
    this.totalAmountPoints = false;

    this.redeemInputTimer;
    this.paymentFormWrapper = this.$el.closest("form");

    this.initAsyncCall = true;

    this.splitTemplate = this.$("[data-template='corporate-card-wrapper']")
      .length
      ? Handlebars.compile(
          this.$("[data-template='corporate-card-wrapper']").html()
        )
      : {};

    var PaymentMethodTypes = new Backbone.Collection(
      options.settings.paymentMethodTypes
    );

    this.corporateCreditCardWrapper = this.$(
      '[data-payment-method-row="corporate_credit_card"]'
    );

    new CCHackView({
      el: this.corporateCreditCardWrapper,
      paymentMethodType: PaymentMethodTypes.find({
        type_label: "corporate_credit_card"
      }),
      paymentMethods: options.settings.paymentMethods.credit_card,
      dispatcher: options.dispatcher
    });

    this.splitPaymentCalculator();

    this.dispatcher.on(
      "paymentOption:changed",
      this.processPaymentOptionChange.bind(this)
    );

    this.dispatcher.on(
      "paymentMethodType:changed",
      this.processPaymentMethodTypeChange.bind(this)
    );

    this.dispatcher.on(
      "initFromBillingInfo",
      this.initFromBillingInfo.bind(this)
    );
  },

  initFromBillingInfo: function(data) {
    this.processPaymentOptionChange(
      PaymentUtility.buildPaymentOptionChangeDataFromBillingInfo(
        data.billingInfo
      )
    );

    this.processPaymentMethodTypeChange(
      PaymentUtility.buildPaymentMethodTypeChangeDataFromBillingInfo(
        data.billingInfo
      )
    );
  },

  processPaymentOptionChange: function(data) {
    this.paymentAllowZero = data.allowZero
      ? data.allowZero
      : data.paymentOption.get("allow_zero_amount");
    this.paymentAmount = this.paymentAllowZero ? this.totalAmount : data.amount;

    this.splitPaymentCalculator(true);
  },

  processPaymentMethodTypeChange: function(data) {
    let corporateCardContent = this.$(
      '[data-payment-method-row="corporate_credit_card"]'
    );

    if (data.paymentMethodType.get("type_label") === "corporate_credit_card") {
      corporateCardContent.removeClass("is-hidden");
      this.$("[data-points-to-redeem]").prop("disabled", false);

      this.processSplitPayment(this.displayCalculatorFields);
    } else {
      corporateCardContent.addClass("is-hidden");
      this.$("[data-points-to-redeem]").prop("disabled", true);
      this.processSplitPayment(false);
    }

    // this to override the logic of making everything enabled
    // while picking new methods
  },

  processSplitPayment: function(openCalculator) {
    let splitPaymentBlock = this.$("[data-split-payment-card-block]");

    if (openCalculator && splitPaymentBlock.length) {
      splitPaymentBlock.component().open();
    } else if (splitPaymentBlock.length) {
      splitPaymentBlock.component().close();
    }
  },

  // @todo delete after is done via configuration

  // This method populates and clears the billing address section if a externalBillingAddress Object exists

  externalAddressPopulate: function(endpointCall) {
    // As the logic from the external address needs to meet how much money is owed, it will only work when the
    // remainingCost is = 0
    let remainingCost = this.currentRemainingCost;

    let addressBlock = REVELEX.externalBillingAddress || {};
    let paymentAddressBlock =
      this.$("[data-address-block='billing']").parent() || "";

    paymentAddressBlock.removeClass("corporate_credit_card_enabled");
    if (
      (this.$("[data-payment-method-type-input='corporate_credit_card']").is(
        ":checked"
      ) ||
        endpointCall === true) &&
      remainingCost === 0 &&
      Object.keys(addressBlock).length
    ) {
      paymentAddressBlock.addClass("corporate_credit_card_enabled");
      paymentAddressBlock.find("[data-address-1]").val(addressBlock.address1);
      paymentAddressBlock.find("[data-address-2]").val(addressBlock.address2);
      paymentAddressBlock.find("[data-city]").val(addressBlock.city);

      this.externalCountryPopulate(addressBlock.country);

      paymentAddressBlock.find("[data-state]").val(addressBlock.state);

      // Doing this to remove any bad format that comes from the zip code
      paymentAddressBlock
        .find("[data-postal-code]")
        .val(addressBlock.postalCode)
        .focus()
        .blur();
      this.settings.hasAddress = false;
    } else if (Object.keys(addressBlock).length && !this.settings.hasAddress) {
      // if saved addresses are provided, make the dropdown to go to its initial value
      if (paymentAddressBlock.find("[data-profile-addresses]").length) {
        paymentAddressBlock
          .find("[data-profile-addresses]")
          .find("option:selected")
          .prop("selected", false);
        paymentAddressBlock
          .find("[data-profile-addresses]")
          .find("option:first-child")
          .prop("selected", true);
      }

      paymentAddressBlock.find("[data-address-1]").val("");
      paymentAddressBlock.find("[data-address-2]").val("");
      paymentAddressBlock.find("[data-city]").val("");
      paymentAddressBlock.find("[data-state]").val("");
      paymentAddressBlock.find("[data-postal-code]").val("");
    }
  },

  // Country State widget works independently we need to trigger a change when the address get prepopulated
  externalCountryPopulate: function(country) {
    let paymentAddressBlock = this.$("[data-address-block='billing']") || "";
    let paymentAddressCountry = paymentAddressBlock.find("[data-country]");

    if (
      typeof country === "string" &&
      paymentAddressCountry.val() !== country
    ) {
      paymentAddressCountry.val(country).trigger("change");
    }
  },

  // Controlling the points limit and giving a 2 second gap per keystroke
  redeemInputLimitTimer: function() {
    clearTimeout(this.redeemInputTimer);

    this.redeemInputTimer = setTimeout(() => {
      this.redeemInputLimit();
    }, 1500);

    this.toggleRedeemButton();
  },

  toggleRedeemButton: function() {
    let currentInput = this.$("[data-points-to-redeem]");
    let currentCalculatorButton = this.$("[data-split-payment-button]");

    if (currentInput.val() !== currentInput.attr("data-current-points")) {
      currentCalculatorButton.removeAttr("disabled");
    } else if (!this.initAsyncCall) {
      currentCalculatorButton.attr("disabled", true);
    }
  },

  // Validating the max for the points to redeem input

  redeemInputLimit: function() {
    let currentInput = this.$("[data-points-to-redeem]");

    if (currentInput) {
      let currentValue = parseFloat(currentInput.val()).toFixed(2);
      let currentMax = parseFloat(currentInput.attr("max")).toFixed(2);

      currentInput.val(currentValue);

      if (parseFloat(currentValue) <= 0) {
        currentInput.val(0);
      } else if (parseFloat(currentValue) >= parseFloat(currentMax)) {
        currentInput.val(currentMax);
      }

      this.toggleRedeemButton();
    }
  },

  // split payment calculator logic

  splitPaymentCalculator: function(initPaymentMethodChange) {
    this.redeemInputLimit();
    // Checking if there's an amount to be paid by the client
    let corporateCardButton = this.$(
      "[data-payment-method-type-input='corporate_credit_card']"
    );

    let creditCardButton = this.$(
      "[data-payment-method-type-input='credit_card']"
    );

    // Guaranteed comes with the amount of 1 and it that payment method
    // doesnt allow split payment or point redemption
    if (this.paymentAmount > 1) {
      let url = this.settings.calculatorURL;
      let calculatorWrapper = this.$("[data-split-payment-calculator-wrapper]");

      let redeemPointsInput = calculatorWrapper.find("[data-points-to-redeem]");
      let availablePointsInput = this.$("[data-available-points]");

      let pointsToRedeem =
        redeemPointsInput.val() || availablePointsInput.val() || 0;

      if (initPaymentMethodChange === true) {
        if (this.paymentAmount <= availablePointsInput.val()) {
          pointsToRedeem = this.paymentAmount;
        } else {
          pointsToRedeem = availablePointsInput.val() || 0;
        }
      }

      let errorDialog = this.$("[data-dialog-error-calculator]").component();

      const reward_data = {
        "reward_data[amount]":
          pointsToRedeem === 0 && this.paymentAllowZero
            ? this.paymentAmount
            : pointsToRedeem,
        "reward_data[total]": this.paymentAmount
      };

      let splitPaymentButton = this.$("[data-split-payment-button]");

      // send request
      $.ajax({
        type: "GET",
        dataType: "json",
        cache: false,
        url: url,
        data: $.param(reward_data),
        statusCode: {
          500: function() {
            errorDialog.open();
          }
        },
        beforeSend: () => {
          this.$("[data-split-payment-calculator]").addClass("is-loading");

          if (!this.initAsyncCall) {
            splitPaymentButton.attr("disabled", true);
          }
        },
        success: response => {
          if (response.success) {
            response.data.AvailablePoints = parseFloat(
              response.data.AvailablePoints
            );
            response.data.MarkupPercent = parseFloat(
              response.data.MarkupPercent
            );
            response.data.PointsRemaining = parseFloat(
              response.data.PointsRemaining
            );
            response.data.RemainingCost = parseFloat(
              response.data.RemainingCost
            );
            response.data.RemainingCostWithMarkup = parseFloat(
              response.data.RemainingCostWithMarkup
            );
            response.data.total = this.paymentAmount;

            response.data.usablePoints = parseFloat(
              reward_data["reward_data[amount]"]
            );

            // generating the max for the calculator

            if (response.data.total >= response.data.AvailablePoints) {
              response.data.currentMax = response.data.AvailablePoints;
            } else {
              response.data.currentMax = response.data.total;
            }

            if (response.data.usablePoints >= response.data.currentMax) {
              response.data.usablePoints = response.data.currentMax;
            }

            this.currentRemainingCost = response.data.RemainingCost;

            if (this.initAsyncCall) {
              response.data.splitEnabled = true;
            }

            if (corporateCardButton.is(":checked")) {
              this.externalAddressPopulate(true);
            }

            let currentSplitCalculator = this.$(
              "[data-split-payment-calculator-wrapper]"
            );

            if (currentSplitCalculator.length) {
              currentSplitCalculator.html(this.splitTemplate(response.data));

              this.paymentFormWrapper.component().reflow();

              this.$el.loadComponents();
              this.$el.loadModules();

              if (response.data.RemainingCost > 0) {
                this.displayCalculatorFields = true;
              } else {
                this.displayCalculatorFields = false;
              }

              if (corporateCardButton.is(":checked")) {
                this.processSplitPayment(this.displayCalculatorFields);
              }
            }

            if (response.data.usablePoints === 0) {
              corporateCardButton.addClass("corporate-zero-funds");
            } else {
              corporateCardButton.removeClass("corporate-zero-funds");
            }
          } else {
            this.splitPaymentErrorMessages(response);
            corporateCardButton.prop({ disabled: true, checked: false });
            corporateCardButton.removeClass("corporate-zero-funds");
          }

          this.$("[data-split-payment-calculator]").removeClass("is-loading");

          // check if we currently have any points from the endpoint or not
          let currentUsablePoints = response.data.usablePoints ?? 0;

          if (!currentUsablePoints) {
            creditCardButton.trigger("click");
          }

          this.initAsyncCall = false;
        },
        error: () => {
          this.splitPaymentErrorMessages(response);
          this.$("[data-split-payment-calculator]").removeClass("is-loading");
          this.$("[data-split-payment-calculator]").removeClass("disabled");
          corporateCardButton.attr("disabled", true);
        }
      });
    } else {
      corporateCardButton.attr("disabled", true);
    }
  },

  splitPaymentErrorMessages: function(response) {
    let errorDialog = this.$("[data-dialog-error-calculator]").component();
    let errorDialogBody = this.$("[data-dialog-error-calculator-body]");

    let errors = "";

    if (response.messages.fault.length) {
      response.messages.fault.forEach(
        element => (errors = errors.concat("<p>" + element + "</p>"))
      );
    }
    if (response.messages.general.length) {
      response.messages.general.forEach(
        element => (errors = errors.concat("<p>" + element + "</p>"))
      );
    }
    errorDialogBody.html(errors);
    errorDialog.reflow().open();
  }
});

module.exports = ExternalHackView;
