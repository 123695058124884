var PaymentUtility = require("./_payment.utility");

var CreditCardFeeView = Backbone.View.extend({
  initialize: function(options) {
    this.setDefaults(options);

    this.dispatcher = options.dispatcher;

    this.template = Handlebars.compile(
      this.$("[data-credit-card-fee-text]").length
        ? this.$("[data-credit-card-fee-text]").html()
        : ""
    );

    this.dispatcher.on(
      "paymentOption:changed",
      this.processPaymentOptionChange.bind(this)
    );
    this.dispatcher.on(
      "paymentMethod:changed",
      this.processPaymentMethodChange.bind(this)
    );

    this.dispatcher.on(
      "initFromBillingInfo",
      this.initFromBillingInfo.bind(this)
    );
  },

  initFromBillingInfo: function(data) {
    this.processPaymentOptionChange(
      PaymentUtility.buildPaymentOptionChangeDataFromBillingInfo(
        data.billingInfo
      )
    );

    this.processPaymentMethodChange(
      PaymentUtility.buildPaymentMethodChangeDataFromBillingInfo(
        data.billingInfo
      )
    );
  },

  processPaymentOptionChange: function(data) {
    this.paymentAmount = data.amount;
  },

  processPaymentMethodChange: function(data) {
    this.creditCard = data.paymentMethod.get("credit_card");

    this.render();
  },

  /**
   * This method renders the view.
   */
  render: function() {
    var displayContainer = this.$("[data-credit-card-fee-display]");

    displayContainer.empty();

    if (!this.creditCard) {
      displayContainer.append(this.template({ defaultText: true }));
    } else {
      var fee = 0;
      var feeCap = null;
      var isPercentage = false;
      var locale = REVELEX.settings.currentLocale.replace("_", "-");

      if (this.creditCard.fee_percentage) {
        fee = this.paymentAmount * (this.creditCard.fee_percentage / 100);
        feeCap = this.creditCard.fee_cap;

        if (feeCap && fee > feeCap) {
          fee = feeCap;
        } else {
          isPercentage = true;
        }
      } else if (this.creditCard.fee) {
        fee = this.creditCard.fee;
      }

      if (fee > 0) {
        if (!isPercentage) {
          displayContainer.append(
            this.template({
              fee: fee.toLocaleString(locale, {
                style: "currency",
                currency: this.currency
              }),
              feeText: true
            })
          );
        } else {
          displayContainer.append(
            this.template({
              fee: fee.toLocaleString(locale, {
                style: "currency",
                currency: this.currency
              }),
              credit_card: this.creditCard,
              fee_percentage: this.creditCard.fee_percentage,
              percentageText: true
            })
          );
        }
      }
    }
  },

  /**
   * This method sets the default properties.
   */
  setDefaults: function(options) {
    this.creditCard = null;
    this.currency = "";
    this.paymentAmount = 0;
    this.currency = options.currency;
  }
});

module.exports = CreditCardFeeView;
