var $ = require("jquery");

var AuthView = Backbone.View.extend({
  setCompletionStatus: function() {
    // check if the iframe contents contain the dom element with the completed attr
    if (
      this.$el.contents().find("[data-payment-authentication-completed]").length
    ) {
      this.is_completed = true;
    }

    // trigger the status updated event
    Backbone.Events.trigger("paymentAuthentication:statusUpdated");
  },

  getCompletionStatus: function() {
    return this.is_completed;
  },

  initialize: function() {
    this.is_completed = false;
  }
});

var PaymentAuthentication = Backbone.View.extend({
  checkCompletionStatus: function() {
    var allCompleted = [];

    // check the status of all the views
    _.each(this.authViews, function(View) {
      if (View.getCompletionStatus()) {
        // the view is completed so add to the all completed list
        allCompleted.push(true);
      }
    });

    // make sure the number of completed views is the same as the number of authorization views
    if (allCompleted.length === this.authViews.length) {
      // trigger loader
      Backbone.Events.trigger("loadingSplashOn");

      // redirect the browser to the designated redirect url
      window.document.location = this.$el.data(
        "payment-authentication-redirect"
      );
    }
  },

  initialize: function() {
    var self = this;

    self.authViews = [];

    // create and track the views for each authentication iframe
    this.$("[data-payment-authentication-frame]").each(function() {
      var View = new AuthView({
        el: this
      });

      self.authViews.push(View);
    });

    // add a listener for when an authorization as been completed. ie. the iframe was redirected back to our system
    $(document).on("paymentAuthentication:authCompleted", function() {
      // have all the view set their completion status
      _.each(self.authViews, function(View) {
        View.setCompletionStatus();
      });
    });

    // add a listener to check the completion status if the status updated was triggered
    Backbone.Events.on(
      "paymentAuthentication:statusUpdated",
      this.checkCompletionStatus.bind(this)
    );
  }
});

module.exports = PaymentAuthentication;
