// Commons Chunk Declaration
var Commons = registerComponents({
  // Import JS components that belong to this chunk
  components: require.context("./components/", false, /.js/),
  // Import JS Modules that belong to this chunk
  modules: require.context("./modules/", false, /.js/),
  // Define Path name
  path: "commons"
});

module.exports = Commons;
