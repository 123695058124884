var $ = require("jquery");
require("timepicker");

var Timepicker = Backbone.View.extend({
  initialize: function() {
    var options = {};
    this.settings = this.$el.attr("data-settings");
    var parsedSettings = JSON.parse(this.settings);
    options = $.extend(options, parsedSettings);
    $(this.$el).timepicker(options);
  }
});

module.exports = Timepicker;
