var postalCodeMask = require("./../../utilities/libraries/masks/postalCodesByCountry");
//Regex for each country postal code
var postalCodeRegex = require("./../../utilities/libraries/regex/postalCodesByCountry");

var PaymentUtility = require("./_payment.utility");

var Address = Backbone.Model.extend({});

var AddressView = Backbone.View.extend({
  /**
   * The internal event listeners for the view.
   */
  events: {
    "change [data-profile-addresses]": "processAddressSelection",
    "change [data-country]": "processCountryChange",
    "focus [data-postal-code]": "unMaskPostalCode",
    "states-updated [data-country-state-select]": "selectState"
  },

  /**
   * This method performs the initialization of the view.
   */
  initialize: function(options) {
    this.dispatcher = options.dispatcher;

    this.maskPostalCode();

    this.addressType = this.$el.data("address-block");

    this.dispatcher.on(
      "paymentMethodType:changed",
      this.processPaymentMethodTypeChange.bind(this)
    );

    this.dispatcher.on("creditCard:changed", this.selectAddress.bind(this));

    this.dispatcher.on(
      "initFromBillingInfo",
      this.initFromBillingInfo.bind(this)
    );

    if (
      this.addressType === "billing" &&
      this.$("[data-additional-email-language]").length
    ) {
      this.$("[data-state],[data-country]").on(
        "change",
        this.processAdditionalEmailLanguage.bind(this)
      );

      this.$("[data-country-state-select]").on(
        "states-updated",
        this.processAdditionalEmailLanguage.bind(this)
      );

      this.processAdditionalEmailLanguage();
    }
  },

  processAdditionalEmailLanguage: function() {
    var lang = this.$("[data-additional-email-language]").data(
      "additionalEmailLanguage"
    );

    if (
      this.$("[data-country]").val() === lang?.required_country &&
      this.$("[data-state]").val() === lang?.required_state
    ) {
      this.$("[data-additional-email-language]").removeClass("is-hidden");
    } else {
      this.$("[data-additional-email-language]").addClass("is-hidden");
    }
  },

  initFromBillingInfo: function(data) {
    this.processPaymentMethodTypeChange(
      PaymentUtility.buildPaymentMethodTypeChangeDataFromBillingInfo(
        data.billingInfo
      )
    );

    this.processAdditionalEmailLanguage();
  },

  processPaymentMethodTypeChange: function(data) {
    var paymentMethodAssociations = new PaymentUtility.PaymentMethodAssociationCollection(
      data.paymentOption.get("payment_method_associations")
    );

    var Assoc =
      paymentMethodAssociations.get(data.paymentMethodType.id) ||
      new PaymentUtility.PaymentMethodAssociation();

    switch (this.addressType) {
      case "billing":
        this.render(
          Assoc.get("billing_address_display"),
          Assoc.get("billing_address_required")
        );
        break;
      case "shipping":
        this.render(
          Assoc.get("shipping_address_display"),
          Assoc.get("shipping_address_required")
        );
        break;
    }
  },

  /**
   * This method processes the change of address selection.
   *
   * @param event  The data to use to process the change
   */
  processAddressSelection: function(event) {
    var selection = $(event.currentTarget).find("option:selected");

    this.currentProfileAddress = new Address(selection.data("profile-address"));

    this.renderAddress();
  },

  /**
   * This method processes the change of country
   *
   * @param event
   */
  processCountryChange: function(event) {
    this.maskPostalCode($(event.target).val());
  },

  /** this is used to clear postal code mask from input when user is interacting with the field */

  unMaskPostalCode: function() {
    this.$("[data-postal-code]").val(
      this.$("[data-postal-code]").inputmask("unmaskedvalue")
    );
  },

  /**
   * This method masks the postal code input based on the supplied country
   *
   * @param country
   */

  maskPostalCode: function() {
    var country = this.$("[data-country]").val(),
      postalCodePattern = postalCodeMask(country),
      postalCodeValidation = postalCodeRegex(country),
      enabledCountryField = this.$("[data-country]:enabled").val();

    this.$("[data-postal-code]").inputmask(postalCodePattern, {
      placeholder: "",
      showMaskOnHover: false,
      showMaskOnFocus: false,
      skipOptionalPartCharacter: " ",
      onincomplete: function() {
        this.maskPostalCode(country);
      }.bind(this)
    });

    //Checking if the selected country enabled and require a postal code. If there is no regex for the selected
    // country, disable the postal code and remove required class from the label.
    if (enabledCountryField) {
      if (postalCodeValidation === "") {
        this.$("[data-postal-code]")
          .val("")
          .attr("disabled", true)
          .siblings("label")
          .removeClass("is-required");
      } else {
        this.$("[data-postal-code]")
          .attr("disabled", false)
          .siblings("label")
          .addClass("is-required");
      }
    }

    if (country === "GB" && this.$("[data-postal-code]").val().length > 4) {
      /** UK Postal codes last 3 digits are required, doing this will force any string to make a space before the last 3 digits */
      this.$("[data-postal-code]").val(
        this.$("[data-postal-code]")
          .val()
          .replace(/\s|_/g, "")
          .toUpperCase()
          .replace(/.{3}$/, " $&")
      );
    }
  },

  /**
   * This method updates the view display based on the supplied address.
   *
   * @param address The address data to use to render the view
   */
  renderAddress: function() {
    this.$("[data-address-1]").val(this.currentProfileAddress.get("address1"));
    this.$("[data-address-2]").val(this.currentProfileAddress.get("address2"));
    this.$("[data-address-3]").val(this.currentProfileAddress.get("address3"));
    this.$("[data-country]")
      .val(this.currentProfileAddress.get("country"))
      .trigger("change");

    this.$("[data-city]").val(this.currentProfileAddress.get("city"));
    this.$("[data-postal-code]").val(this.currentProfileAddress.get("postal"));

    this.maskPostalCode(this.currentProfileAddress.get("country"));
  },

  selectState: function() {
    if (this.currentProfileAddress && this.currentProfileAddress.get("state")) {
      this.$("[data-state]").val(this.currentProfileAddress.get("state"));
    }
  },

  render: function(display, require) {
    this.$el[display ? "removeClass" : "addClass"]("is-hidden");

    this.$("[data-address-require-input]")[require ? "attr" : "removeAttr"](
      "required",
      true
    );
    this.$("[data-address-require-label]")[
      require ? "addClass" : "removeClass"
    ]("is-required");

    if (display) {
      this.$("input:not([data-state]), select:not([data-state])").attr(
        "disabled",
        false
      );
      this.$("[data-country]").trigger("change");
    } else {
      this.$("input, select").attr("disabled", true);
    }
  },

  selectAddress: function(data) {
    this.$('[data-profile-addresses="billing"]')
      .val(data.addressId)
      .trigger("change");
  }
});

module.exports = AddressView;
