var $ = require("./module.loader");

// Beta testing of extending jQuery with render Items
$.fn.renderItems = function() {
  // Fallback depending on the context. This method can be called from jQuery or a Backbone view
  var context = this.$el || this;

  if (!context.templatesLoaded) {
    console.warn("You need to run $.loadTemplates() first.");
    return false;
  }

  // Typically means [data-items] were rendered asynchronously and were not available when loadTemplates() ran
  if (!context.items) {
    console.warn("You need to run $.loadTemplates() again.");
    return false;
  }

  // Render each sections passed
  context.items.each(
    function(index, item) {
      item = $(item);
      var data = item.data();

      // Combine model data with item's settings
      if (data.settings) {
        if (typeof data.settings == "string") {
          data.settings = JSON.parse(data.settings);
        }

        var renderData = $.extend({}, data.settings, $.renderData[context.cid]);
      } else {
        var renderData = $.renderData[context.cid];
      }

      if (data.template) {
        item.html(data.template(renderData));
        if (renderData.settings || data.settings) {
          Backbone.Events.trigger("contentReloaded", {
            content: data.template(renderData),
            componentId:
              (data.settings ? data.settings.id : renderData.settings.id) ||
              context.cid
          });
        }
        item.loadComponents();
        // gives option to the templates to listen
        // to this eventListener and perform actions based on it
        context.trigger("renderItems:loaded");
      } else {
        console.warn(
          "The following element was targeted for rendering but it's missing its template. Are you loading templates asynchrounisly?",
          item
        );
      }
    }.bind(context)
  );
};

$.fn.loadTemplates = function(attr) {
  // Render by default when loading templates
  attr = attr || { data: {} };
  attr.render = typeof attr.render == "undefined" ? true : attr.render;

  // Cid to store specific component instance data
  // cid=> can be passed as argument when calling loadTemplates or from the model
  const modelId = attr.cid || attr.data.cid;

  // Make model data available
  $.renderData = $.renderData || {};
  $.renderData[modelId] = $.renderData[modelId] || attr.data.attributes;

  // Fallback depending on the context. This method can be called from jQuery or a Backbone view
  var context = this.$el || this;
  context.items = context.find("[data-item]");

  //Storing cid for future use on retrieving the data
  context.cid = modelId;

  // We save all the handlebars templates into its parent's data attribute to have them always available
  context.items.each(function(index, item) {
    var template = $(item).find("[data-template]");

    if (template.length) {
      $(item).data("template", Handlebars.compile(template.html()));
    }
  });

  this.templatesLoaded = true;

  if (attr.render) {
    context.renderItems();
  }
};

module.exports = $;
