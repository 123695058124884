var $ = require("jquery");
var GoogleMapsLoader = require("google-maps");
const querystring = require("query-string");

const flattenObject = require("../../utilities/flattenObject");

var GoogleMaps = Backbone.View.extend({
  events: {
    "click [data-google-map-marker-trigger]": "triggerMarkerClick",
    resultsProcessing: "addLoading",
    resultsUpdate: "removeLoading"
  },

  fullLocales: {
    en_AU: "en-AU",
    en_GB: "en-GB",
    pt_BR: "pt-BR",
    pt_PT: "pt-PT",
    zh_CN: "zh-CN",
    zh_TW: "zh-TW"
  },

  gmapMarkerMap: [],
  markersInfoWindowContent: [],
  apiLoading: false,

  removeLoading: function() {
    if (this.gmapContainerWrapper) {
      this.gmapContainerWrapper.removeClass("is-loading");
    }
    if (this.gmapContainer) {
      this.gmapContainer.removeClass("is-loading");
    }

    _.each(
      this.$("[data-messages]").find("[data-component='advisories']"),
      advisory => {
        $(advisory)
          .component()
          .reflow();
      }
    );
  },

  addLoading: function() {
    let gmapLoader = this.gmapContainerWrapper
      ? this.gmapContainerWrapper
      : this.gmapContainer;
    gmapLoader.addClass("is-loading");
  },

  loadGoogleMapsAPI: function() {
    if (this.apiLoading) {
      // If it's loading the api, persist at a 500 inverval
      setTimeout(this.loadGoogleMapsAPI.bind(this), 500);
    } else if (typeof window.google == "undefined") {
      // If it's the first instance of google maps, register library and initMap after loading it.
      this.loadLibrary();
    } else if (window.google && window.google.maps) {
      // If google api is loaded, just init map
      this.initMap();
    }

    return this;
  },

  loadLibrary: function() {
    // In case of multiple instances initialized at the same time,
    // This flag would avoid this method to run more than once.
    this.apiLoading = true;

    var language = REVELEX.settings.gmapLanguage;

    if (language.length) {
      if (this.fullLocales[language]) {
        language = this.fullLocales[language];
      } else {
        language = language.slice(0, 2);
      }
    }

    // updating the key to read from itself as it might
    // come from the geolocation component
    GoogleMapsLoader.KEY = GoogleMapsLoader.KEY || REVELEX.settings.gmapKey;
    GoogleMapsLoader.LANGUAGE = language;
    GoogleMapsLoader.load(this.initMap.bind(this));
  },

  buildGmapOptions: function() {
    return {
      center: new google.maps.LatLng(
        this.settings.map.latitude,
        this.settings.map.longitude
      ),
      zoom: this.settings.map.zoom,
      mapTypeId: this.getMapTypeId(),
      mapTypeControl: this.settings.map.mapTypeControl,
      streetViewControl: this.settings.map.streetViewControl,
      fullscreenControl: this.settings.map.fullscreenControl,
      clickableIcons:
        typeof this.settings.map.clickableIcons !== "undefined"
          ? this.settings.map.clickableIcons
          : true
    };
  },
  buildStreetViewOptions: function() {
    return {
      position: new google.maps.LatLng(
        this.settings.map.latitude,
        this.settings.map.longitude
      ),
      zoom: this.settings.map.zoom
    };
  },

  // checking if infoWindow is open or not
  isInfoWindowOpen: function(infoWindow) {
    var map = infoWindow.getMap();
    return map !== null && typeof map !== "undefined";
  },

  // pixelOffset is added to move the initial location of the infoWindow
  initMap: function() {
    let self = this;
    let gmap;
    let gmapMarkerImage;
    let gmapMarkerImageStart;
    let gmapMarkerImageEnd;
    let gmapMarkerImageStartEnd;
    let gmapMarkerImageHover;
    let gmapBounds = new google.maps.LatLngBounds();
    let gmapInfoWindow = new google.maps.InfoWindow({
      maxWidth: this.settings.infoWindow.maxWidth,
      minWidth: this.settings.infoWindow.minWidth,
      pixelOffset: new google.maps.Size(
        this.settings.infoWindow.pixelOffsetX,
        this.settings.infoWindow.pixelOffsetY
      )
    });
    let markers = [];
    let gmapContainer;

    if (this.settings.map.externalContainer) {
      let gmapContainerWrapper = $("[data-google-map-external-wrapper]");
      gmapContainer = $("[data-google-map-external-container]");

      this.gmapContainerWrapper = gmapContainerWrapper.length
        ? gmapContainerWrapper
        : false;
    } else {
      gmapContainer = this.$("[data-google-map-container]");
    }

    this.gmapContainer = gmapContainer;

    this.removeLoading();

    let labelStyles = this.settings.markerStyling
      ? this.settings.markerStyling
      : null;

    if (this.streetViewMap.length === 0) {
      gmap = new google.maps.Map(gmapContainer[0], this.buildGmapOptions());
    } else {
      gmap = new google.maps.StreetViewPanorama(
        gmapContainer[0],
        this.buildStreetViewOptions()
      );
      google.maps.event.addListener(gmap, "links_changed", function() {
        var links = gmap.getLinks();
        if (links.length > 0) {
          gmap.setPov({
            heading: links[0].heading,
            pitch: 0
          });
        }
      });
    }

    // While interacting with the map, add an active state to its container
    google.maps.event.addListener(gmap, "click", function() {
      gmapInfoWindow.close();
      self.mapClassHandler(gmapContainer, true);

      // if there's an active/hover effect to markers,
      // resetting look back to base
      if (self.settings.icons.hover) {
        self.resetMarkers(markers, gmapMarkerImage);
      }
    });

    // on leaving the map viewport, removing active class
    google.maps.event.addListener(gmap, "mouseout", function() {
      // checking if infoWindow is open, if not keep the map active
      let currentInfoWindow = self.isInfoWindowOpen(gmapInfoWindow);
      if (!currentInfoWindow) {
        self.mapClassHandler(gmapContainer, false);
      }
    });

    // create the custom marker image if configured
    if (this.settings.icons.base) {
      gmapMarkerImage = new google.maps.MarkerImage(this.settings.icons.base);
    }

    // create the custom marker image for the first marker if configured
    if (this.settings.icons.start) {
      gmapMarkerImageStart = new google.maps.MarkerImage(
        this.settings.icons.start
      );
    }

    // create the custom marker image for the last marker if configured
    if (this.settings.icons.end) {
      gmapMarkerImageEnd = new google.maps.MarkerImage(this.settings.icons.end);
    }

    // create the custom marker image for the first/last marker if configured
    if (this.settings.icons.startEnd) {
      gmapMarkerImageStartEnd = new google.maps.MarkerImage(
        this.settings.icons.startEnd
      );
    }

    // create the custom marker image for the first/last marker if configured
    if (this.settings.icons.hover) {
      gmapMarkerImageHover = new google.maps.MarkerImage(
        this.settings.icons.hover
      );
    }

    // add the map markers if configured
    if (this.settings.markers.length) {
      _.each(this.settings.markers, (marker, index) => {
        // if a label is present and label custom styles are passed,
        // adding color to the marker
        if (labelStyles) {
          marker.label.color = labelStyles.color || "";
        }
        let markerLatLong = new google.maps.LatLng(
          marker.latitude,
          marker.longitude
        );

        let gmapMarker = new google.maps.Marker({
          position: markerLatLong,
          map: gmap,
          title: marker.title,
          label: marker.label ? marker.label : null
        });

        markers.push(gmapMarker);

        // adding hover state for marker
        // google doesn't have support for states so doing a workaround with over and out

        if (gmapMarkerImageHover) {
          // both cases are needed to retrieve and set label changes,
          // saying this all can be modified easily

          google.maps.event.addListener(gmapMarker, "mouseover", function() {
            let label = this.getLabel();

            gmapMarker.setIcon(gmapMarkerImageHover);
            label.color = labelStyles.colorActive;
            this.setLabel(label);
          });

          google.maps.event.addListener(gmapMarker, "mouseout", function() {
            let label = this.getLabel();
            label.color = labelStyles.color;
            if (!label.isActive) {
              gmapMarker.setIcon(gmapMarkerImage);
              this.setLabel(label);
            }
          });

          // adding active state to google marker

          google.maps.event.addListener(
            gmapMarker,
            "click",
            (function(marker, index) {
              return function() {
                self.mapClassHandler(gmapContainer, true);
                self.resetMarkers(markers, gmapMarkerImage);

                let label = this.getLabel();

                label.isActive = true;
                gmapMarker.setIcon(gmapMarkerImageHover);
                label.color = labelStyles.colorActive;
                this.setLabel(label);
              };
            })(marker, index)
          );
        }

        // determine which custom image to use for the marker if configured
        if (
          gmapMarkerImageStartEnd &&
          marker.id == this.settings.map.firstMarkerId &&
          marker.id == this.settings.map.lastMarkerId
        ) {
          gmapMarker.setIcon(gmapMarkerImageStartEnd);
        } else if (
          gmapMarkerImageStart &&
          marker.id == this.settings.map.firstMarkerId
        ) {
          gmapMarker.setIcon(gmapMarkerImageStart);
        } else if (
          gmapMarkerImageEnd &&
          marker.id == this.settings.map.lastMarkerId
        ) {
          gmapMarker.setIcon(gmapMarkerImageEnd);
        } else if (gmapMarkerImage) {
          gmapMarker.setIcon(gmapMarkerImage);
        }

        // add the marker to the marker hash map
        this.gmapMarkerMap[marker.id] = gmapMarker;

        if (this.settings.markers.length >= 2) {
          // extend the map bounds to include this marker
          gmapBounds.extend(markerLatLong);
          gmap.fitBounds(gmapBounds);
        } else {
          let currentPosition = gmapMarker.getPosition(); // returns LatLng object
          if (this.streetViewMap.length === 0) {
            gmap.setZoom(14);
            gmap.panTo(gmapMarker.position);
            gmap.setCenter(currentPosition);
          }
        }

        // add a click event to display marker info box if provided
        if (this.settings.infoWindow.markerTemplate) {
          google.maps.event.addListener(gmapMarker, "click", function() {
            gmapInfoWindow.setContent(
              self.settings.infoWindow.markerTemplate(marker)
            );
            gmapInfoWindow.open(gmap, gmapMarker);

            // Attempt to initialize any components that are inside the markers
            if (gmapInfoWindow.content) {
              $(gmapInfoWindow.content).loadComponents();
            }
          });
        } else if (this.markersInfoWindowContent.length) {
          google.maps.event.addListener(gmapMarker, "click", function() {
            gmapInfoWindow.setContent(self.markersInfoWindowContent[marker.id]);
            gmapInfoWindow.open(gmap, gmapMarker);

            // Attempt to initialize any components that are inside the markers
            if (gmapInfoWindow.content) {
              $(gmapInfoWindow.content).loadComponents();
              google.maps.event.addListener(
                gmapInfoWindow,
                "domready",
                function() {
                  // Reference to the DIV which receives the contents of the infowindow using jQuery
                  var iwOuter = $(".gm-style-iw");

                  /* The DIV we want to change is above the .gm-style-iw DIV.
                    * So, we use jQuery and create a iwBackground variable,
                    * and took advantage of the existing reference to .gm-style-iw for the previous DIV with .prev().
                    */
                  var iwBackground = iwOuter.prev();

                  // Remove the background shadow DIV
                  iwBackground.parent().addClass("gm-style-iw-parent");
                  iwBackground
                    .children(":nth-child(2)")
                    .css({ display: "none" });

                  // Remove the white background DIV
                  iwBackground
                    .children(":nth-child(4)")
                    .css({ display: "none" });
                }
              );

              google.maps.event.addListener(
                gmapInfoWindow,
                "closeclick",
                () => {
                  self.resetMarkers(markers, gmapMarkerImage);
                }
              );
            }
          });
        }
      });
    } else {
      // this is done to have a fallback for latitude and longitude
      let currentLatitude =
        this.settings.map.latitude ||
        REVELEX[`${this.settings.fallbackObject}`].latitude;
      let currentLongitude =
        this.settings.map.longitude ||
        REVELEX[`${this.settings.fallbackObject}`].longitude;
      gmap.setZoom(14);
      let currentDefaultPosition = new google.maps.LatLng(
        parseFloat(currentLatitude),
        parseFloat(currentLongitude)
      );
      gmap.setCenter(currentDefaultPosition);
    }

    if (
      this.settings.map.externalContainer &&
      this.$("[data-map-addons]").length
    ) {
      let externalWrapper = $("[data-google-map-external-wrapper]");

      externalWrapper.find("[data-map-addons]").remove();
      externalWrapper.append(this.$("[data-map-addons]"));
    }

    // when a button is present on the settings, this where its called for it to be rendered
    if (this.settings.button) {
      const centerControlDiv = document.createElement("div");

      this.customButton(centerControlDiv, gmap);

      gmap.controls[google.maps.ControlPosition.TOP_CENTER].push(
        centerControlDiv
      );
    }

    this.gmap = gmap;
  },

  // this will add an active class to the currentMap when the user interacts with it
  mapClassHandler: function(mapContainer, isActive) {
    if (isActive) {
      if (!mapContainer.is(".is-active")) {
        mapContainer.addClass("is-active");
      }
    } else {
      mapContainer.removeClass("is-active");
    }
  },

  // if there are any hovering/active markers in the initial settings,
  // this reset the markers to its base
  resetMarkers: function(markers, base) {
    var currentStyling = this.settings.markerStyling;
    for (var j = 0; j < markers.length; j++) {
      let currentLabel = markers[j].getLabel();

      try {
        markers[j].setIcon(base);

        markers[j].setLabel(currentLabel);
        currentLabel.isActive = false;
        currentLabel.color = currentStyling.color;
      } catch (e) {
        console.warn("Marker not found");
      }
    }
  },

  getMapTypeId: function() {
    switch (this.settings.map.mapType) {
      case "hybrid":
        return google.maps.MapTypeId.HYBRID;

      case "roadmap":
        return google.maps.MapTypeId.ROADMAP;

      case "satellite":
        return google.maps.MapTypeId.SATELLITE;

      case "terrain":
        return google.maps.MapTypeId.TERRAIN;
    }

    return google.maps.MapTypeId.ROADMAP;
  },

  triggerMarkerClick: function(event) {
    let nextMarker = $(event.currentTarget).data("googleMapMarkerTrigger");
    google.maps.event.trigger(this.gmapMarkerMap[nextMarker], "click");

    if (this.settings.map.firstMarkerId === nextMarker) {
      this.$el
        .find(
          '[data-google-map-marker-info="' +
            nextMarker +
            '"] [data-scroll-trigger-down]'
        )
        .trigger("click");
    }
  },

  customButton: function(event, map) {
    let buttonSettings = this.settings.button;
    let params = REVELEX["search_params"];
    let newParams = [];
    const geocoder = new google.maps.Geocoder();
    const controlUI = document.createElement("div");

    newParams = flattenObject.flatten(params, "search");

    controlUI.classList.add("google-map-custom-button");

    if (buttonSettings.loadingMessage) {
      controlUI.setAttribute(
        "data-loading-splash-custom-title",
        buttonSettings.loadingMessage
      );
      event.title = buttonSettings.loadingMessage;
    }

    controlUI.title = buttonSettings.title;
    controlUI.innerHTML = buttonSettings.text;

    event.appendChild(controlUI);

    google.maps.event.addListener(map, "dragend", function() {
      controlUI.classList.add("is-active");
    });

    controlUI.addEventListener("click", () => {
      let currentMapCenter = map.getCenter();
      let currentLocationName;
      let latLng = {
        lat: currentMapCenter.lat(),
        lng: currentMapCenter.lng()
      };

      newParams["search[latitude]"] = latLng.lat;
      newParams["search[longitude]"] = latLng.lng;

      geocoder
        .geocode({ location: latLng })
        .then(response => {
          if (response.results[0]) {
            currentLocationName = response.results[0].formatted_address;
          } else {
            currentLocationName = "";
            console.warn("No formatted address found");
          }

          newParams.clear = "instance";
          newParams["search[location_name]"] = currentLocationName;

          Backbone.Events.trigger("loadingSplashOn", event);
          window.location =
            buttonSettings.url + "?" + querystring.stringify(newParams);
        })
        .catch(e => console.warn("Geocoder failed due to: " + e));
    });
  },

  initialize: function() {
    // set default settings
    this.settings = {
      icons: {
        base: "",
        start: "",
        end: "",
        startEnd: ""
      },
      infoWindow: {
        maxWidth: 416,
        minWidth: 400,
        templateSelector: null,
        markerTemplate: null,
        pixelOffsetX: 0,
        pixelOffsetY: 0
      },

      map: {
        latitude: 0,
        longitude: 0,
        zoom: 4,
        mapType: "roadmap",
        mapTypeControl: true,
        streetViewControl: true,
        firstMarkerId: null,
        lastMarkerId: null,
        fullscreenControl: false
      },
      markers: []
    };

    if (REVELEX.settings.gmapKey) {
      if (this.$el.data("googleMapSettings")) {
        this.settings = $.extend(
          true,
          this.settings,
          this.$el.data("googleMapSettings")
        );

        this.$el.removeAttr("data-google-map-settings");
      }

      if (this.settings.infoWindow.templateSelector) {
        this.settings.infoWindow.markerTemplate = Handlebars.compile(
          this.$(this.settings.infoWindow.templateSelector).html()
        );
      }

      this.markersInfoWindowContent = [];

      var markersInfo = this.$("[data-google-map-marker-info]");
      if (markersInfo.length) {
        markersInfo.each(
          function(i, el) {
            this.markersInfoWindowContent[
              $(el).data("google-map-marker-info")
            ] = el;
          }.bind(this)
        );
      }
      this.streetViewMap = this.$("[data-street-view-map]");

      REVELEX.GoogleMaps = REVELEX.GoogleMaps || {};
      REVELEX.GoogleMaps.initGoogleMap = this.initMap.bind(this);

      this.loadGoogleMapsAPI();
    }
  }
});

module.exports = GoogleMaps;
