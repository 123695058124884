function takeOverConsole() {
  var console = window.console;
  if (!console) return;

  if (window && window.location.hash.split("#").indexOf("!nakedLogs") >= 0) {
    console.info("NakedLogs Mode: ON.");
    return;
  }

  function intercept(method) {
    var original = console[method];
    console[method] = function() {
      // do sneaky stuff

      if (
        ENV == "prod" &&
        window.location.hash.split("#").indexOf("!showLogs") < 0
      ) {
        return;
      }

      if (original.apply) {
        // Do this for normal browsers
        original.apply(console, arguments);
      } else {
        // Do this for IE
        var message = Array.prototype.slice.apply(arguments).join(" ");
        original(message);
      }
    };
  }
  var methods = ["log", "warn", "error", "info"];
  for (var i = 0; i < methods.length; i++) intercept(methods[i]);
}

module.exports = takeOverConsole();
