/**
 * Conditional Attrs
 */

var ConditionalAttribute = Backbone.View.extend({
  defaults: {
    operator: "==",
    separator: /\|/g,
    value: "true",
    type: "any"
  },
  initialize: function(args) {
    this.settings = args.settings;
    this.form = args.form;
    this.localData = this.$el.data() || {};
    this.type =
      this.localData["form" + this.settings.Name + "IfRule"] ||
      this.defaults.type;

    this.pullData()
      .bindTargets()
      .scanConditions();

    var hasOnload = _.has(
      this.localData,
      "form" + this.settings.Name + "Onload"
    );
    var runOnload = this.localData["form" + this.settings.Name + "Onload"];

    if (!hasOnload || (hasOnload && runOnload)) {
      this.runCheck();
    }
  },
  pullData: function() {
    // Pull Target(s)
    this.target = this.localData["form" + this.settings.Name + "If"];
    this.targets = this.target.split(this.defaults.separator);
    this.targetSelector = this.target.replace(this.defaults.separator, ",");
    this.target = this.form.find(this.targetSelector);

    // Pull Value(s)
    this.value = _.has(this.localData, "form" + this.settings.Name + "IfValue")
      ? this.localData["form" + this.settings.Name + "IfValue"]
      : this.defaults.value;
    this.value = _.isString(this.value)
      ? this.value.split(this.defaults.separator)
      : [this.value];

    // Pull Operators(s)
    this.operator = this.localData["form" + this.settings.Name + "IfOperator"];
    this.operator = this.operator
      ? this.operator.split(this.defaults.separator)
      : [this.defaults.operator];

    return this;
  },
  bindTargets: function() {
    // Bind Target(s)
    if (!this.localData["form" + this.settings.Name + "IfBinded"]) {
      // Create binding at the form level to listen to the event of the target element only
      this.form.on(
        this.settings.event,
        this.targetSelector,
        this.runCheck.bind(this)
      );

      // Set Binding Flag
      this.$el.data("form-" + this.settings.name + "-if-binded", true);
    }
    return this;
  },
  scanConditions: function() {
    this.validators = [];
    _.each(
      this.targets,
      function(target, i) {
        var validator = {
          id: i,
          target: this.targets[i]
        };

        validator.operator = this.operator[i] || this.defaults.operator;
        validator.value = !_.isUndefined(this.value[i])
          ? this.value[i]
          : this.defaults.value;

        this.validators.push(validator);
      }.bind(this)
    );

    return this;
  },
  runCheck: function() {
    var results = _.map(this.validators, this.validate.bind(this));

    if (results.length === 1) {
      return this.settings.prop
        ? this.switchProp(results[0])
        : this.switchAttr(results[0]);
    }

    if (results.length > 1 && this.type === "all") {
      // Case for All
      result = _.every(results, function(result) {
        return result === true;
      });
    } else if (this.type === "any") {
      // Case for if Any
      result = _.indexOf(results, true) >= 0 ? true : false;
    }

    return this.switchAttr(result);
  },
  validate: function(validator) {
    var result = true,
      value = null,
      target = this.form.find(validator.target),
      operator = validator.operator,
      expectedValue = _.isBoolean(validator.value)
        ? validator.value.toString()
        : validator.value;

    if (target.length > 1 && target.first().is(":radio")) {
      target = target.filter(":checked").length
        ? target.filter(":checked")
        : target.first();
    }

    // Get current value of the field
    if (target.is(":checkbox")) {
      // If checkbox, response expected is boolean
      value = target[0].checked;
    } else if (target.is(":radio") && !target.is(":checked")) {
      // If its an unchecked radio button, just assign false
      // instead of checking its value
      value = false;
    } else {
      // Grab value in all other scenarios
      value = target.val() ? target.val() : "";
    }

    // If boolean / int, convert to string to compare same var type
    value = !_.isString(value) ? value.toString() : value;

    // If empty string, replace value to be compared to string boolean
    // If strict check is present, do regular check against value
    value =
      _.isString(value) &&
      value.length === 0 &&
      !this.$el.is("[data-form-strict-check]")
        ? "true"
        : value;

    // Identify if multiple values provided as comma separated string
    if (_.isString(expectedValue) && expectedValue.split(",").length > 1) {
      // Run operation for all values
      var expectedValues = expectedValue.split(",");

      _.each(
        expectedValues,
        function(possibleValue) {
          if (!this.calc[operator](value, possibleValue)) {
            result = false;
          }
        }.bind(this)
      );

      // Additional Check for not equal operator with multiple values
      if (operator === "!=" && _.indexOf(expectedValues, value) >= 0) {
        result = false;
      } else if (operator === "==" && _.indexOf(expectedValues, value) >= 0) {
        result = true;
      }
    } else {
      // Run operation for single value
      result = this.calc[operator](value, expectedValue);
    }

    return result;
  },
  switchAttr: function(result) {
    let currentTarget = this.$el;
    let parentWrapper =
      currentTarget.parents("[data-form-field-wrapper]") || false;

    // Add/Remove custom attr based on response.
    if (result) {
      if (parentWrapper) {
        parentWrapper.attr(this.settings.attr, true);
      }

      currentTarget.attr(this.settings.attr, true);

      currentTarget.trigger(this.settings.name + "On");

      // reset if target element is a checkbox or radio and is checked
      // target elements needs to have a data-form-uncheck-if-disable=true
      // flag to trigger logic
      if (
        currentTarget.is(":checked") &&
        currentTarget.is("[data-form-uncheck-if-disabled]") &&
        (currentTarget.is(":checkbox") || currentTarget.is(":radio"))
      ) {
        currentTarget.prop("checked", false);
        currentTarget.trigger("change");
      }
    } else {
      if (parentWrapper) {
        parentWrapper.attr(this.settings.attr, false);
      }

      currentTarget.removeAttr(this.settings.attr);

      currentTarget.trigger(this.settings.name + "Off");
    }

    return this;
  },
  switchProp: function(result) {
    let parentWrapper = this.$el.parents("[data-form-field-wrapper]") || false;
    // Add/Remove custom attr based on response.
    //TODO: Initial value of the element is been validated
    var initialValue = this.$el.prop(this.settings.prop);

    if (result) {
      if (parentWrapper) {
        parentWrapper.attr(this.settings.attr, true);
      }
      this.$el.prop(this.settings.prop, true);
      this.$el.trigger(this.settings.name + "On");

      //TODO: Look for a better way to have 2nd level conditionals (when an element conditional attribute depends on other element that also has a conditional attribute)
      if (initialValue === false) {
        this.$el.trigger(this.settings.event);
      }
    } else {
      if (parentWrapper) {
        parentWrapper.attr(this.settings.attr, false);
      }

      this.$el.prop(this.settings.prop, false);
      this.$el.trigger(this.settings.name + "Off");

      //TODO: Look for a better way to have 2nd level conditionals (when an element conditional attribute depends on other element that also has a conditional attribute)
      if (initialValue === true) {
        this.$el.trigger(this.settings.event);
      }
    }

    return this;
  },
  calc: {
    "==": function(a, b) {
      return a == b;
    },
    "!=": function(a, b) {
      return a != b;
    },
    ">": function(a, b) {
      return a > b;
    },
    "<": function(a, b) {
      return a < b;
    },
    ">=": function(a, b) {
      return a >= b;
    },
    "<=": function(a, b) {
      return a <= b;
    }
  }
});

module.exports = ConditionalAttribute;
