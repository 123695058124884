var CustomAdvisoryTerms = Backbone.View.extend({
  events: {
    "click [data-custom-advisory-terms]": "requireTerms"
  },

  initialize: function() {
    this.termsDialog = this.$("[data-custom-advisory-terms-terms-modal]");
  },

  requireTerms: function(event) {
    var form = $(event.currentTarget).parent("form");

    if (this.termsDialog.length) {
      event.preventDefault();

      var Dialog = this.termsDialog.component();

      Dialog.reflow().open();

      Dialog.currentModal.on("click", "[data-terms-accepted]", function() {
        form.submit();
        Backbone.Events.trigger("loadingSplashOn");
      });
    } else {
      Backbone.Events.trigger("loadingSplashOn", event);
    }
  }
});

module.exports = CustomAdvisoryTerms;
