var $ = require("jquery");

var AuthenticationAdyen = require("./_authentication.adyen");

/**
 * Payment External Authentication
 * Revelex is using this module in order to handle multiple external
 * Payment Authentication vendors
 *
 * Clients
 * VXP Virgin Payment - iFrame solution
 *
 * Explora Journeys ADYEN - Drop in solution
 * https://docs.adyen.com/online-payments/web-drop-in?tab=codeBlocksessions_8EuzL_Java_2#page-introduction
 *
 * This logic works after the review page is submitted, it depends on configuration
 */

// getting a state of the iframe for
// triggering the loading Splash or not
var AuthView = Backbone.View.extend({
  tagName: "iframe",
  events: {
    load: "onLoad"
  },
  onLoad: function() {
    if (!this.is_completed) {
      this.is_completed = true;
      Backbone.Events.trigger("loadingSplashOff");
    }
  },

  submitForm: function() {
    Backbone.Events.trigger("loadingSplashOn");
    this.currentForm.submit();
  },

  initialize: function() {
    this.is_completed = false;
    this.currentForm = this.$el.parent().find("form");
  }
});

var PaymentExternalAuthentication = Backbone.View.extend({
  handleMerchantResponse: function(response) {
    let merchantResponseForm = document.createElement("form");
    let merchantResponseData = document.createElement("input");

    Backbone.Events.trigger("loadingSplashOn");

    merchantResponseForm.method = "GET";
    merchantResponseForm.action = this.settings.paymentAuthenticationRedirect;

    merchantResponseData.setAttribute("type", "hidden");
    merchantResponseData.setAttribute("name", "data");
    merchantResponseData.setAttribute("value", JSON.stringify(response));

    merchantResponseForm.appendChild(merchantResponseData);

    document.body.appendChild(merchantResponseForm);
    merchantResponseForm.submit();
    document.body.removeChild(merchantResponseForm);
  },

  paymentIframeInit: function() {
    // create views for each authentication iframe
    this.$("[data-payment-authentication-frame]").each(function() {
      var View = new AuthView({
        el: this
      });

      View.submitForm();
    });

    // add a listener to handle merchant response
    window.addEventListener("message", event => {
      // storing the data received from Gateway
      let currentData;
      // matching received code with statusCodes, see above
      let acceptedCode = false;

      // we have checked that Gateway sends strings and objects,
      // but all are object formatted
      if (typeof event.data !== "object") {
        currentData = event.data.length ? JSON.parse(event.data) : {};
      } else {
        currentData = event.data;
      }

      // getting the current instance of the iframe
      let currentIframe = this.$("[data-payment-authentication-frame]")[0];

      // ignore empty objects
      if (
        typeof currentData !== "undefined" &&
        Object.keys(currentData).length
      ) {
        // checking if statusCode is valid
        if (
          currentData.statusCode &&
          this.statusCodes.indexOf(parseInt(currentData.statusCode)) !== -1
        ) {
          acceptedCode = true;
        }

        // getting the current size of the iframe
        // and giving a fallback just in case we get a CORS issue

        try {
          currentIframe.style.height = currentData.height
            ? currentData.height
            : currentIframe.contentWindow.document.body.offsetHeight + "px";
        } catch (e) {
          console.warn(
            "height not provided by merchant and CORS blocked happened"
          );
        }

        // if we get an acceptedCode trigger form submission
        if (acceptedCode) {
          this.handleMerchantResponse(currentData);
        }
      }
    });
  },

  paymentVerificationRedirectInit: function() {
    // create views for each payment wrapper
    this.$("[data-payment-verification-redirect-wrapper]").each(function() {
      var View = new AuthView({
        el: this
      });

      View.submitForm();
    });
  },

  initialize: function() {
    this.dispatcher = this;
    /**
     * Status Codes
     * Creating array with expected statusCodes from Merchant
     *
     * 100: Successful
     * 102: Invalid data
     * 103: Authentication/Authorization failed
     * 104: Client transaction id duplicated
     * 105: Invalid signature
     * 150: General failure
     * 151: Request received, Server Timeout
     * 152: Request received, Server Timeout
     * 153: Request received, Gateway down
     * 154: Gateway unreachable
     * 200: Card declined
     */
    this.statusCodes = [100, 102, 103, 104, 105, 150, 151, 152, 153, 154, 200];

    // extending settings
    if (this.$el.data("settings")) {
      this.settings = this.settings = $.extend(
        true,
        this.settings,
        this.$el.data("settings")
      );
    }

    this.templates = {};

    this.templates.messagesTemplate = Handlebars.compile(
      this.$("[data-messages-template]").length
        ? this.$("[data-messages-template]").html()
        : ""
    );

    if (this.$("[data-payment-authentication-frame]").length) {
      this.paymentIframeInit();
    } else if (this.$("[data-payment-authentication-wrapper]").length) {
      let currentWrapper = this.$("[data-payment-authentication-wrapper]");
      this.settings = this.settings = $.extend(
        true,
        this.settings,
        currentWrapper.data("settings")
      );

      var currentExternalVendor = new AuthenticationAdyen({
        el: currentWrapper ? currentWrapper : "<div></div>",
        settings: this.settings,
        dispatcher: this.dispatcher
      });
    } else if (this.$("[data-payment-verification-redirect-wrapper]").length) {
      this.paymentVerificationRedirectInit();
    }
  }
});

module.exports = PaymentExternalAuthentication;
