module.exports = function(country) {
  switch (country) {
    case "CA":
      return "a9a 9a9";

    case "US":
      return "99999[-9999]";

    case "AU":
      return "9999";

    case "GB":
      return "**[*][*] ***";

    case "MT":
      return "***[ ]****";

    default:
      return "remove";
  }
};
