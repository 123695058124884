/**
 * Date and time picker
 * Lets see if this calendar module is better :)
 * @todo Time picker
 */

var CalendarTemplate = require("./_calendar.templates");

var OutputView = require("../../utilities/output-view");

var momentMasks = require("./../../utilities/libraries/moment-locale");

var OutputViewCalendar = OutputView.extend({
  onComponentDetach: function() {
    this.component.closeCalendar();
  },
  onComponentAttach: function(e) {
    this.component.openCalendar(e, true);
  }
});

/**
 * Calendar module
 * This module is encompassed in one view
 */
var Calendar = Backbone.View.extend({
  /**
   * Default element is an empty div
   */
  el: "<div></div>",

  /**
   * Initialization
   * Loads default settings, and merges with passed in `options.settings`
   * Sets moment locale
   * @param Object options
   */
  initialize: function(args) {
    // click events not recognized on some iphone versions
    this.deviceAgent = navigator.userAgent.toLowerCase();
    this.clickHandler = this.deviceAgent.match(/(ipod|iphone)/)
      ? "touchstart"
      : "click";

    var options = {};
    var dataSettings = this.$el.attr("data-calendar-settings");
    if (dataSettings) {
      try {
        var parsedSettings = JSON.parse(dataSettings);
        options = $.extend(options, parsedSettings);
      } catch (e) {
        console.warn(
          "**RVLX** Invalid calendar settings format on element: ",
          this.$el
        );
      }
    } else {
      options = args.settings;
    }

    this.$el.data("calendar", this);

    // Default settings
    var settings = {
      // locale for calendar (Webpack uses currentLocale Camelcase)
      locale:
        REVELEX && REVELEX.settings && REVELEX.settings.currentLocale
          ? REVELEX.settings.currentLocale.replace("_", "-")
          : "en-US",

      // type (single, start, end, linked)
      type: "single",

      // ordinal for linked calendars
      ordinal: false,

      // name for use in global namespace, pass custom name for start/end calendars
      name: null,

      // highlight content of the input field when focus
      select_on_focus: true,

      // enable, false to disable
      is_enabled: true,

      clickHandler: this.clickHandler,

      // datepicker
      triggerMatchId: false,
      date: false,
      show_date: true,
      show_months: 1,
      show_months_mobile: 1,
      show_month_select: true,
      show_year_select: true,
      fill_linked_date: true,
      date_min: false,
      date_max: false,
      weekdayOutput: "min",
      dates_excluded: false,
      date_display_format: "L",
      date_input_format: "MM/DD/YYYY",
      date_output_format: "MM/DD/YYYY",
      date_input_mask: true,
      date_auto_render: true,
      date_template: CalendarTemplate.date,
      date_month_select_template: CalendarTemplate.month_select,
      date_year_select_template: CalendarTemplate.year_select,
      date_year_select_range: 12,
      date_template_selector: '[data-template="date"]',
      date_trigger_selector: "[data-calendar-trigger]",
      date_trigger_active_class: "is-active",
      date_calendar_target: "[data-calendar-target]",
      date_input_target: "[data-calendar-input]",
      date_output_target: "[data-calendar-output]",
      date_render: false,
      date_difference: {
        amount: 2,
        max: "6",
        max_amount: "months",
        unit: "days"
      },
      allow_primary_offset: false,
      // @todo timepicker
      show_time: false,
      time_display_format: "LT",
      time_output_format: "HH:mm",
      time_template: "",
      cid: this.cid,
      parentAutoclose: false
    };

    // Set settings
    this.settings = $.extend(settings, options);

    // for handlebars, we need to convert
    // date into a moment element for it to work properly

    if (this.isOnlyNumbers(this.settings.date) && !isNaN(this.settings.date)) {
      this.settings.date = moment
        .unix(this.settings.date)
        .format(this.settings.date_input_format);
    }

    // force mobile calendar to display only one panel
    if (window.screen.width <= 767) {
      this.settings.show_months_mobile = 1;
    }

    // If used on mobile device we make inputs read only so we dont trigger the virtual keyboard
    if (this.deviceAgent.match(/(ipod|iphone|ipad|android)/)) {
      this.$el.find(this.settings.date_input_target).attr("readonly", true);
    }

    // Set moment locale
    moment.locale(momentMasks(this.settings.locale));

    // Set state
    this.state = {
      selected_date: null,
      current_month: null,
      current_year: null,
      is_month_select: false,
      is_year_select: false,
      months: [],
      select_months: {},
      select_years: {}
    };

    // Prepare global namespace
    window.REVELEX = window.REVELEX || {};
    window.REVELEX.Calendars = window.REVELEX.Calendars || {};

    // Set into global namespace
    if (this.settings.name) {
      if (this.settings.type === "single") {
        window.REVELEX.Calendars[this.settings.name] = this;
      } else if (this.settings.type === "linked" && this.settings.ordinal) {
        window.REVELEX.Calendars[this.settings.name] =
          window.REVELEX.Calendars[this.settings.name] || {};
        window.REVELEX.Calendars[this.settings.name][
          this.settings.ordinal
        ] = this;
      } else if (this.settings.type === "start") {
        window.REVELEX.Calendars[this.settings.name] =
          window.REVELEX.Calendars[this.settings.name] || {};
        window.REVELEX.Calendars[this.settings.name].start = this;
      } else if (this.settings.type === "end") {
        window.REVELEX.Calendars[this.settings.name] =
          window.REVELEX.Calendars[this.settings.name] || {};
        window.REVELEX.Calendars[this.settings.name].end = this;
      }
    } else {
      this.settings.name = this.generateName();
      while (typeof window.REVELEX.Calendars[this.settings.name] === "object") {
        this.settings.name = this.generateName();
      }
      window.REVELEX.Calendars[this.settings.name] = this;
    }

    // Compile templates, set state, bind events
    this.setElements()
      .compileTemplates()
      .setState()
      .bindEvents();

    // if we are replicating the linked calendar , trigger the "changed:date"
    if (parseInt(this.settings.ordinal) > 1) {
      var prevoiusLinkedCalendar =
        REVELEX.Calendars[this.settings.name][
          parseInt(this.settings.ordinal) - 1
        ];
      if (prevoiusLinkedCalendar) {
        prevoiusLinkedCalendar.trigger("changed:date", {
          date: moment(prevoiusLinkedCalendar.state.selected_date),
          component: this.$el
        });
      }
    }

    // Autorender, if set (i.e. from data-module)
    if (this.settings.date_auto_render && this.settings.show_date) {
      this.renderDate();
    }

    //Android keyboard input bug fix
    var ua = navigator.userAgent,
      chrome = /chrome/i.test(ua),
      android = /android/i.test(ua);

    if (chrome && android) {
      this.$el.find('input[type="text"]').removeAttr("maxlength");
      this.$el
        .find('input[type="text"]')
        .inputmask(this.settings.date_input_mask, { reverse: true });
    }
    //End of Android fix//
  },

  updateSettings: function(newSettings) {
    this.settings = _.extend(this.settings, newSettings);

    this.setState();
    this.renderDate();
    if (this.elements.$date_input) {
      this.elements.$date_input.trigger("keyup");
    }
    return this;
  },

  /**
   * Default event handlers
   */
  events: {
    "click [data-next-month]": "handleNextMonth",
    "keydown [data-next-month]": "handleNextMonth",
    "click [data-prev-month]": "handlePrevMonth",
    "keydown [data-prev-month]": "handlePrevMonth",
    "click [data-next-year]": "handleNextYear",
    "keydown [data-next-year]": "handleNextYear",
    "click [data-prev-year]": "handlePrevYear",
    "keydown [data-prev-year]": "handlePrevYear",
    "click [data-next-year-range]": "handleNextYearRange",
    "keydown [data-next-year-range]": "handleNextYearRange",
    "click [data-prev-year-range]": "handlePrevYearRange",
    "keydown [data-prev-year-range]": "handlePrevYearRange",
    "click [data-day]": "handleSelectDay",
    "keydown [data-day]": "handleSelectDay",
    "click [data-select-month]": "handleSelectMonth",
    "keydown [data-select-month]": "handleSelectMonth",
    "click [data-select-year]": "handleSelectYear",
    "keydown [data-select-year]": "handleSelectYear",
    "click [data-month]": "handleMonth",
    "keydown [data-month]": "handleMonth",
    "click [data-year]": "handleYear",
    "keydown [data-year]": "handleYear",
    "touchstart [data-calendar]": "startTouch",
    "touchmove [data-calendar]": "scrollCalendar",
    "click [data-output-view-close]": "close"
  },

  reset: function() {
    var defaultDate = this.settings.reset_date
      ? moment(this.settings.reset_date, this.settings.date_input_format)
      : moment()
          .add(
            this.settings.date_difference.amount,
            this.settings.date_difference.unit
          )
          .format(this.settings.date_input_format);

    this.setDate(defaultDate, true, true, null, true);
  },

  close: function(e) {
    if (this.settings.outputView) {
      //Output view function that controls visibility and attach/detach events
      this.elements.$date_calendar.toggleOutput(e);
      this.isOpen = false;
    } else {
      this.closeCalendar();
    }
  },

  outsideClick: function(e) {
    // If click wasn't inside view and state is open
    // and its not a trigger button, close
    if (
      !this.$el.is(e.target) &&
      !$(e.target).parents("[data-calendar=" + this.cid + "]").length &&
      !this.$(e.target).length &&
      this.state.isOpen
    ) {
      this.closeCalendar();
    }
  },

  startTouch: function(e) {
    initialY = e.touches[0].clientY;
  },

  scrollCalendar: function(e) {
    if (initialY === null) {
      return;
    }

    var currentY = e.touches[0].clientY;
    var screenHeight = screen.height;
    var positionYPercentage = (currentY * 100) / screenHeight;

    var diffY = initialY - currentY;

    if (positionYPercentage > 50 && diffY > 0) {
      this.handleNextMonth(e);
    } else if (positionYPercentage < 50 && diffY < 0) {
      this.handlePrevMonth(e);
    }

    initialY = null;
  },

  /**
   * Generates a random name for the calendar, if not set
   * @returns {string} name
   */
  generateName: function() {
    return Math.random()
      .toString(36)
      .slice(2);
  },

  /**
   * Bind base events
   * @returns {Calendar} this
   */
  bindEvents: function() {
    // Render when month or day is changed
    this.on({
      "changed:month": this.handleChangeMonth.bind(this),
      "changed:date": this.handleChangeDate.bind(this)
    });

    // Render when select view is changed
    this.on({
      "changed:select": this.render.bind(this)
    });

    //If settings are updated by another component or module, reset component
    this.on({ "changed:settings": this.reset.bind(this) });

    if (this.elements.$date_trigger) {
      this.bindTrigger();
    }

    if (this.elements.$date_input) {
      this.bindInput();
    }

    return this;
  },

  /**
   * Method to be overridden to attach custom events
   * Need to be called directly after overriding
   * @returns {Calendar} this
   */
  bindAdditionalEvents: function() {
    return this;
  },

  openCalendar: function(e, attached) {
    // For a calendar with start and end date, if the start date is empty,
    // and the user clicks on the end date, the focus will be sent by default to the start date.

    if (attached) {
      this.state.isOpen = false;
    }

    if (
      this.settings.type === "end" &&
      window.REVELEX.Calendars[
        this.settings.name
      ].start.elements.$date_input.val() === ""
    ) {
      let start_calendar = window.REVELEX.Calendars[this.settings.name].start;
      start_calendar.elements.$date_trigger.click();
      start_calendar.openCalendar();
      return false;
    }

    if (!this.state.isOpen) {
      if (this.elements.$date_calendar) {
        if (this.settings.outputView) {
          this.renderDate();
        } else {
          this.elements.$date_calendar.addClass(
            this.settings.date_trigger_active_class
          );
        }
      }
      this.state.isOpen = true;

      if (!this.settings.outputView) {
        //defining left position of calendar
        // horizontal position depending on how far the end of viewport is

        let hasWrapper = this.elements.$date_trigger.parents(
          "[data-input-group]"
        );

        let currentWrapper = hasWrapper.length
          ? hasWrapper
          : this.elements.$date_trigger;

        let triggerPosition = currentWrapper[0].getBoundingClientRect();

        // Position definitions
        const positionDef = {
          left: { left: triggerPosition.left }
        };

        const position = positionDef["left"] ? positionDef["left"] : {};

        //Adding top position (+ margin + height to prevent overlapping between trigger and tooltip)
        position.left = triggerPosition.left;

        let space =
          innerWidth -
          triggerPosition.left +
          this.elements.$date_calendar.width();

        if (
          triggerPosition.left + this.elements.$date_calendar.width() >
          space
        ) {
          position.left = "auto";
          position.right = 0;

          this.elements.$date_calendar.css(position);
        } else {
          this.elements.$date_calendar.css("left", 0);
        }

        $(document).on(
          "click.calendarClose." + this.cid,
          this.outsideClick.bind(this)
        );
      }

      //If target to add active class exists, add class
      if (this.elements.$calendar_status_target) {
        this.elements.$calendar_status_target.addClass(
          this.settings.date_trigger_active_class
        );
      }
    }
    return this;
  },

  closeCalendar: function(e) {
    if (this.state.isOpen && !this.settings.outputView) {
      this.elements.$date_calendar.removeClass(
        this.settings.date_trigger_active_class
      );
      if (
        !$("[data-calendar-target]").hasClass(
          this.settings.date_trigger_active_class
        )
      ) {
        $(document.body).removeClass("calendar-open stop-events");
      }

      this.state.isOpen = false;

      if (!this.settings.outputView) {
        $(document).off("click.calendarClose." + this.cid);
      }
      if (
        window.REVELEX.Calendars[this.settings.name].end !== undefined &&
        window.REVELEX.Calendars[
          this.settings.name
        ].end.elements.$date_trigger.val() === ""
      ) {
        $("[data-dates-range-connector]").removeClass("date-selected");
      }
    } else if (this.settings.outputView) {
      this.state.isOpen = false;
    }

    //If target to remove active class exists, remove class
    if (this.elements.$calendar_status_target) {
      this.elements.$calendar_status_target.removeClass(
        this.settings.date_trigger_active_class
      );
    }

    return this;
  },

  /**
   * Bind calendar triggers
   * @returns {Calendar} this
   */
  bindTrigger: function() {
    if (this.elements.$date_trigger && this.elements.$date_calendar) {
      // Bind trigger
      this.elements.$date_trigger.on(
        "click touchstart",
        function(e) {
          if (this.settings.is_enabled) {
            this.openCalendar(e);
          }
        }.bind(this)
      );

      if (this.settings.hide_on_mouseleave) {
        var mouseTimeout;
        this.$el.on("mouseleave", e => {
          //hide calendar on mouseleave if hide_on_mouseleave option is true

          mouseTimeout = setTimeout(() => {
            this.closeCalendar();
          }, 500);
        });

        this.$el.on("mouseover mouseenter", e => {
          clearTimeout(mouseTimeout);
        });
      }
    }

    return this;
  },

  /**
   * Bind date input
   * @returns {Calendar} this
   */
  bindInput: function() {
    if (this.elements.$date_input && this.settings.date_input_mask) {
      this.elements.$date_input.on("keypress", this.handleInput.bind(this));
      this.elements.$date_input.on("keyup", this.handleInputEnd.bind(this));
    }

    return this;
  },

  /**
   * Handles date input
   * @param e
   * @returns {bool}
   */
  handleInput: function(e) {
    var $control = $(e.target);
    var position = false;

    // Get input cursor position
    if (typeof $control[0].selectionStart !== "undefined") {
      position = $control[0].selectionStart;
    } else if (window.document.selection) {
      var range = window.document.selection.createRange();
      range.moveStart("character", -$control[0].value.length);
      position = range.text.length;
    }

    return true;
  },

  /**
   * Handles end of input
   * @param e
   * @returns {boolean}
   */

  handleInputEnd: function(e) {
    var $control = $(e.target),
      // replace everything is not 0-9 , - , . or /
      currentValue = e.target.value
        ? e.target.value.replace(/[^0-9\-\.|\/]/g, "")
        : "0",
      rawValue = e.target.value.replace(/[^0-9]/g, ""),
      mask = this.settings.date_input_format,
      selectionSpot = $control[0].selectionStart,
      currentDate = this.state.selected_date
        ? this.state.selected_date.format(this.settings.date_input_format)
        : false,
      changed = false,
      linked_calendar =
        this.settings.type === "start"
          ? window.REVELEX.Calendars[this.settings.name].end
          : false,
      closeCalendarSwitch = linked_calendar ? true : false;

    if (currentValue === currentDate) {
      return true;
    }

    // process calendar output when the date is null or when the date matches the format
    if (
      !currentValue ||
      currentValue.length === this.settings.date_input_format.length
    ) {
      var r = this.setDate(currentValue, true, closeCalendarSwitch, true);

      if (r) {
        changed = true;
      } else {
        $control.focus();
      }
    }

    if (changed) {
      this.closeCalendar();

      // If it's a linked calendar and it's start, open end
      if (this.settings.type === "start") {
        if (linked_calendar) {
          linked_calendar.openCalendar();
        }
      }
    }

    // Force position to be at the end by clearing the field and adding the value again (hack for android)
    if (currentValue.length != this.settings.date_input_format.length) {
      // if position is already at the end do not assign the the rawValue to the field
      $control[0].value = "";
      $control[0].value = rawValue;
    }

    return true;
  },

  /**
   * Sets jQuery elements from settings so that we do not have to do a check every time
   * @returns {Calendar} this
   */
  setElements: function() {
    this.elements = {};

    // Date template
    if (
      this.settings.date_template_selector &&
      this.$(this.settings.date_template_selector).length > 0
    ) {
      this.elements.$date_template = this.$(
        this.settings.date_template_selector
      );
    }

    // Date trigger
    if (
      this.settings.date_trigger_selector &&
      this.$(this.settings.date_trigger_selector).length > 0
    ) {
      this.elements.$date_trigger = this.$(this.settings.date_trigger_selector);
    }

    // Calendar display
    if (
      this.settings.date_calendar_target &&
      this.$(this.settings.date_calendar_target).length > 0
    ) {
      if (this.settings.outputView) {
        this.settings.id = this.settings.name + "-" + this.settings.type;
        this.settings.trigger =
          '[data-calendar-trigger="' + this.settings.id + '"]';

        this.elements.$date_calendar = new OutputViewCalendar({
          component: this,
          settings: this.settings
        });
      } else {
        this.elements.$date_calendar = this.$(
          this.settings.date_calendar_target
        );
      }
    }

    // Date input
    if (
      this.settings.date_input_target &&
      this.$(this.settings.date_input_target).length > 0
    ) {
      this.elements.$date_input = this.$(this.settings.date_input_target);
    } else if (
      this.settings.date_input_target &&
      $('[data-calendar-input="' + this.settings.date_input_target + '"]')
        .length
    ) {
      this.elements.$date_input = $(
        '[data-calendar-input="' + this.settings.date_input_target + '"]'
      );
    }

    // Date output
    if (
      this.settings.date_output_target &&
      this.$(this.settings.date_output_target).length > 0
    ) {
      this.elements.$date_output = this.$(this.settings.date_output_target);
    } else if (
      this.settings.date_output_target &&
      $('[data-calendar-output="' + this.settings.date_output_target + '"]')
        .length
    ) {
      this.elements.$date_output = $(
        '[data-calendar-output="' + this.settings.date_output_target + '"]'
      );
    }

    //Target to set calendar active class
    if (
      this.settings.calendar_status_target &&
      $(this.settings.calendar_status_target).length > 0
    ) {
      this.elements.$calendar_status_target = $(
        this.settings.calendar_status_target
      );
    }

    // if initial date is not accepted, set date to false and clear input area
    if (
      (this.settings.date && parseInt(this.settings.date) === 0) ||
      isNaN(parseInt(this.settings.date))
    ) {
      this.settings.date = false;
      this.elements.$date_input.val("");
    }

    // Add jquery mask
    if (this.elements.$date_input && this.settings.date_input_mask) {
      var format = this.settings.date_input_format;
      var mask = this.settings.date_input_format.replace(/\w/g, 9);
      this.elements.$date_input.inputmask(mask, { placeholder: format });
    }

    return this;
  },

  /**
   * Compiles templates
   * If `settings.date_template_selector` is given, and exists as a child of this.$el, will compile the HTML of the
   * template Otherwise, if `settings.date_template` is given: and is a function (already compiled), will set as
   * template otherwise, will attempt to compile as a string
   * @returns {Calendar} this
   */
  compileTemplates: function() {
    if (this.settings.show_date) {
      // Compile calendar template
      if (this.elements.$date_template) {
        this.settings.date_template = Handlebars.compile(
          this.elements.$date_template.html()
        );
      } else if (typeof this.settings.date_template !== "function") {
        this.settings.date_template = Handlebars.compile(
          this.settings.date_template
        );
      }

      // Compile select month template
      if (typeof this.settings.date_month_select_template !== "function") {
        this.settings.date_month_select_template = Handlebars.compile(
          this.settings.date_month_select_template
        );
      }

      // Compile select year template
      if (typeof this.settings.date_year_select_template !== "function") {
        this.settings.date_year_select_template = Handlebars.compile(
          this.settings.date_year_select_template
        );
      }
    }
    return this;
  },

  /**
   * Handles when month is changed
   * @returns {Calendar} this
   */
  handleChangeMonth: function() {
    this.render();
    return this;
  },

  /**
   * Handles when date is changed
   * If calendar is part of start/end/linked calendars, update as appropriate
   * @returns {Calendar} this
   */
  handleChangeDate: function(data) {
    // Flags for if we need to update a linked calendar
    var has_linked_calendar = false;
    var linked_calendar = null;
    var date_min = this.state.selected_date
      ? this.state.selected_date
      : this.settings.date_min;

    // If is a linked calendar, check if it has a next calendar
    if (
      this.settings.name &&
      this.settings.ordinal &&
      this.settings.type === "linked" &&
      window.REVELEX.Calendars[this.settings.name] &&
      window.REVELEX.Calendars[this.settings.name][
        parseInt(this.settings.ordinal) + 1
      ]
    ) {
      has_linked_calendar = true;
      linked_calendar =
        window.REVELEX.Calendars[this.settings.name][
          parseInt(this.settings.ordinal) + 1
        ];
    }

    // If is a start calendar, check if it has an end calendar
    if (
      this.settings.name &&
      this.settings.type === "start" &&
      window.REVELEX.Calendars[this.settings.name] &&
      window.REVELEX.Calendars[this.settings.name].end
    ) {
      if (!this.state.selected_date) {
        this.render();
        return this;
      }

      has_linked_calendar = true;
      linked_calendar = window.REVELEX.Calendars[this.settings.name].end;
    }

    // If has linked calendar, check it and push back start date if needed (and current calendar has a date)
    if (
      has_linked_calendar &&
      linked_calendar &&
      (this.state.selected_date || date_min)
    ) {
      // Update linked calendar minimum date
      linked_calendar.settings.date_min = this.settings.allow_primary_offset
        ? moment(date_min).add(
            this.settings.date_difference.amount,
            this.settings.date_difference.unit
          )
        : moment(date_min);

      // If linked calendar date is before this calendar date, set linked calendar date to
      //  `date_difference` amount of days after this calendar date
      if (linked_calendar.settings.fill_linked_date) {
        var new_date = moment(date_min).add(
          linked_calendar.settings.date_difference.amount,
          linked_calendar.settings.date_difference.unit
        );

        if (linked_calendar.settings.date_difference.max) {
          // temporary max date
          var linked_calendar_max_temp = moment(date_min).add(
            linked_calendar.settings.date_difference.max,
            linked_calendar.settings.date_difference.maxUnit
          );

          linked_calendar.settings.date_max = linked_calendar_max_temp.isBefore(
            this.settings.date_max
          )
            ? linked_calendar_max_temp
            : linked_calendar.settings.date_max;
        }

        // If new end date isn't selectable (i.e. goes past maximum date), try to set to maximum date and fall back to minimum date
        if (linked_calendar.isSelectable(new_date)) {
          linked_calendar.state.selected_date = new_date;
        } else {
          if (
            linked_calendar.settings.date_max &&
            linked_calendar.isSelectable(linked_calendar.settings.date_max)
          ) {
            linked_calendar.state.selected_date = moment(
              linked_calendar.settings.date_max
            );
          } else {
            linked_calendar.state.selected_date = moment(date_min);
          }
        }
      } else {
        linked_calendar.state.selected_date = null;
      }

      // This will to support the max gap between two linked calendars if set by offset_max
      linked_calendar.settings.date_max =
        this.settings.date_difference.max && this.state.selected_date
          ? moment(this.state.selected_date)
              .add(1, "day")
              .add(this.settings.date_difference.max, "months")
              .startOf("day")
          : this.settings.date_max;

      if (
        linked_calendar.settings.date_max.isAfter(
          moment(this.settings.date_max).startOf("day")
        )
      ) {
        linked_calendar.settings.date_max = this.settings.date_max;
      }

      // Change end calendar current month
      linked_calendar.state.current_month = linked_calendar.state.selected_date
        ? moment(linked_calendar.state.selected_date).startOf("month")
        : moment(linked_calendar.settings.date_min).startOf("month");
      linked_calendar.state.current_year = linked_calendar.state.selected_date
        ? moment(linked_calendar.state.selected_date).startOf("year")
        : moment(linked_calendar.settings.date_min).startOf("year");

      // Trigger changed date event on end calendar
      linked_calendar.trigger("changed:date", {
        date: linked_calendar.state.selected_date
          ? moment(linked_calendar.state.selected_date).format(
              linked_calendar.settings.date_output_format
            )
          : null
      });
    }

    // If is an end calendar, rerender start calendar
    if (
      this.settings.name &&
      this.settings.type === "end" &&
      window.REVELEX.Calendars[this.settings.name] &&
      window.REVELEX.Calendars[this.settings.name].start
    ) {
      // Get start calendar
      var start_calendar = window.REVELEX.Calendars[this.settings.name].start;
      start_calendar.render();
    }

    this.state.is_month_select = false;
    this.state.is_year_select = false;
    this.render();
    return this;
  },

  /**
   * Method to increase current month
   * Triggers `changed:month` event
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleNextMonth: function(e) {
    if (e.type === "keydown" && (e.keyCode !== 32 && e.keyCode !== 13)) {
      return this;
    }
    var month = moment(this.state.current_month)
      .endOf("month")
      .add(1, "day")
      .startOf("day");
    if (this.isSelectable(month, true)) {
      this.state.current_month = month;
      this.trigger("changed:month", { next: true });
    }
    return this;
  },

  /**
   * Method to decrease current month
   * Triggers `changed:month` event
   * @param {Event} e
   * @returns {boolean} this
   */
  handlePrevMonth: function(e) {
    if (e.type === "keydown" && (e.keyCode !== 32 && e.keyCode !== 13)) {
      return this;
    }

    var month = moment(this.state.current_month)
      .subtract(1, "day")
      .startOf("day");
    if (this.isSelectable(month, true)) {
      this.state.current_month = month.startOf("month");
      this.trigger("changed:month", { previous: true });
    }
    return this;
  },

  /**
   * Method to increase current year
   * Triggers `changed:select` event
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleNextYear: function(e) {
    if (e.type === "keydown" && (e.keyCode !== 32 && e.keyCode !== 13)) {
      return this;
    }

    var year = moment(this.state.current_year).add(1, "year");
    this.state.current_year = year;
    this.trigger("changed:select", { next: true });
    return this;
  },

  /**
   * Method to decrease current year
   * Triggers `changed:select` event
   * @param {Event} e
   * @returns {Calendar} this
   */
  handlePrevYear: function(e) {
    if (e.type === "keydown" && (e.keyCode !== 32 && e.keyCode !== 13)) {
      return this;
    }

    var year = moment(this.state.current_year).subtract(1, "year");
    this.state.current_year = year;
    this.trigger("changed:select", { previous: true });
    return this;
  },

  /**
   * Method to increase current year range
   * Triggers `changed:select` event
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleNextYearRange: function(e) {
    if (e.type === "keydown" && (e.keyCode !== 32 && e.keyCode !== 13)) {
      return this;
    }
    var year = moment(this.state.current_year).add(
      this.settings.date_year_select_range,
      "year"
    );
    this.state.current_year = year;
    this.trigger("changed:select", { next: true });
    return this;
  },

  /**
   * Method to decrease current year range
   * Triggers `changed:select` event
   * @param {Event} e
   * @returns {Calendar} this
   */
  handlePrevYearRange: function(e) {
    if (e.type === "keydown" && (e.keyCode !== 32 && e.keyCode !== 13)) {
      return this;
    }

    var year = moment(this.state.current_year).subtract(
      this.settings.date_year_select_range,
      "year"
    );
    this.state.current_year = year;
    this.trigger("changed:select", { previous: true });
    return this;
  },

  /**
   * Method to change selected day, called when day is clicked
   * Passes to setDate()
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleSelectDay: function(e) {
    if (e.type === "keydown" && (e.keyCode !== 32 && e.keyCode !== 13)) {
      return this;
    }

    var date = moment(
      e.currentTarget.attributes["data-day"].nodeValue,
      this.settings.date_output_format
    ).startOf("day");
    this.setDate(date, false, true);
    $("[data-dates-range-connector]").addClass("date-selected");
    e.stopPropagation();
    return this;
  },

  /**
   * Method to show select month interface, called when month/year from calendar interface is clicked
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleSelectMonth: function(e) {
    if (e.type === "keydown" && (e.keyCode !== 32 && e.keyCode !== 13)) {
      return this;
    }
    this.state.is_month_select = true;
    this.state.is_year_select = false;
    this.trigger("changed:select");
    return this;
  },

  /**
   * Method to show select year interface, called when year from select month interface is clicked
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleSelectYear: function(e) {
    if (e.type === "keydown" && (e.keyCode !== 32 && e.keyCode !== 13)) {
      return this;
    }

    this.state.is_month_select = false;
    this.state.is_year_select = true;
    this.trigger("changed:select");
    return this;
  },

  /**
   * Method to handle selection of a month, called when a month is clicked from select month interface
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleMonth: function(e) {
    if (e.type === "keydown" && (e.keyCode !== 32 && e.keyCode !== 13)) {
      return this;
    }

    var $control = $(e.target);
    var month = $control.attr("data-month");

    if (month) {
      month = moment(month, this.settings.date_output_format);
      if (month.isValid()) {
        this.state.is_month_select = false;
        this.state.is_year_select = false;
        this.state.current_month = month;
        this.trigger("changed:select");
      }
    }

    return this;
  },

  /**
   * Method to handle selection of a year, called when a year is clicked from select year interface
   * @param {Event} e
   * @returns {Calendar} this
   */
  handleYear: function(e) {
    if (e.type === "keydown" && (e.keyCode !== 32 && e.keyCode !== 13)) {
      return this;
    }

    var $control = $(e.target);
    var year = $control.attr("data-year");

    if (year) {
      year = moment(year, this.settings.date_output_format);
      if (year.isValid()) {
        this.state.is_month_select = true;
        this.state.is_year_select = false;
        this.state.current_year = year;
        this.state.current_month = moment(this.state.current_month).year(
          year.year()
        );
        this.trigger("changed:select");
      }
    }

    return this;
  },

  /**
   * Method to set date, used by handleSelectDay() and can be called from usage in outside JS
   * Triggers `changed:date` event
   * @param {string} date
   * @param {Moment} date
   * @param {bool} update_month
   * @param {bool} close_calendar
   * @returns {Calendar} this
   */
  setDate: function(date, update_month, close_calendar, return_flag, reset) {
    var date = date || false;
    var update_month = update_month || false;
    var close_calendar = close_calendar || false;
    var return_flag = return_flag || false;
    var updated = false;

    if (date) {
      date = moment.isMoment(date)
        ? date
        : moment(date, this.settings.date_input_format)
            .locale(moment.locale())
            .startOf("day");
      if (
        (date.isValid() &&
          this.isSelectable(date) &&
          !date.isSame(this.state.selected_date)) ||
        reset
      ) {
        this.state.selected_date = date;
        updated = true;

        if (update_month) {
          this.state.current_month = moment(date)
            .locale(moment.locale())
            .startOf("month");
          this.state.current_year = moment(date)
            .locale(moment.locale())
            .startOf("year");
        }
      } else {
        date = this.state.selected_date;
      }
      this.trigger("changed:date", {
        date: moment(date)
          .locale(moment.locale())
          .format(this.settings.date_output_format),
        component: this.$el
      });
    } else {
      date = moment().startOf("day");

      if (
        this.settings.date_min &&
        this.settings.date_max &&
        moment(date)
          .locale(moment.locale())
          .endOf("day")
          .isAfter(this.settings.date_min) &&
        date.isBefore(
          moment(this.settings.date_max)
            .locale(moment.locale())
            .endOf("day")
        )
      ) {
        date = date;
      } else if (this.settings.date_max) {
        date = this.settings.date_max;
      } else if (this.settings.date_min) {
        date = this.settings.date_min;
      }

      if (update_month) {
        this.state.current_month = moment(date)
          .locale(moment.locale())
          .startOf("month");
        this.state.current_year = moment(date)
          .locale(moment.locale())
          .startOf("year");
      }

      this.state.selected_date = null;
      this.trigger("changed:date", { date: null, component: this.$el });
    }

    if (
      close_calendar &&
      this.elements.$date_trigger &&
      this.elements.$date_calendar
    ) {
      if (this.settings.outputView) {
        this.triggerElement.trigger(this.clickHandler);
      } else {
        this.closeCalendar();
      }
      this.$el.addClass("inactive-calendar");
      if (this.settings.type === "start") {
        var linked_calendar = window.REVELEX.Calendars[this.settings.name].end;
        if (linked_calendar) {
          if (this.settings.outputView) {
            linked_calendar.triggerElement.trigger(this.clickHandler);
          } else {
            linked_calendar.$el.removeClass("inactive-calendar");
            linked_calendar.openCalendar();
          }
        }
      } else if (this.settings.type === "end" && !this.settings.outputView) {
        var linked_calendar =
          window.REVELEX.Calendars[this.settings.name].start;
        linked_calendar.$el.removeClass("inactive-calendar");
      }
    }

    return return_flag ? updated : this;
  },

  /**
   * Sets the initial state
   * @returns {Calendar} this
   */
  setState: function() {
    // Set min and max dates
    if (this.settings.date_min) {
      var date_min = moment.isMoment(this.settings.date_min)
        ? this.settings.date_min
        : moment(this.settings.date_min, this.settings.date_input_format);
      this.settings.date_min = date_min.isValid()
        ? date_min.startOf("day")
        : false;
    }

    if (this.settings.date_max) {
      var date_max = moment.isMoment(this.settings.date_max)
        ? this.settings.date_max
        : moment(this.settings.date_max, this.settings.date_input_format);
      this.settings.date_max = date_max.isValid()
        ? date_max.startOf("day")
        : false;
    }

    if (this.settings.dates_excluded) {
      let x;
      for (x in this.settings.dates_excluded) {
        let excluded_start_date = moment.isMoment(
          this.settings.dates_excluded[x].start_date
        )
          ? this.settings.dates_excluded[x].start_date
          : moment(
              this.settings.dates_excluded[x].start_date,
              this.settings.date_input_format
            );

        let excluded_end_date = moment.isMoment(
          this.settings.dates_excluded[x].end_date
        )
          ? this.settings.dates_excluded[x].end_date
          : moment(
              this.settings.dates_excluded[x].end_date,
              this.settings.date_input_format
            );

        this.settings.dates_excluded[
          x
        ].start_date = excluded_start_date.isValid()
          ? excluded_start_date.startOf("day")
          : false;

        this.settings.dates_excluded[x].end_date = excluded_end_date.isValid()
          ? excluded_end_date.startOf("day")
          : false;
      }

      var date_max = moment.isMoment(this.settings.date_max)
        ? this.settings.date_max
        : moment(this.settings.date_max, this.settings.date_input_format);
      this.settings.date_max = date_max.isValid()
        ? date_max.startOf("day")
        : false;
    }

    // Turn off show select month/year for multiple months
    if (this.settings.show_months > 1) {
      this.settings.show_month_select = false;
    }

    // Set current date and current month
    if (this.settings.show_date) {
      var date = moment().startOf("day");
      var initial_date = false;

      if (this.settings.date) {
        date = this.settings.date;
        initial_date = true;
      } else if (
        this.settings.date_min &&
        this.settings.date_max &&
        moment(date)
          .endOf("day")
          .isAfter(this.settings.date_min) &&
        date.isBefore(moment(this.settings.date_max).endOf("day"))
      ) {
        date = date;
      } else if (this.settings.date_max) {
        date = this.settings.date_max;
      } else if (this.settings.date_min) {
        date = this.settings.date_min;
      }

      date = moment.isMoment(date)
        ? date
        : moment(date, this.settings.date_input_format).startOf("day");

      this.state.current_month = moment(date).startOf("month");
      this.state.current_year = moment(date).startOf("year");

      if (initial_date) {
        this.state.selected_date = date;
        this.renderDateInput().renderDateOutput();
      }
    }

    // If is an end calendar, rerender start calendar
    if (
      this.settings.name &&
      this.settings.type === "end" &&
      window.REVELEX.Calendars[this.settings.name] &&
      window.REVELEX.Calendars[this.settings.name].start
    ) {
      window.REVELEX.Calendars[this.settings.name].start.render();
    }

    // Set view
    this.state.is_month_select = false;
    this.state.is_year_select = false;

    return this;
  },

  /**
   * Builds the months array that will be used to render calendar
   * After finishing, `this.state.months` will look like:
   *  [
   *      {
   *          weeks: [
   *              {days: [ {date: ...}, {date: ...}, {date: ...}, {date: ...}, {date: ...}, {date: ...}, {date: ...}
   * ]},
   *              {days: [ {date: ...}, {date: ...}, {date: ...}, ... ]},
   *              {days: [ {date: ...}, {date: ...}, {date: ...}, ... ]},
   *              {days: [ {date: ...}, {date: ...}, {date: ...}, ... ]},
   *          ],
   *          year: 2015,
   *          month: {
   *              full: "January",
   *              short: "Jan"
   *          },
   *          weekdays: {
   *              full: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
   *              short: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
   *              min: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
   *          },
   *          has_previous_month: false,
   *          has_next_month: true
   *      },
   *      {
   *          weeks: [
   *              {days: [ {date: ...}, {date: ...}, {date: ...}, ... ]},
   *              {days: [ {date: ...}, {date: ...}, {date: ...}, ... ]},
   *              ...
   *      },
   *      ...
   *  ]
   * @returns {array} months
   */
  setMonths: function() {
    var date = this.state.current_month;
    var weekdays = {};
    var currentWeekdays = {};
    var has_previous_month = this.isSelectable(
      moment(this.state.current_month).subtract(1, "day"),
      true
    );
    var has_next_month = this.isSelectable(
      moment(this.state.current_month)
        .endOf("month")
        .startOf("day")
        .add(1, "day"),
      true
    );
    var show_month_select = this.settings.show_month_select;

    if (
      show_month_select &&
      this.settings.date_max &&
      this.settings.date_min &&
      this.settings.date_max.month() === this.settings.date_min.month() &&
      this.settings.date_max.year() === this.settings.date_min.year()
    ) {
      show_month_select = false;
    }

    if (moment.localeData().firstDayOfWeek() === 1) {
      weekdays = {
        full: [
          moment()
            .isoWeekday(1)
            .format("dddd"),
          moment()
            .isoWeekday(2)
            .format("dddd"),
          moment()
            .isoWeekday(3)
            .format("dddd"),
          moment()
            .isoWeekday(4)
            .format("dddd"),
          moment()
            .isoWeekday(5)
            .format("dddd"),
          moment()
            .isoWeekday(6)
            .format("dddd"),
          moment()
            .isoWeekday(7)
            .format("dddd")
        ],
        short: [
          moment()
            .isoWeekday(1)
            .format("ddd"),
          moment()
            .isoWeekday(2)
            .format("ddd"),
          moment()
            .isoWeekday(3)
            .format("ddd"),
          moment()
            .isoWeekday(4)
            .format("ddd"),
          moment()
            .isoWeekday(5)
            .format("ddd"),
          moment()
            .isoWeekday(6)
            .format("ddd"),
          moment()
            .isoWeekday(7)
            .format("ddd")
        ],
        min: [
          moment()
            .isoWeekday(1)
            .format("dd"),
          moment()
            .isoWeekday(2)
            .format("dd"),
          moment()
            .isoWeekday(3)
            .format("dd"),
          moment()
            .isoWeekday(4)
            .format("dd"),
          moment()
            .isoWeekday(5)
            .format("dd"),
          moment()
            .isoWeekday(6)
            .format("dd"),
          moment()
            .isoWeekday(7)
            .format("dd")
        ]
      };
    } else {
      weekdays = {
        full: moment.weekdays(),
        short: moment.weekdaysShort(),
        min: moment.weekdaysMin().map(day => day.charAt(0))
      };
    }

    currentWeekdays = weekdays[this.settings.weekdayOutput];

    this.state.months = [];
    //If anything other than a number was passed on show_months, we use 1 instead
    this.settings.show_months =
      typeof this.settings.show_months !== "number"
        ? 1
        : this.settings.show_months;

    // If it's iPhone or Android, set show_months to show_months_mobile
    if (
      this.deviceAgent.match(/(ipod|iphone|ipad|android)/) &&
      window.screen.width <= 767
    ) {
      this.settings.show_months = this.settings.show_months_mobile;
    }

    // Create a month object based on how many months to show at a time
    for (var i = 0; i < this.settings.show_months; i++) {
      var month = moment(this.state.current_month).add(i, "months");
      var first_day = moment(month).startOf("month");
      var last_day = moment(month)
        .endOf("month")
        .startOf("day");
      var current_day = moment(first_day);
      this.state.months[i] = {
        weeks: [],
        weekdays: currentWeekdays,
        month: {
          index: current_day.month(),
          full: moment.months(current_day.month()),
          short: moment.monthsShort(current_day.month())
        },
        months: {
          full: moment.months(),
          short: moment.monthsShort()
        },
        year: current_day.year(),
        show_month_select: show_month_select,
        has_previous_month: has_previous_month,
        has_next_month: has_next_month,
        cid: this.cid,
        parentAutoclose: this.settings.parentAutoclose
      };

      // Loop through until last day of month
      while (
        last_day.diff(current_day, "days") > 0 ||
        current_day.isSame(last_day)
      ) {
        // Check if day is start/end/range
        var is_start_end = this.isStartEnd(current_day);

        // If this is the first day, or if this is a different week than the last day, create a new week
        //  inside current month
        if (
          current_day.isSame(first_day) ||
          !current_day.isSame(moment(current_day).subtract(1, "day"), "week")
        ) {
          this.state.months[i].weeks.push({ days: [] });
        }

        // If this is the first day and the first day is not the beginning of the week, fill up the week
        //  with dates from previous month
        if (current_day.isSame(first_day) && current_day.weekday() !== 0) {
          var week_beginning = moment(current_day).startOf("week");
          while (
            this.state.months[i].weeks[this.state.months[i].weeks.length - 1]
              .days.length < current_day.weekday()
          ) {
            var filler_week = this.state.months[i].weeks[
              this.state.months[i].weeks.length - 1
            ].days;
            var filler_day = moment(week_beginning).add(
              filler_week.length,
              "days"
            );
            var filler_start_end = this.isStartEnd(filler_day);
            filler_week.push({
              date: filler_day,
              date_day: filler_day.date(),
              date_output: filler_day.format(this.settings.date_output_format),
              is_previous_month: true,
              is_selectable: this.isSelectable(filler_day),
              is_start: filler_start_end === 1 || filler_start_end === 4,
              is_range: filler_start_end === 2,
              is_end: filler_start_end === 3 || filler_start_end === 4
            });
          }
        }

        // Add day to current week
        this.state.months[i].weeks[
          this.state.months[i].weeks.length - 1
        ].days.push({
          date: moment(current_day),
          date_day: current_day.date(),
          date_output: current_day.format(this.settings.date_output_format),
          is_selectable: this.isSelectable(current_day),
          is_selected: current_day.isSame(this.state.selected_date),
          is_start: is_start_end === 1 || filler_start_end === 4,
          is_range: is_start_end === 2,
          is_end: is_start_end === 3 || filler_start_end === 4
        });

        // If this is the last day and the last day is not the end of the week, fill up the week with dates
        //  from next month
        if (current_day.isSame(last_day) && current_day.weekday() !== 6) {
          var week_beginning = moment(current_day).startOf("week");
          while (
            this.state.months[i].weeks[this.state.months[i].weeks.length - 1]
              .days.length < 7
          ) {
            var filler_week = this.state.months[i].weeks[
              this.state.months[i].weeks.length - 1
            ].days;
            var filler_day = moment(week_beginning).add(
              filler_week.length,
              "days"
            );
            var filler_start_end = this.isStartEnd(filler_day);
            filler_week.push({
              date: filler_day,
              date_day: filler_day.date(),
              date_output: filler_day.format(this.settings.date_output_format),
              is_next_month: true,
              is_selectable: this.isSelectable(filler_day),
              is_start: filler_start_end === 1 || filler_start_end === 4,
              is_range: filler_start_end === 2,
              is_end: filler_start_end === 3 || filler_start_end === 4
            });
          }
        }

        // Increment day
        current_day.add(1, "day");
      }
    }
    return this;
  },

  /**
   * Builds the array of months that will be used to render select month interface
   * @returns {Calendar} this
   */
  setSelectMonths: function() {
    var current_year = this.state.current_year.year();
    var show_year_select = this.settings.show_year_select;
    var months = [];

    // Disable year select if max and min dates are the same year
    if (this.settings.date_min && this.settings.date_max) {
      if (this.settings.date_min.year() === this.settings.date_max.year()) {
        show_year_select = false;
      }
    }

    // Build each month
    for (var i = 0; i < 12; i++) {
      var month = moment([current_year, i, 1]);
      var is_selectable =
        this.isSelectable(month) ||
        this.isSelectable(moment(month).endOf("month"));

      var month_info = {
        full: moment.months(i),
        short: moment.monthsShort(i),
        month_output: month.format(this.settings.date_output_format),
        is_selected:
          is_selectable && month.month() === this.state.current_month.month(),
        is_selectable: is_selectable
      };

      months.push(month_info);
    }

    this.state.select_months = {
      year: current_year,
      months: months,
      show_year_select: show_year_select,
      has_previous_year: this.isSelectable(
        moment(this.state.current_year).subtract(1, "day")
      ),
      has_next_year: this.isSelectable(
        moment(this.state.current_year).add(1, "year")
      ),
      cid: this.cid,
      parentAutoclose: this.settings.parentAutoclose
    };

    return this;
  },

  /**
   * Builds the array of years that will be used to render select year interface
   * @returns {Calendar} this
   */
  setSelectYears: function() {
    var current_year = this.state.current_year.year();
    var range_amount = this.settings.date_year_select_range;
    var start_year = current_year - Math.floor(range_amount / 2);
    var end_year = start_year + (range_amount - 1);
    var years = [];

    // Build each year
    for (var i = 0; i < range_amount; i++) {
      var year = moment([start_year + i, this.state.current_year.month(), 1]);
      var is_selectable = this.isSelectableYear(year);
      var year_info = {
        year: year.year(),
        year_output: year.format(this.settings.date_output_format),
        is_selected:
          year.year() == this.state.current_year.year() && is_selectable,
        is_selectable: is_selectable
      };
      years.push(year_info);
    }

    this.state.select_years = {
      start_year: start_year,
      end_year: end_year,
      years: years,
      has_previous_year_range:
        this.settings.date_min && this.settings.date_min.year() >= start_year
          ? false
          : true,
      has_next_year_range:
        this.settings.date_max && this.settings.date_max.year() <= end_year
          ? false
          : true,
      cid: this.cid,
      parentAutoclose: this.settings.parentAutoclose
    };

    return this;
  },

  /**
   * Checks if a given moment date object is within calendar's range
   * @param {Moment} date
   */
  isSelectable: function(date, skipExclusion) {
    // loop to take care of exclusion of chunks in the calendar
    if (this.settings.dates_excluded && !skipExclusion) {
      let x;
      for (x in this.settings.dates_excluded) {
        if (
          date.isBetween(
            this.settings.dates_excluded[x].start_date,
            this.settings.dates_excluded[x].end_date,
            null,
            "[]"
          )
        ) {
          return false;
        }
      }
    }

    if (
      !(moment.isMoment(date) && date.isValid()) ||
      (this.settings.date_min && date.isBefore(this.settings.date_min)) ||
      (this.settings.date_max && date.isAfter(this.settings.date_max))
    ) {
      return false;
    }

    return true;
  },

  /**
   * Checks if a given date's YEAR is within calendar's range
   * @param {Moment} date
   */
  isSelectableYear: function(date) {
    if (
      !(moment.isMoment(date) && date.isValid()) ||
      (this.settings.date_min && date.year() < this.settings.date_min.year()) ||
      (this.settings.date_max && date.year() > this.settings.date_max.year())
    ) {
      return false;
    }

    return true;
  },

  /**
   * Checks if a given moment date object is the start, end, or in range (of start/end calendars)
   * Returns  1 if it is the start date
   *          2 if it is between start and end dates
   *          3 if it is the end date
   *          4 if it is both start and end
   *          0 otherwise
   * @param {Moment} date
   * @returns {Number} 0, 1, 2, 3
   */
  isStartEnd: function(date) {
    if (
      this.settings.name &&
      moment.isMoment(date) &&
      date.isValid() &&
      window.REVELEX.Calendars[this.settings.name] &&
      (this.settings.type === "start" || this.settings.type === "end")
    ) {
      var date = date.startOf("day");
      var start_calendar = window.REVELEX.Calendars[this.settings.name].start;
      var end_calendar = window.REVELEX.Calendars[this.settings.name].end;

      if (
        start_calendar &&
        end_calendar &&
        start_calendar.state.selected_date &&
        end_calendar.state.selected_date &&
        start_calendar.state.selected_date.isSame(date) &&
        end_calendar.state.selected_date.isSame(date)
      ) {
        return 4;
      }

      if (
        start_calendar &&
        start_calendar.state.selected_date &&
        start_calendar.state.selected_date.isSame(date)
      ) {
        return 1;
      }

      if (
        end_calendar &&
        end_calendar.state.selected_date &&
        end_calendar.state.selected_date.isSame(date)
      ) {
        return 3;
      }

      if (
        start_calendar &&
        end_calendar &&
        start_calendar.state.selected_date &&
        end_calendar.state.selected_date &&
        date.isAfter(start_calendar.state.selected_date) &&
        date.isBefore(end_calendar.state.selected_date)
      ) {
        return 2;
      }
    }

    return 0;
  },

  /**
   * Renders the month select interface
   * @returns {*} Calendar this / string content
   */
  renderMonthSelect: function() {
    this.setSelectMonths();
    var content = $(
      this.settings.date_month_select_template(this.state.select_months)
    );

    let currentCalendar = this.elements.$date_calendar.length
      ? this.elements.$date_calendar
      : this.elements.$date_calendar.$el;

    if (this.elements.$date_calendar) {
      currentCalendar
        .empty()
        .append(content)
        .focus();
      return this;
    } else {
      return content;
    }
  },

  /**
   * Renders the year select interface
   * @returns {*} Calendar this / string content
   */
  renderYearSelect: function() {
    this.setSelectYears();
    var content = $(
      this.settings.date_year_select_template(this.state.select_years)
    );

    let currentCalendar = this.elements.$date_calendar.length
      ? this.elements.$date_calendar
      : this.elements.$date_calendar.$el;

    if (this.elements.$date_calendar) {
      currentCalendar
        .empty()
        .append(content)
        .focus();
      return this;
    } else {
      return content;
    }
  },

  /**
   * Renders the datepicker calendar
   * If a render target is given, will automatically replace the target's content with rendered content
   * Otherwise will return the HTML of the render result
   * @returns {*} Calendar this / string content
   */
  renderDate: function() {
    this.state.months.cid = this.cid;
    this.setMonths();
    var content = $(this.settings.date_template(this.state.months));

    let currentCalendar = this.elements.$date_calendar.length
      ? this.elements.$date_calendar
      : this.elements.$date_calendar.$el;

    if (this.elements.$date_calendar) {
      if (this.settings.outputView) {
        this.elements.$date_calendar.render(content);
      } else {
        currentCalendar
          .empty()
          .append(content)
          .focus();
      }
      return this;
    } else {
      return content;
    }
  },

  /**
   * Renders the date display value
   * @returns {Calendar} this
   */
  renderDateInput: function() {
    if (this.elements.$date_input) {
      if (this.state.selected_date) {
        this.elements.$date_input
          .val(
            moment(this.state.selected_date)
              .locale(moment.locale())
              .format(this.settings.date_display_format)
          )
          .trigger("change");
      } else {
        // Change event should not be triggered if no change was made.
        if (this.elements.$date_input.val()) {
          this.elements.$date_input.val("").trigger("change");
        }
      }
    }
    return this;
  },

  /**
   * Renders the date output value
   * @returns {Calendar} this
   */
  renderDateOutput: function() {
    if (this.elements.$date_output) {
      if (this.state.selected_date) {
        if (this.settings.type == "end") {
          $("[data-dates-range-connector]").addClass("date-selected");
        }
        this.elements.$date_output
          .val(
            moment(this.state.selected_date)
              .locale(moment.locale())
              .format(this.settings.date_output_format)
          )
          .trigger("change");
      } else {
        // Change event should not be triggered if no change was made.
        if (this.elements.$date_output.val()) {
          this.elements.$date_output.val("").trigger("change");
        }
      }
    }
    return this;
  },

  /**
   * Wrapper render function
   * Checks for date/time show settings
   * Checks if render function are passed and will use in place of default
   * @returns {Calendar} this
   */
  render: function() {
    if (this.settings.show_date) {
      if (this.state.is_month_select) {
        this.renderMonthSelect();
      } else if (this.state.is_year_select) {
        this.renderYearSelect();
      } else {
        // Render calendar
        if (typeof this.settings.date_render === "function") {
          this.setMonths();
          this.settings.date_render.call();
        } else {
          this.renderDate();
        }

        this.renderDateInput().renderDateOutput();
      }
    }

    return this;
  },

  isOnlyNumbers: function(string) {
    return /^\d+$/.test(string);
  }
});

module.exports = Calendar;
