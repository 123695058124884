/**
 * Sticky Component
 * Ver: 2.2
 */

var breakpoints = {
  desktop: { max: 2550, min: 1171 },
  tablet: { max: 1170, min: 769 },
  mobile: { max: 768, min: 0 }
};

var Sticky = Backbone.View.extend({
  initialize: function() {
    this.settings = this.$el.data("settings") || {};
    this.gap = this.settings.gap || 0;

    this.id = this.settings.stickyID || "currentSticky";

    // If sticky works only on specific breakpoints activate window resizing listener
    this.breakpoints = this.$el.data("breakpoints");

    if (this.breakpoints) {
      $(window).on(
        "resize",
        function() {
          this.bindEvents();
        }.bind(this)
      );
    }

    // If a container was defined to set limits
    this.stickyContainer = $(this.$el.data("stickyContainer"));
    this.containerScroll = this.$el.is("[data-sticky-container-scroll]");

    // Get list of other sticky elements that allow staking
    this.isStackable = this.$el.is("[data-sticky-stack]");
    this.stack = this.isStackable ? $("[data-sticky-stack]") : {};

    this.reflow();

    $(window).on("resizeView", this.resize.bind(this));
  },

  validateViewportSize: function() {
    var currentWidth = window.innerWidth;
    return _.find(this.breakpoints, function(breakpoint) {
      breakpoint = breakpoints[breakpoint];
      if (currentWidth > breakpoint.min && currentWidth < breakpoint.max) {
        return true;
      }
    });
  },

  // $.off before $.on to avoid multiple bindings in case of reflow.
  bindEvents: function(container) {
    container = container || $(window);

    container.off("scroll.sticky." + this.cid).off("resize.sticky." + this.cid);

    // If viewport doesn't match any defined breakpoint don't bind
    if (this.breakpoints && !this.validateViewportSize()) {
      this.stick(false);
      return false;
    }

    // If sticky component has a container or sticky component loads asynchronous content then recalculate heights
    if (this.stickyContainer || this.$el.is("[data-sticky-async]")) {
      container.on(
        "scroll.sticky." + this.cid,
        this.calculateContainerHeight.bind(this)
      );
    }

    container.on("scroll.sticky." + this.cid, this.checkPosition.bind(this));
  },

  /**
   * When the page loads lower in the document or when there's a delayed rendering we need wait for the first window scroll to run the final bindings
   * We move document to top 0 to calculate originalTop right
   */
  windowHack: function() {
    $("html, body").scrollTop(0);

    $(window).off("scroll.windowHack." + this.cid);

    this.bindEvents();
  },

  reflow: function() {
    if (this.containerScroll) {
      // If it's container scroll we bind to both the window and the container for it to recalculate the top when moving both scrollbars
      this.bindEvents();
      this.bindEvents(this.stickyContainer);
    } else {
      // Force browser to go to scrollTop = 0 to properly set originalTop of elements
      // in case we load the page on the middle of document.
      if ($(window).scrollTop()) {
        $(window)
          .off("scroll.windowHack." + this.cid)
          .on("scroll.windowHack." + this.cid, this.windowHack.bind(this));
      } else {
        this.bindEvents();
      }
    }

    // Placeholder to replace sticky component with width and height
    this.placeholder = $("<div />");

    // Set State Flag
    this.status = "off";

    this.$el.loadComponents();
    this.$el.loadModules();

    return this;
  },
  getPlaceholder: function() {
    // Placeholder Generator
    if (this.status == "on") {
      return $("<div data-sticky-placeholder=" + this.id + " />");
    } else {
      return $("<div data-sticky-placeholder=" + this.id + " />").css(
        this.currents
      );
    }
  },

  checkPosition: function(e) {
    var container = this.containerScroll ? this.stickyContainer : $(window);

    // Get current position
    var containerTop = container.scrollTop() + this.gap;

    // Set first values if they haven't been set
    if (!this.originalTop) {
      this.setFirstValues();
    }

    // Add height of other sticky-stack elements
    if (this.stack.length) {
      var stackHeight = this.getStackHeight();
      containerTop += stackHeight;
    }

    // If containerTop is between sticky parent limits
    if (this.stickyContainer.length) {
      var control =
        containerTop > this.originalTop &&
        containerTop < this.bottomLimit + this.gap;
    } else {
      var control = containerTop > this.originalTop;
    }

    // Pass in result
    this.stick(control, stackHeight, e);

    return this;
  },

  stick: function(control, stackHeight, e) {
    // Add top difference when scrolling container
    var containerTop = this.containerScroll
      ? this.stickyContainer.offset().top -
        $(window).scrollTop() +
        (this.$el.outerHeight(true) - this.$el.innerHeight())
      : this.gap;

    // Will only happen when a container scroll moves window scrollbar
    if (this.containerScroll && e.currentTarget.window) {
      this.$el.css("top", containerTop);
      return false;
    }

    // Will only be triggered the first time the condition succeeds
    if (control && this.status == "off") {
      // Init -sticky- state.

      // Pull current size of component
      this.currents = {
        width: this.$el.outerWidth(true),
        "max-width": this.$el.outerWidth(true),
        height: this.$el.outerHeight(),
        top: containerTop + "px"
      };

      // If there are other sticky stack elements floating, overwrite top value to stack them
      if (this.isStackable) {
        this.currents["top"] = stackHeight;
      }

      // Create Placeholder, add it next to the component and keep
      // instance to be able to remove it without bothering the DOM.
      this.currentPlaceholder = this.getPlaceholder();
      this.currentPlaceholder.insertAfter(this.$el);

      // Add State Class
      this.$el.addClass("is-sticky").removeClass("is-anchored");

      // Fix component size
      this.$el.css(this.currents);

      // Update State Flag
      this.status = "on";
    }

    // Will only be triggered the first time the condition fails
    // only if it has succeeded before
    if (!control && this.status == "on") {
      // Undo - sticky state.

      // If reached end of container (custom class to optionally anchor sticky element to the bottom)
      if (
        this.stickyContainer.length &&
        !this.containerScroll &&
        this.$el.offset().top >= this.bottomLimit - this.gap
      ) {
        this.$el.addClass("is-anchored");
      }

      // Remove State Class
      this.$el.removeClass("is-sticky");

      // Remove Fixed size (added on -stick-)
      this.$el.removeAttr("style");

      // Eliminate Last Placeholder
      this.currentPlaceholder.remove();

      // Update State Flag
      this.status = "off";
    }

    return this;
  },

  /**
   * Get combined height of all current stack floating objects
   */
  getStackHeight: function() {
    var height = 0;

    _.each(this.stack, function(element) {
      height += $(element).hasClass("is-sticky") ? $(element).outerHeight() : 0;
    });

    return this.status == "on" ? height - this.$el.outerHeight() : height;
  },

  /**
   * First values are set open scroll event to ensure all elements are set and heights are final
   */
  setFirstValues: function() {
    if (this.containerScroll) {
      this.originalTop = this.$el.position().top;
    } else {
      this.originalTop = this.$el.offset().top;
    }
    //Check container height and limits
    this.calculateContainerHeight();
  },

  calculateContainerHeight: function() {
    // Check bottom limit the first time it runs
    if (this.stickyContainer.length) {
      //Saving sticky parent limits, bottom (parent top + container height - sticky component height)
      this.bottomLimit = !this.containerScroll
        ? this.stickyContainer.offset().top +
          this.stickyContainer.outerHeight() -
          this.$el.outerHeight(true)
        : this.stickyContainer.outerHeight();
    }
  },

  isSticky: function() {
    // Auxiliary method created for public API.
    return this.status == "on" ? true : false;
  },

  // resizing feature for sticky header
  resize: function() {
    // gathering current placeholder
    let currentPlaceholder = $("[data-sticky-placeholder=" + this.id + "]");

    if (currentPlaceholder.length) {
      // if placeholder exist remove max-width and width
      // and recalculate again
      currentPlaceholder.css({
        "max-width": "none",
        width: "auto"
      });

      currentPlaceholder.css({
        "max-width": currentPlaceholder.outerWidth(true),
        width: currentPlaceholder.outerWidth(true)
      });

      this.$el.css({
        "max-width": currentPlaceholder.outerWidth(true),
        width: currentPlaceholder.outerWidth(true)
      });
    }
  }
});

module.exports = Sticky;
