var $ = require("jquery");
var Collapser = require("./collapser");

var CollapserField = Collapser.extend({
  /**
   * This method checks if the collapser can be toggled.
   *
   * @param e
   *
   * @returns boolean
   */
  canBeToggled: function(e) {
    return !(
      this.isOpen() &&
      ($(e.currentTarget).is(":checked") || $(e.currentTarget).is(":selected"))
    );
  },

  /**
   * This method adds a change listener for select field elements
   */
  onInit: function() {
    $(document).on(
      "change.collapser." + this.cid,
      '[data-collapser-trigger-select="' + this.id + '"]',
      this.collapseSelect.bind(this)
    );

    $(document).on(
      "change-multi.collapser." + this.cid,
      '[data-collapser-trigger="' + this.id + '"]',
      this.collapseSelectMulti.bind(this)
    );

    $(document).on(
      "change.collapser." + this.cid,
      '[data-collapser-trigger-check="' + this.id + '"]',
      this.collapseCheck.bind(this)
    );

    // If data-collapser-trigger-check is checked,
    // it will run the open functionality

    let currentCheckSelector =
      $('[data-collapser-trigger-check="' + this.id + '"]') || false;

    if (currentCheckSelector.length && currentCheckSelector.is(":checked")) {
      this.open(true);
    } else if (currentCheckSelector.length) {
      this.close();
    }
  },

  /**
   * This method handles the logic of determining if this collapser should be toggled.
   *
   * @param e
   *
   * @returns CollapserField
   */
  collapse: function(e) {
    if (
      e &&
      e.currentTarget &&
      $(e.currentTarget).data("collapser-trigger") === this.id &&
      this.canBeToggled(e)
    ) {
      if (!$(e.currentTarget).data("collapser-trigger-action")) {
        // If no action specified, toggle
        this.toggle();
      } else {
        // Store Action
        var action = $(e.currentTarget).data("collapser-trigger-action");

        // Explicitly execute declared action
        if (action == "open") {
          this.open();
        } else if (action == "close") {
          this.close();
        }
      }
    }

    return this;
  },

  /**
   * This method finds and triggers the click event of the selected option of a select
   *
   * @param e
   *
   * @returns CollapserField
   */
  collapseSelect: function(e) {
    if (
      e &&
      e.currentTarget &&
      $(e.currentTarget).data("collapser-trigger-select") === this.id
    ) {
      if ($(e.currentTarget).attr("multiple")) {
        $(e.currentTarget)
          .find("option")
          .trigger("change-multi");
      } else {
        $(e.currentTarget)
          .find("option:selected")
          .trigger("click");
      }
    }

    return this;
  },

  collapseSelectMulti: function(e) {
    if (
      e &&
      e.currentTarget &&
      $(e.currentTarget).data("collapser-trigger") === this.id &&
      $(e.currentTarget).is(":selected")
    ) {
      this.open();
    } else {
      this.close();
    }

    return this;
  },

  collapseCheck: function(e) {
    if (
      e &&
      e.currentTarget &&
      $(e.currentTarget).data("collapser-trigger-check") === this.id
    ) {
      if ($(e.currentTarget).is(":checked")) {
        this.open();
      } else {
        this.close();
      }
    }

    return this;
  },

  closeNestedCollapsers: function() {
    this.$('[data-component="collapser-field"]').each(function() {
      $(this)
        .component()
        .close();
    });
    return this;
  }
});

module.exports = CollapserField;
