//The module counts the total number of selected travelers using the [data-traveler] attribute and checks that number with the passengers stored in the shel pnr.
// Both numbers should match to complete the booking. Once the number selected passengers reaches the number in pnr the "Add passenger" button disables. If the numbers does not match the warning modal pop up.
//Currently it is being used for Amex
var TravelerLimit = Backbone.View.extend({
  events: {
    "click [data-check-travelers]": "checkTravelers",
    "field-removed": "limitTravelers",
    "field-added": "limitTravelers"
  },

  checkTravelers: function(e) {
    //If number os selected passengers does not match with pnr will populate the passengers modal, otherwise will submit the form
    this.travelersDialog = $('[data-dialog-id="travelers-info-pnr"]');

    if (this.numberSelectedTravelers != this.settings.total_travelers_limit) {
      this.travelersDialog.component().open();
    } else {
      $("[data-form-traveler-creation]").submit();
    }
  },

  limitTravelers: function() {
    //Every time when user add/remove the passenger, the number of selected passengers will update
    //If we have multiple sailing booking, the total number of travelers will be only in the expanded collapser (only visible travelers)
    //If we have multiple  cabin booking, the total number of travelers will be calculated from all cabins (visible and hidden in the collapser)
    this.numberSelectedTravelers = this.settings.side_by_side
      ? $("[data-traveler]:visible").length
      : $("[data-traveler]").length;

    if (this.numberSelectedTravelers >= this.settings.total_travelers_limit) {
      // Hide add passengers button
      this.$("[data-field-replicator-traveler]").addClass("is-disabled");
    } else {
      // Show add passengers button if the number of passengers less or equal than in pnr
      this.$("[data-field-replicator-traveler]").removeClass("is-disabled");
    }
  },

  initialize: function() {
    this.settings = {};

    if (this.$el.data("settings")) {
      this.settings = this.settings = $.extend(
        true,
        this.settings,
        this.$el.data("settings")
      );
    }
    //The multiple sailing booking behaves similar to the multi cabin booking;
    //If we have multiple sailing booking, the total number of travelers will be only in the expanded collapser (only visible travelers)
    //If we have multiple  cabin booking, the total number of travelers will be calculated from all cabins (visible and hidden in the collapser)
    this.numberSelectedTravelers = this.settings.side_by_side
      ? $("[data-traveler]:visible").length
      : $("[data-traveler]").length;

    // disable add passengers button on initial load if number of passengers match
    if (this.numberSelectedTravelers >= this.settings.total_travelers_limit) {
      this.$("[data-field-replicator-traveler]").addClass("is-disabled");
    }
    //Checking the selected travelers for each sailing (when new sailing collapser has been opened)
    Backbone.Events.on(
      "travelerInformation:limitTravelers collapser-opened",
      this.limitTravelers.bind(this)
    );
  }
});

module.exports = TravelerLimit;
