// Get all required ie11 polyfills
require("./utilities/polyfills");

// Polyfill for fetch for  IE11
require("whatwg-fetch");

// Global reference of chunks
$.rvlx = {};
$.rvlx.chunks = $.rvlx.chunks || {};

// Require Global Utilities
require("./utilities/console");

// Declare all our Chunks
var chunks = [
  "commons",
  "dev",
  "air",
  "cruise",
  "river-cruise",
  "hotel",
  "admin",
  "insurance",
  "manual-tour",
  "customer",
  "guided-vacation",
  "car",
  "tools",
  "activity",
  "order-viewer"
];

_.each(
  chunks,
  function(chunk) {
    // Default states for async and sync chunks
    $.rvlx.chunks[chunk] = {
      isLoaded: chunk == "commons" ? true : false,
      isLoading: false,
      queue: {
        components: [],
        modules: []
      }
    };
  }.bind(this)
);

/**
 *
 *      Sync and Async Product/Path Definitions
 *
 **/

// Sync Chunk: Declare Commons library
var commons = require("./commons/commons");

// Async Chunk: Declare HOTEL library.
if ($('[data-path="dev"]').length) {
  $.rvlx.chunks["dev"].isLoading = true;
  require.ensure(["./dev/dev"], function(require) {
    require("./dev/dev");
  });
}

// Async Chunk: Declare CRUISE library.
if ($('[data-path="cruise"]').length) {
  $.rvlx.chunks["cruise"].isLoading = true;
  require.ensure(["./cruise/cruise"], function(require) {
    require("./cruise/cruise");
  });
}

// Async Chunk: Declare HOTEL library.
if ($('[data-path="hotel"]').length) {
  $.rvlx.chunks["hotel"].isLoading = true;
  require.ensure(["./hotel/hotel"], function(require) {
    require("./hotel/hotel");
  });
}

// Async Chunk: Declare AIR library.
if ($('[data-path="air"]').length) {
  $.rvlx.chunks["air"].isLoading = true;
  require.ensure(["./air/air"], function(require) {
    require("./air/air");
  });
}

// Async Chunk: Declare RIVER CRUISE library.
if ($('[data-path="river-cruise"]').length) {
  $.rvlx.chunks["river-cruise"].isLoading = true;
  require.ensure(["./river-cruise/river-cruise"], function(require) {
    require("./river-cruise/river-cruise");
  });
}

// Async Chunk: Declare RIVER CRUISE library.
if ($('[data-path="insurance"]').length) {
  $.rvlx.chunks["insurance"].isLoading = true;
  require.ensure(["./insurance/insurance"], function(require) {
    require("./insurance/insurance");
  });
}

// Async Chunk: Declare ADMIN library.
if ($('[data-path="admin"]').length) {
  $.rvlx.chunks["admin"].isLoading = true;
  require.ensure(["./admin/admin"], function(require) {
    require("./admin/admin");
  });
}

// Async Chunk: Declare ADMIN library.
if ($('[data-path="manual-tour"]').length) {
  $.rvlx.chunks["manual-tour"].isLoading = true;
  require.ensure(["./manual-tour/manual-tour"], function(require) {
    require("./manual-tour/manual-tour");
  });
}

if ($('[data-path="customer"]').length) {
  $.rvlx.chunks["customer"].isLoading = true;
  require.ensure(["./customer/customer"], function(require) {
    require("./customer/customer");
  });
}

// Async Chunk: Declare GUIDED vacation library.
if ($('[data-path="guided-vacation"]').length) {
  $.rvlx.chunks["guided-vacation"].isLoading = true;
  require.ensure(["./guided-vacation/guided-vacation"], function(require) {
    require("./guided-vacation/guided-vacation");
  });
}

// Async Chunk: Declare car library.
if ($('[data-path="car"]').length) {
  $.rvlx.chunks["car"].isLoading = true;
  require.ensure(["./car/car"], function(require) {
    require("./car/car");
  });
}

if ($('[data-path="tools"]').length) {
  $.rvlx.chunks["tools"].isLoading = true;
  require.ensure(["./tools/tools"], function(require) {
    require("./tools/tools");
  });
}

if ($('[data-path="activity"]').length) {
  $.rvlx.chunks["activity"].isLoading = true;
  require.ensure(["./activity/activity"], function(require) {
    require("./activity/activity");
  });
}

if ($('[data-path="order-viewer"]').length) {
  $.rvlx.chunks["order-viewer"].isLoading = true;
  require.ensure(["./order-viewer/order-viewer"], function(require) {
    require("./order-viewer/order-viewer");
  });
}
