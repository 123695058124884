// Global output view
var CloseTemplate =
  "<button type='button' class='output-view-close icon-close' data-output-view-close='{{trigger}}'><span class='sr-only'>Close</span></button>";

module.exports = Backbone.View.extend({
  initialize: function(params) {
    // Passing settings is optional. We take it if it's passed, if not we default to component's settings.
    params.settings = params.settings || params.component.settings;

    // If neither is available we default to the component's data attributes
    params.settings = params.settings || params.component.$el.data();

    // We make all the data of the component available here
    $.extend(this, params);

    // Setting the DOM element
    this.$el = $(this.settings.outputView);
    this.el = this.$el[0];

    // ADA setup
    this.$el.attr({ tabindex: 0 });

    // Fallback for components that use name instead
    this.settings.id = this.settings.id || this.settings.name;

    // Fallback to backbone id
    this.settings.id = this.settings.id || this.component.cid;

    // Compiles close trigger
    this.closeTemplate = Handlebars.compile(CloseTemplate);

    // Will hold the logic for the current Animation
    window.currentViewAnimation = null;

    if (!this.$el.hasClass("is-visible")) {
      this.toggleViewState();
    }

    // Bind events to open view
    this.bindViewEvents();
  },

  bindViewEvents: function() {
    // click events not recognized on some iphone versions
    this.deviceAgent = navigator.userAgent.toLowerCase();

    this.clickHandler = this.settings.clickHandler;

    if (!this.clickHandler) {
      this.clickHandler = "click";
    }

    // Save component's trigger for event use
    this.component.triggerElement = this.settings.trigger
      ? this.component.$(this.settings.trigger)
      : this.component.$el;

    // Give trigger a generic data attribute so all
    // connected triggers can be identified

    this.component.triggerElement.attr(
      "data-output-view-trigger",
      this.settings.outputView
    );

    this.component.$el.on(
      "keyup",
      "input" + this.settings.trigger,
      this.focusTrigger.bind(this)
    );

    this.component.$el.on(
      this.clickHandler,
      this.settings.trigger,
      this.toggleOutput.bind(this)
    );

    this.events = this.events || {};

    // Add the undelegate event to make all attached views trigger undelegateEvents() and detach events
    this.events["undelegateEvents"] = "undelegateComponentEvents";

    // Map output view events to map component's methods
    _.each(
      this.component.events,
      function(method, handler) {
        this.events[handler] = this.component[method].bind(this.component);
      }.bind(this)
    );

    this.delegateEvents();

    Backbone.Events.on(
      "contentReloaded",
      function(e) {
        if (
          e.componentId === this.settings.id ||
          e.componentId === this.component.cid
        ) {
          this.render(e.content);
        }
      }.bind(this)
    );
  },

  /**
   * Triggers the native backbone method undelegateEvents and runs onComponentDetach
   */
  undelegateComponentEvents: function() {
    if (this.component) {
      this.onComponentDetach();
      this.undelegateEvents();
    }
  },

  /**
   * Manage tab focus in all browsers
   */
  focusTrigger: function(e) {
    var pressedKey = typeof e.which == "number" ? e.which : e.keyCode;
    if (pressedKey === 9 || pressedKey === 13) {
      this.toggleOutput(e);
    }
  },

  /**
   * Activates output for current component and creates events listeners
   */
  toggleOutput: function(e) {
    this.onToggle(e);

    var viewServing = this.$el.attr("data-serving");

    // Unbind events from shared list
    this.$el.trigger("undelegateEvents");

    if (viewServing === this.settings.id) {
      var detach = this.detachComponent(e);
    } else {
      // If we're toggling a different component detach the previous one and attach the new one
      var attach = this.settings.id;
      var detach = viewServing;

      // Run onDetach for previous component

      // Run base and component's commands (render among others)
      this.attachComponent(viewServing);

      // Reactivate events for the view (this instance only)
      this.delegateEvents();
    }

    // From this point on we change names to make sure name-spaced events don't break
    detach = detach ? detach.replace(/[\[\]\.\(\)]/g, "-") : null;
    attach = attach ? attach.replace(/[\[\]\.\(\)]/g, "-") : null;

    // Detach event listeners from previous active component
    if (detach) {
      $(document).off("." + detach);
      this.onComponentDetach(detach);
    }

    // Attach new event listeners for current component
    if (attach) {
      // attachComponent event listener in component to create events for filtering etc.
      this.onComponentAttach(attach);

      // Listener for closeOnClickOutside
      if (this.settings.closeOnClickOutside) {
        $(document).on("mouseup." + attach, this.onClickOutEvent.bind(this));
      }

      if (!this.settings.isStandalone) {
        $(document).on("keyup." + attach, this.onClose.bind(this));
      }
    }
  },

  onClose: function(e) {
    if (
      typeof e !== "undefined" &&
      e.type === "keyup" &&
      (e.keyCode === 27 ||
        (e.keyCode === 9 && $(e.target).is(this.settings.trigger)))
    ) {
      this.onToggle(e);
      e.stopPropagation();
    }
  },

  /**
   * Overwritable method that runs before toggleOutput for component to customize styles etc.
   */
  onToggle: function(e) {
    if (typeof e !== "undefined") {
      let pressedKey = typeof e.which == "number" ? e.which : e.keyCode;

      if (pressedKey === 9) {
        e.preventDefault();
        return false;
      }
    }

    var trigger = this.settings.trigger;
    triggerWrapper = this.component.$(trigger).parent();

    if (
      triggerWrapper.hasClass("is-active") ||
      $(trigger).hasClass("is-active")
    ) {
      $(trigger).removeClass("is-active");
      triggerWrapper.removeClass("is-active");

      this.toggleViewState();
      $(document)
        .find(this.settings.trigger)
        .focus();
      return false;
    }
  },

  /**
   * Default function to render passed content into view
   */
  render: function(content) {
    content = content || this.template;

    if (typeof content !== "function" && content) {
      content = typeof content == "string" ? $(content) : content;
    }

    if ($(content).is("template")) {
      content = $(content).html();
    }

    this.$el.html(content);

    // override for cnm look a like sites
    if (
      !this.settings.hideCloseOutputTrigger &&
      typeof content !== "function" &&
      content
    ) {
      this.$el.prepend(this.closeTemplate(this.settings));
    }
  },

  /**
   * Overwritable method to attach output view to component
   */
  attachComponent: function(viewServing) {
    let triggerWrapper = this.component
      .$(this.component.triggerElement)
      .parent();
    let currentTriggers = $(
      '[data-output-view-trigger="' + this.settings.outputView + '"]'
    );

    // Add the visible class just if it doesn't have it already
    if (!this.$el.hasClass("is-visible")) {
      this.toggleViewState(true);
      $(document)
        .find(this.settings.outputView)
        .focus();
    }

    this.render();

    // Removes class from other trigger's and parent container
    currentTriggers.removeClass("is-active");
    currentTriggers.parent().removeClass("is-active");

    // Updates output view to identify which component it's serving
    this.$el.attr("data-serving", this.settings.id);

    // Adds class to trigger and parent
    this.component.triggerElement.addClass("is-active");
    triggerWrapper.addClass("is-active");
  },

  /**
   * Overwritable method to detach output view to component
   * IMPORTANT: It should always return the component ID
   */
  detachComponent: function() {
    this.toggleViewState();

    // Removes class to trigger's container
    this.component.triggerElement.removeClass("is-active");

    this.$el.attr("data-serving", "");

    return this.settings.id;
  },

  /**
   * Overwritable method to add custom events after detaching component to output view
   */
  onComponentDetach: function() {
    return false;
  },

  /**
   * Overwritable method to add custom events after attaching component to output view
   */
  onComponentAttach: function() {
    return false;
  },

  /**
   * Overwritable callback for click event to close the panel
   */
  onClickOutEvent: function(e) {
    // Close if click outside is:
    // Out of the outputView
    // Not clicking the trigger

    if (
      !$(e.target).closest('[data-serving="' + this.settings.id + '"]')
        .length &&
      !$(e.target).is(
        '[data-output-view-trigger="' + this.settings.outputView + '"]'
      )
    ) {
      this.toggleOutput(e);
    }
  },

  /**
   * @description Enable/Disable all input fields wrapped by this component when called from overwrite functions.
   * The output-view can be shared between different components and exist some cases, like the stepper, where is possible to have hidden inputs
   * alongside with the output-view component fields.
   */
  enableAll: function() {
    this.$el.find(":input").prop("disabled", false);
  },

  disableAll: function() {
    this.$el.find(":input").prop("disabled", true);
  },

  /**
   * @description Enable/Disable current outputToggle state and does proper timeout for animations
   */

  toggleViewState: function(status) {
    // we use a globalTimeout to stop all animations with the ToggleViewState
    if (window.currentViewAnimation) {
      clearTimeout(window.currentViewAnimation);
      window.currentViewAnimation = null;
    }

    if (status) {
      this.$el.removeClass("is-hidden");
      if (this.settings.showBodyOverlay) {
        $(document.body).addClass("overlay-in");
      }

      window.currentViewAnimation = setTimeout(() => {
        if (!this.$el.hasClass("is-visible")) {
          this.$el.addClass("is-visible");
          this.$el.parents("[data-output-wrapper]").addClass("is-visible");
        }
      }, 0);
    } else {
      if (this.settings.showBodyOverlay) {
        $(document.body).removeClass("overlay-in");
      }

      this.$el.removeClass("is-visible");
      this.$el.parents("[data-output-wrapper]").removeClass("is-visible");

      window.currentViewAnimation = setTimeout(() => {
        if (!this.$el.hasClass("is-visible")) {
          this.$el.addClass("is-hidden");
        }
      }, 400);
    }
  }
});
