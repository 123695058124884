/*
 This function appends components and modules into the library
 used by the jQuery components and module loader.
 */

/** Module or component won't load?
 *  If a certain package is not working, please make sure that
 *  "page-module" block has data-path="NAME_OF_PACKAGE" so it gets registered as well
 */

var requireAll = require("./requireAll");

var registerComponents = function(options) {
  var path = options.path;

  // Inform about the chunk being loaded
  console.info(
    "%c" + path.toUpperCase() + " Package loaded",
    "color: #CD7927; font-weight: bold;"
  );

  // Update Chunk State
  $.rvlx.chunks[path].isLoading = false;
  $.rvlx.chunks[path].isLoaded = true;

  if (options.components) {
    // Require Components
    options.components = requireAll(options.components);

    // Retrieve registered components or set empty object
    var components = $.fn.component.components || {};

    // Append components to path
    components[path] = _.extend(components[path] || {}, options.components);

    // Append components to the components library
    $.fn.component.components = components;

    // Initializing components on Queue
    _.each(
      $.rvlx.chunks[path].queue.components,
      function(component) {
        component.component();
      }.bind(this)
    );

    // Init autoload components
    $(document).loadComponents({ autoload: true, path: path });
  }

  if (options.modules) {
    // Require Modules
    options.modules = requireAll(options.modules);

    // Retrieve registered modules or set empty object
    var modules = $.fn.module.modules || {};

    // Append modules to path
    modules[path] = _.extend(modules[path] || {}, options.modules);

    // Append modules to the module library
    $.fn.module.modules = modules;

    // Initializing modules on Queue
    _.each(
      $.rvlx.chunks[path].queue.modules,
      function(component) {
        component.module();
      }.bind(this)
    );

    // Init autoload modules
    $(document).loadModules({ autoload: true, path: path });
  }

  return components;
};

module.exports = registerComponents;
