// This file contains all the polyfill required for IE11
// once IE11 is depreciated we can remove this file, and its include in ../app.js

// Polyfill for promises for IE11
require("es6-promise").polyfill();

// Polyfill for fetch for  IE11
require("whatwg-fetch");

// Polyfill for es6 .remove() for IE11
(function(arr) {
  arr.forEach(function(item) {
    if (item.hasOwnProperty("remove")) {
      return;
    }
    Object.defineProperty(item, "remove", {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return;
        }
        this.parentNode.removeChild(this);
      }
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
