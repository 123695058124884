module.exports = function(country) {
  switch (country) {
    case "ES":
      return /^(\d{5})$/;

    case "FR":
      return /^(\d{5})$/;

    case "IT":
      return /^(\d{5})$/;

    case "PT":
      return /^\d{4}-\d{3}$/;

    default:
      return "";
  }
};
