var $ = require("jquery");

var Reminder = Backbone.View.extend({
  initialize: function() {
    this.template = Handlebars.compile(
      this.$("[data-reminder-template]").html() || ""
    );

    this.modal = this.$("[data-reminder-modal]");

    $(document).on("click", "[data-reminder-edit]", this.renderEdit.bind(this));

    $(document).on(
      "click",
      "[data-reminder-detail]",
      this.renderReminder.bind(this)
    );

    $(document).on(
      "click",
      "[data-reminder-delete]",
      this.deleteReminder.bind(this)
    );
  },

  renderEdit: function(event) {
    var self = this;

    let Dialog = this.modal.component();

    // this will determine if theres dialog already open
    // if yes, it will look for the data-dialog-body and replace it

    let dialogContent = Dialog.currentModal
      ? Dialog.currentModal.find("[data-dialog-body]")
      : this.modal;

    dialogContent.html(
      this.template({
        isEdit: true,
        data: $(event.currentTarget).data("reminderEdit")
      })
    );

    // Check if current Dialog is missing to create a new instance of it
    if (!Dialog.currentModal) {
      Dialog.reflow().open();
    }

    Dialog.currentModal.on("submit", "[data-reminder-form]", function(event) {
      event.preventDefault();

      var formData = $(this).serializeJSON();

      $.ajax({
        type: "post",
        dataType: "json",
        cache: false,
        url: $(this).attr("action"),
        data: $(this).serialize(),

        beforeSend: function() {
          Backbone.Events.trigger("loadingSplashOn", event);
        },

        success: function(response) {
          if (response.success) {
            Dialog.close();
            Backbone.Events.trigger("loadingSplashOn", {
              title: response.messages.general.join("<br>")
            });
            document.location = document.location.href
              .replace("clear=all_channels", "")
              .replace("clear=channel", "");
          } else {
            var reminder = formData.reminder;
            reminder.date_mdY = reminder.date;
            reminder.time_hia = reminder.time;

            Dialog.currentModal.find("[data-dialog-body]").html(
              self.template({
                isEdit: true,
                data: reminder,
                response: response
              })
            );

            Dialog.reflow();

            Backbone.Events.trigger("loadingSplashOff");
          }
          return true;
        },

        error: function() {
          Backbone.Events.trigger("loadingSplashOff");
          return false;
        }
      });
    });
  },

  renderReminder: function(event) {
    this.modal.html(
      this.template({
        isDetail: true,
        reminder: $(event.currentTarget).data("reminderDetail")
      })
    );

    var Dialog = this.modal.component();

    Dialog.reflow().open();
  },

  deleteReminder: function(event) {
    var self = this;

    $.ajax({
      type: "get",
      dataType: "json",
      cache: false,
      url: $(event.currentTarget).data("reminderDelete"),

      beforeSend: function() {
        Backbone.Events.trigger("loadingSplashOn", event);
      },

      success: function(response) {
        if (response.success) {
          Backbone.Events.trigger("loadingSplashOn", {
            title: response.messages.general.join("<br>")
          });
          document.location = document.location.href
            .replace("clear=all_channels", "")
            .replace("clear=channel", "");
        } else {
          var messages = response.messages;
          messages.isMessages = true;

          $("[data-reminder-messages]").html(self.template(messages));

          Backbone.Events.trigger("loadingSplashOff");
        }
        return true;
      },

      error: function() {
        Backbone.Events.trigger("loadingSplashOff");
        return false;
      }
    });
  }
});

module.exports = Reminder;
