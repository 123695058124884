/**
 * Tree component
 * This tree component is a lightweight version of
 * our current tree structure
 * @todo live update
 */

require("jstree");

const treeContainerEl =
  '<div class="tree-container" data-tree-container></div>';
const inputContainerEl =
  '<div class="tree-inputs is-hidden" data-tree-inputs></div>';
const searchContainerEl = '<div class="input-has-icon icon-search"></div>';
const searchInputEl = '<input type="text" data-tree-search>';
const clearSearchEl =
  '<a href="javascript:void(0)" class="icon icon-close is-hidden" data-tree-clear-search></a>';

var Tree = Backbone.View.extend({
  events: {
    "click [data-tree-clear-search]": "clearSearch",
    "click [data-tree-select-all]": "selectAll",
    "click [data-tree-clear-selection]": "clearSelection"
  },

  initialize: function() {
    var self = this;

    this.settings = {
      typeInputNames: {}
    };

    if (this.$el.data("settings")) {
      this.settings = $.extend(
        true,
        this.settings,
        eval(this.$el.data("settings"))
      );
    }

    this.$el.append(treeContainerEl);

    this.treeSelectionsLength = this.$("[data-tree-selections]");
    this.tree = this.$("[data-tree-container]");

    this.tree.wrap("<div class='form-field form-field-row'></div>");

    var options = {
      core: {
        expand_selected_onload: true,
        strings: {
          "Loading ...": ""
        }
      },
      plugins: ["types"],
      types: {
        agency: {
          icon: "icon-org-unit",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.agency ||
              this.settings.typeInputNames.all
          }
        },
        site: {
          icon: "icon-website",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.site ||
              this.settings.typeInputNames.all
          }
        },
        cruise: {
          icon: "icon-cruise",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.cruise ||
              this.settings.typeInputNames.all
          }
        },
        river_cruise: {
          icon: "icon-river-cruise",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.river_cruise ||
              this.settings.typeInputNames.all
          }
        },
        managed_product: {
          icon: "icon-managed-product",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.managed_product ||
              this.settings.typeInputNames.all
          }
        },
        air: {
          icon: "icon-air",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.air ||
              this.settings.typeInputNames.all
          }
        },
        car: {
          icon: "icon-car",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.car ||
              this.settings.typeInputNames.all
          }
        },
        hotel: {
          icon: "icon-hotel",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.hotel ||
              this.settings.typeInputNames.all
          }
        },
        generic: {
          icon: "icon-generic",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.generic ||
              this.settings.typeInputNames.all
          }
        },
        insurance: {
          icon: "icon-insurance",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.insurance ||
              this.settings.typeInputNames.all
          }
        },
        activity: {
          icon: "icon-activity",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.activity ||
              this.settings.typeInputNames.all
          }
        },
        dynamic_package: {
          icon: "icon-dynamic-package",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.dynamic_package ||
              this.settings.typeInputNames.all
          }
        },
        guided_vacation: {
          icon: "icon-guided-vacation",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.guided_vacation ||
              this.settings.typeInputNames.all
          }
        },
        fee: {
          icon: "icon-fee",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.fee ||
              this.settings.typeInputNames.all
          }
        }
      }
    };

    if (this.settings.data) {
      options.core.data = this.settings.data;
    }

    if (this.settings.allowSearch) {
      this.formField = $('<div class="form-field"></div>');
      this.searchEl = $(searchContainerEl);
      this.searchInputEl = $(searchInputEl);
      this.clearSearchEl = $(clearSearchEl);

      this.searchEl.append(this.searchInputEl);
      this.searchEl.append(this.clearSearchEl);

      this.formField.append(this.searchEl);

      this.$el.prepend(this.formField);

      options.plugins.push("search");
      options.search = {
        show_only_matches: true,
        close_opened_onclear: true
      };
    }

    if (this.settings.includeInputs || this.settings.includeOnlyCheckbox) {
      this.inputsContainer = $(inputContainerEl);

      this.$el.append(this.inputsContainer);

      options.plugins.push("checkbox");
      options.checkbox = {
        whole_node: true,
        three_state: false,
        keep_selected_style: false
      };

      if (!this.settings.includeOnlyCheckbox && !this.settings.mergeInput) {
        this.tree.on("changed.jstree", function(e, data) {
          self.inputsContainer.empty();

          _.each(data.selected, function(id) {
            var el = $(self.$('[id="' + id + '"]')[0]);

            if (el.data("treeInputName")) {
              self.inputsContainer.append(
                '<input type="hidden" name="' +
                  el.data("treeInputName") +
                  '" value="' +
                  id +
                  '">'
              );
            }
          });
        });
      }

      if (this.settings.mergeInput) {
        this.tree.on("changed.jstree", function(e, data) {
          self.inputsContainer.empty();

          var selectedIds = _.map(data.selected).join();

          if (self.settings.treeInputName) {
            self.inputsContainer.append(
              '<input type="hidden" name="' +
                self.settings.treeInputName +
                '" value="' +
                selectedIds +
                '">'
            );
          }
        });
      }
    }

    if (this.settings.includeActions) {
      this.pluginActions(this.settings);

      options.plugins.push("actions");
      options.actions = {};
    }

    this.tree.jstree(options);

    var to = false;

    this.$("[data-tree-search]").keyup(function() {
      if (self.searchInputEl.val().length) {
        self.clearSearchEl.removeClass("is-hidden");
        self.searchEl.removeClass("icon-search");
      } else {
        self.clearSearchEl.addClass("is-hidden");
        self.searchEl.addClass("icon-search");
      }

      if (to) {
        clearTimeout(to);
      }
      to = setTimeout(function() {
        var v = self.searchInputEl.val();
        self.tree.jstree(true).search(v);
      }, 250);
    });

    // Tree is fully loaded and initialized
    this.tree.on("ready.jstree", () => {
      this.treeSelectionsLength.val(this.tree.jstree().get_selected().length);
    });
  },

  clearSearch: function() {
    this.searchInputEl.focus().val("");
    this.searchInputEl.trigger("keyup");
  },

  _toggleNodeState: function(tree, parentNode, fn) {
    let selectedNodeIds = [];

    const children = parentNode.children;
    if (children.length) {
      children.forEach(
        function(nodeId) {
          selectedNodeIds = selectedNodeIds.concat(
            this._toggleNodeState(tree, tree.get_node(nodeId), fn)
          );
        }.bind(this)
      );
    }

    fn(tree, parentNode);

    selectedNodeIds.push(parentNode.id);
    return selectedNodeIds;
  },
  _checkNode: function(tree, node) {
    const nodeDOM = tree.get_node(node, true);
    nodeDOM
      .children(".jstree-anchor")
      .attr("aria-selected", true)
      .addClass("jstree-clicked");

    const nodeObj = tree.get_node(node);
    nodeObj.state.selected = true;
  },
  _unCheckNode: function(tree, node) {
    const nodeDOM = tree.get_node(node, true);
    nodeDOM
      .children(".jstree-anchor")
      .attr("aria-selected", false)
      .removeClass("jstree-clicked");

    const nodeObj = tree.get_node(node);
    nodeObj.state.selected = false;
  },

  // this will update node state based on fn (check / uncheck)
  toggleSelection: function(event, fn, selection) {
    const tree = this.tree.jstree(true);
    const currentNode = tree.get_node($(event.currentTarget).data("node-id"));
    let selectedNodeIds = [];

    // update current node state
    selectedNodeIds = selectedNodeIds.concat(
      this._toggleNodeState(tree, currentNode, (tree, node) => fn(tree, node))
    );

    const children = currentNode.children;
    if (children.length) {
      children.forEach(
        function(nodeId) {
          selectedNodeIds = selectedNodeIds.concat(
            this._toggleNodeState(tree, tree.get_node(nodeId), (tree, node) =>
              fn(tree, node)
            )
          );
        }.bind(this)
      );
    }

    tree._data.core.selected = selection(tree, selectedNodeIds);
  },

  selectAll: function(event) {
    this.toggleSelection(
      event,
      (tree, node) => this._checkNode(tree, node),
      (tree, selectedNodeIds) => {
        let selectedNodes = tree._data.core.selected
          .concat(selectedNodeIds)
          .filter((nodeId, index, array) => {
            const node = tree.get_node(nodeId);

            return (
              array.indexOf(nodeId) === index &&
              !(node && node.state && node.state.disabled)
            );
          });

        this.tree.trigger("changed.jstree", {
          action: "select_all",
          selected: selectedNodes
        });

        return selectedNodes;
      }
    );
  },
  clearSelection: function(event) {
    this.toggleSelection(
      event,
      (tree, node) => this._unCheckNode(tree, node),
      (tree, selectedNodeIds) => {
        let selectedNodes = tree._data.core.selected.filter(
          nodeId => selectedNodeIds.indexOf(nodeId) === -1
        );

        this.tree.trigger("changed.jstree", {
          action: "clear_all",
          selected: selectedNodes
        });

        return selectedNodes;
      }
    );
  },

  // split keys into a multidimensional array (ex: message[applicability][org_units])
  _splitKeys: function(object) {
    const result = {};

    for (const key in object) {
      const parts = key.split(/[\[\]]/).filter(Boolean);
      let current = result;

      for (let i = 0; i < parts.length; i++) {
        if (i === parts.length - 1) {
          current[parts[i]] = object[key];
        } else {
          current[parts[i]] = current[parts[i]] || {};
          current = current[parts[i]];
        }
      }
    }

    return result;
  },

  getSelectedNodes: function() {
    const tree = this.tree.jstree();
    const selectedNodeIds = tree.get_selected();

    let inputsSelection = {};
    selectedNodeIds.forEach(
      function(nodeId) {
        const nodeType = tree.get_node(nodeId).type;
        const inputTypeName = this.settings.typeInputNames[nodeType];
        if (!inputsSelection[inputTypeName]) {
          inputsSelection[inputTypeName] = [];
        }

        inputsSelection[inputTypeName].push(nodeId);
      }.bind(this)
    );

    return inputsSelection;
  },

  // output a serialize version of selected nodes
  serialize: function() {
    return new URLSearchParams(this.getSelectedNodes()).toString();
  },

  toJSON: function() {
    const inputsSelection = this.getSelectedNodes();
    return this._splitKeys(
      Object.keys(inputsSelection).reduce((selection, inputType) => {
        // Using regex to remove square brackets at the end
        selection[inputType.replace(/\[\]$/, "")] = inputsSelection[
          inputType
        ].join(",");
        return selection;
      }, {})
    );
  },

  /**
   * redraw_node - Override the redraw_node method to add custom buttons to tree nodes.
   *
   * @param {HTMLElement} obj - The DOM element representing the node being redrawn.
   * @param {boolean} deep - Flag indicating whether to redraw child nodes.
   * @param {Function} callback - Callback function to be executed after the node is redrawn.
   * @param {boolean} force_draw - Flag indicating whether to force the redraw.
   * @returns {HTMLElement} - The modified DOM element representing the node.
   */

  pluginActions: function(settings) {
    $.jstree.plugins.actions = function(options, parent) {
      // Call the parent's redraw_node method to per
      this.redraw_node = function(obj, deep, callback, force_draw) {
        obj = parent.redraw_node.call(this, obj, deep, callback, force_draw);

        if (obj) {
          const nodeId = $(obj).attr("id");
          const node = this.get_node(nodeId);

          if (node.children.length) {
            const selectAll = `<button type="button" class="button is-anchor select-all" data-tree-select-all data-node-id="${
              node.id
            }">${settings.tokens.selectAll}</button>`;
            const clearAll = `<button type="button" class="button is-anchor clear-all" data-tree-clear-selection data-node-id="${
              node.id
            }">${settings.tokens.clearAll}</button>`;

            const ulElement = $(obj)
              .find("ul")
              .first();

            // If the node is opened, insert buttons before the <ul> element,
            // otherwise append them to the node
            if (node.state.opened) {
              ulElement.before(
                `<div class="jstree-actions-wrapper">${selectAll}${clearAll}</div>`
              );
            } else {
              $(obj).append(
                `<div class="jstree-actions-wrapper">${selectAll}${clearAll}</div>`
              );
            }
          }
        }

        return obj;
      };
    };
  }
});

module.exports = Tree;
