/**
 * Tree Component
 * Take an object and build it in tree fashion
 */

var TreeData = Backbone.Model.extend({
  defaults: {
    id: null,
    name: null,
    is_active: false,
    is_selected: false,
    type: {},
    data: {}
  }
});

var TreeDataCollection = Backbone.Collection.extend({
  model: TreeData
});

var Tree = Backbone.View.extend({
  events: {
    "click [data-children-action]": "childrenAction"
  },
  childrenAction: function(e) {
    let currentTarget = $(e.currentTarget);
    var node = currentTarget.closest("ul");
    var checkboxes = node.find("input");

    if (currentTarget.data("childrenAction") === "select") {
      $(checkboxes).prop("checked", true);
    } else {
      $(checkboxes).prop("checked", false);
    }
  },
  fetchTree: function() {
    fetch(this.settings.url, {
      credentials: "same-origin"
    })
      .then(response => {
        return response.text();
      })
      .then(data => {
        this.treeData.set(JSON.parse(data));

        this.render();
      })
      .catch(error => {
        console.error(error);
      });
  },
  render: function() {
    //Loads Templates
    this.output.html("").html(
      this.treeTemplate({
        data: this.treeData.get("data")
      })
    );
  },
  initialize: function() {
    if (this.$el.data("settings")) {
      this.settings = this.settings = $.extend(
        true,
        this.settings,
        this.$el.data("settings")
      );
    }

    this.output = this.$("[data-tree-output]").length
      ? this.$("[data-tree-output]")
      : false;

    this.treeTemplate = Handlebars.compile(
      this.$("[data-tree-template]").length
        ? this.$("[data-tree-template]").html()
        : ""
    );

    //Set Default Data
    this.treeData = new TreeData();

    this.fetchTree();
  }
});

module.exports = Tree;
