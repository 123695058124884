// Image checker
// Image checker will check any third party images to see if they exists.
// If images are 404, the images will be replaced with a fallback structure
// settings needs to be applied to main module or component that will be extending this functionality for example
//
//  data-settings='{
//     "imageCheck": {
//
//       Container which the image is getting inserted
//
//       "container"     : "[data-image-checker]",
//
//       Fallback class to handle 404 images
//       "fallbackImage" : "item-summary-image-na",
//
//       If there's a master icon declare it in the main object
//       "icon"          : "bed",
//     }

const imageChecker = Backbone.View.extend({
  /**
   *
   * @param el
   * @param settings
   */
  checkImageStatus: function(el, settings) {
    let imageContainer = settings ? settings.container : false;
    let iconClass = settings ? settings.icon : false;
    let fallbackImage = settings ? settings.fallbackImage : false;

    var loadCounter = 0;
    var errorCounter = 0;

    // check image availability
    // get an array with all hotel images available
    let imagesArray = el.$(imageContainer);

    imagesArray.each((index, element) => {
      // extract image url to start testing all images
      let currentImageUrl = element.style.backgroundImage
        .slice(5)
        .substring(0, element.style.backgroundImage.length - 7);

      let currentID = $(element).attr("data-image-id");

      //create image to check
      let currentImage = document.createElement("img");
      currentImage.setAttribute("class", "is-hidden");
      currentImage.setAttribute("id", element.dataset.imageId);
      currentImage.setAttribute("src", currentImageUrl);

      document.body.append(currentImage);

      currentImage.onload = () => {
        loadCounter = loadCounter + 1;
        // if all images are checked trigger the done event to let the gallery know to run
        if (
          settings.isGallery &&
          loadCounter + errorCounter === imagesArray.length
        ) {
          el.$el.trigger("imageChecker:done");
        }
      };

      // when error gets triggered
      // add image fallback
      currentImage.onerror = image => {
        errorCounter = errorCounter + 1;
        let currentImageError = el.$(
          "[data-image-id=" + image.currentTarget.id + "]"
        );
        // if the container has its own declared icon use that one instead
        iconClass = currentImageError.data("iconClass")
          ? currentImageError.data("iconClass")
          : iconClass;

        let errorClassName = fallbackImage + " icon-" + iconClass;

        currentImageError.removeAttr("style").addClass(errorClassName);

        // fallbackMethod: When the image has a image checker
        // array, this will go and check all images in the array
        // and display the first one with an OK status
        if (currentImageError.data("imageCheckerArray")) {
          // building options array to easy transition data
          let fallbackOptions = {
            el: currentImageError,
            errorClassName: errorClassName
          };

          //creating array
          let currentFallback = currentImageError
            .data("imageCheckerArray")
            .split(",");

          currentImageError.removeAttr("data-image-checker-array");

          //if the fallback has actual items, check for the fallback
          if (Object.keys(currentFallback).length) {
            this.fallbackCheck(currentFallback, fallbackOptions, 0);
          }
        }

        if (
          errorCounter === settings.realCounter &&
          settings.wrapperActionHide
        ) {
          el.$el
            .parent()
            .find("[data-image-unavailable]")
            .removeClass("is-hidden");

          //disable if there's a tab attach to the gallery
          if (el.$el.closest("[data-image-checker-tab-wrapper]").length) {
            el.$el
              .closest("[data-image-checker-tab-wrapper]")
              .find("[data-tabs-gallery]")
              .addClass("is-disabled");
          }

          el.remove();
        }

        if (currentImageError.closest("[data-image-checker-wrapper]").length) {
          currentImageError.closest("[data-image-checker-wrapper]").remove();
        }

        // if all images are checked trigger the done event to let the gallery know to run

        if (
          settings.isGallery &&
          loadCounter + errorCounter === imagesArray.length
        ) {
          el.$el.trigger("imageChecker:done");
        }
      };
    });
  },

  /**
   *
   * @param fallbackImages
   * @param options
   * same as the regular checker, this will go and look the fallbackImages array
   * and return a working url to display in the image container
   */
  fallbackCheck: function(fallbackImages, options, currentCheck) {
    let currentFallbackURL = "";

    //create image to check
    let currentImage = document.createElement("img");
    currentImage.setAttribute("class", "is-hidden");
    currentImage.setAttribute("src", fallbackImages[currentCheck]);

    document.body.append(currentImage);

    currentImage.onload = () => {
      if (!currentFallbackURL) {
        currentFallbackURL = fallbackImages[currentCheck];
        options.el
          .css(
            "background",
            "url('" + currentFallbackURL + "') center center no-repeat"
          )
          .removeClass(options.errorClassName);
        return currentFallbackURL;
      }
    };

    if (currentFallbackURL) {
      return false;
    }

    currentImage.onerror = () => {
      if (currentCheck < fallbackImages.length) {
        this.fallbackCheck(fallbackImages, options, ++currentCheck);
      }
    };
  },

  initialize: function(settings) {
    this.settings = {};

    this.settings = this.settings = $.extend(
      true,
      settings,
      this.settings,
      this.$el.data("settings")
    );

    this.checkImageStatus(this, this.settings.imageCheck);
  }
});

module.exports = imageChecker;
