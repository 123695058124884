var GoogleAnalytics = Backbone.View.extend({
  initialize: function(options) {
    this.settings = options;

    Backbone.Events.on("publishAnalyticsData", this.publishData.bind(this));

    if (this.settings.send_events) {
      $(document).on("click", "[data-analytics-id]", this.sendEvent.bind(this));
    }
  },

  publishData: function(data) {
    if (data.google_analytics) {
      if (data.google_analytics.page_data.dataAsDomObj) {
        window[data.google_analytics.page_data.dataAsDomObj] =
          data.google_analytics.page_data.data;
      } else {
        var sendName = google_analytics.account_name
          ? google_analytics.account_name + ".send"
          : "send";

        ga(sendName, data.google_analytics.page_data);
      }
    }
  },

  sendEvent: function(event) {
    var data = {
      eventAction: "click",
      eventCategory: this.settings.page,
      eventLabel: $(event.currentTarget).data("analyticsId")
    };

    if (this.settings.event_category) {
      data.eventCategory = this.settings.event_category;
    }

    if (this.settings.event_action) {
      data.eventAction = this.settings.event_action;
    }

    if (this.settings.event_label) {
      data.eventLabel = this.settings.event_label;
    }

    if (this.settings.event_value) {
      data.eventValue = this.settings.event_value;
    }

    var sendName = this.settings.account_name
      ? this.settings.account_name + ".send"
      : "send";

    ga(sendName, data);
  }
});

module.exports = GoogleAnalytics;
