var $ = require("jquery");

var PaymentMethodView = require("./_payment.payment-method");

var FCCView = PaymentMethodView.extend({
  processPaymentMethodTypeChange: function(data) {
    PaymentMethodView.prototype.processPaymentMethodTypeChange.call(this, data);

    if (
      data.paymentMethodType.get("type_label") !==
      this.paymentMethodType.get("type_label")
    ) {
      this.$("[data-payment-fcc-certificate-number]").attr("disabled", true);
    }
  },

  processSelection: function(event) {
    PaymentMethodView.prototype.processSelection.call(this, event);

    this.$("[data-payment-fcc-certificate-number]").attr("disabled", false);

    this.$el.removeClass("is-hidden");
  }
});

module.exports = FCCView;
