var $ = require("jquery");

var SliderView = Backbone.View.extend({
  events: {
    "click [data-slider-link]": "showContentSlider",
    "click [data-slider-back]": "showMainSlider"
  },
  initialize: function() {
    this.reflow();
  },
  reflow: function() {
    //Initializing...
    //Content element being displayed
    this.displayed = null;
    //Main slider element which contain the links with the data attribute
    //value that match the data attribute value of the content element
    // that is going to be displayed
    this.mainSlider = this.$("[data-slider-main]");
    //Content slider containing the content elements to be displayed
    this.contentSlider = this.$("[data-slider-content]");
    // List of links element that trigger the content to be displayed
    this.links = this.$("[data-slider-link]");
    //List of content elements that are going to be displayed
    this.content = this.$("[data-slider-link-content]");
    //If slider was placed inside dialog component, getting the top level of that dialog content
    this.dialogContent = this.contentSlider.closest("[data-dialog-content]");
    //List that contains {data-attribute-value: [link-element, content-element]}
    this.query = {};

    this.cache = {};

    //Load all components inside the slider
    if (this.$("[data-auto-load-on-open]")) {
      this.$el.loadComponents();
    }

    //Create query list
    for (var i = 0; i < this.links.length; i++) {
      //data-links-slider attribute value
      var key = $(this.links[i]).data("slider-link");

      var contentElement = _.filter(
        this.content,
        function(el) {
          return $(el).data("slider-link-content") == key;
        }.bind(this)
      );

      this.query[key] = {
        link: $(this.links[i]),
        content: $(contentElement),
        cache: null
      };
    }
  },
  openSlider: function(sliderElement) {
    this.showContentSlider(null, sliderElement.data("slider-link"));
  },
  showContentSlider: function(e, targetAttribute) {
    //Shows content that its data-attribute match the
    //one the clicked element has
    this.attribute = targetAttribute || $(e.target).data("slider-link");
    this.slide = this.query[this.attribute];

    var remoteURL = this.slide.content.data("sliderAsync");
    if (remoteURL) {
      // Set loading state.
      this.slide.content.addClass("is-loading");
      if (this.slide.cache) {
        this.onAsync(this.slide.cache);
      } else {
        $.ajax({
          type: "GET",
          dataType: "html",
          url: remoteURL,
          success: function(response) {
            this.slide.cache = response;
            this.onAsync(response);
          }.bind(this),
          error: function() {
            this.contentSlider.addClass("is-active");
            this.displayed = slide.content.addClass("is-displayed");
            this.dialogContent.scrollTop(0).addClass("subcontent-is-displayed");
            slide.content.loadComponents();
          }.bind(this)
        });
      }
    } else {
      this.contentSlider.addClass("is-active");
      this.displayed = this.slide.content.addClass("is-displayed");
      this.dialogContent.scrollTop(0).addClass("subcontent-is-displayed");
    }
    $(document).trigger("sliderLinkClicked", e);
    return this;
  },
  onAsync: function(response) {
    this.slide.cache = _.clone(response);

    // Populate and update loading state
    this.slide.content
      .html(this.slide.cache)
      .removeClass("is-loading")
      .loadComponents();

    this.contentSlider.addClass("is-active");
    this.displayed = this.slide.content.addClass("is-displayed");
    this.dialogContent.scrollTop(0).addClass("subcontent-is-displayed");
    this.onAsyncSuccess(response, this.$el);
  },
  onAsyncSuccess: function() {
    return this;
  },
  showMainSlider: function() {
    //Returns back to the main slider(links)
    this.contentSlider.removeClass("is-active");
    this.displayed.removeClass("is-displayed");
    this.dialogContent.removeClass("subcontent-is-displayed");
  }
});
module.exports = SliderView;
