var $ = require("./component.loader");

// Declare a sub tree module
// initialization inside jquery
$.fn.loadModules = function(args) {
  // Set Selector for loadModules
  var selector =
    args && args.path && args.path != "commons"
      ? "[data-module][data-path='" + args.path + "']"
      : "[data-module]";

  // If autoload flag, autoload only
  if (args && args.autoload) {
    selector = "[data-autoload]" + selector;
  }

  // Array to store all modules instances
  var modules = [];

  var allModules = $(this).find(selector);
  var nestedModules = $(this).find(selector + " [data-module]");
  var viableModules = [];

  // Only modules that are not nested within others are viable to be initialized
  _.each(
    allModules,
    function(module) {
      var isViable = true;
      _.each(
        nestedModules,
        function(nestedComponent) {
          if (nestedComponent === module) {
            isViable = false;
          }
        }.bind(this)
      );
      if (isViable) {
        viableModules.push(module);
      }
    }.bind(this)
  );

  // Use this log to test nested modules initialization
  // console.log(allModules.length, nestedModules.length, viableModules.length)

  // Initialize viable modules
  $(viableModules).each(function() {
    modules.push($(this).module());
  });

  // Return all instances of initialized modules
  return modules;
};

// Declare our Modules
// initialization inside jquery
$.fn.module = function() {
  // Retrieve module
  var modules = $.fn.module.modules || {};

  // Cache element's data
  var data = this.data();

  if (data && data.component) {
    return this;
  }

  // Check if element is a data module
  if (!data) {
    console.warn("Failed to initialize undefined module.");
    return false;
  }

  // Checks if the module has been previously initialized
  if (data.initialized) {
    return data.initialized;
  }

  // Set path
  var path = data["path"] || "commons";

  // Checks if chunk exists in our library
  if (!modules[path] && !_.has($.rvlx.chunks, path)) {
    console.warn("Package not found: " + path.toUpperCase());
    return false;
  }

  // If the chunk exists but is not ready, add to the queue
  if (_.has($.rvlx.chunks, path) && !modules[path]) {
    $.rvlx.chunks[path].queue.modules.push(this);
    return false;

    // But if is loaded, check if the component exists in its chunk
  } else if (
    $.rvlx.chunks[path].isLoaded &&
    (!modules[path][data.module] || this === undefined)
  ) {
    console.warn(
      "! Module not found: " + path.toUpperCase() + "/" + data.module
    );
    return false;
  }

  // If not initialized, create instance passing the invoker element
  var module = new modules[path][data.module]({ el: this });

  // Store instance in "initialized" data attr
  this.data("initialized", module);

  // console.log initialization
  console.log(
    "%c- " + path.toUpperCase() + " Module init: %c" + data.module,
    "color: #9975AB; font-weight: bold;",
    "color: #9975AB;"
  );

  // Return the instance
  return module;
};

// Module Getter
$.getModule = function(name, path) {
  var chunk = path || "commons";
  return $.fn.module.modules[chunk] && $.fn.module.modules[chunk][name]
    ? $.fn.module.modules[chunk][name]
    : false;
};

module.exports = $;
