// adding a unique identifier for session control,
// for some instances like google to control billing
const { v4: uuidv4 } = require("uuid");

/*
 *   List Component
 */
var WrapperTemplate = [
  "<div class='input-has-icon icon-search' data-input-group></div>"
].join("\n");

var ClearBtn = [
  '<a data-list-clear-selection class="list-close-icon"><span class="sr-only">Close</span></a>'
].join("\n");

var MainTemplate = [
  "{{#if noResults}}",
  "<p class='list-option'>" +
    REVELEX.settings.defaultMessages.noResultsFound +
    "</p>",
  "{{/if}}",
  "{{#if resultsNumber}}",
  "<p class='list-option-number'><b>{{resultsNumber}}</b> Results:</p>",
  "{{/if}}",
  "{{#each options}}",
  "{{> singleOption}}",
  "{{/each}}"
].join("\n");

var OptionItem = [
  "<a class='list-option' href='javascript:void(0)' data-list-option='{{uid}}'>",
  "{{{label}}}",
  "</a>"
].join("\n");

// Responsible for storing the current results and exporting the format for the handlebars template.
var Results = Backbone.Model.extend({
  initialize: function(m, options) {
    this.settings = options.settings;

    this.set("selection", new Backbone.Model(this.settings.selection || {}));

    if (!this.get("matches")) {
      this.resetMatches();
    }
  },
  getSelection: function() {
    // Returns current selection (Model) or empty Model
    var selection = this.get("selection");

    return selection.toJSON();
  },
  resetSelection: function() {
    // Flushes the Selection Model
    var selection = this.get("selection");
    selection.clear();
    return this;
  },
  resetMatches: function() {
    // Flushes the Matches Array
    return this.set("total_results", null).set("matches", []);
  },
  export: function() {
    // This function outputs the result structure expected by the Template
    var output = {},
      results = this.get("matches"),
      selection = this.getSelection();

    // Results Count is an optional Setting and doesn't show up when there is a selection
    if (this.settings.resultsCount && _.isEmpty(selection)) {
      output.noResults = results.length == 0 ? true : false;
      output.resultsNumber = results.length;
      output.noResultsMessage =
        this.settings.noResultsMsg != null
          ? this.settings.noResultsMsg
          : REVELEX.settings.defaultMessages.noResultsFound;
    }

    // Total Results from Backend for async requests
    if (this.settings.async && this.get("total_results")) {
      output.resultsNumber = this.get("total_results");
    }

    // If current selection, pass it into handlebars template
    if (selection) {
      output.selection = selection;
    }

    output.messages = this.get("messages");

    output.options = results;
    return output;
  }
});

// Responsible for populating label in case of not provided and set global unique ids
var Option = Backbone.Model.extend({
  defaults: {
    metaData: {}
  },
  initialize: function() {
    // If label not provided, populate with value
    if (!this.get("label")) {
      this.set("label", this.get("value"));
    }

    // Create a unique id
    this.set("uid", _.uniqueId("list-option-"));
  }
});

// Responsible to search for both async/non-async strategies and update Results with actual matches from whole list.
var Options = Backbone.Collection.extend({
  model: Option,
  initialize: function(m, options) {
    this.settings = options.settings;
    this.results = options.results;
    return this;
  },

  hasLabelMatch: function(query, model) {
    var pattern = new RegExp(query, "gi");
    var labels = this.settings.keyLabel.split(",");

    for (var x = 0; x < labels.length; x++) {
      if (model[labels[x]].search(pattern) !== -1) {
        return true;
      }
    }

    return false;
  },

  search: function(query) {
    var results = [];
    var pattern = new RegExp(query, "gi");
    var list = this.toJSON();

    _.each(
      list,
      function(model) {
        if (
          this.hasLabelMatch(query, model) ||
          (typeof model["meta"] == "string" &&
            model["meta"].search(pattern) != -1)
        ) {
          // Bold matched characters optional feature
          if (this.settings.highlightMatch) {
            var labels = this.settings.keyLabel.split(",");

            for (var x = 0; x < labels.length; x++) {
              model[labels[x] + "_html"] = model[labels[x]]
                .toString()
                .replace(pattern, "<b>$&</b>");
            }
          }

          results.push(model);
        }
      }.bind(this)
    );

    this.results.set("matches", results);
    return this;
  },
  syncResults: function() {
    // Pass all options to results collection
    this.results.resetMatches().set("matches", this.toJSON());

    return this;
  }
});

var List = Backbone.View.extend({
  events: {
    "click [data-list-option]": "select",
    "click [data-list-clear-selection]": "clearSelection",
    "change [data-list-hidden-input]": "setSelectionState",
    "keydown [data-list-input],[data-list-option]": "handleFocus",
    "click [data-list-input]": "processInput",
    "keyup [data-list-input]": "processInput",
    "focusin [data-list-input]": "handleClassState"
  },
  initialize: function(e) {
    this.reflow();
  },
  select: function(e, additionalCalls) {
    // Method can be triggered via click/press/external api
    var query = {};

    // If method is triggered by a dom event, we find the unique id via data attributes
    if (typeof e == "object" && e.target) {
      var $target = $(e.currentTarget);
      query["uid"] = $target.data("list-option");
    } else if (typeof e === "string") {
      // if string is passed in, we assume it is the key value
      query[this.settings.keyValue] = e;
    } else {
      // if object is passed in, we assume it contains the key value
      query[this.settings.keyValue] = e[this.settings.keyValue];
    }

    // For async lists, new option is created for selection. For others, we run the query against the list
    this.selection;

    if (typeof e !== "string") {
      this.selection = this.list.findWhere(query);
    }

    if (!this.selection) {
      this.selection = new Option(e);
    }

    if (this.selection && !additionalCalls) {
      this.setSelectedOption(this.selection);
    }

    this.hideResults();

    return this;
  },
  setSelectedOption: function(selection) {
    var displayLabel = "";

    if (this.displayLabelTemplate && !_.isEmpty(selection)) {
      displayLabel = this.displayLabelTemplate(selection.toJSON());
    } else {
      displayLabel = selection.get(this.settings.keyLabel);
    }

    if (displayLabel) {
      this.previousLabelQuery = displayLabel.replaceAll("&amp;", "&");
      this.$("[data-list-input]")
        .val(this.previousLabelQuery)
        .attr("aria-activedescendant", selection.toJSON().uid);
    } else {
      this.previousLabelQuery = "";
      this.$("[data-list-input]")
        .removeAttr("aria-activedescendant")
        .val();
    }

    if (selection.get(this.settings.keyValue)) {
      this.$("[data-list-hidden-input]").val(
        selection.get(this.settings.keyValue)
      );
    } else {
      this.$("[data-list-hidden-input]").val();
    }

    this.results.set({
      selection: selection.clone(),
      matches: [],
      total_results: null
    });

    //If a list of other elements is passed on [data-list-populate]
    //we tell it to also populate the results on the array of items we passed
    if (this.settings.listPopulate && this.settings.listPopulate.length) {
      this.populateOtherElements();
    }

    if (this.results.get("matches").length) {
      this.showResults();
    }

    this.$("[data-list-hidden-input]").trigger("change");
  },
  populateOtherElements: function() {
    _.each(
      this.settings.listPopulate,
      function(item) {
        //Get element key
        var element = Object.keys(item)[0],
          valueKey = item[element],
          value = this.results.attributes.selection.attributes[valueKey];

        //Change value or render depending if it's input
        element = $(element);
        if (element[0].tagName === "INPUT") {
          element.val(value);
        } else {
          element.html(value || " ");
        }

        //Log event for reference
        console.warn(
          "Element " +
            Object.keys(item)[0] +
            " was populated with selection made on: ",
          this.$el
        );
      }.bind(this)
    );
  },
  setSelectionState: function() {
    // This class defines the conditional display of the clear button.
    if (this.$("[data-list-hidden-input]").val()) {
      this.$el.addClass("has-selection");
      this.$el.removeClass("has-results").attr("aria-expanded", false);
    } else {
      this.$el.removeClass("has-selection");
    }
  },
  clearSelection: function() {
    this.$("[data-list-input]")
      .removeAttr("aria-activedescendant")
      .focus()
      .val("");

    // triggering change to run validation if needed
    this.$("[data-list-hidden-input]")
      .val("")
      .trigger("change");

    this.$("[data-list-results]")
      .empty()
      .addClass("is-hidden");

    this.$el.removeClass("has-results").attr("aria-expanded", false);

    this.results.resetSelection();

    if (!_.isEmpty(this.results.getSelection())) {
      //If we populate other elements clear them too
      if (this.settings.listPopulate && this.settings.listPopulate.length) {
        this.populateOtherElements();
      }

      if (!this.settings.async) {
        this.list.syncResults();
      }
    }

    this.setSelectionState();

    return this;
  },
  processInput: function(e) {
    let currentInput = $(e?.currentTarget);
    let currentHiddenInput = this.$("[data-list-hidden-input]");

    if (currentInput.length && !currentInput.val().length) {
      this.clearSelection();
    }

    if (e?.type === "click") {
      currentInput.select();
    }

    clearTimeout(this.inputTimeout);

    this.inputTimeout = setTimeout(() => {
      let query =
        this.previousLabelQuery === currentInput?.val() &&
        currentHiddenInput.length &&
        currentHiddenInput.val().length
          ? currentHiddenInput
          : this.$("[data-list-input]");

      query = query
        .val()
        .trim()
        .toLowerCase();

      // checking the size of the query and removing any "empty" words
      let queryLength = this.settings.minWord
        ? query
            .split(" ")
            .map(el => el.trim())
            .filter(el => el !== "").length
        : query.length;

      let minimumType = this.settings.minWord
        ? this.settings.minWord
        : this.settings.minCount;

      if (queryLength < minimumType) {
        this.$el.removeClass("has-results").attr("aria-expanded", false);
        return false;
      }

      if (this.settings.async && this.previousQuery !== query) {
        this.results.resetMatches();
      }

      if (_.isEmpty(query) && !this.settings.async) {
        // If [data-list-input] is empty and the list type is not async
        this.list.syncResults();
      } else if (!this.settings.async) {
        // if It's not async and there is a query
        this.list.search(query);
      } else if (this.settings.async && queryLength >= minimumType) {
        // If it's async and the query's length is over the minimum setting

        if (this.previousQuery !== query) {
          this.previousQuery = query.toLowerCase();
        }

        // Set loading State
        this.$el.addClass("is-loading");

        // Hide results on loading state
        this.hideResults();

        // Trigger Search
        this.list.search(query);
      } else if (queryLength === 0) {
        // Hide results on loading state
        this.hideResults();
      }

      //If the custom input needs to be accepted as well
      if (this.settings.listCustomInput) {
        this.$("[data-list-hidden-input]").val(query);
      }

      return this;
    }, 300);
  },
  handleClassState: function() {
    this.$el.addClass("is-active");
  },

  outsideClick: function(e) {
    // If click wasn't inside view and state is open and its not a trigger button, close
    if (!this.$el.is(e.target) && !this.$(e.target).length) {
      this.$el.removeClass("is-active").attr("aria-expanded", false);
      $(document).off("click.listClose." + this.cid);

      if (
        this.results.get("matches").length > 0 &&
        this.$("[data-list-input]").val().length > 2 &&
        !this.settings.listIgnoreAutoSelect
      ) {
        this.$("[data-list-option]")
          .first()
          .trigger("click");
      }
    }
  },

  handleFocus: function(e) {
    var $target = $(e.currentTarget),
      pressedKey = e.keyCode,
      next;

    // Only prevent default if down or up arrows are pressed to avoid scrolling.
    if (pressedKey === 38 || pressedKey === 40 || pressedKey === 13) {
      e.preventDefault();
      e.stopPropagation();
    }

    // If arrow UP was pressed
    if (pressedKey === 38) {
      next = $target.prev("[data-list-option]");
      // Check if the focused element is the input
      if (next.length && $target.is("[data-list-option]")) {
        next.focus();
      } else {
        this.$("[data-list-input]").focus();
      }
    } else if (pressedKey === 40) {
      // If it's a DOWN arrow
      if ($target.is("[data-list-input]")) {
        this.$("[data-list-option]:first").focus();
      } else if (
        $target.is("[data-list-option]") &&
        $target.next().is("[data-list-option]")
      ) {
        $target.next().focus();
      }
    } else if (pressedKey === 13) {
      // If pressed ENTER key
      if ($target.is("[data-list-option]")) {
        this.select(e);
      }
    } else if (pressedKey === 8 && $(e.currentTarget).val().length <= 3) {
      // If backspace key entered clear Selection

      this.clearSelection();
    }

    return this;
  },
  render: function() {
    if (this.settings.async) {
      this.$el.removeClass("is-loading");
    }

    // as the model gets changed when data is available on the template,
    // the initial render needs to stop
    if (this.settings.listStopRender) {
      this.settings.listStopRender = false;
      return false;
    }

    if (this.results.get("matches").length > 0 || this.settings.noResultsMsg) {
      this.$el.addClass("has-results").attr("aria-expanded", true);
    }

    var output = this.results.export();

    this.$("[data-list-results]").html(
      '<div class="list-results-wrapper" role="listbox">' +
        this.template(output) +
        "</div>"
    );

    if (this.results.get("matches").length || output.noResults) {
      this.showResults();
    }

    return this;
  },
  hideResults: function() {
    this.$("[data-list-results]").addClass("is-hidden");
    return this;
  },
  showResults: function() {
    this.$el.addClass("has-results").attr("aria-expanded", true);
    this.$("[data-list-results]").removeClass("is-hidden");
    this.handleClassState();
    $(document).on("click.listClose." + this.cid, this.outsideClick.bind(this));
    return this;
  },
  setSettings: function() {
    var locals = this.$el.data();

    this.settings = {
      source: "dom", // data || async || dom
      highlightMatch: locals["listHighlightMatch"] === undefined ? false : true,
      async: false,
      resultsCount: locals["listResultsCount"] === undefined ? false : true,
      minCount: locals["listMinCount"] || 2,
      minWord: locals["listMinWord"] || 0,
      minDelay: locals["listMinDelay"] || 1500,
      keyValue: locals["listValue"] || "value",
      keyLabel: locals["listLabel"] || "label",
      queryTerm: locals["listQueryTerm"] || "terms",
      keyArray: locals["listArray"] || null,
      cache: locals["listCache"] === undefined ? true : locals["listCache"],
      selection: locals["listSelection"] || null,
      displayLabel: locals["listDisplayLabel"] || null,
      noResultsMsg: locals["noResultsMessage"] || null,
      unsuccessfulResponseMsg: locals["unsuccessfulResponseMessage"] || null,
      listPopulate: locals["listPopulate"] || null,
      listCustomInput: locals["listCustomInput"] || null,
      listIgnoreAutoSelect: locals["listIgnoreAutoSelect"] || false
    };

    if (typeof locals["list"] == "object") {
      this.settings.source = "data";

      // this is done to stop initial render of an object
      this.settings.listStopRender = true;
    } else if (typeof locals["listAsync"] == "string") {
      this.settings.source = "async";
      this.settings.url = locals["listAsync"];
      this.settings.async = true; // To be used as a flag.
      isAsync = this.settings.async;
    } else {
      this.settings.source = "dom";
    }

    return this;
  },
  populate: function() {
    // Pass in selection flag and settings to Results Model.
    this.results = new Results(
      {},
      {
        settings: this.settings
      }
    );

    // Render on Results Model change.
    this.listenTo(this.results, "change:matches", this.render.bind(this));
    this.listenTo(
      this.results,
      "failure:success-response",
      this.responseFailure.bind(this)
    );

    // Strategy for source types
    switch (this.settings.source) {
      case "data":
        this.list = new Options(this.$el.data("list"), {
          settings: this.settings,
          results: this.results
        }).syncResults();

        break;
      case "async":
        this.settings.currentSession = uuidv4();
        this.list = new List.AsyncOptions([], {
          settings: this.settings,
          results: this.results
        });

        this.results.list = this.list;

        break;
      case "dom":
        var options = [];
        this.$("[data-list-object]").each(
          function(i, el) {
            options.push($(el).data("listObject"));
          }.bind(this)
        );

        this.list = new Options(options, {
          settings: this.settings,
          results: this.results
        }).syncResults();

        break;
    }
    return this;
  },
  prepareTemplates: function() {
    var externalTemplate = this.$el.data("listExternalTemplate") || false,
      singleOptionTemplate,
      mainListTemplate,
      labelTemplate;

    if (externalTemplate) {
      singleOptionTemplate = $(
        '[data-list-external-template-single-option="' + externalTemplate + '"]'
      );
      mainListTemplate = $(
        '[data-list-external-template-main="' + externalTemplate + '"]'
      );
      labelTemplate = $(
        '[data-list-external-template-label="' + externalTemplate + '"]'
      );
      this.settings.displayLabel = labelTemplate.length
        ? labelTemplate.html()
        : labelTemplate;
    } else {
      // Check dom for single option or main templates, and assemble them with partial wrap in one bundle
      // template.
      singleOptionTemplate = this.$('[data-list-template="single-option"]');
      mainListTemplate = this.$('[data-list-template="main"]');
    }

    var optionPartial = singleOptionTemplate.length
      ? singleOptionTemplate.html()
      : OptionItem;

    optionPartial =
      '{{#*inline "singleOption"}}' + optionPartial + "{{/inline}}";

    var mainTemplate = mainListTemplate.length
      ? mainListTemplate.html()
      : MainTemplate;

    var templateBundle = optionPartial + mainTemplate;

    // Compile Bundle Template
    this.template = Handlebars.compile(templateBundle);

    // Compile Display Label
    if (this.settings.displayLabel) {
      this.displayLabelTemplate = Handlebars.compile(
        this.settings.displayLabel
      );
    }
  },
  reflow: function() {
    // Parse Settings
    this.setSettings();

    // Parse and compile templates
    this.prepareTemplates();

    this.populate();

    let currentInput = this.$("[data-list-input]");
    let inputWrapper = currentInput.parent("[data-input-group]");

    inputWrapper.length
      ? currentInput.after(ClearBtn)
      : currentInput.wrap(WrapperTemplate).after(ClearBtn);

    this.inputTimeout = false;
    this.previousQuery = "";
    this.previousLabelQuery = "";

    if (this.settings.selection) {
      this.select(this.settings.selection);
    }

    return this;
  },
  getSelection: function() {
    return this.results.getSelection();
  },

  /**
   * Async success=false
   */
  responseFailure: function() {
    //Remove loading state
    if (this.settings.async) {
      this.$el.removeClass("is-loading");
    }

    //Variables needed to compile the template
    var output = {};
    output.noResults = true;
    output.notSuccess = true;
    output.unsuccessfulResponseMsg =
      this.settings.unsuccessfulResponseMsg != null
        ? this.settings.unsuccessfulResponseMsg
        : REVELEX.settings.defaultMessages.noResultsFound;
    output.messages = this.results.messages ? this.results.messages : false;

    //Template
    this.$("[data-list-results]").html(
      '<div class="list-results-wrapper" role="listbox">' +
        this.template(output) +
        "</div>"
    );

    //Hide Results div to show only the unsuccessfulResponseMsg,
    //Note: Add to <div class="manage-customer-search-results-summary"> the data attribute "data-search-results-summary"
    if (this.$("[data-search-results-summary]")) {
      this.$("[data-search-results-summary]").addClass("is-hidden");
    }

    if (this.settings.listCustomInput && this.settings.async) {
      //If the input field has custom input attribute and has async call, the field should accept what is being typed
      // when there is no results found. No need to show error message to avoid confusion.
      this.$el.removeClass("has-results").attr("aria-expanded", false);
    } else {
      //Show message
      return this.showResults();
    }
  },

  reset: function() {
    this.clearSelection();
  },

  setDuplicateData: function(data) {
    this.setSelectedOption(data);
  },

  getDuplicateData: function() {
    return this.selection;
  }
});

// Extension from Options responsible for making the Async Request for async list
// moving AsyncOptions to be part of the list object as is needed for the geolocation module
List.AsyncOptions = Options.extend({
  search: function(query) {
    this.query = query;

    if (!this.cache) {
      this.cache = {};
    }

    if (this.delay) {
      clearTimeout(this.delay);
    }

    if (_.has(this.cache, query.toLowerCase())) {
      this.delay = null;
      this.parse(this.cache[query.toLowerCase()]);
    } else {
      this.delay = setTimeout(this.fetch.bind(this), this.settings.minDelay);
    }

    return this;
  },

  parse: function(response) {
    var results = [],
      pattern = new RegExp(this.query, "gi");

    // To be safe
    if (typeof response == "string") {
      response = $.parseJSON(response);
    }

    // When retrieving external api and don't follow the same data structure as the expected ones
    // we will force the data to build as expected
    if (response[this.settings.keyArray]) {
      response.data = response.data || [];
      response.success = response.success || true;
      response.data[this.settings.keyArray] =
        response.data[this.settings.keyArray] ||
        response[this.settings.keyArray];
    }

    // Update Options Collection
    var data = [];

    // Use key array and convert to array if object is provided.
    if (
      this.settings.keyArray &&
      _.isObject(response.data[this.settings.keyArray])
    ) {
      data = _.values(response.data[this.settings.keyArray]);
    } else if (
      this.settings.keyArray &&
      _.isArray(response.data[this.settings.keyArray])
    ) {
      data = response.data[this.settings.keyArray];
    } else {
      if (Object.keys(response.data).length) {
        data = response.data;
      }
    }

    if (response.success && data) {
      this.reset(data);

      results = this.toJSON();

      // If Highlight Setting, Highlight
      if (this.settings.highlightMatch) {
        _.each(
          results,
          function(option, i) {
            var labels = this.settings.keyLabel.split(",");

            for (var x = 0; x < labels.length; x++) {
              if (option[labels[x]]) {
                option[labels[x] + "_html"] = option[labels[x]]
                  .toString()
                  .replace(pattern, "<b>$&</b>");
              }
            }
          }.bind(this)
        );
      }

      // If total results provided, send to Results Model
      if (response.data["total_results"]) {
        this.results.set("total_results", response.data["total_results"]);
      }

      this.results.set("messages", response.messages);

      // Update Results Matches
      this.results
        .set("matches", results, { silent: true })
        .trigger("change:matches");
    } else {
      console.warn("Success: false. On list endpoint.");
      //Even the response is false, there is might be messages explains why the response was false
      if (response.messages.fault.length > 0) {
        this.results.messages = response.messages;
      }

      this.results.trigger("failure:success-response");
    }
  },
  fetch: function() {
    // Set empty object to be passed into the request
    let data = {},
      currentQuery = this.query;

    // Use the query term to store the query
    data[this.settings.queryTerm] = this.query;

    //adding a session token
    data["sessiontoken"] = this.settings.currentSession;

    // Async Request passing data and setting success callback
    $.ajax({
      credentials: "same-origin",
      crossDomain: true,
      method: "get",
      url: this.settings.url,
      data: data,
      headers: {}
    }).done(
      function(response) {
        // Make sure that the current query is still the same used for the async request
        if (currentQuery === this.query) {
          this.parse(response);
        }

        // Add query to the cached response
        response.query = this.query;

        if (this.settings.cache) {
          // Store response in cache obj
          this.cache[currentQuery] = response;
        }
      }.bind(this)
    );
  }
});

module.exports = List;
