var $ = require("jquery");

/**
 * Runs after initialize on every component
 * Removes exposed data-settings / data-component-settings object from the DOM (these objects should be loaded on initialize)
 * Adds functionality to print component information on the console on DEV environment or #!nakedLogs on rightClick
 */
Backbone.View.prototype.postInit = function() {
  const data = this.$el.data();
  const component = data.component;
  const settings = data.settings || data[component + "Settings"];

  // Clean up markup by removing settings objects
  if (settings) {
    this.$el.removeAttr("data-settings data-" + component + "-settings");
  }

  // In development or when passing #!nakedLogs allow developers to print component data in console
  if (
    ENV == "dev" ||
    window.location.hash.split("#").indexOf("!nakedLogs") >= 0
  ) {
    this.$el.on("contextmenu", e => {
      e.stopPropagation();
      console.log({
        Component: component,
        settings: settings,
        componentData: this.$el.component()
      });
    });
  }
};

/**
 * Removes component from the omegalog object
 * Meant to be extended for additional functionality (remove bindings, unload children, etc.)
 */
Backbone.View.prototype.unload = function(e) {
  console.log(
    "%c [X] " + e + ": " + this.cid + "%c - Component destroyed",
    "color: #E474C1; font-weight: bold; font-style: italic;",
    "color: #E474C1; font-style: italic;"
  );
  return false;
};

/**
 * Meant to be extended by each component implementation for reflow/reinitializing.
 */
Backbone.View.prototype.reset = function() {
  var missingFunction = this.$el[0].dataset.component;
  console.log(
    `The ${missingFunction} component does not have a 'reset' function.`
  );
  return false;
};

module.exports = Backbone;
