//This component controls the system advisories and messages.

//Main View Container
var html2canvas = require("html2canvas");

var mainView = Backbone.View.extend({
  events: {
    "click [data-expander]": "toggleTextBox",
    "click [data-advisory-copy]": "copyAdvisory",
    "click [data-take-screenshot]": "takeScreenshot"
  },
  initialize: function() {
    this.settings = {
      isExpanded: false
    };

    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
    }

    this.textWrapper = this.$("[data-text-wrapper]");
    this.expander = this.$("[data-expander]");
    this.expanded = this.settings.isExpanded;

    this.reflow();
  },
  toggleTextBox: function() {
    // check if expanded
    let expanded = this.$el.hasClass("is-expanded") ? true : false;

    switch (expanded) {
      //if expanded remove is-expanded class
      case true:
        this.$el.removeClass("is-expanded");
        this.textWrapper.removeClass("is-expanded");
        break;
      // if not expanded add is-expanded class
      default:
        this.$el.addClass("is-expanded");
        this.textWrapper.addClass("is-expanded");
        break;
    }
  },
  copyAdvisory: function(e) {
    const message = this.$("[data-text-wrapper]")
      .text()
      .replace(/\s\s+/g, "\n")
      .trim();
    const button = $(e.currentTarget);

    button.removeClass("fade-in-message");

    // Copy the text inside the text field
    navigator.clipboard.writeText(message).then(x => {
      button.addClass("fade-in-message");
    });
  },
  reflow: function() {
    //Gets max height from element css. not having it fixed makes this component more customisable
    var maxContainerHeight = parseFloat(this.textWrapper.outerHeight());
    this.screenshot = this.$el.data("take-screenshot") || false;

    // Gets height of the textBox
    var textHeight = this.$("[data-text-wrapper]").prop("scrollHeight");

    // If the height of the text max is greater then the max height allowed, then show the view more button
    if (textHeight > maxContainerHeight) {
      this.$el.addClass("is-expandable");
      // used to determine if the message is expanded by default or not
      this.expanded ? this.$el.addClass("is-expanded") : false;
      this.expander.removeClass("is-hidden");
    } else {
      this.textWrapper.css("max-height", "none");
    }
  },

  /**
   * Captures a screenshot of the specified area and triggers a download of the screenshot image.
   *
   */
  takeScreenshot: function() {
    const captureArea = document.querySelector(".app-container");

    // Ensure all fonts are loaded before capturing the screenshot
    document.fonts.ready.then(() => {
      html2canvas(captureArea, {
        windowWidth: captureArea.scrollWidth,
        windowHeight: captureArea.scrollHeight,
        scale: 2 // Increase scale for better quality
      })
        .then(canvas => {
          // set image url and download
          const img = canvas.toDataURL("image/png");
          const link = document.createElement("a");
          link.href = img;
          const formattedDateTime = this.getFormattedDateTime();
          link.download = `screenshot-${formattedDateTime}.png`;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => {
          console.error("Error capturing screenshot:", error);
        });
    });
  },

  /**
   * Generates a formatted date-time string for use in screenshot filenames.
   * The format of the returned string is: "DD-MM-YYYY-HH-MM-SS".
   *
   * @returns {string} A formatted date-time string.
   */
  getFormattedDateTime: function() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const date = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${date}/${month}/${year}-${hours}:${minutes}:${seconds}`;
  }
});

module.exports = mainView;
