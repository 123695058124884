var $ = require("jquery");
var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

/**
 * In this file we are extending the backbone view to add custom methods
 *  - postInit method: Fires after initialize
 *  - reset: It's meant to be overwritten with custom logic of each component
 *  - unload: It's meant to be overwritten with custom logic of each component.
 *    By default it removes the component from the component log summary.
 */
require("./Backbone.extensions.js");

// Declare a sub tree component
// initialization inside jquery
$.fn.loadComponents = function(args) {
  // Set Selector for loadComponents
  var selector =
    args && args.path && args.path != "commons"
      ? "[data-component][data-path='" + args.path + "']"
      : "[data-component]:not([data-path])";

  // If autoload flag, autoload only
  if (args && args.autoload) {
    selector = "[data-autoload]" + selector;
  }

  // Array to store all components instances
  var components = [];

  var allComponents = $(this).find(selector);
  var nestedComponents = $(this).find(selector + " [data-component]");
  var viableComponents = [];

  // Only components that are not nested within others are viable to be initialized
  _.each(
    allComponents,
    function(component) {
      var isViable = true;
      _.each(
        nestedComponents,
        function(nestedComponent) {
          if (nestedComponent === component) {
            isViable = false;
          }
        }.bind(this)
      );
      if (isViable) {
        viableComponents.push(component);
      }
    }.bind(this)
  );

  // Use this log to test nested components initialization
  // console.log(allComponents.length, nestedComponents.length, viableComponents.length)

  // Initialize viable components and assign args
  $(viableComponents).each(function() {
    components.push($(this).component(args));
  });

  // Return all instances of initialized components
  return components;
};

// Declare our component
// initialization inside jquery
$.fn.component = function(options) {
  // Retrieve components
  var components = $.fn.component.components || {};

  // Cache element's data
  var data = this.data();

  // Check if element is a data component
  if (!data) {
    console.warn("Failed to initialize undefined component.");
    return false;
  }

  // Set path
  var path = data["path"] || "commons";

  // Checks if the module has been previously initialized
  if (data.initialized) {
    return data.initialized;
  }

  // Checks if chunk exists in our library
  if (!components[path] && !_.has($.rvlx.chunks, path)) {
    console.warn("Package not found: " + path.toUpperCase());
    return false;
  }

  // If the chunk exists but is not ready, add to the queue
  if (_.has($.rvlx.chunks, path) && !components[path]) {
    $.rvlx.chunks[path].queue.components.push(this);
    return false;

    // But if is loaded, check if the component exists in its chunk
  } else if (
    $.rvlx.chunks[path].isLoaded &&
    (!components[path][data.component] || this === undefined)
  ) {
    console.warn(
      "! Component not found: " + path.toUpperCase() + "/" + data.component
    );
    return false;
  }

  // If there are options sent thru loadComponents make sure is not empty
  let currentOptions = $(options).length ? options.options : {};

  // If not initialized, create instance passing the invoker element
  var component = new components[path][(this.data("component"))]({
    el: this,
    options: currentOptions
  });

  // Run post initialize function
  component.postInit();

  // Create mutation observer to trigger unload of components when they're removed from the DOM
  var componentObserver = new MutationObserver(
    function(mutationsList) {
      if (mutationsList[0].removedNodes.length) {
        // Run unload method if the event fire belongs to the removed element
        if (mutationsList[0].removedNodes[0] === component.el) {
          component.unload(this.data("component"));

          // Stop observing DOM element
          componentObserver.disconnect();
        }
      }
    }.bind(this)
  );

  // Mutable observers observe node modifications on children, so to observe a component we target the parent
  var componentParent = component.$el.parent();
  if (componentParent.length) {
    componentObserver.observe(componentParent[0], { childList: true });
  }

  // Store instance in "initialized" data attr
  this.data("initialized", component);

  // console.log initialization
  console.log(
    "%c- " + path.toUpperCase() + " Comp. init: %c" + this.data("component"),
    "color: #3795d4; font-weight: bold;",
    "color: #3795d4;"
  );

  // Return the instance
  return component;
};

// Component Getter
$.getComponent = function(name, path) {
  var chunk = path || "commons";
  return $.fn.component.components[chunk] &&
    $.fn.component.components[chunk][name]
    ? $.fn.component.components[chunk][name]
    : false;
};

module.exports = $;
