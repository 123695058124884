var $ = require("jquery");
var matrixSliderView = Backbone.View.extend({
  events: {
    "click [data-scroller-left]": "slideLeft",
    "click [data-scroller-right]": "slideRight"
  },
  initialize: function() {
    //Getting elements
    this.component = this.$el.find("[data-scroller-content]");
    this.leftArrow = this.$el.find("[data-scroller-left]");
    this.rightArrow = this.$el.find("[data-scroller-right]");

    //Getting settings
    var data = this.$el.data();
    this.scrollLenght = this.$el.find("[data-matrix-spacer]").outerWidth();
    this.interval = data.scrollerInterval || 1;
    this.unit = data.scrollerUnit || "%";

    //Calculating displacement
    this.totalInterval = this.scrollLenght * this.interval;
    this.total = 0;

    this.displayContainer = this.$el.find(".scroller-content").width();
    if (this.unit == "%") {
      this.innerContainer =
        (this.component.width() * 100) / this.displayContainer;
      this.displayContainer = 100;
    } else {
      this.innerContainer = this.component.width();
    }
    this.$el.loadComponents();
    this.$el.loadModules();
    //Disabling the limits
    this.checkLimits();
  },
  checkLimits: function() {
    this.total == 0
      ? this.leftArrow.addClass("is-disabled")
      : this.leftArrow.removeClass("is-disabled");
    if (this.innerContainer - this.total <= this.displayContainer) {
      this.rightArrow.addClass("is-disabled");
    } else {
      this.rightArrow.removeClass("is-disabled");
    }
  },
  slideRight: function() {
    //Calculating displacement right direction
    this.total = this.total + this.totalInterval;
    this.component.css("margin-left", "-" + this.total + this.unit);
    this.checkLimits();
  },
  slideLeft: function() {
    //Calculating displacement left direction
    this.total = this.total - this.totalInterval;
    this.component.css("margin-left", "-" + this.total + this.unit);
    this.checkLimits();
  }
});
module.exports = matrixSliderView;
