var $ = require("jquery");

var AuthenticationStatus = Backbone.View.extend({
  checkStatus: function() {
    var self = this;

    $.ajax({
      type: "GET",
      dataType: "json",
      cache: false,
      url: self.settings.url,

      success: function(response) {
        if (response.success) {
          self.$("[data-status-true]").removeClass("is-hidden");
          self.$("[data-status-false]").addClass("is-hidden");
        } else {
          self.$("[data-status-true]").addClass("is-hidden");
          self.$("[data-status-false]").removeClass("is-hidden");
        }

        return true;
      },

      error: function() {
        return false;
      }
    });
  },

  /**
   * This method performs the initialization of the view.
   */
  initialize: function() {
    this.settings = this.$el.data("settings");

    this.checkStatus();
  }
});

module.exports = AuthenticationStatus;
