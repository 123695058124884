/**
 * Templates for use with async-form.js
 * Isolated to individual file for neatness
 */
var ConfirmationDialogTemplate;
var ConfirmationTemplate;

ConfirmationDialogTemplate = `
  <template class="is-hidden"
            data-confirmation-modal
            data-component="dialog"
            data-dialog-class="dialog-polar dialog-save"
            data-dialog-outside-click-close="false">
  </template>`;

ConfirmationTemplate = `
  <div class="dialog-head">
    <div class="dialog-title">{{titleMessage}}</div>
  </div>
  <div class="dialog-body"> 
    <p>
      {{#each this.general}}
          {{{this}}}<br>
      {{/each}}
    </p>
  </div>
  <div class="dialog-footer">
    <div class="dialog-actions">
      <button type="button" 
              class="button button-action icon-check" 
              data-dialog-action>
          {{submitButtonMessage}}
      </button>
    </div>
  </div>`;

module.exports = {
  confirmationDialogWrapper: ConfirmationDialogTemplate,
  confirmationDialog: ConfirmationTemplate
};
