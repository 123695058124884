var $ = require("jquery");

var PaymentMethodView = require("./_payment.payment-method");

var CheckView = PaymentMethodView.extend({
  processPaymentMethodTypeChange: function(data) {
    PaymentMethodView.prototype.processPaymentMethodTypeChange.call(this, data);

    if (
      data.paymentMethodType.get("type_label") !==
      this.paymentMethodType.get("type_label")
    ) {
      this.$("[data-payment-check-number]").attr("disabled", true);
      this.$("[data-payment-check-first-name]").attr("disabled", true);
      this.$("[data-payment-check-last-name]").attr("disabled", true);

      this.toggleField("[data-payment-check-account-number]", false);
      this.toggleField("[data-payment-check-routing-number]", false);
      this.toggleField("[data-payment-check-payable-to]", false);
      this.toggleField("[data-payment-check-send-to]", false);
      this.toggleField("[data-payment-check-send-to-address]", false);
    }
  },

  processSelection: function(event) {
    PaymentMethodView.prototype.processSelection.call(this, event);

    var method = this.paymentMethods.find({
      component_id: $(event.currentTarget).val()
    });

    var config = method.get("check");

    this.$("[data-payment-check-number]").attr("disabled", false);
    this.$("[data-payment-check-first-name]").attr("disabled", false);
    this.$("[data-payment-check-last-name]").attr("disabled", false);

    this.toggleField(
      "[data-payment-check-account-number]",
      config.mandatory_account_number
    );
    this.toggleField(
      "[data-payment-check-routing-number]",
      config.mandatory_routing_number
    );
    this.toggleField(
      "[data-payment-check-payable-to]",
      config.payable_to_field
    );
    this.toggleField("[data-payment-check-send-to]", config.send_to_address);
    this.toggleField(
      "[data-payment-check-send-to-address]",
      config.send_to_field
    );

    this.$el.loadComponents();
    this.$el.loadModules();

    this.$el.removeClass("is-hidden");
  }
});

module.exports = CheckView;
