var TravelerNameTemplate =
  "{{{first_name}}} {{{middle_name}}} <span>{{{last_name}}} {{{second_last_name}}}</span>";
var TitleOptionTemplate =
  '<option value="{{id}}" {{#if isSelected}}selected{{/if}} {{#unless id}}disabled{{/unless}}>{{name}}</option>';
var PassportOptionTemplate =
  '<option value="{{id}}">{{country_code}} {{number}}</option>';

var Passport = Backbone.Model.extend({
  defaults: {
    issueDate: false,
    expirationDate: false
  }
});

var Traveler = Backbone.Model.extend({
  defaults: {
    gender: "M",
    date_of_birth: false
  },

  getPassports: function() {
    var travelDocs = this.get("travel_documents_USING_LABELS");

    return travelDocs ? travelDocs.passport : {};
  },

  getContactInfo: function() {
    var contactInfo = this.get("traveler_contact_info");

    return (
      contactInfo ?? {
        address: {},
        phone_number: { phone_number: "", country_code: "" }
      }
    );
  },

  getEmergencyContactInfo: function() {
    var contactInfo = this.get("emergency_info");

    return (
      contactInfo ?? {
        email_addresses: [{ email_address: "" }],
        phone_numbers: [{ phone_number: "", country_code: "" }]
      }
    );
  }
});

var TravelerView = Backbone.View.extend({
  /**
   * Internal event listeners for the view
   */
  events: {
    "change [data-traveler-gender]": "processGenderChange",
    "click [data-no-middle-name-check]": "processNoMiddleNameCheck",
    "change [data-traveler-no-middle-name]": "updateMiddleNameRequired",
    "change [data-profile-travelers]": "processTravelerSelection",
    "change [data-profile-passports]": "processPassportSelection",
    "change [data-traveler-name-update]": "updateTravelerName",
    "keyup [data-traveler-name-update]": "updateTravelerName",
    "input [data-traveler-name-update]": "updateTravelerName",
    "keyup [data-traveler-contact-email]": "populateContactEmail",
    "keyup [data-traveler-contact-phone]": "populateContactPhone",
    "change [data-traveler-contact-phone-country-code]":
      "populateContactPhoneCountryCode"
  },

  // The default ages for the fake travelers
  ages: {
    ADT: 35,
    SRC: 65,
    CHD: 8,
    INF: 0
  },

  // The default last names for the fake travelers
  lastNames: [
    "White",
    "Boop",
    "Blue",
    "Black",
    "Smith",
    "Jones",
    "Green",
    "Rubble"
  ],

  /**
   * This method initializes the view.
   *
   * @param options
   */
  initialize: function(options) {
    this.settings = {
      titles: []
    };

    this.travelerNumber = null;
    this.currentTraveler = null;

    if (options.settings) {
      this.settings = $.extend(true, this.settings, options.settings);
    }

    this.travelerNumber = this.$el.data("travelerNumber");
    this.travelerNameTpl = options.travelerNameTpl;
    this.titleOptionTpl = options.titleOptionTpl;
    this.passportOptionTpl = options.passportOptionTpl;

    // Doing this to avoid different data structure for titles
    if (!Array.isArray(this.settings.titles)) {
      this.settings.titles = Object.values(this.settings.titles);
    }

    this.updateTitles(this.$("[data-traveler-gender]").val());

    //If the shell pnr data is present, pre-populate the travelers info from pnr
    if (this.$el.data("travelerPnrProfile")) {
      this.render(new Traveler(this.$el.data("travelerPnrProfile")));
    }

    if (
      this.$("[data-profile-travelers]").length &&
      this.$("[data-profile-travelers] option").is("[selected]")
    ) {
      this.processTravelerSelection(this.$("[data-profile-travelers]"));
    }
  },

  /**
   * This method processes the change of the traveler gender.
   *
   * @param event
   */
  processGenderChange: function(event) {
    if (this.$("[data-traveler-gender]").not(".is-disabled")) {
      this.updateTitles($(event.currentTarget).val());
    }
  },

  /**
   * This method updates the traveler titles based on the supplied gender.
   *
   * @param gender
   */
  updateTitles: function(gender) {
    // If titles are missing or the gender dropdown is disabled
    // ignore this method
    if (
      (typeof this.settings.titles === "object" && !this.settings.titles) ||
      (this.settings.titles instanceof Array && !this.settings.titles.length) ||
      this.$("[data-traveler-gender]").is(".is-disabled")
    ) {
      return;
    }

    // checking if the gender has a default value for title,
    // if the passenger object is present this won't have a value

    let selectedGenderTitle = this.$(
      "[data-traveler-gender] option:selected"
    ).data("genderDefaultTitle");

    // retrieve the dropdown for the title and get value
    let titleEl = this.$("[data-traveler-title]");
    let preSelectedTitle = titleEl.val();

    // replacing the preselectedTitle if there's
    // no passenger object
    if (selectedGenderTitle !== undefined) {
      preSelectedTitle = selectedGenderTitle;
    }

    // if there's no preselected Title, pick the first from the list
    let isFirst = !preSelectedTitle;

    // remove all the existing title options
    titleEl.empty().append(this.titleOptionTpl());

    // filter titles and check if it belongs
    // to BOTH or to current GENDER
    this.settings.titles.filter(title => {
      if (
        title["applicable_gender"] === "B" ||
        title["applicable_gender"] === gender
      ) {
        // checking if there's a preselected value, if not pick the first one
        if (
          (preSelectedTitle && title["id"] === Number(preSelectedTitle)) ||
          isFirst
        ) {
          title.isSelected = true;

          // making the isFirst flag false to ignore the functionality
          isFirst = false;
        }

        // keeping previous logic to build the dropdown
        titleEl.append(this.titleOptionTpl(title));
      }
    });
  },

  /**
   * This method enables/disables the middle name input based on the status of the no middle name checkbox.
   *
   * @param event
   */
  processNoMiddleNameCheck: function(event) {
    this.$("[data-traveler-middle-name]").prop(
      "disabled",
      !!$(event.currentTarget).is(":checked")
    );
  },

  updateMiddleNameRequired: function() {
    this.$("[data-traveler-middle-name]").focus();
  },

  /**
   * This method populates fake traveler data.
   */
  populateFakeTravelers: function(count, isUplift, isTBD) {
    var today = new Date();
    var birthYear = today.getFullYear();
    var travelerType = this.$el.data("traveler-type");

    if (travelerType) {
      birthYear = today.getFullYear() - this.ages[travelerType];
    }

    var firstName = "Betty";
    var lastName = this.lastNames[
      Math.floor(Math.random() * this.lastNames.length)
    ];

    if (count === 0 && isUplift) {
      firstName = "Arthur";
      lastName = "Davis";
    } else if (isTBD) {
      firstName = "TBD";
      lastName = "TBD";
    }

    this.$("[data-traveler-gender]")
      .val("F")
      .trigger("change");
    this.$("[data-traveler-title]").val("2");
    this.$("[data-traveler-first-name]")
      .val(firstName)
      .trigger("change");
    this.$("[data-traveler-last-name]")
      .val(lastName)
      .trigger("change");
    this.$("[data-traveler-no-middle-name]")
      .attr("checked", true)
      .trigger("change");

    var Calendar = this.$(
      '[data-calendar="date-of-birth-calendar-' + this.travelerNumber + '"]'
    ).component();

    Calendar.setDate("01/01/" + birthYear);
  },

  /**
   * This method updates the traveler inputs with the supplied traveler information.
   *
   * @param traveler
   */
  render: function(traveler) {
    this.currentTraveler = traveler;

    var passports = traveler.getPassports();
    var travelerContactInfo = traveler.getContactInfo();
    var emergencyContactInfo = traveler.getEmergencyContactInfo();

    traveler = traveler.toJSON();

    if (this.$("[data-traveler-gender]").not(".is-disabled")) {
      this.$("[data-traveler-gender]")
        .val(traveler.gender || "M")
        .change();
    }

    if (this.$("[data-traveler-title]").not(".is-disabled")) {
      this.$("[data-traveler-title]")
        .val(traveler.title_id)
        .trigger("change");
    }

    if (this.$("[data-traveler-first-name]").not(".is-disabled")) {
      this.$("[data-traveler-first-name]")
        .val(traveler.first_name)
        .trigger("change");
    }

    if (this.$("[data-traveler-middle-name]").not(".is-disabled")) {
      this.$("[data-traveler-middle-name]")
        .val(traveler.middle_name)
        .trigger("change");

      if (traveler.middle_name !== "") {
        this.$("[data-no-middle-name-check]").removeAttr("checked");
        this.$("[data-traveler-middle-name]").removeAttr("disabled");
      }
    }

    if (this.$("[data-traveler-last-name]").not(".is-disabled")) {
      this.$("[data-traveler-last-name]")
        .val(traveler.last_name)
        .trigger("change");
    }

    if (this.$("[data-traveler-second-last-name]").not(".is-disabled")) {
      this.$("[data-traveler-second-last-name]")
        .val(traveler.second_last_name)
        .trigger("change");
    }

    if (this.$("[data-traveler-suffix-id]").not(".is-disabled")) {
      this.$("[data-traveler-suffix-id]").val(traveler.suffix_id || "");
    }

    if (this.$("[data-traveler-redress-number]").not(".is-disabled")) {
      this.$("[data-traveler-redress-number]").val(
        traveler.redress_number || ""
      );
    }

    if (this.$("[data-traveler-traveler-number]").not(".is-disabled")) {
      this.$("[data-traveler-known-traveler-number]").val(
        traveler.known_traveler_number || ""
      );
    }

    if (this.$("[data-calendar-input]").not(".is-disabled")) {
      if (
        this.$(
          '[data-calendar="date-of-birth-calendar-' + this.travelerNumber + '"]'
        ).length
      ) {
        var DOBCalendar = this.$(
          '[data-calendar="date-of-birth-calendar-' + this.travelerNumber + '"]'
        ).component();

        // date_of_birth will be always a timespan, so it need to be formatted
        DOBCalendar.setDate(
          traveler.date_of_birth
            ? moment
                .unix(traveler.date_of_birth)
                .utc()
                .endOf("day")
                .format("L")
            : null
        );
        DOBCalendar.render();
      }
    }

    if (this.$("[data-traveler-contact-phone]").not(".is-disabled")) {
      this.$("[data-traveler-contact-phone]").val(
        travelerContactInfo.phone_number.phone_number || ""
      );
    }

    if (
      this.$("[data-traveler-contact-phone-country-code]").not(".is-disabled")
    ) {
      var ContactCountryCode = this.$(
        "[data-traveler-contact-phone-country-code]"
      ).component();

      if (ContactCountryCode) {
        ContactCountryCode.render(
          travelerContactInfo.phone_number.country_code || ""
        );
      }
    }

    if (this.$("[data-traveler-contact-email]").not(".is-disabled")) {
      this.$("[data-traveler-contact-email]").val(
        travelerContactInfo.email_address || ""
      );
    }

    if (this.$("[data-traveler-contact-address1]").not(".is-disabled")) {
      this.$("[data-traveler-contact-address1]").val(
        travelerContactInfo.address.address1 || ""
      );
    }

    if (this.$("[data-traveler-contact-address2]").not(".is-disabled")) {
      this.$("[data-traveler-contact-address2]").val(
        travelerContactInfo.address.address2 || ""
      );
    }

    if (this.$("[data-traveler-contact-city]").not(".is-disabled")) {
      this.$("[data-traveler-contact-city]").val(
        travelerContactInfo.address.city || ""
      );
    }

    if (this.$("[data-traveler-contact-country]").not(".is-disabled")) {
      this.$("[data-traveler-contact-country]")
        .val(travelerContactInfo.address.country || "")
        .trigger("change");
    }

    if (this.$("[data-traveler-contact-state]").not(".is-disabled")) {
      this.$("[data-traveler-contact-state]").val(
        travelerContactInfo.address.state || ""
      );

      var CountryState = this.$(
        "[data-traveler-contact-country-state]"
      ).component();

      if (CountryState) {
        CountryState.selectState(
          travelerContactInfo.address.country || "",
          travelerContactInfo.address.state || ""
        );
      }
    }

    if (this.$("[data-traveler-contact-postal]").not(".is-disabled")) {
      this.$("[data-traveler-contact-postal]").val(
        travelerContactInfo.address.postal || ""
      );
    }

    if (this.$("[data-traveler-contact-language]").not(".is-disabled")) {
      this.$("[data-traveler-contact-language]").val(traveler.language_id);
    }

    this.updatePassports(passports || {});

    if (passports && passports.length) {
      this.renderPassport(new Passport(passports[0]));
      this.$("[data-profile-passports]").val(passports[0].id);
    } else {
      this.renderPassport(new Passport());
    }

    if (
      this.$("[data-traveler-emergency-contact-first-name]").not(".is-disabled")
    ) {
      this.$("[data-traveler-emergency-contact-first-name]").val(
        emergencyContactInfo.first_name || ""
      );
    }

    if (
      this.$("[data-traveler-emergency-contact-last-name]").not(".is-disabled")
    ) {
      this.$("[data-traveler-emergency-contact-last-name]").val(
        emergencyContactInfo.last_name || ""
      );
    }

    if (this.$("[data-traveler-emergency-contact-email]").not(".is-disabled")) {
      this.$("[data-traveler-emergency-contact-email]").val(
        emergencyContactInfo.email_addresses[0].email_address || ""
      );
    }

    if (this.$("[data-traveler-emergency-contact-phone]").not(".is-disabled")) {
      this.$("[data-traveler-emergency-contact-phone]").val(
        emergencyContactInfo.phone_numbers[0].phone_number || ""
      );
    }

    if (
      this.$("[data-traveler-emergency-contact-phone-country-code]").not(
        ".is-disabled"
      )
    ) {
      var EmergencyCountryCode = this.$(
        "[data-traveler-emergency-contact-phone-country-code]"
      ).component();

      if (EmergencyCountryCode) {
        EmergencyCountryCode.render(
          emergencyContactInfo.phone_numbers[0].country_code || ""
        );
      }
    }
  },

  /**
   * This method updates the passport inputs with the supplied passport information.
   *
   * @param passport
   */
  renderPassport: function(passport) {
    if (this.$("[data-traveler-passport-number]").is(".is-disabled")) {
      return;
    }

    passport = passport.toJSON();

    let passportTrigger = this.$(
      "[data-passenger-passport-check=" + this.travelerNumber + "]"
    );

    // Open collapser if data for passport exist
    if (passport && passport.number && passportTrigger) {
      passportTrigger.trigger("click");
    }

    this.$("[data-traveler-passport-number]").val(passport.number);
    this.$("[data-traveler-passport-country]").val(passport.country_code);
    this.$("[data-traveler-citizenship-country]").val(passport.country_code);

    if (
      this.$(
        '[data-calendar="passport-expiration-calendar-' +
          this.travelerNumber +
          '"]'
      ).length
    ) {
      var ExpCal = this.$(
        '[data-calendar="passport-expiration-calendar-' +
          this.travelerNumber +
          '"]'
      ).component();

      // expiration_date will be always a timespan, so it need to be formatted
      ExpCal.setDate(
        passport.expiration_date
          ? moment.unix(passport.expiration_date).format("L")
          : null
      );
      ExpCal.render();
    }

    if (
      this.$(
        '[data-calendar="passport-issue-calendar-' + this.travelerNumber + '"]'
      ).length
    ) {
      var IssCal = this.$(
        '[data-calendar="passport-issue-calendar-' + this.travelerNumber + '"]'
      ).component();

      IssCal.setDate(
        passport.issue_date
          ? moment.unix(passport.issue_date).format("L")
          : null
      );
      IssCal.setState();
      IssCal.render();
    }
  },

  /**
   * This method updates the passport options
   *
   * @param passports
   */
  updatePassports: function(passports) {
    var passportEl = this.$("[data-profile-passports]");

    // remove all of the existing title options
    passportEl.empty().append(this.passportOptionTpl());

    // loop through all the titles and add the titles that match the supplied gender or is applied to both genders.
    for (var p in passports) {
      passportEl.append(this.passportOptionTpl(passports[p]));
    }

    if (passports.length) {
      this.$("[data-traveler-passports]").removeClass("is-hidden");
    } else {
      this.$("[data-traveler-passports]").addClass("is-hidden");
    }
  },

  /**
   * This method processes the selected traveler option.
   *
   * @param event
   */
  processTravelerSelection: function(event) {
    let currentEvent = event.currentTarget ? event.currentTarget : event;
    var selection = $(currentEvent).find("option:selected");

    this.render(new Traveler(selection.data("profile-traveler")));
  },

  /**
   * This method processes the selected passport option.
   *
   * @param event
   */
  processPassportSelection: function(event) {
    var passport = null;

    if ($(event.currentTarget).val()) {
      passport = _.findWhere(this.currentTraveler.getPassports(), {
        id: parseInt($(event.currentTarget).val())
      });
    }

    this.renderPassport(passport ? new Passport(passport) : new Passport());
  },

  /**
   * This method disables traveler selection for a traveler that has already been used.
   *
   * @param ids
   */
  restrictTravelerSelection: function(ids) {
    var self = this;

    this.$("[data-profile-travelers] option").removeAttr("disabled");

    // loop through the selected travelers
    _.each(ids, function(traveler) {
      // disable the traveler if the passenger number is different from the passenger number the traveler was
      // selected for
      if (self.travelerNumber !== traveler.travelerNumber) {
        self
          .$('[data-profile-travelers] option[value="' + traveler.id + '"]')
          .attr("disabled", true);
      }
    });
  },

  /**
   * This method updates the traveler name display
   *
   * @param event
   */
  updateTravelerName: function(event) {
    var travelerNum = $(event.currentTarget).data("travelerNameUpdate");

    let currentNameField = $('[data-traveler-name="' + travelerNum + '"]');

    let currentFirstName =
      $('[data-traveler-first-name="' + travelerNum + '"]').val() || false;
    let currentMiddleName =
      $('[data-traveler-middle-name="' + travelerNum + '"]').val() || false;
    let currentLastName =
      $('[data-traveler-last-name="' + travelerNum + '"]').val() || false;
    let currentSecondLastnameName =
      $('[data-traveler-second-last-name="' + travelerNum + '"]').val() ||
      false;

    let namePieces = {};

    if (currentFirstName.length) {
      namePieces.first_name = currentFirstName;
    }
    if (currentMiddleName.length) {
      namePieces.middle_name = currentMiddleName;
    }
    if (currentLastName.length) {
      namePieces.last_name = currentLastName;
    }
    if (currentSecondLastnameName.length) {
      namePieces.second_last_name = currentSecondLastnameName;
    }

    currentNameField.html(this.travelerNameTpl(namePieces));

    Object.keys(namePieces).length
      ? currentNameField.addClass("is-filled")
      : currentNameField.removeClass("is-filled");
  },

  populateContactEmail: function(event) {
    this.$('[data-traveler-contact-email="dependent"]:not(.is-disabled)').val(
      $(event.currentTarget).val()
    );
  },

  populateContactPhone: function(event) {
    this.$('[data-traveler-contact-phone="dependent"]:not(.is-disabled)').val(
      $(event.currentTarget).val()
    );
  },

  populateContactPhoneCountryCode: function(event) {
    var PrimaryEl = $(event.currentTarget).component();
    var countryCode = "";

    if (PrimaryEl) {
      countryCode = PrimaryEl.getSelectedValue();
    }

    var CountryCode = this.$(
      '[data-traveler-contact-phone-country-code="dependent"]:not(.is-disabled)'
    ).component();

    if (CountryCode) {
      CountryCode.render(countryCode || "");
    }
  },

  setTBDContactEmail: function(email) {
    this.$("[data-traveler-contact-email][required]:not(.is-disabled)").val(
      email || "noreply@revelex.com"
    );
  },

  setTBDContactPhone: function(phone) {
    var regex = /^\+?\d+$/g;

    if (phone) {
      phone = phone.replaceAll("-", "");
    }

    if (!regex.test(phone)) {
      phone = null;
    }

    this.$("[data-traveler-contact-phone][required]:not(.is-disabled)").val(
      phone || "5619885588"
    );
  }
});

var TravelerInformationView = Backbone.View.extend({
  /**
   * Internal event listeners for the view.
   */
  events: {
    "click [data-fake-travelers]": "populateFakeTravelers",
    "click [data-fake-travelers-uplift]": "populateFakeTravelersUplift",
    "click [data-tbd-travelers]": "populateFakeTravelersTBD",
    "change [data-profile-travelers]": "restrictTravelerSelection"
  },

  /**
   * This method handles the populating of fake traveler data.
   */
  populateFakeTravelers: function() {
    _.each(this.travelerViews, function(View) {
      View.populateFakeTravelers();
    });
  },

  populateFakeTravelersUplift: function() {
    _.each(this.travelerViews, function(View, index) {
      View.populateFakeTravelers(index, true);
    });
  },

  populateFakeTravelersTBD: function() {
    var self = this;

    _.each(this.travelerViews, function(View, index) {
      View.populateFakeTravelers(index, false, true);
      View.setTBDContactEmail(self.settings.tbdEmail);
      View.setTBDContactPhone(self.settings.tbdPhone);
    });
  },

  /**
   * This method collects and announces all the traveler/passenger selections.
   */
  restrictTravelerSelection: function() {
    var ids = [];

    _.each(this.$("[data-profile-travelers]"), function(select) {
      var id = $(select).val();
      var travelerNumber = $(select).data("profileTravelers");

      if (id && id.length) {
        ids.push({
          travelerNumber: travelerNumber,
          id: id
        });
      }
    });

    _.each(this.travelerViews, function(View) {
      View.restrictTravelerSelection(ids);
    });

    Backbone.Events.trigger("travelerInformation:travelersSelected");
  },

  /**
   * This method initializes the view.
   */
  initialize: function() {
    this.settings = {};

    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
    }

    var self = this;

    self.travelerViews = [];

    var travelerNameTpl = Handlebars.compile(TravelerNameTemplate);
    var titleTpl = Handlebars.compile(TitleOptionTemplate);
    var passportTpl = Handlebars.compile(PassportOptionTemplate);

    this.$("[data-traveler-block]").each(function() {
      var View = new TravelerView({
        el: this,
        travelerNameTpl: travelerNameTpl,
        titleOptionTpl: titleTpl,
        passportOptionTpl: passportTpl,
        settings: self.settings
      });

      self.travelerViews.push(View);
    });

    this.restrictTravelerSelection();

    Backbone.Events.on(
      "travelerInformation:restrictTravelers",
      function(data) {
        _.each(this.travelerViews, function(View) {
          View.restrictTravelerSelection(data.travelerIds);
        });
      },
      this
    );

    this.$el.loadComponents();
    this.$el.loadModules();
  }
});

module.exports = TravelerInformationView;
