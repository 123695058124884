/*
 *  Tour Component
 */
var $ = require("jquery");
var introJs = require("intro.js").introJs;

var ToursView = Backbone.View.extend({
  events: {
    "click [data-tour-start]": "startTour",
    open: "scanSteps",
    "click [data-tour-goto-step]": "startTourAt"
  },
  initialize: function() {
    this.introjs = introJs();
    this.$controls = this.$("[data-tour-controls]");
    this.template = Handlebars.compile(
      this.$('[data-tour-template="main"]').html()
    );
    this.collapser = this.$("[data-tour-collapser]").length
      ? this.$("[data-tour-collapser]").component()
      : null;
    if (this.collapser) {
      this.listenTo(this.collapser, "open", this.scanSteps.bind(this));
    }
  },
  scanSteps: function() {
    this.setLoadingState().parseSteps();
    return this;
  },
  startTour: function() {
    this.closeCollapser();
    this.introjs.start();
    return this;
  },
  startTourAt: function(e) {
    this.closeCollapser();
    var step = $(e.currentTarget).data("tour-goto-step");
    if (step === 0) {
      this.introjs.start();
    } else {
      this.introjs.goToStep(step).start();
    }
    return this;
  },
  closeCollapser: function() {
    if (this.collapser) {
      this.collapser.close();
    }
    return this;
  },
  setLoadingState: function() {
    this.steps = [];
    this.$controls.html(this.template({ isLoading: true }));
    return this;
  },
  parseSteps: function() {
    $("[data-tour-step]").each(
      function(i, el) {
        var options = $(el).data();
        var id = i;

        var step = {
          intro: options.tourText,
          element: el,
          name: options.tourName || "Step " + id,
          id: id
        };

        $(el).data("tour-step-id", id);

        step.intro = "<h3>" + step.name + "</h3>" + step.intro;

        if (options.tourPosition) {
          step.position = options.tourPosition;
        }

        this.steps.push(step);
      }.bind(this)
    );

    return this.updateTour();
  },
  updateTour: function() {
    var settings = {
      steps: this.steps,
      showProgress: true,
      scrollToElement: true
    };

    if (this.$el.data("tour-label-skip")) {
      settings.skipLabel = this.$el.data("tour-label-skip");
    }

    if (this.$el.data("tour-label-done")) {
      settings.doneLabel = this.$el.data("tour-label-done");
    }

    this.introjs.setOptions(settings);
    this.$controls.html(
      this.template({
        isLoading: false,
        steps: this.steps
      })
    );

    return this;
  }
});

module.exports = ToursView;
