/*
 * Email Viewer
 * File to insert email content inside an iframe.
 * TODO: Should be replaced by srcdocs when IE11 supports ends
 */

var $ = require("jquery");

var IframeViewer = Backbone.View.extend({
  checkIframeLoaded: function() {
    // Get a handle to the iframe element
    try {
      let iframe = this.iframe.get(0);
      let iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

      // Check if loading is complete
      if (iframeDoc.readyState === "complete") {
        // The loading is complete, call the function we want executed once the iframe is loaded
        this.populate();
        return;
      }

      // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
      window.setTimeout(this.checkIframeLoaded.bind(this), 100);
    } catch (e) {
      console.warn("Container has been deleted before loading!");
    }
  },
  populate: function() {
    this.iframeWrapper = this.iframe.contents().find("body");
    // adding margin 0 to help fitting the iframe on smaller resolutions
    this.iframeWrapper.css("margin", "0").html(this.content);

    this.emailWrapper = this.iframeWrapper.find("#wrapper").length
      ? this.iframeWrapper.find("#wrapper")
      : this.iframeWrapper;

    this.resize();
  },
  resize: function() {
    // a way to learn if all images are loaded
    this.imageCounter = 0;
    this.iframeImages = this.iframe.contents().find("img");

    if (this.emailWrapper) {
      this.emailWrapper.css({
        "max-width": this.emailWrapper.outerWidth(),
        width: "100%"
      });
      this.iframe.height(this.emailWrapper.outerHeight());
    }

    // this function will check for all images in the iframe to be loaded
    $(this.iframeImages).on("load", () => {
      this.imageCounter++;
      if (this.imageCounter === this.iframeImages.length) {
        // when all images are loaded, we set the height of the iframe
        // 50 will be use for extra needed gap for vertical bar not to show if not needed
        this.iframe.height(this.iframeWrapper.outerHeight() + 50);
        this.print();
      }
    });
  },
  print: function() {
    if (this.settings.isPrint) {
      window.print();
    }
  },
  initialize: function() {
    this.settings = {
      isPrint: false
    };

    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
    }

    this.iframe = this.$("[data-iframe-static-wrapper]");
    this.content = this.$el.data("iframeStaticContent");

    this.checkIframeLoaded();
  }
});

module.exports = IframeViewer;
