// Member login
// This Module is integrates facebook and google sign in functions

// expected settings to be fetched
var StateModel = Backbone.Model.extend({
  defaults: {
    action: "",
    fb: {
      method: "",
      appId: "",
      cookie: Boolean,
      xfbml: Boolean,
      version: "",
      scope: ""
    },
    google: {
      id: ""
    }
  }
});

var MemberLogin = Backbone.View.extend({
  events: {
    "click [data-facebook-button]": "fbLogin"
  },

  // facebook log in state
  fbLogin: function() {
    // fetch profile information and accessToken
    FB.login(
      response => {
        if (response.authResponse) {
          access_token = response.authResponse.accessToken; //get access token
          user_id = response.authResponse.userID; //get FB UID

          FB.api("/me", function(response) {
            public_profile = response.public_profile;
            user_email = response.email;
          });

          // if login is succesful log into the application
          this.checkLoginState();
        } else {
          //user hit cancel button
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope: this.settings.fb.scope
      }
    );
  },

  // Used to build a form to submit the access token
  checkLoginState: function() {
    FB.getLoginStatus(response => {
      if (response.status === "connected") {
        document.body.classList.remove("is-loaded");

        var facebook_access_token = response.authResponse.accessToken;
        var myForm = document.createElement("form");
        var myInput = document.createElement("input");

        if (this.settings.callbackUrl) {
          var myCallback = document.createElement("input");
          myForm.appendChild(myCallback);
        }

        myForm.method = this.settings.fb.method;
        myForm.action = this.settings.action;

        if (this.settings.callbackUrl) {
          myCallback.setAttribute("type", "hidden");
          myCallback.setAttribute("name", "callback");
          myCallback.setAttribute("value", this.settings.callbackUrl);
        }

        myInput.setAttribute("name", "facebook_access_token");
        myInput.setAttribute("value", facebook_access_token);
        myForm.appendChild(myInput);

        document.body.appendChild(myForm);
        myForm.submit();
        document.body.removeChild(myForm);
      }
    });
  },

  addElement: function(elementScope, element, id, url, attr) {
    var elem,
      fjs = elementScope.getElementsByTagName(element)[0],
      val;

    if (elementScope.getElementById(id)) {
      return;
    }
    elem = elementScope.createElement(element);
    for (val in attr) {
      if (attr.hasOwnProperty(val)) elem.setAttribute(val, attr[val]);
    }
    elem.id = id;
    elem.name = id;
    elem.src = url;
    elem.async = true;
    elem.defer = true;
    fjs.parentNode.insertBefore(elem, fjs);
  },

  initialize: function() {
    this.settings = this.settings = $.extend(
      true,
      this.settings,
      this.$el.data("settings")
    );

    if (this.settings.fb) {
      // Builds the init call to facebook SDK
      window.fbAsyncInit = () => {
        FB.init({
          appId: this.settings.fb.appId,
          cookie: this.settings.fb.cookie,
          xfbml: this.settings.fb.xfbml,
          version: this.settings.fb.version
        });

        FB.AppEvents.logPageView();
      };

      this.addElement(
        document,
        "script",
        "facebook-jssdk",
        "https://connect.facebook.net/en_US/sdk.js"
      );
    }
  }
});

module.exports = MemberLogin;
