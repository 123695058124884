/**
 * This module handles the display of a loading splash.
 *
 * Live examples can be found on the preview site page
 * non production view - https://{environment}/{site}/{checkout}/{branch}/app/0/preview/0/home.html
 * production viewing - https://{site}/app/0/preview/0/home.html
 *
 * Global Loader
 *  - displays a full page loading splash
 *
 *  - triggered by clicking any dom element that has the data-loading-splash-item attribute
 *
 *  - can be turned on via javascript by using the following line
 *          Backbone.Events.trigger('loadingSplashOn');
 *
 *  - can be turned off via javascript by using the following line
 *          Backbone.Events.trigger('loadingSplashOff');
 *
 *  - loading splash title and message can be customized by adding data-loading-splash-title and
 * data-loading-splash-message attributes to the dom element that has the data-loading-splash-item attribute
 *
 * Examples:
 *
 * <button data-loading-splash-item
 *         data-loading-splash-title="Custom title"
 *         data-loading-splash-message="Custom message">Button to show loader</button>
 *
 * <a href="#" data-loading-splash-item>Some link to show loader</a>
 */

var $ = require("jquery");

var LoadingSplash = Backbone.View.extend({
  /**
   * Properties to store the default title and message that was set for the loading splash when initialized
   */
  defaultTitle: "",
  defaultMessage: "",

  progressBar: document.getElementsByTagName("figcaption"),
  startTimestamp: window.performance.now(),
  isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),

  /**
   * This method updates and displays the loader.
   *
   * @param title
   * @param message
   */
  render: function(title, message) {
    // update the title and message using the supplied data or defaults
    this.$("[data-loading-splash-title]").html(
      title ? title : this.defaultTitle
    );
    this.$("[data-loading-splash-message]").html(
      message ? message : this.defaultMessage
    );

    $("body").removeClass("is-loaded");
    this.$el.attr("aria-live", "polite");

    this.showLoader();
    this.showAnimationCount();
  },

  /**
   * This method hides the loading splash
   */
  hideLoader: function() {
    $("body").addClass("is-loaded");
    this.$el.attr("aria-live", "off");
  },

  /**
   * This method show the loading splash
   */
  showLoader() {
    $("body").removeClass("is-loaded");
    this.$el.attr("aria-live", "polite");

    this.switchLoaderId("loading-number");
  },

  /**
   * This method show loading splash and animating number counters.
   */
  showAnimationCount() {
    let progressCount = document.getElementById("loading-number");

    if (progressCount) {
      let animationCount = 0;
      const updateProgressBar = () => {
        if (animationCount < 100) {
          animationCount++;
          progressCount.textContent = animationCount + " %";
        }
      };

      const animate = () => {
        if (animationCount < 100) {
          setTimeout(() => {
            requestAnimationFrame(() => {
              updateProgressBar();
              animate();
            });
          }, 20);
        } else if (animationCount == 100) {
          setTimeout(() => {
            this.switchLoaderId("is-loading");
            progressCount.textContent = "";
          }, 300);
        }
      };
      animate();
    }
  },

  /**
   * This method switch id name to animating number or loading spinner
   */
  switchLoaderId: function(newId) {
    if (!this.isSafari) {
      $(this.progressBar).removeAttr("id");
      $(this.progressBar).attr("id", newId);
    }
  },

  /**
   * This method processes a loading splash item click
   *
   * @param option
   */
  triggerLoader: function(option) {
    var title = "";
    var message = "";

    if (option) {
      let currentOption = option.currentTarget || option.element;

      if (option["jquery"]) {
        currentOption = option;
      }

      if (currentOption) {
        title = $(currentOption).attr("data-loading-splash-custom-title");
        message = $(currentOption).attr("data-loading-splash-custom-message");
      } else {
        title = option.title;
        message = option.message;
      }
    }

    this.render(title, message);
  },

  /**
   * This method active function before page unload
   * (trigger when refeash page from bownser)
   */
  triggerBeforeUnload() {
    this.showLoader();
    this.showAnimationCount();
  },

  initialize: function() {
    // set the default title and message of the loader
    this.defaultTitle =
      this.$el.data("loadingDefaultTitle") ||
      this.$("[data-loading-splash-title]").html();
    this.defaultMessage = this.$("[data-loading-splash-message]").html();

    Backbone.Events.on("loadingSplashOn", this.triggerLoader.bind(this));
    Backbone.Events.on("loadingSplashOff", this.hideLoader.bind(this));

    // this is a global loader so we must add document level event listeners
    $(document).on(
      "click",
      "[data-loading-splash-item]",
      this.triggerLoader.bind(this)
    );

    if (!this.$el.data("loadingSplashStayOn")) {
      this.hideLoader();
    }
  }
});

// create the Loader view
module.exports = LoadingSplash;
