/*
 *   Countdown Textarea
 */

var CountdownTextarea = Backbone.View.extend({
  events: {
    "input textarea": "updateCount"
  },
  initialize: function() {
    // Find out if it's nested on a form
    var form = this.$el.parents("form");

    if (form.length) {
      // If so, bind to the reset event of the form
      form.on("reset", this.resetCount.bind(this));
    }

    // reflow separation for selector caching
    this.reflow();
  },
  reflow: function() {
    // cache selectors
    this.$textarea = this.$("textarea");
    this.$label = this.$("[data-countdown-textarea-label]");

    // set limit
    this.charLimit = this.$textarea.attr("maxlength") || 100;

    // pre-compile template
    this.template = Handlebars.compile(this.$label.html());

    // set first value
    this.count = false;

    // run update for first render
    this.updateCount();
  },
  updateCount: function(e) {
    var currentLength = this.$textarea.val().length;

    // If count is the same as last update, quit function
    if (this.count && currentLength == this.count) {
      return false;
    }

    // Calculate remaining characters
    var remainingChars = this.charLimit - currentLength;

    // Update current count
    this.count = currentLength;

    // Render Label
    var label = this.template({
      remaining: remainingChars,
      count: this.count,
      charLimit: this.charLimit
    });

    // Update Label
    this.$("[data-countdown-textarea-counter]").html(label);

    // Return Current Instance
    return this;
  },
  resetCount: function() {
    // ensure that count will run as first time
    this.count = false;

    // reset value
    this.$textarea.val("");

    // update label
    this.updateCount();

    return this;
  }
});

module.exports = CountdownTextarea;
