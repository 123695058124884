var $ = require("jquery");

var PaymentUtility = require("./_payment.utility");

var PaymentMethodView = Backbone.View.extend({
  events: {
    "change [data-payment-method]": "processSelection"
  },

  initialize: function(options) {
    this.dispatcher = options.dispatcher;

    this.paymentMethodType = options.paymentMethodType;
    this.paymentMethods = new PaymentUtility.PaymentMethodCollection(
      options.paymentMethods
    );

    this.dispatcher.on(
      "paymentProcess:changed",
      this.processPaymentProcessChange.bind(this)
    );

    this.dispatcher.on(
      "paymentMethodType:changed",
      this.processPaymentMethodTypeChange.bind(this)
    );

    this.dispatcher.on(
      "initFromBillingInfo",
      this.initFromBillingInfo.bind(this)
    );
  },

  initFromBillingInfo: function(data) {
    this.processPaymentProcessChange(
      PaymentUtility.buildPaymentProcessChangeDataFromBillingInfo(
        data.billingInfo
      )
    );

    this.processPaymentMethodTypeChange(
      PaymentUtility.buildPaymentMethodTypeChangeDataFromBillingInfo(
        data.billingInfo
      ),
      true,
      data.billingInfo
    );
  },

  processPaymentProcessChange: function(data) {
    var self = this;

    this.processType = data.processType;

    this.paymentMethods.each(function(method) {
      if (
        (self.processType === "manual" && method.get("allow_manual")) ||
        (self.processType === "electronic" && method.get("allow_electronic"))
      ) {
        self
          .$('option[value="' + method.get("component_id") + '"]')
          .removeClass("is-hidden");
      } else {
        self
          .$('option[value="' + method.get("component_id") + '"]')
          .addClass("is-hidden");
      }
    });
  },

  processPaymentMethodTypeChange: function(data, isInitializing) {
    var initializing = isInitializing || false;

    if (
      data.paymentMethodType.get("type_label") ===
      this.paymentMethodType.get("type_label")
    ) {
      this.$("[data-payment-method]").attr("disabled", false);

      if (!initializing) {
        this.$("[data-payment-method]").trigger("change");
      }
    } else {
      this.$el.addClass("is-hidden");
      this.$("[data-payment-method]").attr("disabled", true);
    }
  },

  processSelection: function(event) {
    var method =
      this.paymentMethods.get($(event.currentTarget).val()) ||
      new PaymentUtility.PaymentMethod();

    this.dispatcher.trigger("paymentMethod:changed", {
      paymentMethod: method
    });
  },

  toggleField: function(selector, show) {
    if (show) {
      this.$(selector).removeClass("is-hidden");
      this.$(selector)
        .find("input, select")
        .attr("disabled", false);
    } else {
      this.$(selector).addClass("is-hidden");
      this.$(selector)
        .find("input, select")
        .attr("disabled", true);
    }
  }
});

module.exports = PaymentMethodView;
