var $ = require("jquery");

require("webticker");

var SystemAlert = Backbone.View.extend({
  initialize: function() {
    var self = this;

    var alerts = REVELEX.settings.alerts || [];

    if (alerts.length) {
      _.each(REVELEX.settings.alerts, function(item) {
        self.$("ul").append("<li>" + item + "</li>");
      });

      this.$el.removeClass("is-hidden");

      this.$("ul").webTicker({
        startEmpty: false,
        height: "40px"
      });
    }
  }
});

module.exports = SystemAlert;
