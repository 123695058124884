/**
 * Templates for use with portlet.js
 * Isolated to individual file for neatness
 */

var RemoveDialogTemplate;
var RemoveTemplate;

RemoveDialogTemplate = `
  <template class="is-hidden"
            data-component="dialog"
            data-dialog-class="dialog-polar"
            data-dialog-id="portlet-remove"
            data-dialog-outside-click-close="false"
            data-portlet-remove-modal>
  </template>`;

RemoveTemplate = `
  <div class="dialog-head">
      <div class="dialog-title">
        <small>{{currentTriggerData.title}}</small>
        {{currentTriggerData.name}}
      </div>
  </div>
  <div class="dialog-body"> 
      <p>{{currentTriggerData.description}}</p>
  </div>
  <div class="dialog-footer">
      <div class="dialog-actions">
        <div class="dialog-actions-left">
          <button type="button" 
                  class="button button-action-alternate" 
                  data-dialog-close>
            No
          </button>
        </div>
        <div class="dialog-actions-right">
          <button type="button" 
                  class="button button-action" 
                  data-dialog-action>
            Yes, please
          </button>
        </div>
      </div>
  </div>
`;

module.exports = {
  remove_dialog_template: RemoveDialogTemplate,
  remove_template: RemoveTemplate
};
