let CompareItem = Backbone.Model.extend({
  defaults: {
    isActive: true
  },

  idAttribute: "collection_id"
});

let CompareItemCollection = Backbone.Collection.extend({
  model: CompareItem
});

let Compare = Backbone.View.extend({
  /**
   * Default settings for the view.
   */
  settings: {
    compareLimit: 4,
    css: {
      wrapperClass: "has-compare-tray",
      activeClass: "is-open",
      inActiveClass: "is-collapsed"
    }
  },

  /**
   * Error message flags for the tray
   */
  errors: {
    limitReached: false
  },

  /**
   * Compare dialog
   */
  dialog: {},

  /**
   * Flag indicating the tray has errors
   */
  hasErrors: false,

  /**
   * The internal event listeners for the view.
   */
  events: {
    "click [data-compare-tray-item]": "processCompareItemClick",
    "click [data-compare-tray-item-remove]": "processCompareItemRemove",
    "click [data-compare-tray-clear]": "collapseTray",
    "click [data-compare-tray-compare]": "showComparison"
  },

  /**
   * This method processes the compare item click
   *
   * @param event The data to use to process the change
   */
  processCompareItemClick: function(event) {
    let el = $(event.currentTarget);
    let elData = el.data("compare-tray-model");
    let Item = new CompareItem(elData);
    if (el.is(":checked")) {
      if (this.collection.length < this.settings.compareLimit) {
        this.addItem(Item);
        this.trigger("added", event);
      } else {
        this.hasErrors = true;
        this.errors.limitReached = true;
        this.render();
        $(event.currentTarget).prop("checked", false);
      }
    } else {
      this.removeItem(Item);
      this.trigger("removed", event);
    }
  },

  /**
   * This method processes the removal of a compare item in the tray
   *
   * @param event The data to use to process the remove
   */
  processCompareItemRemove: function(event) {
    let Item = this.collection.get(
      $(event.currentTarget).data("compareTrayItemRemove")
    );
    this.removeItem(Item);
    this.trigger("removed", event);
  },

  /**
   * This method processes the removal of a compare item in the modal
   *
   * @param event The data to use to process the remove
   */
  processCompareModalItemRemove: function(event) {
    let Item = this.collection.get(
      $(event.currentTarget).data("compareModalItemRemove")
    );

    //re-render the content of the dialog
    this.removeItem(Item);
    if (this.collection.length > 1) {
      let data = this.setupData();
      $("[data-dialog-content]").html(
        this.compareModalTpl({
          compareItems: data.compareItems,
          activeItems: this.collection.length
        })
      );

      if (this.dialog) {
        this.dialog.reflow();
        this.dialog.currentModal.loadComponents();
      }
    } else {
      if (this.dialog) {
        this.dialog.close();
      }
    }
  },

  /**
   * This method adds an item from the comparison collection
   *
   * @param Item The model to add
   */
  addItem: function(Item) {
    this.collection.add(Item);
    this.render();
  },

  /**
   * This method removes an item to the comparison collection
   *
   * @param Item The model to remove
   */
  removeItem: function(Item) {
    this.collection.remove(Item);
    this.$('[data-compare-tray-item="' + Item.get("collection_id") + '"]').prop(
      "checked",
      false
    );
    this.render();
  },

  /**
   * This method collapses the tray
   */
  collapseTray: function() {
    let tray = this.$("[data-compare-tray-container]");
    tray.addClass(this.settings.css.inActiveClass);
    tray.on("animationend", e => {
      if (e.originalEvent.animationName === "smallBounceOut") {
        this.clearTray();
      }
    });
  },

  /**
   * This method clears the compare item collection
   */
  clearTray: function() {
    // Uncheck the boxes in the results view
    for (var x = 0; x < this.collection.length; x++) {
      this.$(
        '[data-compare-tray-item="' +
          this.collection.toArray()[x].get("collection_id") +
          '"]'
      ).prop("checked", false);
    }

    this.collection.reset();
    this.trigger("cleared");
    this.render();
  },

  /**
   * Pad the empty collection with four inactive models
   */
  padItems: function() {
    let items = this.collection.clone();
    // pad the collection with empty items to met the display limit
    if (items.length < this.settings.compareLimit) {
      for (
        let i = this.collection.length;
        i < this.settings.compareLimit;
        i++
      ) {
        items.add(
          new CompareItem({ isActive: false, labelCount: this.labelCount })
        );
      }
    }
    return items;
  },

  /**
   * If there are errors add the classes so styles are applied
   */
  errorsUI: function() {
    if (this.hasErrors) {
      this.el.classList.add("error");
      window.setTimeout(() => {
        this.el.classList.remove("error");
        this.hasErrors = false;
        this.render();
      }, 3000);
    } else {
      this.el.classList.remove("error");
    }
  },

  /**
   * Build the data needed for either view
   */
  setupData: function() {
    let items = this.padItems();
    return {
      compareItems: items.toJSON(),
      maxItems: this.settings.compareLimit,
      disableCompareButton: this.collection.length < 2,
      hasErrors: this.hasErrors,
      errors: this.errors
    };
  },

  /**
   * We know there is at least one model in the collection so show the tray
   */
  showTray: function() {
    let data = this.setupData();
    this.$("[data-compare-tray-container]")
      .html(this.compareTpl(data))
      .removeClass(this.settings.css.inActiveClass)
      .addClass(this.settings.css.activeClass);
    this.$el.addClass(this.settings.css.wrapperClass);
    let el = this.$("[data-compare-tray-item-container]");
    if (this.collection.length > this.settings.compareLimit - 1)
      el.scrollLeft(el.width());
  },

  /**
   * This method updates and displays the compare modal
   */
  showComparison: function() {
    if (this.dialog) {
      let data = this.setupData();
      this.dialog.$el.html(
        this.compareModalTpl({
          compareItems: data.compareItems,
          activeItems: this.collection.length
        })
      );
      this.dialog.reflow().open();
    }
  },

  /**
   * Collection is empty so hide the tray
   */
  hideTray: function() {
    this.$("[data-compare-tray-container]")
      .removeClass(this.settings.css.activeClass)
      .addClass(this.settings.css.inActiveClass)
      .empty();
    this.$el.removeClass(this.settings.css.wrapperClass);
  },

  /**
   * Parse the string of settings in the markup and combine with our own
   */
  parseSettings: function() {
    if (this.$el.data("compare-tray-settings")) {
      this.settings = $.extend(
        true,
        this.settings,
        eval(this.$el.data("compare-tray-settings"))
      );
    }
  },

  /**
   * Compile the handlerbars templates
   */
  setupHandlebarsTemplates: function() {
    let compareTray = this.$("[data-compare-tray-template]");
    let compareTrayModal = this.$("[data-compare-tray-modal-template]");
    this.compareTpl = Handlebars.compile(
      compareTray.length ? compareTray.html() : ""
    );

    this.compareModalTpl = Handlebars.compile(
      compareTrayModal.length ? compareTrayModal.html() : ""
    );
  },

  /**
   * Handle the session storage
   */
  sessionStorage: function() {
    if (this.settings.token) {
      this.collection.set(
        JSON.parse(
          window.sessionStorage.getItem("compare-" + this.settings.token)
        )
      );
      this.collection.on("change add remove update reset", collection => {
        window.sessionStorage.setItem(
          "compare-" + this.settings.token,
          JSON.stringify(collection)
        );
      });
    }
  },

  /**
   * Render
   */
  render: function() {
    if (this.collection.length) {
      this.showTray();
      $("body").addClass("compare-tray-open");
    } else {
      this.hideTray();
      $("body").removeClass("compare-tray-open");
    }
    this.errorsUI();
    this.hasErrors = false;
    this.errors = [];

    this.$el.loadComponents();
  },

  /**
   * This method performs the initialization of the view.
   */
  initialize: function() {
    this.setupHandlebarsTemplates();
    this.parseSettings();
    this.collection = new CompareItemCollection();
    this.sessionStorage();
    this.$el.loadComponents();

    if (this.$("[data-compare-tray-modal]").length) {
      this.dialog = this.$("[data-compare-tray-modal]").component();
    }

    $(document).on("click", "[data-compare-modal-item-remove]", event => {
      this.processCompareModalItemRemove(event);
    });
    this.render();
  }
});

module.exports = Compare;
