module.exports = {
  VI: {
    // Visa
    maxlength: 16,
    cvvlength: 3,
    pattern: "^4[0-9]{12}(?:[0-9]{3})?$"
  },
  MC: {
    // Mastercard
    maxlength: 16,
    cvvlength: 3,
    pattern: "^5[1-5][0-9]{14}$"
  },
  AX: {
    // Amex
    maxlength: 15,
    cvvlength: 4,
    pattern: "^3[47][0-9]{13}$"
  },
  DS: {
    // Discover
    maxlength: 16,
    cvvlength: 3,
    pattern: "^6(?:011|5[0-9]{2})[0-9]{12}$"
  },
  DC: {
    // Diners
    maxlength: 14,
    cvvlength: 3,
    pattern: "^3(?:0[0-5]|[68][0-9])[0-9]{11}$"
  },
  JCB: {
    // JCB
    maxlength: 16,
    cvvlength: 3,
    pattern: "^(?:2131|1800|35d{3})d{11}$"
  },
  none: {
    maxlength: 14,
    cvvlength: 3,
    pattern: "[0-9]+"
  }
};
