module.exports = function(country) {
  switch (country) {
    case "AD":
      return /^(?:AD)*(\d{3})$/;
    case "AL":
      return /^(\d{4})$/;
    case "AM":
      return /^(\d{6})$/;
    case "AR":
      return /^[A-Z]?\d{4}[A-Z]{0,3}$/;
    case "AS":
      return /96799/;
    case "AT":
      return /^(\d{4})$/;
    case "AU":
      return /^(\d{4})$/;
    case "AX":
      return /^(?:FI)*(\d{5})$/;
    case "AZ":
      return /^(?:AZ)*(\d{4})$/;
    case "BA":
      return /^(\d{5})$/;
    case "BB":
      return /^(?:BB)*(\d{5})$/;
    case "BD":
      return /^(\d{4})$/;
    case "BE":
      return /^(\d{4})$/;
    case "BG":
      return /^(\d{4})$/;
    case "BH":
      return /^(\d{3}\d?)$/;
    case "BL":
      return /^(\d{5})$/;
    case "BM":
      return /^([A-Z]{2}\d{2})$/;
    case "BN":
      return /^([A-Z]{2}\d{4})$/;
    case "BR":
      return /^\d{5}-?\d{3}$/;
    case "BY":
      return /^(\d{6})$/;
    case "CA":
      return /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) ?(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/;
    case "CH":
      return /^(\d{4})$/;
    case "CL":
      return /^(\d{7})$/;
    case "CN":
      return /^(\d{6})$/;
    case "CO":
      return /^(\d{6})$/;
    case "CR":
      return /^(\d{5})$/;
    case "CS":
      return /^(\d{5})$/;
    case "CU":
      return /^(?:CP)*(\d{5})$/;
    case "CV":
      return /^(\d{4})$/;
    case "CX":
      return /^(\d{4})$/;
    case "CY":
      return /^(\d{4})$/;
    case "CZ":
      return /^\d{3}\s?\d{2}$/;
    case "DE":
      return /^(\d{5})$/;
    case "DK":
      return /^(\d{4})$/;
    case "DO":
      return /^(\d{5})$/;
    case "DZ":
      return /^(\d{5})$/;
    case "EC":
      return /^([a-zA-Z]\d{4}[a-zA-Z])$/;
    case "EE":
      return /^(\d{5})$/;
    case "EG":
      return /^(\d{5})$/;
    case "ES":
      return /^(\d{5})$/;
    case "ET":
      return /^(\d{4})$/;
    case "FI":
      return /^(?:FI)*(\d{5})$/;
    case "FM":
      return /^(\d{5})$/;
    case "FO":
      return /^(?:FO)*(\d{3})$/;
    case "FR":
      return /^(\d{5})$/;

    case "GB":
      return /^([a-zA-Z]{2}[0-9]{1}[a-zA-Z]{1}( )?[0-9]{1}[a-zA-Z]{2}$)|^([a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}( )?[0-9]{1}[a-zA-Z]{2})$|^([a-zA-Z]{1}[0-9]{1}( )?[0-9]{1}[a-zA-Z]{2})$|^([a-zA-Z]{1}[0-9]{2}( )?[0-9]{1}[a-zA-Z]{2})$|^([a-zA-Z]{2}[0-9]{1}( )?[0-9]{1}[a-zA-Z]{2})$|^([a-zA-Z]{2}[0-9]{2}( )?[0-9]{1}[a-zA-Z]{2})$/;
    case "GE":
      return /^(\d{4})$/;
    case "GF":
      return /^((97|98)3\d{2})$/;
    case "GG":
      return /^((?:(?:[A-PR-UWYZ][A-HK-Y]\d[ABEHMNPRV-Y0-9]|' .'[A-PR-UWYZ]\d[A-HJKPS-UW0-9])\s\d[ABD-HJLNP-UW-Z]{2})|GIR\s?0AA)$/;
    case "GL":
      return /^(\d{4})$/;
    case "GP":
      return /^((97|98)\d{3})$/;
    case "GR":
      return /^(\d{5})$/;
    case "GT":
      return /^(\d{5})$/;
    case "GU":
      return /^(969\d{2})$/;
    case "GW":
      return /^(\d{4})$/;
    case "HN":
      return /^([A-Z]{2}\d{4})$/;
    case "HR":
      return /^(?:HR)*(\d{5})$/;
    case "HT":
      return /^(?:HT)*(\d{4})$/;
    case "HU":
      return /^(\d{4})$/;
    case "ID":
      return /^(\d{5})$/;
    case "IL":
      return /^(\d{5})$/;
    case "IM":
      return /^((?:(?:[A-PR-UWYZ][A-HK-Y]\d[ABEHMNPRV-Y0-9]|' .'[A-PR-UWYZ]\d[A-HJKPS-UW0-9])\s\d[ABD-HJLNP-UW-Z]{2})|GIR\s?0AA)$/;
    case "IN":
      return /^(\d{6})$/;
    case "IQ":
      return /^(\d{5})$/;
    case "IR":
      return /^(\d{10})$/;
    case "IS":
      return /^(\d{3})$/;
    case "IT":
      return /^(\d{5})$/;
    case "JE":
      return /^((?:(?:[A-PR-UWYZ][A-HK-Y]\d[ABEHMNPRV-Y0-9]|' .'[A-PR-UWYZ]\d[A-HJKPS-UW0-9])\s\d[ABD-HJLNP-UW-Z]{2})|GIR\s?0AA)$/;
    case "JO":
      return /^(\d{5})$/;
    case "JP":
      return /^\d{3}-\d{4}$/;
    case "KE":
      return /^(\d{5})$/;
    case "KG":
      return /^(\d{6})$/;
    case "KH":
      return /^(\d{5})$/;
    case "KP":
      return /^(\d{6})$/;
    case "KR":
      return /^(\d{5})$/;
    case "KW":
      return /^(\d{5})$/;
    case "KZ":
      return /^(\d{6})$/;
    case "LA":
      return /^(\d{5})$/;
    case "LB":
      return /^(\d{4}(\d{4})?)$/;
    case "LI":
      return /^(\d{4})$/;
    case "LK":
      return /^(\d{5})$/;
    case "LR":
      return /^(\d{4})$/;
    case "LS":
      return /^(\d{3})$/;
    case "LT":
      return /^(?:LT)*(\d{5})$/;
    case "LU":
      return /^(?:L-)?\d{4}$/;
    case "LV":
      return /^(?:LV)*(\d{4})$/;
    case "MA":
      return /^(\d{5})$/;
    case "MC":
      return /^(\d{5})$/;
    case "MD":
      return /^MD-\d{4}$/;
    case "ME":
      return /^(\d{5})$/;
    case "MF":
      return /^(\d{5})$/;
    case "MG":
      return /^(\d{3})$/;
    case "MH":
      return /^969\d{2}(-\d{4})$/;
    case "MK":
      return /^(\d{4})$/;
    case "MM":
      return /^(\d{5})$/;
    case "MN":
      return /^(\d{6})$/;
    case "MP":
      return /^9695\d{1}$/;
    case "MQ":
      return /^(\d{5})$/;
    case "MT":
      return /^[A-Z]{3}\s?\d{4}$/;
    case "MV":
      return /^(\d{5})$/;
    case "MX":
      return /^(\d{5})$/;
    case "MY":
      return /^(\d{5})$/;
    case "MZ":
      return /^(\d{4})$/;
    case "NC":
      return /^(\d{5})$/;
    case "NE":
      return /^(\d{4})$/;
    case "NF":
      return /^(\d{4})$/;
    case "NG":
      return /^(\d{6})$/;
    case "NI":
      return /^(\d{7})$/;
    case "NL":
      return /^(\d{4} ?[A-Z]{2})$/;
    case "NO":
      return /^(\d{4})$/;
    case "NP":
      return /^(\d{5})$/;
    case "NZ":
      return /^(\d{4})$/;
    case "OM":
      return /^(\d{3})$/;
    case "PF":
      return /^((97|98)7\d{2})$/;
    case "PG":
      return /^(\d{3})$/;
    case "PH":
      return /^(\d{4})$/;
    case "PK":
      return /^(\d{5})$/;
    case "PL":
      return /^\d{2}-\d{3}$/;
    case "PM":
      return /^(97500)$/;
    case "PR":
      return /^00[679]\d{2}(?:-\d{4})?$/;
    case "PT":
      return /^\d{4}-?\d{3}$/;
    case "PW":
      return /^(96940)$/;
    case "PY":
      return /^(\d{4})$/;
    case "RE":
      return /^((97|98)(4|7|8)\d{2})$/;
    case "RO":
      return /^(\d{6})$/;
    case "RS":
      return /^(\d{6})$/;
    case "RU":
      return /^(\d{6})$/;
    case "SA":
      return /^(\d{5})$/;
    case "SD":
      return /^(\d{5})$/;
    case "SE":
      return /^(?:SE)?\d{3}\s\d{2}$/;
    case "SG":
      return /^(\d{6})$/;
    case "SH":
      return /^(STHL1ZZ)$/;
    case "SI":
      return /^(?:SI)*(\d{4})$/;
    case "SJ":
      return /^(\d{4})$/;
    case "SK":
      return /^\d{3}\s?\d{2}$/;
    case "SM":
      return /^(4789\d)$/;
    case "SN":
      return /^(\d{5})$/;
    case "SO":
      return /^([A-Z]{2}\d{5})$/;
    case "SV":
      return /^(?:CP)*(\d{4})$/;
    case "SZ":
      return /^([A-Z]\d{3})$/;
    case "TC":
      return /^(TKCA 1ZZ)$/;
    case "TH":
      return /^(\d{5})$/;
    case "TJ":
      return /^(\d{6})$/;
    case "TM":
      return /^(\d{6})$/;
    case "TN":
      return /^(\d{4})$/;
    case "TR":
      return /^(\d{5})$/;
    case "TW":
      return /^(\d{5})$/;
    case "UA":
      return /^(\d{5})$/;
    case "US":
      return /^\d{5}(-\d{4})?$/;
    case "UY":
      return /^(\d{5})$/;
    case "UZ":
      return /^(\d{6})$/;
    case "VA":
      return /^(\d{5})$/;
    case "VE":
      return /^(\d{4})$/;
    case "VI":
      return /^008\d{2}(?:-\d{4})?$/;
    case "VN":
      return /^(\d{6})$/;
    case "WF":
      return /^(986\d{2})$/;
    case "YT":
      return /^(\d{5})$/;
    case "ZA":
      return /^(\d{4})$/;
    case "ZM":
      return /^(\d{5})$/;

    default:
      console.warn(
        "Supplied country has not been set up in postalCodesByCountry.js or country has not been selected yet"
      );
      return "";
  }
};
