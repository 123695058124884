const $ = require("jquery");

// Adding templates to portlet, this will hold the basic remove template
const PortletTemplate = require("./_portlet.templates");

const Portlet = Backbone.View.extend({
  events: {
    "click [data-portlet-modal-trigger]": "renderModal",
    "submit [data-portlet-form]": "sendForm",
    "click [data-portlet-remove]": "sendRemove",
    "click [data-portlet-toggle]": "sendToggle",
    "click [data-content-loader-refresh]": "hideModalTrigger"
  },

  renderModal: function(event) {
    if (
      this.settings.id === $(event.currentTarget).data("portlet-modal-trigger")
    ) {
      var self = this;

      var content = this.templates.modal({
        baseData: this.settings,
        data: $(event.currentTarget).data("portlet-data")
      });

      this.$("[data-portlet-modal]").html(content);
      this.$("[data-portlet-modal]")
        .find("[data-portlet-modal-subtemplate-body]")
        .html(this.templates.modalSubtemplate);

      this.Dialog = this.$("[data-portlet-modal]").component();
      this.Dialog.reflow().open();

      this.Dialog.currentModal.on("submit", "[data-portlet-form]", function(
        event
      ) {
        event.preventDefault();
        self.sendForm(event, true);
      });
    }
  },

  renderMessages: function(messages, isModal) {
    if (isModal) {
      this.Dialog.currentModal
        .find('[data-portlet-modal-messages="' + this.settings.id + '"]')
        .html(this.templates.messages(messages));
    } else {
      this.$('[data-portlet-messages="' + this.settings.id + '"]').html(
        this.templates.messages(messages)
      );
    }
  },

  sendForm: function(event, isModal) {
    if (this.settings.id === $(event.currentTarget).data("portlet-form")) {
      var form = $(event.currentTarget);

      this.sendRequest(
        event,
        form.attr("action"),
        form.serializeJSON(),
        isModal
      );
    }
  },

  sendRemove: function(event) {
    // we divide the sendRemove function, this way we can
    // request for confirmation modal or just delete right away

    let currentTriggerData =
      $(event.currentTarget).data("portletData").removeData || {};

    // if we have removeData inside the portletData, confirmation will pop up
    if (Object.keys(currentTriggerData).length) {
      this.removeDialog.html(
        this.templates.removeContent({
          currentTriggerData
        })
      );

      let currentRemoveDialog = this.removeDialog.component();

      currentRemoveDialog.reflow().open();

      currentRemoveDialog.currentModal.on(
        "click",
        "[data-dialog-action]",
        () => {
          currentRemoveDialog.close();
          this.sendRemoveAction(event);
        }
      );
    } else {
      this.sendRemoveAction(event);
    }
  },

  sendRemoveAction: function(event) {
    if (this.settings.id === $(event.currentTarget).data("portlet-remove")) {
      this.sendRequest(
        event,
        this.settings.urls.delete,
        $(event.currentTarget).data("portlet-data")
      );
    }
  },

  sendToggle: function(event) {
    if (this.settings.id === $(event.currentTarget).data("portlet-toggle")) {
      let currentEvent = $(event.currentTarget);
      var toggleUrl = currentEvent.data("portlet-toggle-url");
      var toggleArray = currentEvent.data("portlet-toggle-array");
      var toggleCsrf = currentEvent.data("portlet-toggle-csrf");
      var toggleData = currentEvent.data("portlet-data");

      toggleData[currentEvent.data("portlet-toggle-name")] = currentEvent.is(
        ":checked"
      )
        ? 1
        : 0;

      var data = new Backbone.Model(toggleCsrf);

      if (toggleArray) {
        data.set(toggleArray, toggleData);
      } else {
        data.set(toggleData);
      }

      this.sendToggleRequest(
        this.settings.urls.toggle[toggleUrl],
        data.toJSON(),
        $(event.currentTarget)
      );
    }
  },

  sendToggleRequest: function(url, data, toggleId) {
    var self = this;

    $.ajax({
      type: "POST",
      dataType: "json",
      cache: false,
      url: url,
      data: data,

      beforeSend: function() {
        self.renderMessages({}, false);

        self.block(self.$el);
      },

      success: function(response) {
        self.renderMessages(response.messages, false);

        if (response.success) {
          toggleId.removeClass("has-error");
        } else {
          toggleId.addClass("has-error");

          if (toggleId.is(":checked")) {
            toggleId.removeProp("checked").removeAttr("checked");
          } else {
            toggleId.prop("checked", true).attr("checked", true);
          }
        }
        toggleId.data().portletToggleCsrf = response.csrf;

        self.unblock(self.$el);

        self.$("[data-portlet-reload]").click();

        return true;
      },

      error: function() {
        self.renderMessages({ ajaxFailure: true }, false);

        toggleId.addClass("has-error");

        if (toggleId.is(":checked")) {
          toggleId.removeProp("checked").removeAttr("checked");
        } else {
          toggleId.prop("checked", true).attr("checked", true);
        }

        self.unblock(self.$el);

        self.$("[data-portlet-reload]").click();
        return false;
      }
    });
  },

  sendRequest: function(event, url, data, isModal) {
    // checking if the form has any extra settings
    var sendRequestSettings =
      $(event.currentTarget).data("portletFormSettings") || {};

    $.ajax({
      type: "POST",
      dataType: "json",
      cache: false,
      url: url,
      data: data,

      beforeSend: () => {
        this.renderMessages({}, isModal);
        this.hideModalTrigger();

        if (isModal) {
          this.block(this.Dialog.currentModal.find("[data-dialog-body]"));
        } else {
          this.block(this.$el);
        }
      },

      success: response => {
        // we start to check if there's a contentLoader present,
        // and we check if there is anything in the cache and if there is remove

        if (
          sendRequestSettings &&
          sendRequestSettings.isContentLoader &&
          _.findWhere($.rvlx.cache["content-loader"], {
            currentLoaderId: sendRequestSettings.loaderId
          })
        ) {
          $.rvlx.cache = $.rvlx.cache || {};
          $.rvlx.cache["content-loader"] = $.rvlx.cache["content-loader"] || [];

          $.rvlx.cache["content-loader"] = $.rvlx.cache[
            "content-loader"
          ].filter(
            loaderId =>
              loaderId.currentLoaderId !== sendRequestSettings.loaderId
          );
        }

        this.renderMessages(response.messages, isModal);

        if (response.success) {
          if (isModal) {
            this.Dialog.close();
          } else {
            this.unblock(this.$el);
          }
        } else {
          if (isModal) {
            // csrf tokens need to be updated for invalid response.
            if (response.csrf) {
              $.each(response.csrf, (key, value) => {
                var csrf_field = this.Dialog.currentModal.find(
                  "input[name='" + key + "']"
                );
                if (csrf_field) {
                  csrf_field.val(value);
                }
              });
            }

            this.unblock(this.Dialog.currentModal.find("[data-dialog-body]"));
          } else {
            this.unblock(this.$el);
          }
        }

        this.$("[data-portlet-reload]").click();

        return true;
      },

      error: () => {
        this.renderMessages({ ajaxFailure: true }, isModal);

        if (isModal) {
          this.unblock(this.Dialog.currentModal.find("[data-dialog-body]"));
        } else {
          this.unblock(this.$el);
        }

        this.$("[data-portlet-reload]").click();
        return false;
      }
    });
  },

  block: function(el) {
    let parentWrapper = $(el).parents("[data-portlet-wrapper]");
    let currentWrapper = parentWrapper.length ? parentWrapper : $(el);

    currentWrapper.addClass("is-loading");
  },

  unblock: function(el) {
    let parentWrapper = $(el).parents("[data-portlet-wrapper]");
    let currentWrapper = parentWrapper.length ? parentWrapper : $(el);

    currentWrapper.removeClass("is-loading");
  },

  hideModalTrigger: function() {
    this.$("[data-portlet-modal-trigger]").addClass("is-hidden");
  },

  showModalTrigger: function() {
    this.$("[data-portlet-modal-trigger]").removeClass("is-hidden");
  },

  initTemplates: function() {
    this.templates = {
      messages: Handlebars.compile(
        this.$(
          '[data-portlet-messages-template="' + this.settings.id + '"]'
        ).html() || ""
      ),
      modal: Handlebars.compile(
        this.$(
          '[data-portlet-modal-template="' + this.settings.id + '"]'
        ).html() || ""
      ),
      removeDialog: Handlebars.compile(
        PortletTemplate.remove_dialog_template || ""
      ),
      removeContent: Handlebars.compile(PortletTemplate.remove_template || ""),
      modalSubtemplate:
        this.$(
          '[data-portlet-modal-subtemplate="' + this.settings.id + '"]'
        ).html() || ""
    };

    // we make sure that we only render the removeDialog only once
    if (!this.$("[data-portlet-remove-modal]").length) {
      this.$el.append(this.templates.removeDialog());
      this.removeDialog = this.$("[data-portlet-remove-modal]");
    }

    if (
      this.$('[data-portlet-modal-template="' + this.settings.id + '"]').length
    ) {
      this.showModalTrigger();
    } else {
      if (!$(this.el).find("[data-content-loader-show]").length) {
        this.hideModalTrigger();
      }
    }
  },

  initialize: function() {
    this.settings = {
      id: ""
    };

    if (this.$el.data("portlet-settings")) {
      this.settings = $.extend(
        true,
        this.settings,
        eval(this.$el.data("portlet-settings"))
      );
    }

    this.initTemplates();

    this.$el.loadComponents();
    this.$el.loadModules();

    this.$('[data-component="content-loader"]').on(
      "content-loaded",
      this.initTemplates.bind(this)
    );
  }
});

module.exports = Portlet;
