/**
 * Refund
 **/

var $ = require("jquery");

var refundView = Backbone.View.extend({
  events: {
    "click [name='refund[issue_card]']": "resetValue",
    "click [name='refund[commission_changing]']": "resetValue",
    "keyup [name='refund[penalty_amount]']": "penaltyAutoCalculate"
  },

  /**
   *
   * @param number
   * @param decimals
   * @returns {number|number}
   */
  roundHalfEven: function(number, decimals) {
    const multiplier = Math.pow(10, decimals);
    const rounded = Math.round(number * multiplier) / multiplier;
    if (rounded % 2 === 0 && Math.abs(rounded - number) === 0.5 / multiplier) {
      return Math.floor(rounded / 2) * 2 === rounded
        ? rounded
        : rounded - 1 / multiplier;
    } else {
      return rounded;
    }
  },

  /**
   *
   * @param e
   * @param ignorePenalty
   * @returns {boolean}
   */
  penaltyAutoCalculate: function(e, ignorePenalty) {
    // if penaltyAutoCalculate is false, avoid this and kill the listener
    if (!this.settings.penalty_autocalculate) {
      this.undelegate(
        "keyup",
        "refund[penalty_amount]",
        "penaltyAutoCalculate"
      );
      return false;
    }

    // if we are going to autoCalculate, lets grab the totalAmount
    let currentGlobalPenalty =
      typeof e === "number" ? e : $(e.currentTarget).val();

    currentGlobalPenalty = parseFloat(currentGlobalPenalty || 0);

    // we are going to loop on the array and grap what we need in order to render each card penalty
    _.each(this.settings.refund, (refundItem, index) => {
      let cardPenalty =
        currentGlobalPenalty * (refundItem.amount / this.settings.total_amount);

      cardPenalty = this.roundHalfEven(cardPenalty, 2);
      let cardAmount = (refundItem.amount - cardPenalty).toFixed(2);

      if (ignorePenalty) {
        cardPenalty = 0;
        cardAmount = 0;
      }

      $("[name='" + refundItem.input_penalty + "']").val(cardPenalty);
      $("[name='" + refundItem.input_refund + "']").val(cardAmount);
    });
  },
  resetValue: function() {
    let vendorCreditIssue = this.$('[name="refund[issue_card]"]:checked').val();
    let commissionChanging = this.$(
      '[name="refund[commission_changing]"]:checked'
    ).val();

    let resetInputs = this.$("[data-original-value]");

    resetInputs.parent().removeClass("input-has-icon icon-readonly");

    let summaryPenalty = $('[name="refund[penalty_amount]"]');

    if (!parseInt(vendorCreditIssue) && parseInt(commissionChanging)) {
      summaryPenalty.val(0);

      this.penaltyAutoCalculate(parseFloat(summaryPenalty.val()), true);
      resetInputs.val("0.00");
      resetInputs
        .attr("readonly", true)
        .parent()
        .addClass("input-has-icon icon-readonly");
    } else if (
      typeof vendorCreditIssue != "undefined" &&
      typeof commissionChanging != "undefined"
    ) {
      // resetInputs.removeAttr("readonly");
      $.each(resetInputs, function(index, field) {
        let currentField = $(field);
        currentField.val(currentField.data("originalValue"));
      });

      this.penaltyAutoCalculate(parseFloat(summaryPenalty.val()));
    }
  },
  initialize: function() {
    this.settings = {};

    // we gather settings to check any settings
    // needed for penalty autoCalculation
    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
      this.$el.removeAttr("data-settings");
    }

    this.$el.loadComponents();
    this.$el.loadModules();
  }
});

module.exports = refundView;
