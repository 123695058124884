/*
 *   Manual Calculator Component
 */
var $ = require("jquery");

var ManualCalculator = Backbone.View.extend({
  events: {
    "click [data-field-editable]": "showField",
    "click [data-field-actions]": "changeValue",
    "keypress [data-field-input]": "changeValue",
    "keyup [data-field-input]": "disableEdit",
    "fieldChanged [data-field-row]": "updateSubTotal",
    "totalUpdated [data-field-row]": "updateSubTotal"
  },
  showField: function(el) {
    this.$("[data-field]").removeClass("is-active");
    var field = $(el.currentTarget).parents("[data-field]");

    field.addClass("is-active");
    this.render(el);
  },
  changeValue: function(el) {
    var self = $(el.currentTarget);
    var status = self.data("field-actions");
    var field = self.parents("[data-field]");
    var fieldInputAmount = parseFloat(field.find("[data-field-input]").val());
    var fieldAmount = field.find("[data-field-value]");
    var fieldAmountHidden = field.find("[data-field-hidden-value]");

    if (status == "confirm" || el.keyCode == 13) {
      el.preventDefault();
      fieldAmount.text(
        fieldInputAmount.toLocaleString("en-US", {
          style: "decimal",
          minimumFractionDigits: 2
        })
      );
      fieldAmountHidden.val(
        fieldInputAmount.toLocaleString("en-US", {
          style: "decimal",
          minimumFractionDigits: 2
        })
      );
      self.parents("[data-field-row]").trigger("fieldChanged");
      field.removeClass("is-active");
    } else if (status == "cancel") {
      field.removeClass("is-active");
    }
  },
  disableEdit: function(el) {
    var self = $(el.currentTarget);
    var field = self.parents("[data-field]");

    if (el.keyCode == 27) {
      field.removeClass("is-active");
    }
  },
  updateSubTotal: function(el) {
    var self = $(el.currentTarget);
    var total = 0;

    self.find("[data-field-value]").each(
      function(i, el) {
        total = total + parseFloat(" " + $(el).text());
      }.bind(this)
    );

    self.find("[data-field-subtotal]").text(
      total.toLocaleString("en-US", {
        style: "decimal",
        minimumFractionDigits: 2
      })
    );
    this.updateTotal();
  },
  updateTotal: function() {
    var total = 0;

    this.$("[data-field-subtotal]").each(
      function(i, el) {
        total += parseFloat("0" + $(el).text());
      }.bind(this)
    );

    this.$("[data-field-total]").text(
      total.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      })
    );
  },
  render: function(el) {
    var self = $(el.currentTarget);
    var amount = self.find("[data-field-value]").html();
    this.$(el.currentTarget)
      .parents("[data-field]")
      .find("[data-field-wrapper]")
      .html(
        this.template({
          value: amount
        })
      );
    $(el.currentTarget)
      .parents("[data-field]")
      .find("[data-field-input]")
      .focus()
      .select();
  },
  initialize: function() {
    this.template = Handlebars.compile(
      this.$('[data-template="field-editable-actions"]').html()
    );
  }
});

module.exports = ManualCalculator;
