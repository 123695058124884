var $ = require("jquery");

var ExternalReviews = Backbone.View.extend({
  getBazaarvoiceProductIds: function() {
    var productIds = [];

    $("[data-bv-product-id]").each(function() {
      productIds.push($(this).attr("data-bv-product-id"));
    });

    productIds = _.uniq(productIds);

    return productIds;
  },

  checkBazaarvoiceReviews: function() {
    var productIds = this.getBazaarvoiceProductIds();

    if (!productIds.length) {
      return;
    }

    var domain = "api.bazaarvoice.com";

    if (this.settings.isDev) {
      domain = "stg.api.bazaarvoice.com";
    }

    $.ajax({
      type: "GET",
      dataType: "json",
      cache: false,
      url:
        "https://" +
        domain +
        "/data/statistics.json?apiversion=5.4&passkey=" +
        this.settings.bazaarvoice +
        "&stats=Reviews&filter=productid:" +
        productIds.join(","),

      success: response => {
        _.each(response.Results, function(result) {
          if (result.ProductStatistics.ReviewStatistics.TotalReviewCount > 0) {
            let currentItem = $(
              '[data-external-reviews-bv-el="' +
                result.ProductStatistics.ProductId +
                '"]'
            );
            currentItem.removeClass("is-hidden");

            currentItem
              .parents("[data-results-item-wrapper]")
              .addClass("has-reviews");
          }
        });

        return true;
      },

      error: () => {
        return false;
      }
    });
  },

  initialize: function() {
    this.settings = this.$el.data("settings");

    if (this.settings.bazaarvoice) {
      this.checkBazaarvoiceReviews();
    }

    this.$el.loadComponents();
    this.$el.loadModules();
  }
});

module.exports = ExternalReviews;
