/**
 * TODO:
 */

var Countdown = Backbone.View.extend({
  initialize: function() {
    this.reflow();
    this.onUpdate((total = 0));
  },
  rgbToHex: function(rgb) {
    rgb = rgb.match(
      /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
    );
    rgb =
      rgb && rgb.length === 4
        ? "#" +
          ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2)
        : "";
    return rgb.toUpperCase();
  },
  reflow: function() {
    this.settings = this.settings || {};

    this.colors = {
      border: this.rgbToHex(this.$el.css("border-top-color")),
      borderBackground: this.rgbToHex(this.$el.css("border-right-color")),
      doneBackground: this.rgbToHex(this.$el.css("border-bottom-color")),
      number: this.rgbToHex(this.$el.css("border-left-color"))
    };

    var defaults = {
      countdown: 0,
      time: {
        Seconds: {
          text: "Seconds",
          color: this.colors.border,
          show: true
        },
        Minutes: {
          text: "Minutes",
          color: this.colors.border,
          show: true
        },
        Days: {
          show: true,
          color: this.colors.border,
          text: "Days"
        },
        Hours: {
          show: true,
          color: this.colors.border,
          text: "Hours"
        }
      },
      count_past_zero: false,
      circle_bg_color: this.colors.borderBackground,
      use_background: true,
      fg_width: 0.1,
      text_size: 0.12,
      number_size: 1,
      total_duration: "Auto",
      direction: "Clockwise"
    };

    let time = 0;

    if (this.$el.data("settings")) {
      this.settings = $.extend(
        true,
        defaults,
        this.settings,
        this.$el.data("settings")
      );

      time = this.settings.countdown;
    } else {
      var elData = this.$el.data();

      this.settings = _.extend(defaults, elData);

      time = elData["countdown"];

      if (elData["countdownIncludeSeconds"]) {
        this.settings.time.Seconds = _.extend(
          this.settings.time.Seconds,
          elData["countdownIncludeSeconds"]
        );
      }

      if (elData["countdownIncludeMinutes"]) {
        this.settings.time.Minutes = _.extend(
          this.settings.time.Minutes,
          elData["countdownIncludeMinutes"]
        );
      }

      if (elData["countdownIncludeHours"]) {
        this.settings.time.Hours = _.extend(
          this.settings.time.Hours,
          elData["countdownIncludeHours"]
        );
      }

      if (elData["countdownIncludeDays"]) {
        this.settings.time.Days = _.extend(
          this.settings.time.Days,
          elData["countdownIncludeDays"]
        );
      }
    }

    var placeholder = this.$("[data-countdown-placeholder]");

    // Set Countdown Container
    this.$countdown = placeholder.length ? placeholder : this.$el;

    if (time) {
      this.$countdown.data("timer", time);
    }

    // Pass in default settings
    this.$countdown
      .TimeCircles(this.settings)
      .addListener(this.onUpdate.bind(this));

    return this;
  },
  onUpdate: function(unit, value, total) {
    total = parseInt(total || this.settings.countdown);

    if (total < 1) {
      $("body").addClass("countdown-is-done");

      this.$el.removeClass("is-counting").addClass("is-done");
      this.$countdown
        .TimeCircles({
          circle_bg_color: this.colors.doneBackground,
          time: {
            Seconds: { color: this.colors.doneBackground },
            Minutes: { color: this.colors.doneBackground },
            Hours: { color: this.colors.doneBackground },
            Days: { color: this.colors.doneBackground }
          }
        })
        .rebuild();

      //If countdown has a redirect
      var redirectUrl = this.$el.data("countdown-redirect");
      if (redirectUrl) {
        $(location).attr("href", redirectUrl);
      }
    } else if (!this.$el.hasClass("is-counting")) {
      this.$el.addClass("is-counting");
    }
  }
});

module.exports = Countdown;
