var CabinOptionView = Backbone.View.extend({
  /**
   * The internal event listeners for the view.
   */
  events: {
    "click [data-option]": "processCabinOption",
    "click [data-cabin-option-details-trigger]": "displayCabinOptionDetails",
    "click [data-promo-details-trigger]": "displayPromoDetails"
  },

  settings: {},

  displayCabinOptionDetails: function(event) {
    var url = $(event.currentTarget).data("cabin-option-details-trigger");

    var Dialog = this.containers.cabinOptionDetails
      .data("dialog-async", url)
      .component();

    Dialog.reflow().open();
  },

  displayPromoDetails: function(event) {
    var content = this.templates.promoDetails({
      versionId: $(event.currentTarget).data("promo-details-trigger")
    });

    var Dialog = this.containers.promoDetails.html(content).component();

    Dialog.reflow().open();
  },

  processCabinOption: function(event) {
    var option = $(event.currentTarget);

    var optionData = option.data();
    var optionCode = optionData.optionCode;
    var setId = optionData.setId.toString();
    var paxIndex = optionData.passengerIndex;
    var paxOptions = this.$('input[data-option-code="' + optionCode + '"]');

    var setInfo = this.collection.findWhere({ id: setId });

    // check if selection is required for all pax
    if (setInfo.get("is_selectable_by_passenger") == false) {
      paxOptions.each(function() {
        this.checked = option.is(":checked");
      });
    }

    // check if selection is limited
    if (
      setInfo.get("maximum_number_per_passenger") > 0 &&
      option.attr("type") == "checkbox"
    ) {
      var countSelected = this.$(
        'input[data-set-id="' +
          setId +
          '"][data-passenger-index="' +
          paxIndex +
          '"]:checked'
      ).length;
      var setOptions = this.$(
        'input[data-set-id="' +
          setId +
          '"][data-passenger-index="' +
          paxIndex +
          '"]'
      );

      setOptions.each(function() {
        var self = $(this);

        if (countSelected < setInfo.get("maximum_number_per_passenger")) {
          if (!self.is(":checked")) {
            self.prop("disabled", false);
          }
        } else {
          if (!self.is(":checked")) {
            self.prop("disabled", true);
          }
        }
      });
    }
  },

  /**
   * This method initializes the view
   */
  initialize: function() {
    this.containers = {
      cabinOptionDetails: null,
      promoDetails: null
    };

    this.templates = {
      promoDetails: Handlebars.compile(
        this.$('[data-template="promo-details"]').html() || ""
      )
    };

    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
    }

    if (this.settings.optionSets) {
      this.collection = new Backbone.Collection(this.settings.optionSets);
    }

    this.containers.cabinOptionDetails = this.$(
      "[data-cabin-option-details-modal]"
    );
    this.containers.promoDetails = this.$("[data-promo-details-modal]");
  }
});

module.exports = CabinOptionView;
