var $ = require("jquery");

var CountryState = Backbone.View.extend({
  /**
   * This method returns the selected attr if the selected setting matches the supplied code or name
   *
   * @param code
   * @param name
   */
  isSelectedOption: function(code, name, customDropdown) {
    var selected = !customDropdown ? "selected" : "data-dropdown-selected";
    return this.model.attributes.country ==
      this.model.settings.selected.country &&
      (code == this.model.settings.selected.state ||
        name == this.model.settings.selected.state)
      ? selected
      : "";
  },

  /**
   * This method processes the selection of country.
   */
  processCountrySelection: function(e) {
    if ($(e.currentTarget).val()) {
      this.fetchStates($(e.currentTarget).val());
    } else {
      this.model.set("country", null);
      this.stateDropdown.reflow();
    }

    if (this.settings.hasStateInput) {
      this.$("[data-country-state-input] input").focus();
    } else {
      this.$("[data-country-state-select] select").focus();
    }
  },

  setDuplicateData: function(data) {
    var settings = this.model.get("settings");

    settings.selected = data;

    this.model.set("settings", settings);

    this.stateDropdown.reflow();
  },

  getDuplicateData: function() {
    return {
      country: this.model.get("country"),
      state: this.stateDropdown.getState()
    };
  },

  /**
   * This method loads the states.
   *
   * @param country
   */
  fetchStates: function(country) {
    this.model.set("country", country);

    $.ajax({
      type: "GET",
      url: this.settings.url,
      dataType: "json",
      cache: false,
      data: {
        country: country
      },

      beforeSend: () => {
        this.stateDropdown.beforeSend();
      },

      success: response => {
        this.stateDropdown.reflow(response.data.states);
      },

      error: () => {
        this.stateDropdown.reflow();
      }
    });
  },
  initialize: function() {
    this.settings = {
      name: "",
      url: "",
      text: {
        loading: "Loading...",
        noCountry: "Select a Country",
        placeholder: "Select State",
        none: "None"
      },
      selected: {
        country: "",
        state: ""
      },
      hasStateInput: this.$("[data-country-state-input]").length ? true : false,
      stateDefaultEmpty: false
    };
    //Retrieving data attributes
    var data = this.$el.data();

    // update base settings if settings were defined on the element
    if (data.countryStateSettings) {
      this.settings = $.extend(
        true,
        this.settings,
        eval(data.countryStateSettings)
      );
    }

    //Creating model and passing settings
    this.model = new CountryStateModel({
      settings: this.settings
    });

    //Creating view child(depending of the dropdown component) and passing CountryStateModel model
    var initialization = {
      model: this.model,
      el: this.$el
    };
    this.stateDropdown = new CountryStateHTMLSelect(initialization);

    //Getting country selector and component
    var countrySelector =
      '[data-country-state-country="' + this.settings.name + '"]';
    var countryDropdown = $(countrySelector);

    // fetch states if there is already a selected coutry
    countryDropdown.val()
      ? this.fetchStates(countryDropdown.val())
      : this.stateDropdown.reflow();

    // register a listener for the associated country select
    //todo change this to use namespace by using view id
    $(document).on(
      "change",
      countrySelector,
      this.processCountrySelection.bind(this)
    );

    this.currentState = this.stateDropdown.getState();
  },

  selectState: function(country, state) {
    var settings = this.model.get("settings");

    settings.selected.country = country;
    settings.selected.state = state;

    this.model.set("settings", settings);

    this.stateDropdown.reflow();
  }
});

var CountryStateModel = Backbone.Model.extend({
  initialize: function(args) {
    this.settings = args.settings;
  }
});

var CountryStateHTMLSelect = CountryState.extend({
  initialize: function() {
    this.stateComponent = this.$el.find("[data-country-state-select]");
    this.selectComponent =
      this.stateComponent.find("select").length > 0
        ? this.stateComponent.find("select")
        : this.stateComponent.find("[data-dropdown-options-wrapper]");

    //Create a template with the right tags, values and attributes depending if it's a custom or native dropdown
    if (
      this.selectComponent.length > 0 &&
      this.selectComponent[0].tagName == "SELECT"
    ) {
      this.template = {
        tag: "option",
        value: " value",
        name: function(value) {
          return "";
        },
        class: "",
        href: "",
        isCustom: false
      };
    } else {
      this.template = {
        tag: "a",
        value: " data-dropdown-value",
        name: function(value) {
          return ' data-dropdown-name="' + value + '"';
        },
        class: ' class="dropdown-rvlx-options-item"',
        href: ' href="javascript:void(0)"',
        isCustom: true
      };
    }

    this.stateInputContainer = this.$el.find("[data-country-state-input]");
    this.stateInput = this.stateInputContainer.find("input");
    if (this.stateInput.attr("name") !== undefined) {
      this.stateInputName = this.stateInput.attr("name");
    } else {
      this.stateInputName = this.selectComponent.attr("name");
    }
    return this;
  },
  reflow: function(data) {
    var states = data || [];

    //If component wasn't ready on initialize we load it again
    if (this.selectComponent.length === 0) {
      this.selectComponent =
        this.stateComponent.find("select").length > 0
          ? this.stateComponent.find("select")
          : this.stateComponent.find("[data-dropdown-options-wrapper]");
    }

    if (states.length) {
      var self = this;

      // loop and append states to the select
      // empty the select and add the placeholder option
      self.selectComponent.html(
        "<" +
          this.template.tag +
          this.template.href +
          this.template.class +
          this.template.name("") +
          this.template.value +
          (this.model.settings.stateDefaultEmpty ? '="">' : '="-1">') +
          this.model.settings.text.placeholder +
          "</" +
          this.template.tag +
          ">"
      );

      _.each(
        states,
        function(data) {
          self.selectComponent.append(
            "<" +
              this.template.tag +
              this.template.href +
              this.template.class +
              this.template.name(data.name) +
              this.template.value +
              '="' +
              data.code +
              '"' +
              self.isSelectedOption(
                data.code,
                data.name,
                this.template.isCustom
              ) +
              ">" +
              data.name +
              "</" +
              this.template.tag +
              ">"
          );
        }.bind(this)
      );

      // remove the loading and disabled states
      this.stateComponent.removeClass("is-loading is-hidden");
      this.selectComponent.removeAttr("disabled");

      if (this.model.settings.hasStateInput) {
        this.stateComponent.removeClass("is-hidden");

        this.stateInputContainer.addClass("is-hidden");
        this.stateInput
          .val(null)
          .attr("disabled", true)
          .attr("name", "");
      }
    } else if (!this.model.attributes.country) {
      this.stateComponent.removeClass("is-hidden is-loading");
      this.selectComponent.html(
        "<" +
          this.template.tag +
          this.template.name(this.model.settings.text.noCountry) +
          this.template.value +
          '="">' +
          this.model.settings.text.noCountry +
          "</" +
          this.template.tag +
          ">"
      );

      this.stateInputContainer.addClass("is-hidden");
      this.stateInput.val(null);
    } else {
      this.stateComponent.removeClass("is-loading").addClass("is-hidden");
      this.selectComponent
        .html(
          "<" +
            this.template.tag +
            this.template.name(
              this.model.attributes.country
                ? this.model.settings.text.none
                : this.model.settings.text.noCountry
            ) +
            this.template.value +
            '="">' +
            (this.model.attributes.country
              ? this.model.settings.text.none
              : this.model.settings.text.noCountry) +
            "</" +
            this.template.tag +
            ">"
        )
        .attr("disabled", true);

      if (this.model.settings.hasStateInput) {
        this.selectComponent.attr("disabled", true);

        this.stateInputContainer.removeClass("is-hidden is-loading");
        this.stateInput
          .attr("disabled", this.model.attributes.country ? false : true)
          .attr("name", this.stateInputName)
          .val(
            this.isSelectedOption(
              this.model.settings.selected.state,
              this.model.settings.selected.state
            )
              ? this.model.settings.selected.state
              : ""
          );
      }
    }
    this.$("[data-country-state-select]").trigger("states-updated");

    //Change value if it's custom dropdown
    if (this.template.isCustom) {
      this.$("[data-country-state-select]")
        .component()
        .initialize();
    }

    if (
      $('[data-country-state-country="' + this.model.settings.name + '"]').is(
        ":disabled"
      )
    ) {
      this.selectComponent.attr("disabled", true);
      this.stateInput.attr("disabled", true);
    }
  },
  beforeSend: function() {
    // disable and set loading states for the select and input
    this.stateComponent.addClass("is-loading");
    this.selectComponent
      .html(
        "<" +
          this.template.tag +
          this.template.name(this.model.settings.text.loading) +
          this.template.value +
          '="">' +
          this.model.settings.text.loading +
          "</" +
          this.template.tag +
          ">"
      )
      .attr("disabled", true);

    this.stateInputContainer.addClass("is-loading");
    this.stateInput.empty().attr("disabled", true);

    this.$("[data-country-state-select]").trigger("states-loading");
  },

  getState: function() {
    if (this.stateInput.is(":visible")) {
      return this.stateInput.val();
    } else {
      return this.selectComponent.val();
    }
  }
});

module.exports = CountryState;
