const AdyenCheckout = require("@adyen/adyen-web");

/**
 * Payment External Authentication
 *
 * Explora Journeys ADYEN - Drop in solution
 * https://docs.adyen.com/online-payments/web-drop-in/integrated-before-5-0-0
 *
 * This logic works after the review page is submitted, it depends on configuration
 */

// handling default value for Adyen Configuration
var AdyenConfiguration = Backbone.Model.extend({
  defaults: {
    paymentMethodsResponse: {}, // PaymentMethods available will be provided by backEnd.
    environment: "test", // Change to 'live' for the live environment.
    clientKey: false, // Public string key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
    locale: "en-US",
    onSubmit: (state, dropin) => {},
    onAdditionalDetails: (state, dropin) => {},
    onError: (error, component) => {}
  }
});

// Building view for Adyen Widget
var AuthenticationAdyen = Backbone.View.extend({
  createAdyenCheckout: async function(paymentMethodsResponse, clientKey) {
    // cloning model
    this.currentConfiguration = new AdyenConfiguration();

    // setting up configuration and callbacks to render module
    this.currentConfiguration.set({
      clientKey,
      paymentMethodsResponse: paymentMethodsResponse,
      onSubmit: (state, dropin) => {
        // handling response via parent
        this.dispatcher.handleMerchantResponse(state.data);
      },
      onAdditionalDetails: (state, dropin) => {},
      onError: error => {
        this.onPaymentError(error);
      }
    });

    this.currentConfiguration.set(this.currentSettings);

    let currentSession = this.currentConfiguration.get("session")
      ? this.currentConfiguration.get("session")
      : this.currentSettings.session;

    this.currentConfiguration.set("session", currentSession);

    return new AdyenCheckout(this.currentConfiguration.attributes);
  },

  paymentDropInit: async function() {
    // loading required CSS for the widget
    require("@adyen/adyen-web/dist/adyen.css");

    // Create an instance of AdyenCheckout using the configuration object.
    const checkout = await this.createAdyenCheckout();

    // Create an instance of Drop-in and mount it to the container you created.
    const dropinComponent = checkout
      .create("dropin")
      .mount("#dropin-container");
  },

  // @TODO Gathering data from the webhook and processing it for backend
  finalizePaymentCheckout: async function() {},

  // Processing error
  onPaymentError: function(error, component) {
    // If there are any errors we display it on page
    error.hasErrors = true;
    this.dispatcher
      .$("[data-messages]")
      .html(this.dispatcher.templates.messagesTemplate(error));

    // we still process the data as backend will still need the information
    this.dispatcher.handleMerchantResponse(error, component);
  },
  initialize: function(options) {
    // sending object with element + settings and dispatcher

    // setting dispatcher to have access to all parent methods
    this.dispatcher = options.dispatcher;

    // setting global settings to have access to all parent methods
    this.currentSettings = options.settings;

    // init drop method for payment widget
    this.paymentDropInit().then(r => {});
  }
});

module.exports = AuthenticationAdyen;
