var $ = require("jquery");

var WalkMe = Backbone.View.extend({
  events: {
    "click [data-walkme-trigger]": "toggleWalkmeDisplay"
  },
  toggleWalkmeDisplay: function() {
    $(".walkme-text-launcher").click();
  },

  initialize: function() {
    var environment = this.$el.data("environment");
    var walkme = document.createElement("script");
    walkme.type = "text/javascript";
    walkme.async = true;
    walkme.src =
      "https://cdn.walkme.com/users/ea9a2ab35532423aa10c6c7567268298/" +
      environment +
      "walkme_ea9a2ab35532423aa10c6c7567268298_https.js";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(walkme, s);
    window._walkmeConfig = { smartLoad: true };

    window.walkme_event = function(eventData) {
      if (eventData.Type === "LauncherIsShown") {
        $("[data-walkme-wrapper]").removeClass("is-hidden");
        $("[data-walkme-trigger]").html(eventData.ExtraData.customText);
      }
    };

    this.$el.loadComponents();
  }
});

module.exports = WalkMe;
