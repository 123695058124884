var $ = require("jquery");

var AsyncSelect = Backbone.View.extend({
  getOptions: function() {
    var data = {};

    $('[data-async-select-field="' + this.id + '"]').each(function() {
      // make sure to only send data for radios and checkboxes that are checked
      if (
        ($(this).attr("type") === "radio" ||
          $(this).attr("type") === "checkbox") &&
        !$(this).is(":checked")
      ) {
        return;
      }

      data[$(this).data("async-select-field-name")] = $(this).val();

      // add data parameters into request
      if ($(this).data("async-select-field-data-params")) {
        let dataParams = $(this).data("async-select-field-data-params");

        _.each(dataParams, function(value, key) {
          data[key] = value;
        });
      }
    });

    return data;
  },

  sendRequest: function() {
    var self = this;

    $.ajax({
      type: "GET",
      dataType: "json",
      cache: true,
      url: this.url,
      data: this.getOptions(),

      beforeSend: function() {
        self.render({ isLoading: true }, false);
        self.$el.addClass("is-loading");
      },

      success: function(response) {
        var enable = true;

        if (!response.data.length) {
          response.data = null;
          enable = false;
        }

        self.render(response, enable);
        self.$el.removeClass("is-loading");
      },

      error: function() {
        self.render();
        self.$el.removeClass("is-loading");
      }
    });
  },

  render: function(data, enable) {
    this.$("select")
      .empty()
      .html(this.template(data));

    if (enable) {
      this.$("select").removeAttr("disabled", true);
    } else {
      this.$("select").attr("disabled");
    }
  },

  initialize: function() {
    this.id = this.$el.data("async-select-id");
    this.url = this.$el.data("async-select-url");
    this.template = Handlebars.compile(this.$("[data-template]").html() || "");

    $(document).on(
      "change",
      '[data-async-select-field="' + this.id + '"]',
      this.sendRequest.bind(this)
    );

    if (this.$el.data("async-select-auto-fetch")) {
      this.sendRequest();
    }
  }
});

module.exports = AsyncSelect;
