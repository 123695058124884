var $ = require("jquery");
var UpliftUtil = require("../../utilities/uplift");

var UpliftView = Backbone.View.extend({
  initialize: function() {
    this.settings = {
      agentInfo: {
        onChange: this.processOnChange.bind(this),
        sessionTokenCallback: UpliftUtil.processSessionTokenCallback.bind(this)
      }
    };

    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
    }

    console.log("init comp settings");
    console.log(this.settings);

    if (this.settings.isB2B) {
      UpliftUtil.loadB2B(this.settings.code);

      this.initB2B();
    } else {
      UpliftUtil.loadB2C(this.settings.code);

      this.initB2C();
    }

    this.$el.loadComponents();
    this.$el.loadModules();
  },

  initB2B: function() {
    console.log("comp b2b init");

    window.upReady = function() {
      console.log("comp b2b upready");
      console.log(this.settings.agentInfo);

      this.UpliftObj = UpliftUtil.initB2B(this.settings, false);

      if (this.settings.isConfirm) {
        this.processConfirmB2B();
      }
    }.bind(this);
  },

  initB2C: function() {
    console.log("b2c init");

    window.upReady = function() {
      console.log("b2c upready");
      this.UpliftObj = UpliftUtil.initB2C(this.settings, false);

      if (this.settings.isConfirm) {
        this.processConfirmB2C();
      }
    }.bind(this);
  },

  processOnChange: function(data) {
    console.log("comp onchange");
    console.log(data);
  },

  processConfirmB2B: function() {
    var tripId = UpliftUtil.getTripId(this.settings.id);

    if (tripId) {
      if (this.settings.isFailedPayment) {
        console.log("report payment error");
        this.UpliftObj.error(tripId, "payment", this.settings.messages);
      }

      if (this.settings.paymentMethodType === "uplift") {
        console.log("confirm trip");
        this.UpliftObj.confirmTrip(tripId, this.settings.confirmationNumbers);
      } else {
        console.log("cancel trip");

        this.UpliftObj.cancelTrip(tripId);
        UpliftUtil.removeTripId(tripId);
      }
    }
  },

  processConfirmB2C: function() {
    if (this.settings.isFailedPayment) {
      console.log("report payment error");
      this.UpliftObj.error(this.settings.messages, "payment");
    }

    if (this.settings.paymentMethodType === "uplift") {
      this.UpliftObj.confirm(this.settings.confirmationNumbers.join(","));
    }
  }
});

module.exports = UpliftView;
