const ToggleRequest = Backbone.View.extend({
  events: {
    "click [data-toggle-request]": "sendToggleRequest"
  },

  renderMessages: function(messages) {
    this.$('[data-messages="' + this.settings.id + '"]').html(
      this.templates.messages(messages)
    );
  },

  sendToggleRequest: function(event) {
    const currentEvent = $(event.currentTarget);
    const toggleUrl = currentEvent.data("toggle-request");

    let data = new Backbone.Model(this.settings.csrf);
    data.set(currentEvent.data("toggle-request-data"));

    this.sendRequest(toggleUrl, data.toJSON());
  },

  sendRequest: function(url, data) {
    $.ajax({
      type: "POST",
      dataType: "json",
      cache: false,
      url: url,
      data: data,

      beforeSend: () => {
        this.renderMessages({});

        this.block(this.$el);
      },

      success: response => {
        this.renderMessages(response.messages);

        // csrf tokens need to be updated for invalid response.
        if (response.csrf) {
          this.settings.csrf = response.csrf;
        }

        this.unblock(this.$el);

        if (response.success) {
          if (this.settings.refreshOnSuccess) {
            const contentLoaderParent = $('[data-component="content-loader"]');
            if (contentLoaderParent.length) {
              const $contentLoader = contentLoaderParent.component();

              $contentLoader.enforceRefresh = true;
              $contentLoader.loadContent();
            }
          }
        }

        return true;
      },

      error: () => {
        this.renderMessages({ ajaxFailure: true });
        this.unblock(this.$el);

        return false;
      }
    });
  },

  block: function(el) {
    $(el).addClass("is-loading");
  },

  unblock: function(el) {
    $(el).removeClass("is-loading");
  },

  initTemplates: function() {
    this.templates = {
      messages: Handlebars.compile(
        this.$('[data-messages-template="' + this.settings.id + '"]').html() ||
          ""
      )
    };
  },

  initialize: function() {
    this.settings = {
      id: "",
      csrf: null,
      refreshOnSuccess: null
    };

    if (this.$el.data("settings")) {
      this.settings = $.extend(
        true,
        this.settings,
        eval(this.$el.data("settings"))
      );
    }

    this.initTemplates();

    this.$el.loadComponents();
    this.$el.loadModules();
  }
});

module.exports = ToggleRequest;
