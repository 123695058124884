/**
 * input Merge
 **/

var inputValue = Backbone.Model.extend({
  defaults: {}
});

var inputMerge = Backbone.View.extend({
  events: {
    "change [data-input-group-input]": "setModel",
    "keyup [data-input-group-input]": "setModel"
  },
  initialize: function() {
    this.settings = {};
    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
    }

    this.selectionsData = new inputValue();
    this.inputGroup = this.$("[data-input-group-input]");

    //Loads Templates
    this.selectionsData.on("change", this.$el.renderItems.bind(this));

    this.$el.loadTemplates({
      data: this.selectionsData
    });

    this.setModel();
  },

  setModel: function() {
    let options = {};
    let hasEmptyValue = false;

    this.inputGroup.each((index, element) => {
      if ($(element).val().length <= 0) {
        hasEmptyValue = true;
      }

      options[$(element).attr("data-input-group-input")] = $(element).val();
    });

    // clear all values, if at least one value is empty
    if (hasEmptyValue && this.settings.ignoreEmptyValue) {
      this.inputGroup.each((index, element) => {
        options[$(element).attr("data-input-group-input")] = "";
      });
    }

    this.selectionsData.set(options);
  }
});

module.exports = inputMerge;
