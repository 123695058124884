var $ = require("jquery");

window.jQuery = $;

require("poshytip");
require("x-editable");
require("select2");

var value2inputOverride = function(value) {
  if (value !== "-1") {
    this.$input.val(value);
  }
};

var EditableFields = Backbone.View.extend({
  initialize: function() {
    require("../../../../../../build/node_modules/select2/dist/css/select2.min.css");
    require("../../../../../../build/node_modules/x-editable/dist/jquery-editable/css/jquery-editable.css");

    $.fn.editableform.buttons =
      '<button type="submit" class="button button-primary icon-check editable-submit">\n' +
      '<button type="button" class="button button-secondary icon-close editable-cancel"></button>';

    if (this.getSelect2Language()) {
      require("../../../../../../build/node_modules/select2/dist/js/i18n/" +
        this.getSelect2Language() +
        ".js");
    }

    $.fn.editabletypes.text.superclass.value2input = value2inputOverride;
    $.fn.editabletypes.email.superclass.value2input = value2inputOverride;
    $.fn.editabletypes.number.superclass.value2input = value2inputOverride;

    var self = this;
    var fieldsData = this.$el.data("settings");

    self.csrf = fieldsData.csrf;

    this.$("[data-editable-fields-field]").each(function(i, el) {
      var options = $(el).data("settings");

      var editableFieldSettings = self.buildEditableFieldSettings(
        fieldsData,
        options
      );

      if (options.type === "text") {
        editableFieldSettings = self.addTextSettings(
          options,
          editableFieldSettings
        );
      }

      if (options.type === "select2") {
        editableFieldSettings = self.addSelect2Settings(
          options,
          editableFieldSettings
        );
      }

      if (options.type === "airport") {
        editableFieldSettings = self.addAirportSettings(
          options,
          editableFieldSettings
        );
      }

      if (options.type === "number") {
        editableFieldSettings.min = 0;

        if (options.min) {
          editableFieldSettings.min = options.min;
        }

        if (options.max) {
          editableFieldSettings.max = options.max;
        }
      }

      switch (options.type) {
        case "text":
        case "url":
        case "number":
          editableFieldSettings.display = function(value) {
            if (value && value.toString() === "-1") {
              $(this).text("Do No Inherit");
            } else {
              $(this).text(value);
            }
          };
          break;

        case "select":
        case "bitmask":
          editableFieldSettings.display = function(value, sourceData) {
            if (
              (_.isArray(value) && _.indexOf(value, -1) !== -1) ||
              value === "-1"
            ) {
              $(this).text("Do No Inherit");
            } else {
              var html = [];
              var checked = $.fn.editableutils.itemsByValue(value, sourceData);

              if (checked.length) {
                $.each(checked, function(i, v) {
                  html.push($.fn.editableutils.escape(v.text));
                });

                $(this).html(html.join("<br>"));
              } else {
                $(this).empty();
              }
            }
          };
          break;
      }

      $(el).editable(editableFieldSettings);
    });

    this.$el.loadComponents();
    this.$el.loadModules();
  },

  getType: function(type) {
    switch (type) {
      case "bitmask":
        return "checklist";

      case "airport":
        return "select2";

      default:
        return type;
    }
  },

  buildEditableFieldSettings: function(fieldsData, fieldOptions) {
    var self = this;

    var sourceData = fieldOptions.source ? fieldOptions.source.data : null;

    return {
      emptytext: "--",
      savenochange: true,
      showbuttons: fieldOptions.type === "bitmask" ? "bottom" : true,
      disabled: fieldsData.disabled || false,
      mode: "inline",
      url: fieldsData.url,
      maxlength: fieldsData.maxlength,
      type: self.getType(fieldOptions.type),
      source: sourceData,
      title: fieldOptions.title,
      name: fieldOptions.name,
      value: fieldOptions.value,
      pk: fieldOptions.pk,

      params: function(params) {
        var data = params.pk;
        var arrayData = {};

        if (fieldOptions.type === "bitmask") {
          var val = 0;
          var disable_inheritance = false;

          for (x = 0; x < params.value.length; x++) {
            if (params.value[x] === "-1") {
              disable_inheritance = true;
              break;
            }

            val = val + parseInt(params.value[x]);
          }

          if (disable_inheritance) {
            data[params.name] = -1;
          } else {
            data[params.name] = val > 0 ? val : null;
          }
        } else if (
          fieldOptions.type === "checklist" ||
          fieldOptions.type === "select" ||
          fieldOptions.type === "select2"
        ) {
          data[params.name] = params.value ? params.value.toString() : null;
        } else {
          data[params.name] = params.value || null;
        }

        if (fieldsData.array) {
          arrayData[fieldsData.array] = data;
        }

        return _.extend(self.csrf, arrayData || data);
      },

      success: function(response) {
        self.csrf = response.csrf;

        if (!response.success) {
          var msg = "";

          msg += response.messages.fault.join("\n");
          msg += response.messages.general.join("\n");

          return msg;
        }

        Backbone.Events.trigger("loadingSplashOn");
        window.location.reload();
      },

      ajax: {
        processResults: function(data) {
          return {
            results: data
          };
        }
      }
    };
  },

  addTextSettings: function(options, editableFieldSettings) {
    var maxlength = options.maxlength || editableFieldSettings.maxlength;

    if (maxlength) {
      editableFieldSettings.tpl =
        '<input type="text" maxlength="' + maxlength + '">';
    }

    return editableFieldSettings;
  },

  addSelect2Settings: function(options, editableFieldSettings) {
    var maxSelection = options.maxSelection || 1;

    editableFieldSettings.select2 = {
      theme: "classic",
      placeholder: "Select",
      allowClear: true,
      maximumSelectionLength: maxSelection,
      multiple: maxSelection > 1,
      minimumResultsForSearch: 20,
      width: "300px",
      dropdownParent: ".editable-inline"
    };

    editableFieldSettings.tpl =
      maxSelection > 1 ? "<select multiple>" : "<select>";

    if (this.getSelect2Language()) {
      editableFieldSettings.select2.language = this.getSelect2Language();
    }

    if (options.value) {
      editableFieldSettings.value = options.value.toString().split(",");
    }

    if (options.source) {
      var sourceData = options.source.data ? options.source.data : [];

      if (sourceData.length > 20) {
        editableFieldSettings.select2.minimumInputLength = 3;
      }
    }

    if (options.sourceUrl) {
      editableFieldSettings.select2.minimumInputLength = 3;
      editableFieldSettings.source = null;
      editableFieldSettings.sourceCache = false;

      editableFieldSettings.select2.ajax = {
        type: "GET",
        dataType: "json",
        url: options.sourceUrl,
        cache: false,
        delay: 250,

        processResults: function(data) {
          return {
            results: data
          };
        }
      };
    }

    editableFieldSettings.display = function(value) {
      if (_.isArray(value) && _.indexOf(value, "-1") !== -1) {
        $(this).text("Do Not Inherit");
      } else {
        $(this).text(value);
      }
    };

    return editableFieldSettings;
  },

  addAirportSettings: function(options, editableFieldSettings) {
    var self = this;

    editableFieldSettings = this.addSelect2Settings(
      options,
      editableFieldSettings
    );

    editableFieldSettings.select2.ajax.data = function(params) {
      params.airport_lookup = params.term;

      return params;
    };

    editableFieldSettings.select2.ajax.processResults = function(data) {
      return {
        results: $.map(data.data.airports, function(obj) {
          return self.formatAirport(obj);
        })
      };
    };

    return editableFieldSettings;
  },

  formatAirport: function(obj) {
    return {
      id: obj.code,
      value: obj.code,
      text:
        obj.description +
        " (" +
        obj.code +
        ")" +
        (obj.city ? ", " + obj.city : "") +
        (obj.state ? ", " + obj.state : "") +
        ", " +
        obj.country,
      name:
        obj.description +
        " (" +
        obj.code +
        ")" +
        (obj.city ? ", " + obj.city : "") +
        (obj.state ? ", " + obj.state : "") +
        ", " +
        obj.country
    };
  },

  getSelect2Language: function() {
    if (REVELEX.settings.currentLocale.length) {
      switch (REVELEX.settings.currentLocale) {
        case "pt_BR":
          return "pt-BR";

        case "zh_CN":
          return "zh-CN";

        case "zh_TW":
          return "zh-TW";

        default:
          return REVELEX.settings.currentLocale.slice(0, 2);
      }
    }

    return null;
  }
});

module.exports = EditableFields;
