require("jstree");

var treeContainerEl = '<div class="tree-container" data-tree-container></div>';
var inputContainerEl = '<div class="tree-inputs" data-tree-inputs></div>';
var searchContainerEl = '<div class="input-has-icon icon-search"></div>';
var searchInputEl = '<input type="text" data-tree-search>';
var clearSearchEl =
  '<a href="javascript:void(0)" class="icon icon-close is-hidden" data-tree-clear-search></a>';

var Tree = Backbone.View.extend({
  events: {
    "click [data-tree-clear-search]": "clearSearch"
  },

  initialize: function() {
    var self = this;

    this.settings = {
      typeInputNames: {}
    };

    if (this.$el.data("settings")) {
      this.settings = $.extend(
        true,
        this.settings,
        eval(this.$el.data("settings"))
      );
    }

    this.$el.append(treeContainerEl);

    this.tree = this.$("[data-tree-container]");

    var options = {
      core: {
        expand_selected_onload: true,
        strings: {
          "Loading ...": ""
        }
      },
      plugins: ["types"],
      types: {
        agency: {
          icon: "icon-org-unit",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.agency ||
              this.settings.typeInputNames.all
          }
        },
        site: {
          icon: "icon-website",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.site ||
              this.settings.typeInputNames.all
          }
        },
        cruise: {
          icon: "icon-cruise",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.cruise ||
              this.settings.typeInputNames.all
          }
        },
        river_cruise: {
          icon: "icon-river-cruise",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.river_cruise ||
              this.settings.typeInputNames.all
          }
        },
        managed_product: {
          icon: "icon-managed-product",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.managed_product ||
              this.settings.typeInputNames.all
          }
        },
        air: {
          icon: "icon-air",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.air ||
              this.settings.typeInputNames.all
          }
        },
        car: {
          icon: "icon-car",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.car ||
              this.settings.typeInputNames.all
          }
        },
        hotel: {
          icon: "icon-hotel",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.hotel ||
              this.settings.typeInputNames.all
          }
        },
        generic: {
          icon: "icon-generic",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.generic ||
              this.settings.typeInputNames.all
          }
        },
        insurance: {
          icon: "icon-insurance",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.insurance ||
              this.settings.typeInputNames.all
          }
        },
        activity: {
          icon: "icon-activity",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.activity ||
              this.settings.typeInputNames.all
          }
        },
        dynamic_package: {
          icon: "icon-dynamic-package",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.dynamic_package ||
              this.settings.typeInputNames.all
          }
        },
        guided_vacation: {
          icon: "icon-guided-vacation",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.guided_vacation ||
              this.settings.typeInputNames.all
          }
        },
        fee: {
          icon: "icon-fee",
          li_attr: {
            "data-tree-input-name":
              this.settings.typeInputNames.fee ||
              this.settings.typeInputNames.all
          }
        }
      }
    };

    if (this.settings.data) {
      options.core.data = this.settings.data;
    }

    if (this.settings.allowSearch) {
      this.searchEl = $(searchContainerEl);
      this.searchInputEl = $(searchInputEl);
      this.clearSearchEl = $(clearSearchEl);

      this.searchEl.append(this.searchInputEl);
      this.searchEl.append(this.clearSearchEl);

      this.$el.prepend(this.searchEl);

      options.plugins.push("search");
      options.search = {
        show_only_matches: true,
        close_opened_onclear: true
      };
    }

    if (this.settings.includeInputs) {
      this.inputsContainer = $(inputContainerEl);

      this.$el.append(this.inputsContainer);

      options.plugins.push("checkbox");
      options.checkbox = {
        whole_node: true,
        three_state: false,
        keep_selected_style: false
      };

      this.tree.on("changed.jstree", function(e, data) {
        self.inputsContainer.empty();

        if (self.settings.isCSV) {
          var inputs = {};

          _.each(self.settings.typeInputNames, function(name) {
            inputs[name] = [];
          });

          _.each(data.selected, function(id) {
            var el = $(self.$('[id="' + id + '"]')[0]);

            if (el.data("treeInputName")) {
              inputs[el.data("treeInputName")].push(id);
            }
          });

          for (x in inputs) {
            if (inputs[x].length) {
              self.inputsContainer.append(
                '<input type="hidden" name="' +
                  x +
                  '" value="' +
                  inputs[x].join(",") +
                  '">'
              );
            }
          }
        } else {
          _.each(data.selected, function(id) {
            var el = $(self.$('[id="' + id + '"]')[0]);

            if (el.data("treeInputName")) {
              self.inputsContainer.append(
                '<input type="hidden" name="' +
                  el.data("treeInputName") +
                  '" value="' +
                  id +
                  '">'
              );
            }
          });
        }
      });
    }

    this.tree.jstree(options);

    var to = false;

    this.$("[data-tree-search]").keyup(function() {
      if (self.searchInputEl.val().length) {
        self.clearSearchEl.removeClass("is-hidden");
        self.searchEl.removeClass("icon-search");
      } else {
        self.clearSearchEl.addClass("is-hidden");
        self.searchEl.addClass("icon-search");
      }

      if (to) {
        clearTimeout(to);
      }
      to = setTimeout(function() {
        var v = self.searchInputEl.val();
        self.tree.jstree(true).search(v);
      }, 250);
    });
  },

  clearSearch: function() {
    this.searchInputEl.focus().val("");
    this.searchInputEl.trigger("keyup");
  }
});

module.exports = Tree;
