/**
 * Field Validators Library
 * date         : 7/25/2016
 * description  : Meant to work with Parsley.
 *
 * Documentation regarding creating custom validators can be found here:
 * http://parsleyjs.org/doc/index.html#custom
 *
 * Once declared, custom validators can be invoked using data attributes inside
 * form components. IE:
 *
 *  <input type="text" data-validate-phone-us required />
 */

var postalCodeRegex = require("./regex/postalCodesByCountry");
var veciCity = require("./regex/veci-city");

var ValidatorsLibrary = {
  standard: {},
  async: {}
};

ValidatorsLibrary.standard = {
  phoneUs: {
    // Requirement Type can be string | number | integer | regexp | boolean
    requirementType: "string",

    // Validating Function can be validateString | validateNumber | validateMultiple
    validateString: function(value) {
      // Expected format = (999) 999 9999
      // Remove special characters and validate length of 10.
      return value.replace(/_| |\(|\)/g, "").length == 10;
    },
    messages: {
      en: "This value needs to be a valid phone number."
    }
  },

  atLeastOne: {
    requirementType: "string",
    validateString: function(value, req, instance) {
      var output = false;

      // Gets all the Parsley Fields from the current form and extracts the ones
      // that use this validator with the same requirement string
      var siblings = _.filter(
        instance.parent.fields,
        function(el) {
          return el.$element.data("validate-at-least-one") == req;
        }.bind(this)
      );

      // Split requirement string, supported as multiple as comma separated
      var requirements = req.split(",");

      _.each(
        siblings,
        function(el) {
          // Run value comparison through each field for each requirement
          _.each(
            requirements,
            function(requirement) {
              if (el.$element.val() == requirement) {
                output = true;
              }
            }.bind(this)
          );
        }.bind(this)
      );

      if (output) {
        // Find all the fields that follow the same rule with the same requirement excluding self element
        var otherSiblings = _.reject(
          siblings,
          function(s) {
            return s.$element.is(instance.$element);
          }.bind(this)
        );

        // Remove errors for the ones that have validation messages
        _.each(otherSiblings, function(el) {
          // Following our standard form markup, find the .form-field container
          // Added parent() as fallback.
          var field =
            el.$element.parents(".form-field") || el.$element.parent();

          // For the fields that have already been validated, reset UI (ref. Parsley Docs).
          if (field.hasClass("is-invalid") || field.hasClass("is-valid")) {
            el.reset();
          }
        });
      }

      return output;
    },
    messages: {
      en: "There must be at least one <span>%s</span>."
    }
  },

  unique: {
    requirementType: "string",
    validateString: function(value, req, instance) {
      let output = true;

      // Gets all the Parsley Fields from the current form and extracts the ones
      // that use this validator with the same requirement string
      let siblings = _.filter(
        instance.parent.fields,
        function(el) {
          return el.$element.data("validate-unique") === req;
        }.bind(this)
      );

      let uniqueFields = [];
      _.each(
        siblings,
        function(el, i) {
          // check if we have duplicate values
          if (
            uniqueFields.indexOf(el.$element.val()) >= 0 &&
            uniqueFields.length > 0
          ) {
            // invalidating if value found in array and if array has items
            output = false;
          }

          if (el.$element.val().length > 0) {
            uniqueFields.push(el.$element.val());
          }
        }.bind(this)
      );

      // invalidating if array has no items
      if (uniqueFields.length === 0) {
        output = false;
      }

      return output;
    },
    messages: {
      en: "The value need to be unique."
    }
  },

  postalCode: {
    requirementType: "string",
    validateString: function(value, req, instance) {
      // find the country field tied to this postal code field
      var country = _.filter(
        instance.parent.fields,
        function(el) {
          return el.$element.attr("id") === req;
        }.bind(this)
      )[0];

      // fail if no country found
      if (!country) {
        return false;
      }

      // pass if there is no selected country
      if (!country.$element.val()) {
        return true;
      }

      // get the postal code regex for the country
      var regex = postalCodeRegex(country.$element.val());

      // fail if no regex found
      if (!regex) {
        return false;
      }

      // validate the code using the regex defined for the country
      return regex.test(value);
    }
  },

  veciCity: {
    requirementType: "string",
    validateString: function(value, req, instance) {
      // find the country field tied to this postal code field
      var country = _.filter(
        instance.parent.fields,
        function(el) {
          return el.$element.attr("id") === req;
        }.bind(this)
      )[0];

      // fail if no country found
      if (!country) {
        return false;
      }

      // pass if there is no selected country
      if (!country.$element.val()) {
        return true;
      }

      // get the postal code regex for the country
      var regex = veciCity(country.$element.val());

      // return true since veci wants validation only on specific countries
      if (!regex) {
        return true;
      }

      // validate the code using the regex defined for the country
      return regex.test(value);
    }
  },

  string: {
    requirementType: "string",
    validateString: function(value, req) {
      switch (req) {
        case "address":
          var regex = /^[0-9a-zA-ZÀÂÄÁàâäáÖÓöóÈÉÊËèéêëÇçÎÏÍîïíÜÚüúÑñ'\s\-.,]+$/g;
          break;

        case "person_name":
          var regex = /^[A-Za-z\s-'àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]+$/g;
          break;

        case "string":
          var regex = /^[A-Za-z\s\d\-+.,'"?=()àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]+$/g;
          break;

        case "free_text":
          var regex = /^[\w\s\d${}()|[\]\\\/\-+*.,'"?=àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]+$/g;
          break;

        case "username":
          var regex = /^[A-Za-z0-9_@.-]+$/g;
          break;

        case "hosted_agent_token":
          var regex = /^[A-Za-z0-9_]+$/g;
          break;

        case "letters_numbers_only":
          var regex = /^[A-Za-z0-9]+$/g;
          break;

        case "float":
          var regex = /^[0-9]+(\.[0-9]+)?$/g;
          break;

        case "natural_number":
          var regex = /^[0-9]+$/g;
          break;

        case "phone_number":
          var regex = /^\+?\d+$/g;
          break;

        case "email_cc_bcc":
          var regex = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/g;
          break;

        // additional VECI MX field validation
        // Expected: XXXXaammddZZZ
        //  - XXXX    (4) alpha
        //  - aammdd  (6) numbers
        //  - ZZZ     (3)alphanumeric

        case "crm_physical":
          var regex = /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/g;
          break;

        // Expected: XXXXaammddZZZ
        //  - XXX    (3) alpha
        //  - aammdd  (6) numbers
        //  - ZZZ     (3)alphanumeric

        case "crm_moral":
          var regex = /^[A-Z]{3}[0-9]{6}[A-Z0-9]{3}$/g;
          break;

        case "confirmation_number":
          var regex = /^[a-zA-Z0-9-]+$/g;
          break;

        case "image_only":
          var regex = /^(?:[\w-]+\/)*[\w-]+\.(?:jpg|jpeg|png|gif){1}\b.{0,29}$/g;
          break;
      }

      // fail if no regex found
      if (!regex) {
        return false;
      }

      return regex.test(value);
    }
  },

  dataNames: {
    // Requirement Type can be string | number | integer | regexp | boolean
    requirementType: "string",

    // Validating Function can be validateString | validateNumber | validateMultiple
    validateString: function(value, attr, instance) {
      // Check for data attribute to validate
      var dataValue = instance.$element[0].dataset[attr];

      return dataValue == true || (_.isString(dataValue) && dataValue == "true")
        ? true
        : false;
    },
    messages: {
      en: "The value needs to be valid."
    }
  },

  /**
   * Checks if combined values of two elements match expected criteria
   */
  combined: {
    // Requirement Type can be string
    requirementType: "string",

    // Validating Function can be validateString
    validateString: function(value, attr, instance) {
      var target = document.querySelector(attr),
        targetValue = Number(target.value),
        selfValue = Number(value),
        expectedValue = instance.$element.data("validateCombinedValue"),
        operator = instance.$element.data("validateCombinedOperator");

      return eval(targetValue + selfValue + operator + expectedValue);
    },
    messages: {
      en: "The combined values do not match expected criteria."
    }
  },

  numberMinMax: {
    // Requirement Type can be string
    requirementType: "integer",

    // Validating Function can be validateString
    validateNumber: function(value, requirement, elem) {
      clearTimeout(window.validateNumberHolder);

      let currentStatus = false;
      let currentInput = elem.$element;
      let currentVal = parseInt(currentInput.val());

      let min = parseInt(currentInput.data("inputMin"));
      let max = parseInt(currentInput.data("inputMax"));

      if (currentVal < min) {
        currentInput.val(min);
      } else if (currentVal > max) {
        currentInput.val(max);
      }
      currentStatus = true;

      return currentStatus;
    }
  },

  numberMaxError: {
    // Requirement Type can be string
    requirementType: "string",

    // Validating Function can be validateString
    validateString: function(value, requirement, elem) {
      let currentInput = elem.$element;
      let currentVal = parseFloat(currentInput.val());

      let max = parseFloat(currentInput.data("inputMax"));

      if (currentVal > max) {
        return false;
      }
    }
  },

  imageOnly: {},

  treeSelection: {
    requirementType: "integer",
    validateNumber: function(value, requirement, elem) {
      // Get the jstree instance
      var tree = elem.$element
        .closest("[data-component='form']")
        .find("[data-tree-container]")
        .jstree(true);

      // Get the array of selected nodes
      var selectedNodes = tree.get_selected().length;

      elem.$element.val(selectedNodes);

      // Check if at least one node is selected
      return selectedNodes > 0;
    },
    messages: {
      en: "At least one selection is required."
    }
  }
};

ValidatorsLibrary.async = {
  remoteValidation: function(xhr) {
    return xhr.responseJSON.success;
  }
};

module.exports = ValidatorsLibrary;
