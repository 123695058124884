require("tinymce");

var Wysiwyg = Backbone.View.extend({
  initialize: function() {
    var self = this;

    this.settings = {
      charLimit: null,
      localization: {
        addVariable: "Add Variable",
        addGlobal: "Add Global Variable",
        addLocal: "Add Local Variable",
        asVar: "As Variable",
        asIf: "As If Clause",
        asIfElse: "As If/Else Clause",
        characters: "Characters"
      },
      globalVars: [],
      localVars: []
    };

    if (this.$el.data("settings")) {
      this.settings = $.extend(
        true,
        this.settings,
        eval(this.$el.data("settings"))
      );
    }

    this.settings.localization = $.extend(
      true,
      this.settings.localization,
      REVELEX.settings.wysiwyg.localization
    );

    tinymce.baseURL = tinymce.baseURI.directory + "/tinymce";

    var toolbar = this.buildToolbar();

    self.locale = null;

    $.when(self.checkLocale(REVELEX.settings.currentLocale)).done(function() {
      var options = {
        target: self.el,
        plugins:
          "code link image preview hr table visualchars charmap directionality advlist lists emoticons fullscreen",
        menubar: "file edit view insert format tools table variables",
        menu: {
          variables: {
            title: self.settings.localization.addVariable,
            items: "menuGlobal menuLocal"
          }
        },
        toolbar: toolbar,
        toolbar_mode: "wrap",
        character_limit: self.settings.charLimit,
        charmap_append: [[0x02298, "prohibitted sign"]],
        setup: self.setup.bind(self),
        advlist_number_styles:
          "lower-alpha,lower-roman,upper-alpha,upper-roman",
        suffix: ".min",
        branding: false,
        language: self.locale,
        height: self.settings.height || 400,
        maxHeight: self.settings.maxHeight || 600,
        valid_children: "+body[style]",
        protect: [
          /*
            Tinymce does not like twig templates and its automatic content filtering rearranges the the twig template
            content causing undesireable results. This regex is to prevent tinymce from manipulating twig blocks and
            vars within tables

           example 1:

           creating a table as
           <table>
              <tbody>
                {{ var that contains table row html }}
              </tbody>
            </table>

           tiny mce will rewrite the content as
            <p>{{ var that contains table row html }}</p>
            <table>
              <tbody></tbody>
            </table>

           the workaround is to surround the var with an if statement that is always true
           <table>
             <tbody>
                {%if true %}
                  {{ var that contains table row html }}
                {% endif%}
              </tbody>
            </table>

           example 2:
           creating a table as
           <table>
             <tbody>
               {% if some_var %}
                 <td>some var</td>
               {% else %}
                 <td>some other var</td>
               {% endif %}
             </tbody>
           </table>

           tiny mce will rewrite the content as
            <p>{% if some_var %}{% else %}{% endif %}</p>
            <table>
              <tbody>
                <td>some var</td>
                <td>some other var</td>
              </tbody>
            </table>
           */
          /\{% if(.+?) %\}((\?>(\?R)|.)*?)\{% endif %\}/gisu
        ]
      };

      tinymce.init(options);
    });
  },

  setup: function(editor) {
    var self = this;

    self.editorId = editor.id;

    editor.on("change", function(e) {
      this.save();
    });

    if (self.settings.charLimit > 0) {
      editor.on("init", function(e) {
        var container = editor.getContainer(),
          resizeElement = $(container).find(".tox-statusbar__resize-handle");

        $(
          "<div data-wysiwyg-character-count-container>" +
            self.settings.localization.characters +
            ":  <span data-wysiwyg-character-count>" +
            editor.getContent().length +
            "</span> / " +
            self.settings.charLimit +
            "</div>"
        ).insertBefore(resizeElement);
      });

      editor.on("change keyUp keyDown", function() {
        var totalChars = editor.getContent().length;

        $(editor.editorContainer)
          .find("[data-wysiwyg-character-count]")
          .text(totalChars);
        if (totalChars > self.settings.charLimit) {
          $(self).addClass("is-invalid");
          $(editor.editorContainer)
            .find("[data-wysiwyg-character-count-container]")
            .css("color", "red");
        } else {
          $(self).removeClass("is-invalid");
          $(editor.editorContainer)
            .find("[data-wysiwyg-character-count-container]")
            .css("color", "");
        }
      });
    }

    if (self.settings.globalVars.length) {
      editor.ui.registry.addNestedMenuItem("menuGlobal", {
        text: self.settings.localization.addGlobal,
        getSubmenuItems: function() {
          return self.buildVarMenu(
            self.settings.globalVars,
            editor,
            self.settings.localization
          );
        }
      });
    }

    if (self.settings.localVars.length) {
      editor.ui.registry.addNestedMenuItem("menuLocal", {
        text: self.settings.localization.addLocal,
        getSubmenuItems: function() {
          return self.buildVarMenu(
            self.settings.localVars,
            editor,
            self.settings.localization
          );
        }
      });
    }
  },

  checkLocale: function(locale) {
    var self = this;

    if (locale !== "en_US") {
      $.ajax({
        type: "GET",
        async: false,
        url: tinymce.baseURL + "/langs/" + locale + ".js",
        success: function() {
          self.locale = locale;

          return true;
        },
        error: function() {
          var newLocale = locale.split("_");

          if (newLocale.length === 2 && newLocale[0] !== "en") {
            return self.checkLocale(newLocale[0]);
          }

          return null;
        }
      });
    }
  },

  unload: function() {
    var el = tinymce.get(this.editorId);

    if (el !== null) {
      el.destroy();
    }
  },

  buildVarMenu: function(vars, editor, localization) {
    var data = [];

    _.each(vars, function(variable) {
      data.push({
        type: "nestedmenuitem",
        text: variable.name,

        getSubmenuItems: function() {
          return [
            {
              type: "menuitem",
              text: variable.description,
              disabled: true
            },
            {
              type: "menuitem",
              text: localization.asVar,
              onAction: function(_) {
                editor.insertContent("{{ " + variable.token + " }}");
              }
            },
            {
              type: "menuitem",
              text: localization.asIf,
              onAction: function(_) {
                editor.insertContent(
                  "{% if " + variable.token + " %}{% endif %}"
                );
              }
            },
            {
              type: "menuitem",
              text: localization.asIfElse,
              onAction: function(_) {
                editor.insertContent(
                  "{% if " + variable.token + " %}{% else %}{% endif %}"
                );
              }
            }
          ];
        }
      });
    });

    return data;
  },

  buildToolbar: function() {
    var toolbar =
      "undo redo | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | emoticons charmap link hr table ltr rtl | preview code fullscreen";

    var toolbarVars = "";

    if (this.settings.globalVars) {
      toolbarVars = toolbarVars + "menuGlobalVar | ";
    }

    if (this.settings.localVars) {
      toolbarVars = toolbarVars + " menuLocalVar | ";
    }

    toolbar = toolbarVars + toolbar;

    return toolbar;
  }
});

module.exports = Wysiwyg;
