/**
 * Handling global async forms for PA5
 * Currently used on the admin area
 */

// templates are moved to a different js
// to separate logic from templates
const NotificationDialog = require("./notification-dialog");

const AsyncForm = Backbone.View.extend({
  events: {
    "click [data-async-trigger-form]": "submitForm",
    "submit form": "submit"
  },

  // submit child form bypassing default submit form event
  submitForm: function(e) {
    const form = $(e.currentTarget).parents("form");
    if (form.length) {
      if (!form.component().validate({ reset: false })) {
        return this;
      }

      // get component tree selection
      const tree = this.$('[data-component="tree"]');
      if (tree.length) {
        const treeSelectedNodes = tree.component().getSelectedNodes();

        // adding selected nodes as hidden inputs
        _.each(treeSelectedNodes, function(value, key) {
          $(form).append(
            '<input type="hidden" name="' +
              key +
              '" value="' +
              value[0] +
              '" />'
          );
        });
      }

      // display loading splash
      Backbone.Events.trigger("loadingSplashOn");

      // this will bypass the submit form event (using form node [0])
      form[0].submit();
    }
  },

  submit: function(event) {
    event.preventDefault();

    const form = $(event.currentTarget);
    let formDataJson = form.serializeJSON();

    // get selection from tree component
    const tree = this.$('[data-component="tree"]');
    if (tree.length) {
      formDataJson = $.extend(true, formDataJson, tree.component().toJSON());
    }

    // Scroll to the top of the form
    $("html, body").animate(
      {
        scrollTop: this.$el.offset().top
      },
      "slow"
    );

    this.sendRequest(
      event,
      this.settings.actionUrl || form.attr("action"),
      formDataJson
    );
  },

  // @TODO: YS- put messages/response data template together
  renderResponse: function(response) {
    this.$('[data-messages="' + this.settings.id + '"]').html(
      this.templates.messages(response.messages)
    );

    this.$('[data-response-data="' + this.settings.id + '"]').html(
      this.templates.responseData(response.data)
    );
  },

  displayConfirmationDialog: function(messages) {
    messages = $.extend(true, messages, this.settings.messages);

    this.notificationDialog.open(messages);
  },

  sendRequest: function(event, url, data) {
    $.ajax({
      type: "POST",
      dataType: "json",
      cache: false,
      url: url,
      data: data,

      beforeSend: () => {
        this.renderResponse({});

        this.block(this.$el);
      },

      success: response => {
        // csrf tokens need to be updated for invalid response.
        if (response.csrf) {
          $.each(response.csrf, (key, value) => {
            const csrf_field = this.$el.find("input[name='" + key + "']");

            if (csrf_field.length) {
              csrf_field.val(value);
            }
          });
        }

        if (response.success) {
          // if queryKey is available it will add the query to the redirectURL
          // queryKey is expected to always be an array
          // queryKey needs to match both keys from what is going to be send as redirect
          // and what information is going to be fed with

          if (this.settings.queryKey) {
            //fallback for previous entries
            this.settings.queryKey = Array.isArray(this.settings.queryKey)
              ? this.settings.queryKey
              : [this.settings.queryKey];

            const redirectUrl = new URL(this.settings.redirectUrl);

            // update redirectUrl with response data returned
            this.settings.queryKey.forEach(key => {
              if (typeof response.data[key] !== "undefined") {
                redirectUrl.searchParams.set(key, response.data[key]);
              }
            });

            this.settings.redirectUrl = redirectUrl.toString();
          }

          this.displayConfirmationDialog(response.messages);
        } else {
          this.renderResponse(response);
        }

        this.unblock(this.$el);
      },

      error: () => {
        this.renderResponse({ messages: { ajaxFailure: true } });
        this.unblock(this.$el);

        return false;
      }
    });
  },

  block: function(el) {
    let childForm = $(el).find("form");
    let currentWrapper = childForm.length ? childForm : $(el);

    currentWrapper.addClass("is-loading");
  },

  unblock: function(el) {
    let childForm = $(el).find("form");
    let currentWrapper = childForm.length ? childForm : $(el);

    currentWrapper.removeClass("is-loading");
  },

  initTemplates: function() {
    this.templates = {
      messages: Handlebars.compile(
        this.$('[data-messages-template="' + this.settings.id + '"]').html() ||
          ""
      ),
      responseData: Handlebars.compile(
        this.$(
          '[data-response-data-template="' + this.settings.id + '"]'
        ).html() || ""
      )
    };

    this.notificationDialog = new NotificationDialog({
      settings: this.settings
    });
  },

  initialize: function() {
    this.settings = {
      id: "",
      actionUrl: null,
      redirectUrl: null,
      queryKey: ""
    };

    if (this.$el.data("settings")) {
      this.settings = $.extend(
        true,
        this.settings,
        eval(this.$el.data("settings"))
      );
    }

    this.initTemplates();

    this.$el.loadComponents();
    this.$el.loadModules();
  }
});

module.exports = AsyncForm;
