/**
 * Module for scrolling through table columns
 * @Version 3.0
 * @author Luis Caro
 * @date 01/22/2018
 */

var Matrix = Backbone.View.extend({
  initialize: function() {
    this.buttons = this.$("[data-table-scroller-button]");
    this.matrixTable = this.$("[data-table-scroller]");
    this.position = 0;
    this.tableScrollerStaticItem = this.$("[table-scroller-static-item]");

    // This is for when the vertical header needs to be sticky
    // if there is no sticky header then the default value is 0 thus not affecting any of the calculations
    this.staticHeaderWidth = document.querySelector(
      "[table-scroller-static-header]"
    )
      ? document.querySelector("[table-scroller-static-header]").offsetWidth
      : 0;

    // If there is a vertical header we initialize the component with a margin equal to the width of the vertical header
    if (this.staticHeaderWidth > 0) {
      this.matrixTable.css("margin-left", this.staticHeaderWidth + "px");
    }

    // Get width of the matrix container, not including the next and previous buttons.
    // if there is a static header, we subtract the container width by the width of the static header
    this.containerWidth =
      this.$el.find(".table-scroller-mask").width() - this.staticHeaderWidth;

    //@TODO: Hack for form.js initializing component before it's visible
    if (!this.containerWidth) {
      this.$el.data("initialized", false);
      setTimeout(
        function() {
          this.initialize();
        }.bind(this),
        0
      );
      return false;
    }

    // Array containing the widths of every column
    this.columnWidths = _.pluck(
      this.matrixTable.find(
        "> thead > *:first-child > *:not(.sticky-column-width)"
      ),
      "offsetWidth"
    );

    // We keep track of the last column on both ends of the table
    this.currentIndex = 0;

    // If table is larger than the container (too many columns) activate scroller
    if (this.containerWidth < this.matrixTable.outerWidth()) {
      //Add buttons
      if (!this.$el.hasClass("fixed-arrows")) {
        this.$el.addClass("dynamic-arrows");

        // Let buttons track the mouse's movement to move along
        if (this.matrixTable.data("buttons-follow")) {
          this.$el.on(
            "mousemove.scrollButtonsFollow",
            this.scrollButtonsFollow.bind(this)
          );
        }
      }
      // Make current buttons work
      this.buttons.on(
        "mousedown.scrollButtonsMove",
        this.scrollButtonsTrigger.bind(this)
      );

      // Stop interval on muoseup
      this.buttons.on(
        "mouseup.scrollClearInterval",
        this.matrixStop.bind(this)
      );
    } else {
      this.buttons.attr("disabled", "disabled");
    }
    //scroll the sticky column with the container
    var scrollStickyColumn = this.$el.find("[data-table-scroller-mask]");
    if (scrollStickyColumn.length) {
      $(scrollStickyColumn).on("scroll", this.calScrollHeight.bind(this));
    }
    // Initialize children components
    this.$el.loadComponents();
  },

  calScrollHeight: function(e) {
    var topHeight = e.currentTarget.scrollTop;
    $(".sticky-table-height .sticky-column-width").css(
      "margin-top",
      "-" + topHeight + "px"
    );
  },
  scrollButtonsTrigger: function(e) {
    // Get scroll direction
    var newDirection = $(e.currentTarget).data("table-scroller-button");

    // If we're changing scroll direction we change point of reference
    if (this.direction != newDirection) {
      this.changeReference(newDirection);
    }

    this.direction = newDirection;

    // Adding easing
    this.matrixTable.removeClass("ease-out").addClass("ease-in");
    this.matrixMove();
    this.matrixTable.removeClass("ease-out ease-in");

    this.scrolling = setInterval(
      function() {
        this.matrixMove();
      }.bind(this),
      200
    );
  },

  /**
   * Gets the total width of the fully displayed columns to determine the gap and resets the index
   */
  changeReference: function(newDirection) {
    var totalWidth = 0,
      width = 0,
      x = this.currentIndex,
      // If newDirection = +1 we run the loop towards the last column index, if -1 we run it towards the first one (index 0)
      forLimit = newDirection == 1 ? "x >= 0" : "x < this.columnWidths.length";

    for (x; eval(forLimit); x -= newDirection) {
      width = this.columnWidths[x];
      if (totalWidth + width <= this.containerWidth) {
        totalWidth += width;
        this.currentIndex -= newDirection;
      } else {
        this.currentIndex += newDirection;
        break;
      }
    }

    this.currentGap = width - (this.containerWidth - totalWidth);
  },

  matrixMove: function() {
    // Stop if we reached the end of the table
    if (!this.columnWidths[this.currentIndex - this.direction]) {
      clearInterval(this.scrolling);
      var endOfTable = true;
      return false;
    } else if (
      // Set interval for end of table
      this.columnWidths[this.currentIndex - this.direction * 2] &&
      !this.columnWidths[this.currentIndex - this.direction * 3] &&
      !this.currentGap
    ) {
      this.interval =
        this.columnWidths[this.currentIndex - this.direction] +
        this.columnWidths[this.currentIndex - this.direction * 2];
      this.matrixTable.addClass("ease-out");
      this.currentIndex -= this.direction;
    } else {
      // Set regular interval to move to new position
      this.interval = this.currentGap
        ? this.currentGap
        : this.columnWidths[this.currentIndex - this.direction];

      //Activate both buttons
      this.buttons.attr("disabled", false);

      // Set end of table to deactivate pressed button
      if (!this.columnWidths[this.currentIndex - this.direction * 2]) {
        var endOfTable = true;
      }
    }

    // Calculate new position with new interval
    this.position += this.interval * this.direction;

    // Move table
    this.matrixTable.css(
      "margin-left",
      this.position + this.staticHeaderWidth + "px"
    );

    // move the static element as well inside matrix table
    if (this.tableScrollerStaticItem) {
      $(this.tableScrollerStaticItem).css("margin-left", -this.position + "px");
    }

    // Deactivate button
    if (endOfTable) {
      this.buttons
        .filter('[data-table-scroller-button="' + this.direction + '"]')
        .attr("disabled", "disabled");
    }

    // Reset gap
    this.currentGap = 0;

    // Reset column index for both ends
    this.currentIndex -= this.direction;
  },

  matrixStop: function() {
    // Remove easing
    this.matrixTable.removeClass("ease-in").addClass("ease-out");
    clearInterval(this.scrolling);
    this.matrixMove();
  },

  /**
   * Make buttons follow mouse
   */
  scrollButtonsFollow: function(event) {
    var containerOffset = this.$el.offset();
    if (
      event.pageY > containerOffset.top + 65 &&
      event.pageY < containerOffset.top + this.$el.outerHeight() - 50
    ) {
      this.buttons.css(
        "top",
        event.pageY -
          containerOffset.top -
          this.buttons[0].offsetHeight / 2 +
          "px"
      );
    }
  }
});

module.exports = Matrix;
