/**
 * Moment Masks Library
 * description  : Library for mask locales to work with moment
 */

module.exports = function(locale) {
  switch (locale) {
    case "en-US":
      return "en";

    case "en-ES":
      return "es";

    case "ja-JP":
      return "ja";

    case "fr-FR":
      return "fr";

    default:
      return locale;
  }
};
