var $ = require("jquery");

var PromotionTermsTemplate =
  "" +
  "{{#unless promotion.is_combinable}}" +
  "<p>{{{texts.notCombinableWarning}}}</p>" +
  "{{/unless}}" +
  "{{#if promotion.require_terms}}" +
  "<p>{{{texts.termsWarning}}}</p>" +
  "{{{promotion.terms}}}" +
  "{{/if}}" +
  "<p>{{{texts.continueToUse}}}</p>";

var Promotion = Backbone.Model.extend({
  idAttribute: "version_id",

  /**
   * This method checks if the promotion can be combined with the supplied group.
   *
   * @param group_id
   *
   * @returns bool
   */
  combinableWithGroup: function(group_id) {
    var combinableGroups = this.get("combinable_promotion_groups");

    // loop through the combinable groups and report if the group exists
    for (var x = 0; x < combinableGroups.length; x++) {
      if (group_id == combinableGroups[x]) {
        return true;
      }
    }

    return false;
  }
});

var PromotionCollection = Backbone.Collection.extend({ model: Promotion });

var PromotionTermsView = Backbone.View.extend({
  /**
   * This method renders the view.
   *
   * @param promotion
   */
  render: function(promotion) {
    this.currentPromotion = promotion;

    this.$("[data-promotion-terms-body]").html(
      this.template({
        promotion: promotion.toJSON(),
        texts: this.texts
      })
    );

    var Dialog = this.$el.component();

    Dialog.reflow().open();

    // register listeners for the accept/decline buttons
    Dialog.currentModal
      .on(
        "click",
        "[data-promotion-terms-declined]",
        this.processTermsDeclined.bind(this)
      )
      .on(
        "click",
        "[data-promotion-terms-accepted]",
        this.processTermsAccepted.bind(this)
      );
  },

  /**
   * This method handles the acceptance of the terms.
   */
  processTermsAccepted: function() {
    var Dialog = this.$el.component();

    Dialog.close();

    // announce the acceptance of the terms
    this.dispatcher.trigger("promotionTermsAccepted", {
      promotion: this.currentPromotion
    });
  },

  /**
   * This method handles the declining of the terms.
   */
  processTermsDeclined: function() {
    var Dialog = this.$el.component();

    Dialog.close();

    // announce the declining of the terms
    this.dispatcher.trigger("promotionTermsDeclined", {
      promotion: this.currentPromotion
    });
  },

  /**
   * This method initializes the view.
   *
   * @param options
   */
  initialize: function(options) {
    this.template = Handlebars.compile(PromotionTermsTemplate);
    this.texts = options.texts;
    this.dispatcher = options.dispatcher;
  }
});

var PromotionGroupView = Backbone.View.extend({
  /**
   * The internal event listeners for the view.
   */
  events: {
    "change [data-promotion-checkbox]": "processPromotionSelection"
  },

  /**
   * This method toggles the active class of the promotion
   *
   * @param version_id
   * @param isChecked
   */
  toggleActiveClass: function(version_id, isChecked) {
    this.$('[data-promotion-block="' + version_id + '"]')[
      isChecked ? "addClass" : "removeClass"
    ]("is-active");
  },

  /**
   * This method toggles the disabled class of the promotion
   *
   * @param version_id
   * @param isDisabled
   */
  toggleDisabledClass: function(version_id, isDisabled) {
    this.$('[data-promotion-block="' + version_id + '"]')[
      isDisabled ? "addClass" : "removeClass"
    ]("is-disabled");
  },

  /**
   * This method processes the selected promotion
   *
   * @param event
   */
  processPromotionSelection: function(event) {
    var promotion = this.collection.get(
      $(event.currentTarget).data("promotionCheckbox")
    );
    var isChecked = $(event.currentTarget).is(":checked");

    if (promotion) {
      this.toggleActiveClass(promotion.get("version_id"), isChecked);

      // render terms if the promotion is selected and it requires terms acceptance or (is not combinable and there are more than one promotions)
      if (
        isChecked &&
        promotion.get("version_id") &&
        (promotion.get("require_terms") ||
          (!promotion.get("is_combinable") && this.totalPromotionCount > 1))
      ) {
        this.promotionTermsView.render(promotion);
      } else {
        // annouce the change of the promotion
        this.dispatcher.trigger("promotionSelectionChange", {
          promotion: promotion,
          isChecked: isChecked
        });
      }
    }
  },

  /**
   * This method processes the declining of the terms for the selected promotion
   *
   * @param data
   */
  processSelectedPromotionTermsAccepted: function(data) {
    // check if the promotion exists in the collection
    if (this.collection.get(data.promotion.get("version_id"))) {
      this.toggleActiveClass(data.promotion.get("version_id"), true);

      // annouce the change of the promotion
      this.dispatcher.trigger("promotionSelectionChange", {
        promotion: data.promotion,
        isChecked: true
      });
    }
  },

  /**
   * This method processes the declining of the terms for the selected promotion
   *
   * @param data
   */
  processSelectedPromotionTermsDeclined: function(data) {
    // check if the promotion exists in the collection
    if (this.collection.get(data.promotion.get("version_id"))) {
      this.$(
        '[data-promotion-checkbox="' + data.promotion.get("version_id") + '"]'
      ).prop("checked", false);
      this.toggleActiveClass(data.promotion.get("version_id"), false);

      // annouce the change of the promotion
      this.dispatcher.trigger("promotionSelectionChange", {
        promotion: data.promotion,
        isChecked: false
      });
    }
  },

  processGroupCombinability: function(data) {
    var self = this;

    // loop through the promotions
    _.each(_.toArray(this.collection.models), function(promo) {
      // enable/disable accordingly
      if (!promo.get("is_auto_applied")) {
        var enabled = true;

        // see if this promotion can be combined with the promotions already selected
        _.each(_.toArray(data.selectedPromotions.models), function(promotion) {
          // check if the current is combinable or not and is not the same promotion that was selected
          if (
            (!promo.get("is_combinable") || promo.get("is_combinable")) &&
            promo.get("version_id") != promotion.get("version_id")
          ) {
            // check if the promos can be combined
            if (
              !promotion.combinableWithGroup(promo.get("group_id")) ||
              !promo.combinableWithGroup(promotion.get("group_id"))
            ) {
              enabled = false;
            }
          }
        });

        // make sure the decline promotion stays enabled
        if (!promo.get("version_id")) {
          enabled = true;
        }

        // enable/disable the promotion accordingly
        if (enabled) {
          let currentPromotion = self.$(
            '[data-promotion-checkbox="' + promo.get("version_id") + '"]'
          );

          currentPromotion.removeAttr("disabled");
          self.toggleDisabledClass(promo.get("version_id"), false);
          self.toggleActiveClass(
            promo.get("version_id"),
            currentPromotion.is(":checked")
          );
        } else {
          self
            .$('[data-promotion-checkbox="' + promo.get("version_id") + '"]')
            .removeAttr("checked")
            .attr("disabled", true);

          self.toggleActiveClass(promo.get("version_id"), false);
          self.toggleDisabledClass(promo.get("version_id"), true);
        }
      }
    });
  },

  /**
   * This method initializes the view
   */
  initialize: function(options) {
    this.collection = new PromotionCollection(options.promos);
    this.promotionTermsView = options.promotionTermsView;
    this.dispatcher = options.dispatcher;
    this.totalPromotionCount = options.totalPromotionCount;

    // register listeners for terms and group combinability
    this.dispatcher.on(
      "promotionTermsAccepted",
      this.processSelectedPromotionTermsAccepted,
      this
    );
    this.dispatcher.on(
      "promotionTermsDeclined",
      this.processSelectedPromotionTermsDeclined,
      this
    );
    this.dispatcher.on(
      "processPromoGroupCombinability",
      this.processGroupCombinability,
      this
    );
  }
});

var PromotionSelection = Backbone.View.extend({
  /**
   * This property tracks the promotions that have been selected
   */
  selectedPromotions: null,

  /**
   * This method builds the promotion groups
   *
   * @returns array
   */
  buildPromotionGroups: function() {
    var promoGroups = [];

    // process promotion groups
    for (var x in this.settings.promotionGroups) {
      var promos = [];

      // get the promotions that belong to the group
      for (var y in this.settings.promotionGroups[x].version_ids) {
        var promotion = this.collection.get(
          this.settings.promotionGroups[x].version_ids[y]
        );

        // add the found promotion
        if (promotion) {
          promos.push(promotion.toJSON());
        }
      }

      // build the group information
      var group = {
        groupId: this.settings.promotionGroups[x].group_id,
        groupName: this.settings.promotionGroups[x].group_name,
        promos: promos
      };

      promoGroups.push(group);
    }

    return promoGroups;
  },

  initPromotionGroupViews: function() {
    var self = this;
    var promoGroups = self.buildPromotionGroups();

    // create the promotion group views for each group
    _.each(promoGroups, function(group) {
      new PromotionGroupView({
        el: self.$('[data-promo-group="' + group.groupId + '"]'),
        promos: group.promos,
        promotionTermsView: self.promotionTermsView,
        dispatcher: self.dispatcher,
        totalPromotionCount: self.settings.promotionCount
      });
    });

    var noPromo = [];
    noPromo.push({
      id: 0,
      version_id: 0,
      group_id: -1,
      require_terms: false,
      combinable_promotion_groups: []
    });

    new PromotionGroupView({
      el: self.$('[data-promo-group="-1"]'),
      promos: noPromo,
      promotionTermsView: self.promotionTermsView,
      dispatcher: self.dispatcher
    });
  },

  trackPromotions: function(data) {
    // reset selected promotion collection if the no promotion offer was actioned upon
    if (!data.promotion.get("version_id")) {
      this.selectedPromotions.reset();
    }

    if (data.isChecked == true) {
      this.selectedPromotions.add(data.promotion);
    } else {
      this.selectedPromotions.remove(data.promotion);
    }

    this.dispatcher.trigger("processPromoGroupCombinability", {
      selectedPromotions: this.selectedPromotions
    });
  },

  processSelectedPromotions: function() {
    var self = this;

    this.$("[data-promotion-checkbox]:checked").each(function(index, el) {
      var promo = self.collection.get($(el).data("promotionCheckbox"));

      if (promo && !promo.get("is_auto_applied")) {
        self.selectedPromotions.add(promo);
      }
    });

    this.dispatcher.trigger("processPromoGroupCombinability", {
      selectedPromotions: this.selectedPromotions
    });
  },

  togglePromoDecline: function(data) {
    if (
      data.isChecked == true &&
      data.promotion.get("version_id") != 0 &&
      this.$('[data-promotion-checkbox="0"]').is(":checked")
    ) {
      this.$('[data-promotion-checkbox="0"]').prop("checked", false);
    }
  },

  /**
   * This method initializes the view
   */
  initialize: function() {
    this.settings = this.$el.data("promotion-selection-settings");

    this.dispatcher = this;
    this.collection = new PromotionCollection(this.settings.promotions);
    this.selectedPromotions = new PromotionCollection();

    // create dispatcher to allow events to be heard by all views
    this.dispatcher.on("promotionSelectionChange", this.trackPromotions, this);
    this.dispatcher.on(
      "promotionSelectionChange",
      this.togglePromoDecline,
      this
    );

    // create the promotion terms view
    this.promotionTermsView = new PromotionTermsView({
      el: this.$("[data-promotion-selection-terms]"),
      texts: this.settings.promotionTermTexts,
      dispatcher: this.dispatcher
    });

    // process the promotion groups
    this.initPromotionGroupViews();

    // process selected promotions if any are pre checked
    if (
      this.$("[data-promotion-checkbox]:checked").not(
        '[data-promotion-checkbox="0"]'
      ).length
    ) {
      this.processSelectedPromotions();
    }
  }
});

module.exports = PromotionSelection;
