var $ = require("jquery");

var dropdownHTML = '<input type="hidden" data-dropdown-selection>';

var dropdownTemplate = [
  "{{#each this}}",
  '<a href="javascript:void(0)" class="dropdown-rvlx-options-item {{#if selected}}dropdown-rvlx-options-item-selected{{/if}}" data-dropdown-name="{{label}}" data-dropdown-value="{{key}}" {{#if selected}}data-dropdown-selected="true"{{/if}}>{{label}}</a>',
  "{{/each}}"
].join("\n");

var DropdownView = Backbone.View.extend({
  events: {
    "click [data-dropdown-trigger]": "showOverlay",
    "click [data-dropdown-value]": "clickOption",
    "keyup [data-dropdown-trigger]": "handleFocus",
    "keyup [data-dropdown-filter]": "filterBy",
    "keydown [data-dropdown-trigger]": "handleTriggerInput",
    "keydown [data-dropdown-value]": "focusBackOnTrigger",
    "focusin [data-dropdown-value]": "updateData",
    "focusout [data-dropdown-trigger]": "completeSelection",
    change: "displayNewValue"
  },

  initialize: function(data) {
    this.settings = {
      dropdownNoWrap: false,
      hiddenParameters: false
    };

    this.wrapper = this.$el.parents("[data-input-group]").length
      ? this.$el.parents("[data-input-group]")
      : false;
    this.trigger = this.$("[data-dropdown-trigger]");
    this.tempInput = ""; //For readonly dropdowns
    this.deviceAgent = navigator.userAgent.toLowerCase();
    this.iosdevice = this.deviceAgent.match(/(macintosh|ipod|iphone|ipad)/)
      ? true
      : false;
    this.getHtml = this.trigger.attr("data-get-html")
      ? this.trigger.attr("data-get-html")
      : false;
    //Creating hidden input(contain the value to submit) and passing the name
    //There's a bug where backbone is grouping all elements of the parent into $el, this fixes it

    if (!this.$("[data-dropdown-selection]").length) {
      const $dropdownHTML = $(dropdownHTML).attr(
        "id",
        this.$el.data("dropdownId")
      );

      $(this.$el[0]).append($dropdownHTML);
    }

    this.selection = this.$("[data-dropdown-selection]");

    if (this.$el.data("settings")) {
      this.settings = this.settings = $.extend(
        true,
        this.settings,
        this.$el.data("settings")
      );
    }

    if (this.settings && this.settings.hiddenParameters) {
      for (const [key, value] of Object.entries(
        this.settings.hiddenParameters
      )) {
        $(this.selection).attr(`${key}`, `${value}`);
      }
    }
    //Add attributes to hidden input
    var inputAttributes = {
      "data-validate-required-message": this.trigger.data(
        "validate-required-message"
      ),
      "data-validate-pattern-message": this.trigger.data(
        "validate-pattern-message"
      ),
      "data-validate-error-message": this.trigger.data("validate-error-message")
    };

    this.selection.attr(inputAttributes);

    //Add same name of trigger (input) for special validation
    this.selection.attr(name, this.trigger.attr(name));

    var triggerName = this.trigger.attr("name");
    var isRequired = this.trigger.attr("required");
    var isDisabled = this.trigger.attr("disabled");

    if (triggerName) {
      this.selection.attr("name", triggerName);
      this.trigger.removeAttr("name");
    }
    if (isRequired) {
      this.selection.attr("required", "required");
      this.trigger.removeAttr("required");
    }
    if (isDisabled) {
      this.selection.attr("disabled", "disabled");
      this.trigger.removeAttr("disabled");
    }

    // Additional values dropdown can submit based on single selection
    this.additionalValues = this.$("[data-dropdown-additional-value]");

    this.$el.on(
      "requireOff",
      function() {
        this.selection.removeAttr("required");
      }.bind(this)
    );

    this.$el.on(
      "requireOn",
      function() {
        this.selection.attr("required", "required");
      }.bind(this)
    );

    this.$el.on(
      "disableOff",
      function() {
        this.selection.removeAttr("disabled");
      }.bind(this)
    );

    this.$el.on(
      "disableOn",
      function() {
        this.selection.attr("disabled", "disabled");
      }.bind(this)
    );

    //If there's no data-dropdown-options in the dom we create it first
    if (this.$("[data-dropdown-options]").length === 0) {
      this.$el.append(
        '<div class="dropdown-rvlx-options arrow-up" data-dropdown-options>' +
          '<div class="dropdown-rvlx-options-wrapper" data-dropdown-options-wrapper>' +
          "</div>" +
          "</div>"
      );
    }

    this.$overlay = this.$("[data-dropdown-options]");
    // adding tabindex so the options can be part of the app index
    this.$overlay.attr("tabindex", 0);
    this.clicked = false;

    var firstTime = true;

    //Load static values
    this.selected =
      this.$("[data-dropdown-selected]").length > 0
        ? this.$("[data-dropdown-selected]")
        : null;
    this.staticRender(firstTime);

    //Load additional data into list of options
    this.dropDownList = Handlebars.compile(dropdownTemplate);
    this.dropDownWrapper = this.$("[data-dropdown-options-wrapper]");
    this.dropDownFilter = this.$("[data-dropdown-filter]");
    this.dropDownItems = {};
    this.reflow();

    //Remove autocomplete option from dropdown
    this.trigger.attr("autocomplete", "off");

    // resize event to control dropdown location
    $(window).on("resizeView", () => {
      setTimeout(this.dropdownPosition.bind(this), 300);
    });
  },

  //Build the options array from remote data to build list of options
  getAsyncList: function(endpoint) {
    $.ajax({
      url: endpoint,
      method: "post",
      success: function(response) {
        var data = this.$el.data();
        this.options = this.options || [];
        if (!data.dropdownKey || !data.dropdownLabel) {
          console.log(
            "You need to provide the key and label for proper mapping of data"
          );
          return false;
        }

        $.each(
          response.data,
          function(key, item) {
            this.options.push({
              key: item[data.dropdownKey],
              label: item[data.dropdownLabel]
            });
          }.bind(this)
        );

        this.render("");
      }.bind(this)
    });
  },

  reflow: function() {
    var data = this.$el.data();

    // Import options if passed in.
    this.options = data.dropdownList ? data.dropdownList : null;

    // Import selected element if passed in.
    this.selected = data.dropdownSelected ? data.dropdownSelected : null;

    // Import key element if passed in.
    this.key = data.dropdownKey ? data.dropdownKey : null;

    // Import label element if passed in.
    this.label = data.dropdownLabel ? data.dropdownLabel : null;

    this.clicked = false;

    if (data.dropdownAsync) {
      //Adds remote list to this.options and calls renders afterwards
      this.getAsyncList(data.dropdownAsync);
    } else {
      this.render();
    }
    return this;
  },
  staticRender: function(firstTime) {
    this.populateNames();

    if (this.selected) {
      let currentItem = this.selected;
      let currentLabel = currentItem
        .data("dropdown-name")
        .toString()
        .trim();
      let currentHtml = currentItem.html() || currentLabel;

      //if the custom dropdown is a INPUT field add the text as a value or else add it as an HTML
      if (this.trigger[0].tagName === "INPUT") {
        this.trigger.val(currentLabel);
      } else {
        this.trigger.html(currentHtml);
      }

      if ($(this.selected).is("[data-dropdown-id]")) {
        this.selection.attr(
          "data-dropdown-selection-id",
          $(this.selected).attr("data-dropdown-id")
        );
      }

      this.selection
        .val(this.selected.data("dropdown-value"))
        .trigger("change");
    }

    this.checkLengthList();
    return this;
  },
  populateNames: function() {
    var getHtml = this.getHtml;
    var list = this.$overlay.find("[data-dropdown-value]");
    if (!$(list[0]).data("dropdownName")) {
      _.each(list, function(item) {
        var $item = $(item),
          label = getHtml ? $item.html() : $item.text();
        $item.attr("data-dropdown-name", label);
      });
    }
  },

  /*
    ** Handles keydown on trigger
    */
  handleTriggerInput: function(e) {
    var target = $(e.currentTarget),
      pressedKey = e.keyCode,
      next,
      currentValue = e.currentTarget.value || target.text().trim();

    var option = this.getOption(currentValue);

    // Preventing default and propagation for the following keys
    // 38: ArrowUp
    // 40: ArrowDown
    // 13: Enter
    // 32: Spacebar
    // 27: Escape

    if (
      pressedKey === 38 ||
      pressedKey === 40 ||
      pressedKey === 13 ||
      pressedKey === 32 ||
      pressedKey === 27
    ) {
      e.preventDefault();
      e.stopPropagation();
    }

    //If pressed DOWN key, focus on first item and show list if it's not showing
    if (pressedKey === 40) {
      if (!this.clicked) {
        this.showOverlay(e);
      }

      //If an option was found select it, if not select the first item
      if (option.length > 0) {
        option.focus();
      } else {
        this.$("[data-dropdown-value]")
          .not(":hidden")
          .first()
          .focus();
      }
    } else if (pressedKey === 13) {
      // If pressed ENTER key
      //If it's not readonly then submit what's typed
      if (!$(e.currentTarget)[0].hasAttribute("readonly")) {
        if (option.length > 0) {
          option.focus();
        }
      } else {
        this.showOverlay(e);
      }
    } else if (pressedKey === 9 || pressedKey === 27) {
      // If pressed TAB key hide the list of items
      if (option.length > 0) {
        option.focus();
      }
      this.trigger.focus();
      this.hideOverlay(e);
    } else {
      //If pressed anything else make sure the overlay opens
      if (!this.$overlay.hasClass("is-visible")) {
        this.showOverlay(e);
      }
    }
  },

  /*
    ** Handles keyup on trigger to select option on list
    */
  handleFocus: function(e) {
    let target = $(e.currentTarget);
    let pressedKey = e.keyCode;

    if (pressedKey !== 9 && pressedKey !== 13 && pressedKey !== 27) {
      if (!this.clicked) {
        this.showOverlay(e);
      }

      //Focus option based on what's typed (If it's readonly,
      // the trigger won't have a value, get it from input)
      if (target[0].hasAttribute("readonly")) {
        //Add temporary value to help
        // filtering mimicking native dropdowns
        this.tempInput += e.key;

        //Add timer if doesn't exist to keep track of what was typed
        this.tempTimer = setTimeout(
          function() {
            this.tempInput = "";
          }.bind(this),
          500
        );

        //Filter temporal value
        let option = this.getOption(this.tempInput);
        option.focus();
      } else {
        let option = this.getOption(e.currentTarget.value);
        if (option.length > 0) {
          option.parent()[0].scrollTop = option[0].offsetTop;
        }
      }
    }

    return this;
  },

  /*
    ** Selects the correct option based on input
    */
  getOption: function(label) {
    // using arrow keys makes the label
    // come back undefined, adding fallback
    label = label || "";
    //Returns the item with label that the passed label
    var list = this.trigger.siblings("[data-dropdown-options]").find("a"),
      match = _.findIndex(list, function(item) {
        //Selecting first option from dropdown that starts from entered letters/numbers
        if (
          item.innerText
            .trim()
            .toLowerCase()
            .indexOf(label.toLowerCase()) === 0
        ) {
          return true;
        }
      });

    return this.$(list[match]);
  },

  /*
     * Handles keydown when focusing on the items of the list, it refocuses on trigger (input) when typing
    */
  focusBackOnTrigger: function(e) {
    //If the input is readonly clear the timer
    clearTimeout(this.tempTimer);

    e = e || window.event;
    var pressedKey = typeof e.which == "number" ? e.which : e.keyCode,
      target = $(e.currentTarget),
      prev = target.prev("[data-dropdown-value]"),
      next = target.next("[data-dropdown-value]");

    // If arrow UP was pressed
    if (pressedKey === 38) {
      // Check if the focused element is the input
      if (prev.length && target.is("[data-dropdown-value]")) {
        prev.focus();
      } else {
        this.trigger.focus();
      }

      //Fix to keep the div from scrolling when holding down up or down keys
      e.preventDefault();
    } else if (pressedKey === 40) {
      // If arrow DOWN was pressed
      if (
        target.is("[data-dropdown-value]") &&
        target.next().is("[data-dropdown-value]")
      ) {
        next.focus();

        //Force the div scrolling to react like a native dropdown
        if (
          next[0] &&
          next[0].offsetTop + next[0].scrollHeight >=
            target.parent()[0].scrollTop + target.parent()[0].clientHeight
        ) {
          target.parent()[0].scrollTop =
            next[0].offsetTop -
            target.parent()[0].clientHeight +
            next[0].scrollHeight;
        }
      }

      //Fix to keep the div from scrolling when holding down up or down keys
      e.preventDefault();
    } else if (
      pressedKey !== 9 &&
      pressedKey !== 38 &&
      pressedKey !== 40 &&
      pressedKey !== 27 &&
      pressedKey !== 13
    ) {
      //If it's any letter or number focus back the dropdown to insert those chars
      this.trigger.focus();
    } else if (pressedKey === 9 || pressedKey === 13 || pressedKey === 27) {
      //If pressed TAB focus on next dropdown (Need to focus back on trigger for tabindex to work)
      this.tempInput = "";
      this.trigger.focus();
      this.hideOverlay();
    }

    //If enter was hit and we refocused on dropdown, we override list from showing up again
    if (pressedKey === 13) {
      this.clicked = true;
    }
  },

  /*
     * Hides the list when clicking on an option
    */
  clickOption: function(e) {
    //If it's IOS device, focus event is not triggered, so we manually run updateData
    if (this.iosdevice) {
      this.updateData(e);
    }

    if (this.dropDownFilter.length) {
      this.filterBy(false);
    }
    this.hideOverlay();
    this.trigger.focus();
    this.hideOverlay();
  },

  /*
     * When clicking outside of a non-readonly input completes selection
     * if it wasn't done, or clears field if there are no matches
    */
  completeSelection: function(e) {
    if (
      !this.trigger[0].hasAttribute("readonly") &&
      !$(e.relatedTarget).is("[data-dropdown-value], [data-dropdown-filter]")
    ) {
      var option = this.getOption(this.trigger.val());

      //If there was a match select it, otherwise clear field
      if (option.length > 0) {
        option.focus();
      } else {
        this.trigger.val("");
      }
    }
  },

  render: function(passedValue) {
    //Append list into dropdown
    if (this.$overlay.find("[data-dropdown-options-wrapper]").length > 0) {
      this.$overlay
        .find("[data-dropdown-options-wrapper]")
        .append(this.dropDownList(this.formatOptions()));
    } else {
      this.$overlay.append(this.dropDownList(this.formatOptions()));
    }

    //Select item
    var query = {};
    this.selected =
      passedValue != null
        ? this.$('[data-dropdown-value="' + passedValue + '"]')
        : this.selected;
    if (this.selected != null) {
      //Remove existing selected option in case there is one
      this.$("[data-dropdown-selected]")
        .removeClass("dropdown-rvlx-options-item-selected")
        .removeAttr("data-dropdown-selected");

      query["key"] = this.selected;
      currentSelection = _.findWhere(this.options, query);
      if (!_.isEmpty(currentSelection)) {
        this.trigger.val(currentSelection["label"].toString().trim());
      }

      if ($(this.selected).is("[data-dropdown-id]")) {
        this.selection.attr(
          "data-dropdown-selection-id",
          $(this.selected).attr("data-dropdown-id")
        );
      }

      this.selection
        .val($(this.selected).attr("data-dropdown-value"))
        .trigger("change");

      this.displayNewValue();
    }

    this.dropDownItems = this.$("[data-dropdown-options-wrapper]").find(
      "[data-dropdown-id]"
    );

    this.checkLengthList();
    return this;
  },
  formatOptions: function() {
    var options = [];
    _.each(
      this.options,
      function(option) {
        options.push({
          label: option[this.label],
          key: option[this.key],
          selected: option.selected || null
        });
      }.bind(this)
    );
    return options;
  },
  checkLengthList: function() {
    if (this.$("[data-dropdown-value]").length > 8) {
      this.$overlay.addClass("overlay-scroll");
    }
  },
  showOverlay: function(e) {
    //If there's a list
    if (this.$overlay.length) {
      if (!this.clicked) {
        $(document).on(
          "click.dropdownClose." + this.cid,
          this.closeOverlayOutClick.bind(this)
        );

        this.$overlay.addClass("is-visible");
        this.$el.addClass("is-active");
        this.dropdownPosition();

        if (this.wrapper) {
          this.wrapper.addClass("is-focus");
        }
        this.clicked = true;
        this.trigger.attr("aria-expanded", true);

        // Add a class to body , for outside click on ios devices
        $("body").addClass("custom-dropdown-open");

        //Moves the list to current selected item
        var option = this.$overlay.find(
          '[data-dropdown-value="' + this.selected + '"]'
        );
        if (option.length > 0) {
          option.parent()[0].scrollTop = option[0].offsetTop;
        }
      } else {
        this.hideOverlay();
        $("body").removeClass("custom-dropdown-open");
      }
    }
  },
  hideOverlay: function() {
    this.$overlay.removeClass("is-visible");
    this.$el.removeClass("is-active");
    $("body").removeClass("custom-dropdown-open");
    if (this.wrapper) {
      this.wrapper.removeClass("is-focus");
    }
    this.clicked = false;
    this.trigger.attr("aria-expanded", false);
    $(document).off("click.dropdownClose." + this.cid);
  },
  displayNewValue: function(e, isUpdate) {
    // to avoid looping
    if (isUpdate) {
      return false;
    }
    let val = this.$("[data-dropdown-selection]").val();

    if (
      this.$("[data-dropdown-selection]").is("[data-dropdown-selection-id]")
    ) {
      var currentId = this.$("[data-dropdown-selection]").attr(
        "data-dropdown-selection-id"
      );
    }

    let currentItem = currentId
      ? this.$('[data-dropdown-id="' + currentId + '"]')
      : this.$('[data-dropdown-value="' + val + '"]');

    let currentLabel = currentItem.data("dropdown-name");
    let currentHtml = currentItem.html() || currentLabel;

    this.$("[data-dropdown-value]")
      .removeClass("dropdown-rvlx-options-item-selected")
      .removeAttr("data-dropdown-selected");
    this.$('[data-dropdown-value="' + val + '"]')
      .attr("data-dropdown-selected", true)
      .addClass("dropdown-rvlx-options-item-selected");

    if (currentLabel) {
      currentLabel = currentLabel.toString().trim();
      if (this.trigger[0].tagName == "INPUT") {
        this.trigger.val(currentLabel);
      } else {
        this.trigger.html(currentHtml);
      }
    }
  },
  updateData: function(e, val, label) {
    //Fix for when playing around with readonly inputs and tabbing
    if (!e) {
      return false;
    }

    var $value =
      val || val === "" ? val : $(e.target).attr("data-dropdown-value");
    var $label = label
      ? label
      : $(e.target)
          .attr("data-dropdown-name")
          .toString()
          .trim();

    //Add values to dropdown label and to hidden input
    this.trigger.val($label.toString().trim());

    if ($(e.target).is("[data-dropdown-id]")) {
      this.selection.attr(
        "data-dropdown-selection-id",
        $(e.target).attr("data-dropdown-id")
      );
    }

    this.selection.val($value).trigger("change");

    // If data-dropdown-additional-value ia present, change value with corresponding key
    if (this.additionalValues.length) {
      _.each(
        this.additionalValues,
        function(item) {
          var key = item.dataset.dropdownAdditionalValue,
            selectedValue = this.selection.val(),
            additionalValue = selectedValue
              ? this.options[selectedValue][key]
              : "";

          $(item).val(additionalValue);
        }.bind(this)
      );
    }

    // Disables hidden input if value is blank
    var action = $value ? "removeAttr" : "attr";
    this.selection[action]("disabled", true);

    // Saves value in view
    this.selected = $value;

    // Triggers change event
    this.$el.trigger("change");

    // If it's part of a form validate
    this.form = this.$el.parents('[data-component="form"]');
    if (this.form.length) {
      this.trigger.parsley().validate();
      $(this.selection[0])
        .parsley()
        .validate();
    }

    return this;
  },
  closeOverlayOutClick: function(e) {
    if (!this.$el.find(e.target).length) {
      this.hideOverlay(e);
    }
  },
  disable: function() {
    this.trigger.attr("disabled", true);
    return this;
  },
  setPlaceholder: function(text) {
    this.trigger.attr("placeholder", text);
    return this;
  },
  active: function() {
    $(this.trigger).removeAttr("disabled");
    return this;
  },

  // simple filter function in dropdown
  filterBy: function(e) {
    // we make this check to allow the widget to repopulate
    let filterByInput = e ? this.dropDownFilter : undefined;

    if (typeof filterByInput !== "undefined") {
      // if we get any key that is escape we close the dropdown
      let pressedKey = e.keyCode;

      if (pressedKey === 27) {
        this.hideOverlay(e);
        this.trigger.focus();
        // to any direction we redirect them how we handle the regular trigger in the dropdown
      } else if (pressedKey === 38 || pressedKey === 40 || pressedKey === 13) {
        this.handleTriggerInput(e);
      }
    }

    if (typeof filterByInput !== "undefined" && filterByInput.val().length) {
      // hiding all elements
      this.dropDownItems.hide();

      // and showing the only one we care about
      $(
        this.dropDownWrapper[0].querySelectorAll(
          '[data-dropdown-filter-by*="' + filterByInput.val() + '" i]'
        )
      ).show();
    } else {
      // if there is no value show all
      this.dropDownFilter.val("");
      this.dropDownItems.show();
    }
  },

  getSelectedValue: function() {
    return this.selection ? this.selection.val() : "";
  },

  setDuplicateData: function(data) {
    this.render(data);
  },

  getDuplicateData: function() {
    return this.getSelectedValue();
  },

  dropdownPosition: function() {
    if (
      !this.trigger.length ||
      typeof this.trigger === "function" ||
      !this.$overlay.is(".is-visible")
    ) {
      return false;
    }

    let triggerPosition = this.trigger[0].getBoundingClientRect();

    //Moving dropdown depending on position value on data attributes
    const position = {};

    position.width = this.$overlay[0].offsetWidth;

    if (this.settings.dropdownNoWrap) {
      const dropdownList = this.$overlay.find(".dropdown-rvlx-options-item");
      const dropdownListCss = dropdownList.css([
        "font",
        "padding",
        "margin-right"
      ]);
      let maxWidth = 0;
      let maxText = "";

      dropdownList.each((index, element) => {
        const text = $(element)
          .text()
          .trim();
        const width = this.getTextWidth(text, dropdownListCss.font);
        if (width > maxWidth) {
          maxWidth = width;
          maxText = text;
        }
      });

      position.width =
        maxWidth +
        32 +
        parseInt(dropdownListCss["margin-right"]) +
        parseInt(dropdownListCss.padding.split(" ")[1]) * 2;
    }

    position.left = 0;

    // horizontal position depending on how far the end of viewport is
    let xPosition = triggerPosition.left + this.el.offsetWidth + position.width;

    if (xPosition > innerWidth) {
      position.left = this.el.offsetWidth - position.width;
    }

    //Removes previous applied styles
    this.$overlay.removeAttr("style");

    this.$overlay.css(position);
  },

  getTextWidth: function(text, font) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    return context.measureText(text).width;
  }
});

module.exports = DropdownView;
