var $ = require("jquery");

require("sortablejs");
require("jquery-sortable");

var DragDropSort = Backbone.View.extend({
  events: {
    "click [data-drag-drop-sort-remove]": "removeItem"
  },

  initialize: function() {
    var settings = {};

    if (this.$el.data("settings")) {
      settings = $.extend(true, settings, this.$el.data("settings"));
    }

    var config = this.getBaseConfig();

    if (settings.group) {
      config.group.name = settings.group;
    }

    if (settings.multiDrag) {
      config.multiDrag = true;
    }

    if (settings.allowClone) {
      config.group.pull = "clone";
    }

    if (settings.isParent) {
      config.onAdd = this.onParentAdd.bind(this);
      config.sort = false;
    }

    this.$el.sortable(config);
  },

  getBaseConfig: function() {
    return {
      group: {},
      handle: "[data-drag-drop-sort-move]",
      draggable: "[data-drag-drop-sort-item]",
      animation: 150,
      ghostClass: "drag-drop-sort-ghost",
      dragClass: "drag-drop-sort-drag",
      selectedClass: "drag-drop-sort-selected",
      onAdd: function(event) {
        if (event.items.length) {
          _.each(
            event.items,
            function(item) {
              if (!this.duplicateCheck($(item), event.to !== event.from)) {
                this.enable($(item));
              }
            }.bind(this)
          );
        } else {
          if (!this.duplicateCheck($(event.item), event.to !== event.from)) {
            this.enable($(event.item));
          }
        }
      }.bind(this)
    };
  },

  enable: function(item) {
    $(item).removeClass("drag-drop-sort-selected");
    $(item).attr("data-drag-drop-sort-current", true);
    $(item)
      .find("input")
      .removeAttr("disabled");
  },

  disable: function(item) {
    $(item).removeClass("drag-drop-sort-selected");
    $(item).removeAttr("data-drag-drop-sort-current");
    $(item)
      .find("input")
      .attr("disabled", true);
  },

  remove: function(el) {
    el.remove();
  },

  onParentAdd: function(event) {
    if (event.items.length) {
      _.each(
        event.items,
        function(item) {
          if (!this.duplicateCheck($(item), event.to !== event.from)) {
            this.disable($(item));
          }
        }.bind(this)
      );
    } else {
      if (!this.duplicateCheck($(event.item), event.to !== event.from)) {
        this.disable($(event.item));
      }
    }
  },

  removeItem: function(event) {
    this.remove($(event.currentTarget).parent("[data-drag-drop-sort-item]"));
  },

  duplicateCheck: function(el, isDifferentList) {
    if (
      isDifferentList &&
      this.$el.find(
        '[data-drag-drop-sort-item="' +
          el.data("dragDropSortItem") +
          '"][data-drag-drop-sort-current]'
      ).length
    ) {
      this.remove(el);
      return true;
    }

    return false;
  }
});

module.exports = DragDropSort;
