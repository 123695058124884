var $ = require("jquery");

var ShelfBar = Backbone.View.extend({
  initialize: function() {
    this.$("[data-shelf]").each(function() {
      new Shelf({ el: this });
    });
  }
});

var Shelf = Backbone.View.extend({
  events: {
    "click [data-shelf-clear]": "clearShelf"
  },

  /**
   * This method processes the clear shelf event.
   *
   * @param event
   */
  clearShelf: function(event) {
    var elData = $(event.currentTarget).data();
    var shelfViewLink = this.$('[data-shelf-view="' + elData.shelfClear + '"]');

    $.ajax({
      type: "POST",
      dataType: "json",
      cache: false,
      url: elData.clearUrl,

      // before sending the clear request we must disable the view link and remove its href
      beforeSend: function() {
        this.$el.addClass("is-loading");
        shelfViewLink.attr("href", "javascript:void(0);");
      }.bind(this),

      // on success enable the view link and update its href
      success: function() {
        this.$el.removeClass("is-loading");

        shelfViewLink.attr("href", elData.viewUrl);
        return true;
      }.bind(this),

      // on error enable the view link and update its href to the clear url
      error: function() {
        this.$el.removeClass("is-loading");

        shelfViewLink.attr("href", elData.clearUrl);
        return false;
      }.bind(this)
    });
  }
});

module.exports = ShelfBar;
