var $ = require("jquery");

var StateModel = Backbone.Model.extend({
  defaults: {
    sortField: "",
    sortDirection: "",
    filters: {}
  }
});

var Filter = Backbone.Model.extend({
  idAttribute: "key",

  defaults: {
    type: ""
  },

  /**
   * This method checks if the supplied value is a match for the filter
   *
   * @param value
   * @returns {boolean}
   */
  isMatch: function(value) {
    var matches = true;
    var filter = this.get("value");

    filter = filter.toString().toLowerCase();
    value = value ? value.toString().toLowerCase() : "";

    if (value.indexOf(filter) < 0) {
      matches = false;
    }

    return matches;
  }
});

var FilterCheck = Filter.extend({
  defaults: {
    type: "check"
  },

  /**
   * This method checks if the supplied value is a match for the filter
   *
   * @param value
   * @returns {boolean}
   */
  isMatch: function(value) {
    var matches = false;
    var filters = this.get("value");

    value = value ? value.toString().toLowerCase() : "";

    _.each(filters, function(filter) {
      filter = filter.toString().toLowerCase();

      if (value.indexOf(filter) >= 0) {
        matches = true;
      }
    });

    return matches;
  }
});

var FilterRadio = Filter.extend({
  defaults: {
    type: "radio"
  }
});

var FilterRange = Filter.extend({
  defaults: {
    type: "range"
  },

  /**
   * This method checks if the supplied value is a match for the filter
   *
   * @param value
   * @returns {boolean}
   */
  isMatch: function(value) {
    return this.get("min") <= value && value <= this.get("max");
  }
});

var FilterCollection = Backbone.Collection.extend({ model: Filter });

var GridRow = Backbone.Model.extend({
  idAttribute: "row_id"
});

var GridRowCollection = Backbone.Collection.extend({ model: GridRow });

var Grid = Backbone.View.extend({
  events: {
    "click [data-grid-sort-field]": "processSort",
    "change [data-grid-filter-field]": "addFilter",
    "keyup [data-grid-filter-field]": "addFilter",
    "click [data-grid-filter-radio]": "addFilterRadio",
    "click [data-grid-filter-check]": "addFilterCheck",
    "change [data-grid-filter-range]": "addFilterRange",
    "click [data-grid-filter-reset]": "resetFilters",
    "click [data-grid-filter-reset-key]": "resetFilterKey"
  },

  /**
   * This method sets the sorting data
   *
   * @param event
   */
  processSort: function(event) {
    var el = $(event.currentTarget);

    this.StateModel.set("sortField", el.data("grid-sort-field"));
    this.StateModel.set(
      "sortDirection",
      el.hasClass("is-sortable-asc") ? "desc" : "asc"
    );

    this.setSort(el);

    this.filterSort();
  },

  /**
   * This method sets the sort data
   */
  setSort: function() {
    var el = this.$(
      '[data-grid-sort-field="' + this.StateModel.get("sortField") + '"]'
    );

    // remove sorting classes from all fields
    this.$("[data-grid-sort-field]").removeClass(
      "is-active is-sortable-asc is-sortable-desc"
    );

    // update the triggered field with the correct sorting classes
    if (this.StateModel.get("sortDirection") == "desc") {
      el.addClass("is-active is-sortable-desc");
    } else {
      el.addClass("is-active is-sortable-asc");
    }
  },

  /**
   * This method resets the filter fields
   *
   * @param key
   */
  resetFields: function(key) {
    var filterTypes = [
      "data-grid-filter-field",
      "data-grid-filter-check",
      "data-grid-filter-radio",
      "data-grid-filter-range"
    ];
    var x;

    for (x in filterTypes) {
      var resetKey = "";

      if (key) {
        // only reset a specific filter key if requested
        resetKey = "[" + filterTypes[x] + '="' + key + '"]';
      }

      // reset text inputs
      this.$('input[type="text"]' + resetKey).each(function(index, el) {
        $(el).val($(el).defaultValue);
      });

      // reset radios and checkboxes
      this.$(
        'input[type="radio"]' + resetKey + ', input[type="checkbox"]' + resetKey
      ).each(function(index, el) {
        $(el).prop("checked", el.defaultChecked);
      });

      // reset selects
      this.$("select" + resetKey).each(function(index, el) {
        $(el).val(null);
      });

      // reset range sliders
      this.$('[data-component="range-slider"]' + resetKey).each(function(
        index,
        el
      ) {
        $(el)
          .component()
          .reset();
      });
    }
  },

  /**
   * This method resets the filters
   */
  resetFilters: function() {
    this.filters = new FilterCollection();

    this.resetFields();

    this.filterSort();
  },

  /**
   * This method resets a specific filter key
   *
   * @param event
   */
  resetFilterKey: function(event) {
    var key = $(event.currentTarget).data("gridFilterResetKey");

    this.filters.remove(key);

    this.resetFields(key);

    this.filterSort();
  },

  /**
   * This method adds a filter
   *
   * @param event
   */
  addFilter: function(event) {
    var key = $(event.currentTarget).data("gridFilterField");
    var value = $(event.currentTarget).val();

    this.filters.remove(key);

    if (value.length) {
      this.filters.add({
        key: key,
        value: value
      });
    }

    this.filterSort();
  },

  /**
   * This method adds a check filter. For check filters, a row must match one of its values.
   *
   * @param event
   */
  addFilterCheck: function(event) {
    var key = $(event.currentTarget).data("gridFilterCheck");
    var values = [];

    this.filters.remove(key);

    // add the values for all checked checkboxes for this filter
    this.$('[data-grid-filter-check="' + key + '"]:checked').each(function() {
      values.push($(this).val());
    });

    if (values.length) {
      this.filters.add(
        new FilterCheck({
          key: key,
          value: values
        })
      );
    }

    this.filterSort();
  },

  /**
   * This method adds a radio filter
   *
   * @param event
   */
  addFilterRadio: function(event) {
    var key = $(event.currentTarget).data("gridFilterRadio");
    var value = $(event.currentTarget).val();

    this.filters.remove(key);

    if (value.length) {
      this.filters.add(
        new FilterRadio({
          key: key,
          value: value
        })
      );
    }

    this.filterSort();
  },

  /**
   * This method adds a ranger filter. For ranger filters, a row must be between its values.
   *
   * @param event
   */
  addFilterRange: function(event) {
    var key = $(event.currentTarget).data("gridFilterRange");

    this.filters.remove(key);

    this.filters.add(
      new FilterRange({
        key: key,
        min: parseFloat(this.$("[data-" + key + "-min]").val()),
        max: parseFloat(this.$("[data-" + key + "-max]").val())
      })
    );

    this.filterSort();
  },

  /**
   * This method filters and sorts the rows
   */
  filterSort: function() {
    var self = this;

    // new collection to store filtered rows
    var rows = new GridRowCollection();

    // apply filters if there are any
    if (this.filters.length) {
      this.rows.each(function(row) {
        var matches = true;

        // check if the row matches all the filters
        self.filters.each(function(filter) {
          if (!filter.isMatch(row.get(filter.get("key")))) {
            matches = false;
          }
        });

        // add the row to the collection if it matches all the filters
        if (matches) {
          rows.add(row);
        }
      });
    }

    // use the original collection if there are no filters applied and there are no values in the row collection
    if (!this.filters.length && !rows.length) {
      rows = this.rows;
    }

    // apply sorting if it has been set
    if (this.StateModel.get("sortField")) {
      rows.comparator = this.StateModel.get("sortField");
      rows.sort();

      // reverse the order if the direction is to be descending
      if (this.StateModel.get("sortDirection") == "desc") {
        rows.models.reverse();
      }
    }

    this.StateModel.set({ filters: self.filters.toJSON() });

    this.render(rows.toJSON());
  },

  /**
   * Controls the display of the filter module
   */

  toggleFilterView: function() {
    // checking viewport size
    let vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    // this is a collapser, forcing opening
    if (
      this.filterWrapper.data("filterViewMode") === "mobile" ||
      vw <= parseInt(this.filterWrapper.data("filterViewSize"))
    ) {
      this.filterWrapper.removeAttr("data-collapser-disable-autoclose");
      this.filterWrapper.component().close();
    } else {
      this.filterWrapper.attr("data-collapser-disable-autoclose", true);
      this.filterWrapper.component().open();
    }
  },

  /**
   * This method renders the rows
   *
   * @param rows
   */
  render: function(rows) {
    var self = this;

    // empty the container
    this.$("[data-grid-data]").empty();

    // append the rows if there are any
    if (rows.length) {
      this.$("[data-grid-filter-no-results]").addClass("is-hidden");

      _.each(rows, function(row) {
        self.$("[data-grid-data]").append(row.row_html);
        self.$("[data-grid-data]").append(row.dependant_row_html);
      });
    } else {
      this.$("[data-grid-filter-no-results]").removeClass("is-hidden");
    }

    this.$el.trigger("grid-rendered");
  },

  /**
   * This method sets the grids state using the stored state data
   */
  initFilterSortState: function() {
    var self = this;

    this.StateModel.set(
      JSON.parse(window.sessionStorage.getItem("grid-" + self.settings.id))
    );

    // add the stored filters and update the ui
    _.each(this.StateModel.get("filters"), function(filter) {
      switch (filter.type) {
        case "check":
          self.filters.add(new FilterCheck(filter));

          // uncheck the checkboxes that may have been pre checked
          self.$("[data-grid-filter-check]").removeProp("checked");

          _.each(filter.value, function(val) {
            self
              .$(
                '[data-grid-filter-check="' +
                  filter.key +
                  '"][value="' +
                  val +
                  '"]'
              )
              .prop("checked", true);
          });
          break;

        case "radio":
          self.filters.add(new FilterRadio(filter));
          self
            .$(
              '[data-grid-filter-radio="' +
                filter.key +
                '"][value="' +
                filter.value +
                '"]'
            )
            .prop("checked", true);
          break;

        case "range":
          self.filters.add(new FilterRange(filter));

          var Range = self
            .$('[data-grid-filter-range="' + filter.key + '"]')
            .component();
          Range.settings.cur_min = filter.min;
          Range.settings.cur_max = filter.max;
          Range.render();
          break;

        default:
          self.filters.add(filter);
          self
            .$('[data-grid-filter-field="' + filter.key + '"]')
            .val(filter.value);
          break;
      }
    });

    this.setSort();
    this.filterSort();
  },

  initialize: function() {
    var self = this;

    this.settings = {};
    this.filters = new FilterCollection();
    this.rows = new GridRowCollection();
    this.StateModel = new StateModel();

    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
    }

    // Checking how the filters should behave
    // depending on the resolution
    this.filterWrapper = this.$("[data-filter-view-mode]");

    if (this.filterWrapper.length) {
      this.toggleFilterView();
      $(window).on("resizeView", this.toggleFilterView.bind(this));
      $(window).trigger("resizeView");
    }

    // add row information to the collection
    this.$("[data-grid-row]").each(function() {
      var row_id = $(this).data("grid-row");
      var row_data = $(this).data("grid-row-data");

      // set additional row data
      row_data.row_id = row_id;
      row_data.row_html = $(this);
      row_data.dependant_row_html = self.$(
        '[data-grid-row-dependant="' + row_id + '"]'
      );

      var Row = new GridRow(row_data);

      self.rows.add(Row);
    });

    this.$el.loadComponents();
    this.$el.loadModules();

    if (this.settings.defaultSortField) {
      this.StateModel.set({
        sortField: this.settings.defaultSortField,
        sortDirection: this.settings.defaultSortDirection || "asc"
      });
    }

    this.StateModel.set(
      JSON.parse(window.sessionStorage.getItem(this.settings.token))
    );

    // track the state of the grid if an id was provided
    if (this.settings.id) {
      this.initFilterSortState();

      // add listener to track and store the current state of the grid
      this.StateModel.on("change", function(StateModel) {
        window.sessionStorage.setItem(
          "grid-" + self.settings.id,
          JSON.stringify(StateModel)
        );
      });
    }
  }
});

module.exports = Grid;
