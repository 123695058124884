var $ = require("jquery");
var List = require("../../commons/components/list");
var GoogleMapsLoader = require("google-maps");
var GoogleMaps = require("../../commons/components/google-map");

const { v4: uuidv4 } = require("uuid");
/*
 *   Geolocation Component
 *    This will be used to work with 3rd party geoLocation services
 *    1. ArcGIS
 *      a. Documentation for suggest
 *         https://developers.arcgis.com/rest/geocode/api-reference/geocoding-suggest.htm
 *      b. Documentation for findAddressCandidates
 *         https://developers.arcgis.com/rest/geocode/api-reference/geocoding-find-address-candidates.htm
 *
 *     2. Places
 *      We are using our current GoogleMaps loader to load all libraries needed
 *
 *      a. Documentation for autocomplete
 *         https://developers.google.com/maps/documentation/javascript/place-autocomplete
 *      b. Documentation for geoCoding
 *         https://developers.google.com/maps/documentation/geocoding/start
 */

// Using the AsyncOptions to overwrite fetch
// method while using google API

var GoogleOptions = List.AsyncOptions.extend({
  // the key for types is different to the one expected
  // renaming key for easier access
  renameKey: function(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  },
  fetch: function() {
    let currentQuery = this.query;

    // allowed types to be search by google
    let allowedTypes = ["locality", "point_of_interest", "airport"];

    this.settings.AutocompleteService.getPlacePredictions({
      input: currentQuery,
      sessionToken: this.settings.currentSession,
      types: allowedTypes
    })
      .then(response => {
        // Looping predictions to change key
        _.each(response.predictions, item => {
          let locationType;

          // looping types and replacing it to be one
          // entry instead of multiples
          _.every(item.types, currentType => {
            // we check if the type is included in the array and changing
            // cities and point of interested to values supported by the geolocation widget
            if (allowedTypes.includes(currentType)) {
              if (currentType === "locality") {
                currentType = "city";
              } else if (currentType === "point_of_interest") {
                currentType = "poi";
              }

              locationType = currentType;
              return false;
            }

            // if no type is matched, other will be used
            locationType = "other";
          });

          // replacing array with single string
          item.types = locationType;

          // renaming key
          this.renameKey(item, "types", "location_type");
        });

        if (currentQuery === this.query) {
          this.parse(response);
        }

        // Add query to the cached response
        response.query = this.query;

        if (this.settings.cache) {
          // Store response in cache obj
          this.cache[currentQuery] = response;
        }
      })
      .catch(error => {
        console.warn(error);
      });
  }
});

var GeoLocation = List.extend({
  events: {
    "click [data-list-option]": "selectGeolocation",
    "click [data-list-clear-selection]": "clearSelection",
    "change [data-list-hidden-input]": "setSelectionState",
    "keydown [data-list-input],[data-list-option]": "handleFocus",
    "keyup [data-list-input]": "processInput",
    "focusin [data-list-input]": "handleClassState"
  },
  selectGeolocation: function(e) {
    this.select(e, true);

    // Integration with external geolocation API
    // as the data is completely different we will be
    // passing the correct values to the expected ones
    this.selection.attributes.longitude =
      this.selection.attributes.longitude || "";
    this.selection.attributes.latitude =
      this.selection.attributes.longitude || "";

    let selectionQuery;

    // adding gmapKey + and sessiontoken for billing purposes
    if (this.settings.googleAutocompleteKey) {
      selectionQuery = {
        place_id: escape(this.selection.get("place_id")),
        key: this.settings.googleAutocompleteKey,
        f: "json",
        sessiontoken: this.settings.currentSession
      };
    } else {
      selectionQuery = {
        singleLine: escape(this.selection.get("text")),
        magicKey: this.selection.get("magicKey"),
        f: "json"
      };
    }

    $.ajax({
      url: this.settings.callback,
      data: selectionQuery
    }).done(
      function(response) {
        if (this.settings.googleAutocompleteKey) {
          // google retrieves lat and long via geometry
          let currentLocation = response.results[0].geometry.location;

          this.selection.set({
            longitude: currentLocation.lng,
            latitude: currentLocation.lat
          });
        } else {
          // arcGis retrieves lat and long via in the main response
          let currentLocation = response.candidates[0].location;

          this.selection.set({
            longitude: currentLocation.x,
            latitude: currentLocation.y
          });
        }

        if (this.selection) {
          this.setSelectedOption(this.selection);
        }
        // per google's documentation,
        // after getting details sessions needs to be renewed
        if (this.settings.googleAutocompleteKey) {
          this.settings.currentSession = new google.maps.places.AutocompleteSessionToken();
        }
      }.bind(this)
    );
  },
  initialize: function() {
    this.settings = {};
    this.settings = this.settings = $.extend(
      true,
      this.settings,
      this.$el.data("settings")
    );
    this.reflow();
    this.settings.callback = this.$el.data("listCallback");

    // retrieving the google key from the url provided in the call
    // dont want to use the one in the revelex object as it uses fallback for the global key

    const currentParams = this.settings.url.substring(
      this.settings.url.indexOf("?") + 1
    );

    currentParams.trim();

    const currentFetchURL = new URLSearchParams(currentParams);

    this.settings.googleAutocompleteKey = currentFetchURL.get("key")
      ? currentFetchURL.get("key")
      : false;

    // if googleKey is present, replace async methods and load the map
    if (this.settings.googleAutocompleteKey) {
      this.googleInstance = new GoogleAutocomplete({
        listSettings: this.settings
      });

      this.list = new GoogleOptions([], {
        settings: this.settings,
        results: this.results
      });
    }
  }
});

// extending GoogleMaps to only load the places library
var GoogleAutocomplete = GoogleMaps.extend({
  buildGmapOptions: function() {
    return {
      libraries: "places"
    };
  },
  initMap: function() {
    this.settings.currentSession = new google.maps.places.AutocompleteSessionToken();
    this.settings.AutocompleteService = new google.maps.places.AutocompleteService();
  },
  initialize: function(options) {
    this.settings = options.listSettings;
    REVELEX.GoogleMaps = REVELEX.GoogleMaps || {};
    REVELEX.GoogleMaps.initGoogleMap = this.initMap.bind(this);

    GoogleMapsLoader.KEY = this.settings.googleAutocompleteKey;
    GoogleMapsLoader.LIBRARIES = ["places"];

    this.loadGoogleMapsAPI();
  }
});

module.exports = GeoLocation;
