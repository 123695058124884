/**
 * Module for searching, filtering and selecting multiple list items
 * @Version 1.0
 */

/**
 * filter options depend on selecting list items using enpoint data filter
 *        data-settings='{{strip}}{
 *         "endPointAction"     :["{{app_url workflow="CRUISE_SEARCH_UTILITIES" action="FETCH_DESTINATIONS_PORTS_OF_CALL" display_mode="JSON" query_data=""}}",
 *                                "{{app_url workflow="CRUISE_SEARCH_UTILITIES" action="FETCH_DESTINATIONS_EMBARKATION_PORTS" display_mode="JSON" query_data=""}}"]
 *        , "targetId"          : ["search[port_of_call_ids]", "search[departure_port_ids]"]
 *        , "query_data"        : "destination_ids"
 *        , "callbackParameter" : ["port_id", "departure_port_id"]
 *        , "parameterId"       : ["port_id"]
 *        }{{/strip}}'
 */

/**
 * Default template for the list (basic out-of-the-box functionality)
 */
var defaultListTemplate = [
  '<input class="selection-list-filter" type="text" value="{{filter}}" data-selection-list-filter="{{componentSettings.id}}">',
  '<ul class="selection-list-selections" data-selection-list-selections></ul>',
  '<ul data-selection-list-results-list class="selection-list-results-list">',
  "{{#unless componentSettings.maxSelection}}",
  ' <li class="select-all">',
  "   {{#if componentSettings.submitSelectAll}}",
  "     <input type='checkbox' id='selectAll' data-select-all data-option {{#xif selections.length '==' (objLength totalRecords)}}checked{{/xif}}>",
  "   {{else}}",
  '     <input type="checkbox" id="selectAll" data-select-all data-option {{#unless selections.length}}checked{{/unless}}>',
  "   {{/if}}",
  '   <label for="selectAll">Select all</label>',
  " </li>",
  "{{/unless}}",
  " {{#each data}}",
  "   <li>",
  '     <input type="checkbox" {{#if (contains ../selections $key)}}checked{{/if}}  id="{{../componentSettings.id}}{{$key}}" data-option="{{$key}}">',
  '     <label for="{{../componentSettings.id}}{{$key}}">{{{highlightMatch $name ../filter}}}</label>',
  "   </li>",
  " {{/each}}",
  "</ul>"
].join("\n");

/**
 * Template to append hidden inputs
 */
var hiddenInputsTemplate =
  '{{#each selections}}<input type="hidden" name="{{../componentId}}[{{@key}}]" value="{{this}}">{{/each}}';

/**
 * Template to append selections
 * If the widget has free text widget enabled we won't show the "all selected" state
 */
var selectionsTemplate = [
  '{{#xif (eval data.length "!=" (objLength totalRecords)) "||" (eval freeTextEnabled "==" true) }}',
  "   {{#each data}}",
  "     <li>",
  '       <input id="{{../componentId}}{{$key}}" type="checkbox" checked>',
  '       <label for="{{../componentId}}{{$key}}" data-selected-option  data-option="{{$key}}">{{$name}}</label>',
  "     </li>",
  "   {{/each}}",
  "{{else}}",
  ' <li class="clear-all" data-reset>{{labels.clear}}</li>',
  "{{/xif}}"
].join("\n");

var OutputView = require("../../utilities/output-view");

/**
 *  View of results list
 */
var List = OutputView.extend({
  events: {
    "change [data-option]:not([data-select-all])": "handleSelections",
    "keyup [data-selection-list-free-text]": "handleSelections",
    "change [data-select-all]": "selectAll",
    "click [data-selected-option]": "handleSelections",
    "click [data-reset-filter]": "resetFilter",
    "click [data-reset]": "reset",
    "click [data-search-param-close]": "closeSearchParam"
  },

  initialize: function(parameters) {
    this.component = parameters.component;
    this.selectionsData = parameters.selectionsData;
    this.template = parameters.template;
    this.settings = parameters.settings;
    this.filter = parameters.filter;
    this.renderSelectionsArea = parameters.renderSelectionsArea;
    this.reset = parameters.reset;
    this.activeStateTarget = parameters.activeStateTarget
      ? document.querySelector(parameters.activeStateTarget)
      : false;

    this.freeTextEntry = false;
    this.freeTextInput = false;

    // Necessary for output view list
    this.bindViewEvents();

    this.selectionsData.on("change:filter change:page", this.render.bind(this));

    if (this.settings.externalFilter) {
      this.selectionsData.on("change:records", this.render.bind(this));
    }
  },

  /**
   * Make asynchronous calls prior to render
   */
  loadData: function() {
    var filter = this.selectionsData.get("filter"),
      queryTerms = this.settings.queryTerms || "terms",
      autoload = this.component.$el.data("selection-list-autoload") || false,
      finalQuery = filter ? queryTerms + "=" + filter : null,
      triggerButton = this.component.$("[data-selection-list-trigger]");

    $.ajax({
      url: this.settings.async,
      data: finalQuery,
      beforeSend: function() {
        this.$el.parent().addClass("is-loading");
      }.bind(this),

      success: function(response) {
        response =
          typeof response == "object" ? response : JSON.parse(response);

        if (response.success) {
          this.selectionsData.set("records", response.data);
          this.$el.parent().removeClass("is-loading");
          if (autoload) {
            triggerButton.trigger("click");
            this.toggleViewState(true);
          }
        }
      }.bind(this)
    });
  },

  /**
   * This method handles records in case it has a tree structure
   */
  findInTree: function(ob, filteredKeyword) {
    if (
      ob[this.settings.name] &&
      ob[this.settings.name].replace(/<([^>]+)>/gi, " ").match(filteredKeyword)
    ) {
      return true;
    } else if (ob.data) {
      return this.findInTree(ob.data, filteredKeyword);
    }

    // adding filterBy setting to work filtering multiple keys instead of just one
    let labels = this.settings.filterBy ?? this.settings.name;
    labels = labels.split(",");

    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (typeof ob[i] === "object" && ob[i] !== null) {
        if (ob[i].data) {
          return this.findInTree(ob[i].data, filteredKeyword);
        } else if (
          ob[i][this.settings.name]
            .replace(/<([^>]+)>/gi, " ")
            .match(filteredKeyword)
        ) {
          return true;
        }
      } else {
        var matchedKeyword = [];

        for (var x = 0; x < labels.length; x++) {
          ob[labels[x]] = ob[labels[x]] || "";
          matchedKeyword.push(
            ob[labels[x]].replace(/<([^>]+)>/gi, " ").match(filteredKeyword)
          );
        }

        return matchedKeyword.join().replace(",", "");
      }
    }
    return false;
  },

  render: function() {
    var lazyLoader = this.selectionsData.get("lazyLoader"),
      filterValue = this.selectionsData.get("filter"),
      selections = this.selectionsData.get("selections")
        ? this.selectionsData.get("selections")
        : [];

    // Find the matching id from endPoint each action of data-action-selection
    if (REVELEX.settings.endPointData) {
      var filterResult = [];
      REVELEX.settings.endPointData.forEach(data => {
        if (this.settings.id === data.dataId) {
          if (selections.length && data.id.length > 0 && data.success) {
            data.id = data.id.concat(selections);
          }

          for (let i = 0; i < this.settings.data.length; i++) {
            let id = [];
            data.parameterId.forEach(parameterId => {
              id = this.settings.data[i].id
                ? this.settings.data[i].id
                : this.settings.data[i][parameterId];
            });

            if (data.id.includes(id)) {
              filterResult.push(this.settings.data[i]);
            }
          }
          if (filterResult.length) {
            this.selectionsData.set("records", filterResult);
          } else {
            filterResult = this.settings.data;
            this.selectionsData.set("records", filterResult);
          }
        }
      });
    }

    // We save a variable to speed up the filtering process as we type (we delete it when we remove characters from filter)
    // We check the length of records vs results because records can change when selection list is dependant on other list selections
    this.results =
      this.results &&
      this.results.length == this.selectionsData.get("records").length
        ? this.results
        : this.selectionsData.get("records");

    // Temporary result set that gets processed
    var results = this.results;

    // Filter results if available
    if (filterValue) {
      filterValue = filterValue.trim().toLowerCase();

      // Update result variable to paginate and print in DOM
      results = _.filter(
        this.results,
        function(item) {
          var filteredKeyword = new RegExp(filterValue, "gi");
          return this.findInTree(item, filteredKeyword);
        }.bind(this)
      );

      // Update variable to keep track of filtered result set
      this.results = results;
    }

    // Get all results or just part of them if we filtered or we have lazy loader option
    if (lazyLoader) {
      results = this.results.slice(
        0,
        lazyLoader * this.selectionsData.get("page")
      );
    }

    var output = this.template({
      data: results,
      totalRecords: this.selectionsData.get("records"),
      filter: this.selectionsData.get("filter"),
      selections: selections,
      componentSettings: this.settings
    });

    this.el.innerHTML = output;

    this.filter = this.$el.find("[data-selection-list-filter]");

    // Container for selections
    this.selectionsContainer =
      this.selectionsContainer == undefined
        ? this.el.querySelector("[data-selection-list-selections-container]")
        : false;

    // Render selections in selections area
    if (this.settings.showSelections) {
      this.renderSelectionsArea(selections);
    }

    // Focus back on filter if it's part of the list render
    this.filter = !this.filter.length
      ? this.$("[data-selection-list-filter]")
      : this.filter;

    if (this.filter.length) {
      this.filter.focus();

      if (this.filter.val()) {
        this.filter[0].selectionStart = this.filter[0].selectionEnd = this.filter[0].value.length;
      }
    }

    // Create event listener to load more results on scroll
    if (lazyLoader) {
      this.$("[data-selection-list-results-list]").on(
        "scroll",
        this.loadOnScroll.bind(this)
      );
    }
  },

  loadOnScroll: function(e) {
    var page = this.selectionsData.get("page"),
      lazyLoader = this.selectionsData.get("lazyLoader");
    if (
      lazyLoader * page < this.selectionsData.get("records").length &&
      e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
        e.currentTarget.scrollHeight - 100
    ) {
      var currentScrollTop = e.currentTarget.scrollTop;

      // Update page
      this.selectionsData.set("page", page + page);

      // Move scroll to where it was before rendering again
      this.$(
        "[data-selection-list-results-list]"
      )[0].scrollTop = currentScrollTop;
    }
  },

  closeSearchParam: function(e) {
    // Deactivating the active state to make mobile to behave better when closing the dialog
    this.activeStateTarget = false;
    this.toggleOutput(e);
    $(document.body).removeClass("overlay-in");
  },

  resetFilter: function() {
    this.selectionsData.set("filter", "");
    this.results = "";
    this.render();
  },

  /**
   * Handle selections
   */
  handleSelections: function(e) {
    // When doing a free text selection, this will control all flags
    // right now the free text allows as little as 2 characters to continue
    this.freeTextData = {};
    this.freeTextEntry = false;
    this.selectionsData.set("freeTextEntry", false);
    this.avoidHandling = false;

    if (e.type === "keyup" && e.keyCode === 188) {
      this.freeTextInput = $(e.currentTarget);

      if (this.freeTextInput.val().length > 2) {
        this.freeTextEntry = true;
        this.freeTextData = {
          option: this.freeTextInput.val().replace(/,/g, ""),
          isFreeText: true
        };
        this.freeTextInput.val("");
        this.selectionsData.set("freeTextEntry", true);
      } else {
        return false;
      }
    } else if (e.type === "keyup") {
      return false;
    }

    // Holding the full data if free text is present
    var data = this.freeTextEntry ? this.freeTextData : e.currentTarget.dataset,
      optionList = this.freeTextEntry ? this.freeTextData : [data.option],
      selections = this.selectionsData.get("selections") || [],
      subTree = $(e.currentTarget).siblings("ul");

    // If we reached maximum amount of allowed selections don't check it
    if (
      this.settings.maxSelection == selections.length &&
      $(e.currentTarget).prop("checked")
    ) {
      // Uncheck invalid selection
      $(e.currentTarget).prop("checked", false);

      // Add class to list to highlight invalid click
      this.$el.addClass("limit-reached");
      return false;
    } else {
      this.$el.removeClass("limit-reached");
    }

    // Clear all selections if select all was selected and submitSelectAll mode is on
    if (
      this.$("[data-select-all]").prop("checked") &&
      this.settings.submitSelectAll
    ) {
      selections = [];
    }

    // Subtree list
    if (subTree.length) {
      _.each(
        subTree.find("input"),
        function(input) {
          optionList.push(input.dataset.option);
        }.bind(this)
      );
    }

    // If the selected option is not in the model add it
    // to the list (and it's children if its a tree), else remove it from the list
    var action = this.selectionsData.filterData(selections, data.option)
      ? false
      : true;

    // We mange selections in a loop in case it's a tree widget
    // and we're selecting a parent checkbox
    _.each(
      optionList,
      function(option) {
        // each can't break so we will be using avoidHandling flag to avoid extra unwanted entries
        if (!this.avoidHandling) {
          let currentOption = this.freeTextEntry ? optionList : option;
          // If index is available it means we have it in the model
          // and we remove it, else we add it
          var index = this.selectionsData.filterData(
            selections,
            currentOption,
            true
          );

          if (action && typeof index == "undefined") {
            selections.push(currentOption);
          } else if (!action) {
            // else remove it
            selections.splice(index, 1);
          }

          // Check / uncheck elements
          if (!this.freeTextEntry) {
            this.$('[data-option="' + option + '"]').prop("checked", action);
          } else {
            this.avoidHandling = true;
          }
        }
      }.bind(this)
    );

    // save data in model
    this.selectionsData
      .set("selections", selections)
      .trigger("change:selections");

    // Toggle select all
    this.toggleSelectAllCheck();
  },

  /**
   * Checks or unchecks the select all option depending if there are options selected
   */
  toggleSelectAllCheck: function() {
    var selections = this.selectionsData.get("selections") || [],
      checked =
        selections.length || this.settings.submitSelectAll ? false : true;

    this.$("[data-select-all]").prop("checked", checked);
  },

  selectAll: function(e) {
    // Get check status
    var checked = $(e.currentTarget).prop("checked"),
      selections = [];

    if (checked && this.settings.submitSelectAll) {
      // If submitSelectAll is passed we select all entries
      selections = _.map(
        this.selectionsData.get("records"),
        function(item) {
          return String(item[this.settings.key]);
        }.bind(this)
      );
    } else if (!this.settings.submitSelectAll) {
      // The standard mode doesn't allow for unchecking select all so we put it back
      checked = checked || true;
    }

    // Uncheck rendered items
    this.$("[data-option]:not([data-select-all])").prop("checked", false);

    // Reselect select all for when clicking on checked select all
    $(e.currentTarget).prop("checked", checked);

    this.selectionsData.set("selections", selections);
  },

  attachComponent: function(listServing) {
    //  Reset and render again the list
    this.selectionsData
      .set({
        filter: "",
        page: 1
      })
      .trigger("change:page");

    // Add the visible class just if it doesn't have it already
    if (!this.$el.hasClass("is-visible")) {
      this.toggleViewState(true);
      if (this.filter.length) {
        this.filter.focus(e => {
          if ((typeof e !== "undefined" && !e.keyCode) || e.keyCode == 9) {
            e.preventDefault();
            return false;
          }
        });
      }
    }

    // Removes class from other trigger's container
    $('[data-output-view-trigger="' + this.settings.outputView + '"]')
      .parent()
      .removeClass("is-active");

    // Updates output view to identify which component it's serving
    this.$el.attr("data-serving", this.settings.id);

    // Remove the limit reached class from the previous list
    this.$el.removeClass("limit-reached");

    // Adds class to trigger's container
    this.activeStateTarget
      ? this.activeStateTarget.classList.add("is-active")
      : this.component.triggerElement.parent().addClass("is-active");
  },

  detachComponent: function(e) {
    // If we're toggling the same active component detach listener
    // Detach only if trigger is different from filter
    if (
      !e.currentTarget ||
      !_.has($(e.currentTarget).data(), "data-selection-list-filter")
    ) {
      this.toggleViewState();

      // Removes class to trigger's container
      this.activeStateTarget
        ? this.activeStateTarget.classList.remove("is-active")
        : this.component.triggerElement.parent().removeClass("is-active");

      this.$el.attr("data-serving", "");

      return this.settings.id;
    }
  },

  onComponentAttach: function(attach) {
    // Listener for filter input
    $(document).on(
      "keyup." + attach,
      '[data-selection-list-filter="' + this.settings.id + '"]',
      this.component.filterEvent.bind(this)
    );
  },

  /**
   * Callback for click event to close the panel
   */
  onClickOutEvent: function(e) {
    // Close if click outside is:
    // Out of the list &&
    // Not on the same filter or trigger of the component
    if (
      !$(e.target).closest('[data-serving="' + this.settings.id + '"]')
        .length &&
      $(e.target).attr("selection-list-filter") != this.settings.id &&
      !$(e.target).is(this.component.triggerElement)
    ) {
      this.toggleOutput(e);
      $(document.body).removeClass("overlay-in");
    }
  }
});

/**
 * Main component view
 */
var SelectionListView = Backbone.View.extend({
  initialize: function() {
    this.settings = this.$el.data("settings") || {};
    this.filter = this.$("[data-selection-list-filter]");

    var results = this.$("[data-selection-list-results]"),
      template = this.$("[data-selection-list-template]"),
      hasLazyLoader = _.has(this.settings, "lazyLoader");

    /**
     * Model containing information of selected options
     */
    var SelectionsData = Backbone.Model.extend({
      defaults: {
        filter: "",
        selections: []
      },

      /**
       * Does indexOf but matches even if type is different i.e: 4 == "4"
       * If third parameter is passed (boolean) returns only de index, else return the whole item
       * Free text upgrade: As we are waiting a full object for free text we will compare
       * exactly the option to the item
       */
      filterData: function(list, item, getIndexOnly) {
        for (var x = 0; x <= list.length; x++) {
          if (typeof list[x] !== "object" && String(list[x]) == String(item)) {
            return getIndexOnly ? x : item;
          } else if (
            typeof list[x] === "object" &&
            String(list[x]["option"]) == String(item)
          ) {
            return getIndexOnly ? x : list[x]["option"];
          }
        }
      },
      /**
       * Filters component records using master component selections
       */
      filterRecordsByMasterSelection: function(
        masterSelections,
        filterByKey,
        orderResultsBy
      ) {
        // Initial set of records
        const allRecords = this.get("defaultRecords");
        if (masterSelections.length) {
          let recordsToAdd = {},
            recordsByMaster = [],
            filteredRecords = this.get("filteredRecords")
              ? this.get("filteredRecords")
              : {},
            resetSelections = true;

          // Selections will not reset only the 1st time this function runs because we do not want
          // to reset default selections on component load
          if (!Object.keys(filteredRecords).length) {
            resetSelections = false;
          }

          // Loop through options selected in master component
          masterSelections.forEach(option => {
            // If option does not exist in filteredRecords, then filter
            if (!filteredRecords[option]) {
              // Filter records that match each option selected
              recordsToAdd[option] = allRecords.filter(record => {
                // It's a match when key in record is equal to option selected in master
                if (this.externalFilterCriteria) {
                  // when `externalFilterCriteria` exists, it means that other elements should be taken in consideration when filtering
                  return (
                    record[filterByKey] == option &&
                    record[this.externalFilterCriteria.key] ==
                      this.externalFilterCriteria.value
                  );
                } else return record[filterByKey] == option;
              });
              // Add new set of filtered records to display on dependant selection list
              recordsByMaster = recordsByMaster.concat(recordsToAdd[option]);
            } else {
              // If set of records exits on global filtered records, just add it to display on dependant selection list
              recordsByMaster = recordsByMaster.concat(filteredRecords[option]);
            }
          });
          // Extend global filtered records (Object.assign not working IE11)
          _.extend(filteredRecords, recordsToAdd);
          // Updates global filtered records
          this.set("filteredRecords", filteredRecords);
          // Updates records to display on dependant selection list
          // Optional (key to sort results) It's sorted to preserve options order
          this.set(
            "records",
            orderResultsBy
              ? _.sortBy(recordsByMaster, orderResultsBy)
              : recordsByMaster
          );
          // Clear selections
          resetSelections ? this.set("selections", []) : false;
        } else {
          // If master selection is empty, display all records
          this.set("records", allRecords);
        }
      },

      /**
       * Filters records by external value unless reset is true
       * When reset param is true, list values are set to defaults values passed on initialize
       */
      filterRecordsByExternalController: function(
        key = null,
        value = null,
        clearSelection = true,
        resetData = false
      ) {
        // If initial records does not exist, create it. `initialRecords`
        // are only used when we want to reset data to initial state
        if (!this.get("initialRecords")) {
          // defaultRecords will be found only in lists which records were filtered by a master,
          // and for that case `defaultRecords` will match initial state because `records` were already altered
          if (this.get("defaultRecords")) {
            this.set("initialRecords", this.get("defaultRecords"));
          } else {
            this.set("initialRecords", this.get("records"));
          }
          // We set `defaultRecords` here because we will use them for external filtering
          // and we need records state after 1st load
          this.set("defaultRecords", this.get("records"));
        }

        // If default selections does not exist, create it
        this.get("defaultSelections")
          ? false
          : this.set("defaultSelections", this.get("selections"));

        const defaultValues = this.get("defaultRecords");

        if (resetData) {
          // Reset records and selections to default values
          this.set("records", this.get("initialRecords"));
          // Useful when one selection list is used to filter another selection list
          this.set("defaultRecords", this.get("initialRecords"));
          // Reset options selected to default ones
          clearSelection
            ? this.set("selections", [])
            : this.set("selections", this.get("defaultSelections"));
          // Criteria removed
          delete this.externalFilterCriteria;
        } else {
          //We save the external criteria used, can be used for master/dependant filtering
          this.externalFilterCriteria = { key: key, value: value };

          // Filter records as per params
          let filteredRecords = defaultValues.filter(
            record => record[key] == value
          );
          // Set records to filtered records
          this.set("records", filteredRecords);
          //**************************************** */
          // defaultRecords set to filtered records for cases when it has a master selection list
          // (dependent records filtered by master list selections)
          this.get("initialRecords").length !==
          this.get("defaultRecords").length
            ? this.set("defaultRecords", this.get("initialRecords"))
            : false;
          //**************************************** */
          // clear selections
          clearSelection ? this.set("selections", []) : false;
        }
        //If current selection list depends on another selection list, clear stored filtered records
        this.get("filteredRecords") ? this.set("filteredRecords", {}) : false;
      }
    });

    // Component Data: If you have an inner results object use it, else get it from id
    results = results.length
      ? results
      : $("[data-selection-list-results='" + this.settings.results + "']");

    // Get records from data object if present, else get it from the list
    if (this.settings.data) {
      var records = this.settings.data;
    } else if (results.data("settings") && results.data("settings").data) {
      var records = results.data("settings").data;
      this.settings.key = results.data("settings").key;
      this.settings.name = results.data("settings").name;
      this.settings.title = results.data("settings").title
        ? results.data("settings").title
        : results.data("settings").name;
    } else if (!this.settings.async) {
      var records = [];
      console.warn("Data not available or not properly passed");
    }

    // Component Template: If you have an inner results object use it, else get it from id
    template = template.length
      ? template
      : $("[data-selection-list-template='" + this.settings.template + "']");

    // If neither internal nor external templates were defined we use our default template
    template = template.length ? template.html() : defaultListTemplate;

    // Replace in template generic {{key}} , {{title}} and {{$name}} for passed pair
    template = template
      .replace(/\$key/g, this.settings.key)
      .replace(/\$name/g, this.settings.name)
      .replace(/\$title/g, this.settings.title);

    // Compile template and append container for results
    template = Handlebars.compile(template);

    // If filter is not found within component search for an external filter
    this.filter = this.filter.length
      ? this.filter
      : $('[data-selection-list-filter="' + this.settings.id + '"]');

    // Make sure the filter has the component's id
    if (this.filter.length) {
      this.filter.attr("data-selection-list-filter", this.settings.id);
    }

    // Create model to keep record of selections
    // reindex records in case it comes with different keys
    let newRecords = [];

    _.each(records, record => {
      newRecords.push(record);
    });

    this.selectionsData = new SelectionsData({ records: newRecords });

    //If active loader is true create variables for internal pagination
    if (hasLazyLoader) {
      this.selectionsData.set(
        {
          lazyLoader: this.settings.lazyLoader || 40,
          page: 1
        },
        { silent: true }
      );
    }

    // Add container for selections
    this.$el.append("<div class='is-hidden' data-hidden-inputs></div>");

    // Compile template for hidden inputs
    this.hiddenInputsTemplate = Handlebars.compile(hiddenInputsTemplate);

    // Replace in selectionsTemplate generic {{key}} and {{$name}} for passed pair
    this.selectionsTemplate = selectionsTemplate
      .replace(/\$key/g, this.settings.key)
      .replace(/\$name/g, this.settings.name);

    // Compile template for selections
    this.selectionsTemplate = Handlebars.compile(this.selectionsTemplate);

    // Add Id to trigger
    this.settings.trigger =
      '[data-selection-list-trigger="' + this.settings.id + '"]';
    this.$("[data-selection-list-trigger]").attr(
      "data-selection-list-trigger",
      this.settings.id
    );

    this.selectionsData.on(
      "change:selections",
      this.renderSelections.bind(this)
    );

    // Load previous selections
    if (this.settings.selections && this.settings.selections.length) {
      var selections = this.settings.selections;
      this.selectionsData.set("selections", selections, { silent: true });
    }

    // Necessary to validate selections array
    this.validateSelections();

    // Create list view instance
    this.list = new List({
      el: results,
      component: this,
      template: template,
      filter: this.filter,
      settings: this.settings,
      selectionsData: this.selectionsData,
      reset: this.reset.bind(this),
      renderSelectionsArea: this.renderSelectionsArea.bind(this),
      activeStateTarget: this.settings.activeStateTarget
        ? this.settings.activeStateTarget
        : undefined
    });

    // Render label the first time
    this.renderSelections();

    // If is-visible by default, toggle the component open
    if (this.list.$el.hasClass("is-visible")) {
      this.list.toggleOutput();
    }

    if (this.settings.async) {
      this.list.loadData();
    }

    // If selection list is master of another selection list, we save it in REVELEX object
    if (this.settings.masterOf) {
      const targetSelectors = this.settings.masterOf.split(",");
      targetSelectors.forEach(target => {
        // Stores master component in REVELEX obj ensuring global access to it
        window.REVELEX["listMasterOf"]
          ? (window.REVELEX["listMasterOf"][target.trim()] = this)
          : (window.REVELEX["listMasterOf"] = { [target.trim()]: this });
      });
    }

    // If selection list data depends on a master selection list component
    if (this.settings.optionsDependOn && window.REVELEX["listMasterOf"]) {
      // Stores master component
      this.master = window.REVELEX["listMasterOf"][this.settings.id];
      // Saves initial set of records
      this.selectionsData.set("defaultRecords", records);
      // Filters records on initialize
      this.selectionsData.filterRecordsByMasterSelection(
        this.master.selectionsData.get("selections"),
        this.settings.optionsDependOn,
        this.settings.optionsOrderBy || ""
      );
      // Filters records every time a new selections is made on master component
      this.master.selectionsData.on("change:selections", () => {
        this.selectionsData.filterRecordsByMasterSelection(
          this.master.selectionsData.get("selections"),
          this.settings.optionsDependOn,
          this.settings.optionsOrderBy || ""
        );
      });
    }

    return this;
  },

  /**
   * Validate selections array
   */
  validateSelections: function() {
    var retrievedItems = 0,
      selections = this.selectionsData.get("selections"),
      records = this.selectionsData.get("records"),
      notFound = false;

    this.validSelections = [];
    this.validSelectionsLabel = [];

    for (var x = 0; retrievedItems < selections.length; x++) {
      var itemExists = false;
      try {
        var key = records[x][this.settings.key],
          name = records[x][this.settings.name],
          title = records[x][this.settings.title]
            ? records[x][this.settings.title]
            : records[x][this.settings.name],
          selectedValue = title ? title : name;
        itemExists = this.selectionsData.filterData(selections, key);
      } catch (error) {
        // adding a not found flag to active the backup to
        // allow objects to be included in the selections
        notFound = true;
        console.log(
          "%c Error locating item in list. \n Description: " +
            "%c" +
            error.message +
            "",
          "color:#eb0909",
          "color:#906008"
        );
      }

      if (itemExists) {
        this.validSelections.push(key);
        this.validSelectionsLabel.push(selectedValue);
        retrievedItems++;
      } else if (notFound) {
        // adding free text entries
        Object.keys(selections).forEach(e => {
          if (typeof selections[e] === "object") {
            this.validSelections.push(selections[e]);
            this.validSelectionsLabel.push(selections[e].option);
            retrievedItems++;
          }
        });
        break;
      }
    }
    this.selectionsData.set("selections", this.validSelections, {
      silent: true
    });
  },

  reset: function() {
    // Remove selections
    this.selectionsData.set("selections", []).trigger("change:page");

    this.renderSelections();
  },

  /**
   * Splits data of selected items into object
   * Creates object with selections data for rendering hidden inputs
   * Creates object with selections and records data for rendering the selections section
   */
  renderSelections: function() {
    this.validateSelections();

    var selectionsModel = this.selectionsData.get("selections");

    var currentSelections = [];

    Object.keys(selectionsModel).forEach(function(e) {
      if (typeof selectionsModel[e] === "object") {
        currentSelections.push(selectionsModel[e].option);
      } else {
        currentSelections.push(selectionsModel[e]);
      }
    });

    var selections = currentSelections ? currentSelections : [],
      hiddenOutput = this.hiddenInputsTemplate({
        selections: selections,
        componentId: this.settings.id,
        freeTextEnabled: this.settings.freeTextWidget
      });

    // Render hidden inputs
    this.$("[data-hidden-inputs]").html(hiddenOutput);

    //Activate/Deactivate reset button with clicks
    var active = selectionsModel.length ? false : "";
    this.list.$("[data-reset]").attr("disabled", active);

    // Render selections in selections area
    if (this.settings.showSelections) {
      this.renderSelectionsArea(selectionsModel);
      if (this.list.selectionsContainer) {
        // Adding / Removing class from selections container
        action =
          selectionsModel.length &&
          selectionsModel.length !== this.selectionsData.get("records").length
            ? "add"
            : "remove";
        this.list.selectionsContainer.classList[action]("has-selections");
      }
    }

    // Format and render results on trigger element if labels have been defined
    if (this.settings.labels) {
      this.renderSummary(selections);
    }
  },

  /**
   * Displays a summary of the selected items in the trigger
   */
  renderSummary: function(selections) {
    var labelOutput = "",
      availableItems = [],
      labels = this.settings.labels || {},
      records = this.selectionsData.get("records"),
      outputElement = this.$("[data-selection-list-summary]");

    this.fetchAndUpdateEndPointsData(selections);

    if (!selections.length) {
      //If we don't have any selection render the none label
      labelOutput = labels.none
        ? labels.none
        : "Label format missing in settings.";
    } else {
      // If we have selections
      var itemsInLabel = labels.many.split("$item"),
        itemsInLabelCount = itemsInLabel.length - 1,
        retrievedItems = 0;

      if (selections.length == 1) {
        // If we have a single label use it, if not just create a token to replace it with the actual name
        labelOutput = labels.single || "$item";
        itemsInLabelCount = 1;
      } else if (selections.length > 1 && labels.many) {
        labelOutput = labels.many || "$item + %";
        // If we have many selections you must have the many label
      } else {
        labelOutput = "Label format 'many' missing in settings.";
      }

      if (selections.length) {
        for (var x = 0; x <= itemsInLabelCount; x++) {
          labelOutput = labelOutput.replace(
            "$item",
            this.validSelectionsLabel[x]
          );
        }
      } else {
        labelOutput = labels.none;
      }

      if (selections.length > 1 && labels.many) {
        // If we have many selections you must have the many label
        if (itemsInLabelCount) {
          var additionalCount =
            itemsInLabelCount > selections.length
              ? 0
              : selections.length - itemsInLabelCount;

          labelOutput =
            additionalCount > 0
              ? (labelOutput = labelOutput.replace(/%/g, additionalCount))
              : (labelOutput = labelOutput.replace("+%", ""));
        } else {
          labelOutput = labelOutput.replace(/%/g, selections.length);
        }
      } else if (selections.length > 1 && !labels.many) {
        labelOutput = "Label format 'many' missing in settings.";
      }
    }

    // Clear remaining $item in string
    labelOutput = labelOutput.replace(
      /[^a-z0-9]\s(?:\$item)|\s(?:\$item)/g,
      ""
    );

    // Render results on output element
    var printMethod = outputElement[0].tagName == "INPUT" ? "val" : "html";
    outputElement[printMethod](labelOutput).change();
  },

  /**
   * Fetches data from multiple endpoints based on specified actions and sets filters
   */
  fetchAndUpdateEndPointsData: function(selections) {
    var selectionListMain = this.$el
      .parent()
      .parent()
      .find(".selection-list-main");

    if (this.settings.endPointAction) {
      var baseURL = this.settings.endPointAction;
      var updatedEndPoints = [];

      baseURL.forEach(action => {
        var updatedEndPointAction =
          action + "?" + this.settings.query_data + "=" + selections;

        updatedEndPoints.push(updatedEndPointAction);

        selectionListMain.addClass("is-loading");
      });

      Promise.all(
        updatedEndPoints.map(endPoint => {
          return fetch(endPoint).then(response => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          });
        })
      )
        .then(dataArr => {
          selectionListMain.removeClass("is-loading");
          REVELEX.settings.endPointData = [];

          dataArr.forEach((data, dataIndex) => {
            data.dataId = this.settings.targetId[dataIndex];
            data.parameterId = this.settings.parameterId;

            let callbackParameter = this.settings.callbackParameter[dataIndex];

            data.id = Object.values(data.data).flatMap(ids =>
              ids.map(id => id[callbackParameter])
            );

            REVELEX.settings.endPointData.push(data);
          });
        })
        .catch(error => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  },

  /**
   * Render the selected items in a selections area
   */
  renderSelectionsArea: function(selections) {
    var totalRecords = this.selectionsData.get("records") || [],
      allSelected = "removeClass";

    selectionsOutput = _.filter(
      this.selectionsData.get("records"),
      function(item) {
        return this.selectionsData.filterData(
          selections,
          item[this.settings.key]
        );
      }.bind(this)
    );

    // giving the necessary keys to the free text entry to render properly
    Object.keys(selections).forEach(e => {
      if (typeof selections[e] === "object") {
        let currentFreeText = {
          [this.settings.name]: selections[e].option,
          [this.settings.key]: selections[e].option
        };
        selectionsOutput.push(currentFreeText);
      }
    });

    selectionsOutput = this.selectionsTemplate({
      totalRecords: totalRecords,
      data: selectionsOutput,
      labels: this.settings.labels,
      componentId: this.settings.id,
      freeTextEnabled: this.settings.freeTextWidget
    });

    // Render
    this.list.$("[data-selection-list-selections]").html(selectionsOutput);

    // If all are selected add class for special render
    // avoiding all selected state if free text is enabled
    if (
      (this.settings.allSelected && selections.length == 0) ||
      (selections.length == totalRecords.length &&
        !this.settings.freeTextWidget)
    ) {
      allSelected = "addClass";
    }
    this.list
      .$("[data-selection-list-selections]")
      [allSelected]("all-selected");
  },

  /**
   * Callback that processes the input event for filtering
   */
  filterEvent: function(e) {
    var pressedKey = typeof e.which == "number" ? e.which : e.keyCode;

    // If we're deleting we delete the results object in the list view to start filtering back from the entire list
    if (pressedKey == 8 || pressedKey == 46) {
      this.results = null;
    }

    // Update filter to run render again
    this.selectionsData.set({
      filter: e.currentTarget.value,
      page: 1
    });
  },

  setDuplicateData: function(data) {
    this.selectionsData.set("selections", data);
  },

  getDuplicateData: function() {
    return this.selectionsData.get("selections");
  }
});

module.exports = SelectionListView;
