var $ = require("jquery");

var InstallmentOption = Backbone.Model.extend({
  defaults: {
    annual_percentage_rate: 0,
    payment_terms: []
  }
});

var InstallmentOptionCollection = Backbone.Collection.extend({
  model: InstallmentOption
});

var PaymentInstallments = Backbone.View.extend({
  events: {
    "change [data-payment-installments-selector]": "render",
    "click [data-payment-installments-terms-trigger]": "renderTerms"
  },

  initialize: function() {
    this.settings = {};

    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
    }

    this.installmentOptions = new InstallmentOptionCollection(
      this.settings.installmentOptions
    );

    this.paymentTermsTemplate = Handlebars.compile(
      this.$("[data-payment-installments-payment-terms-template]").html() || ""
    );

    this.modal = this.$("[data-payment-installments-terms-modal]");

    this.model = new InstallmentOption();

    this.$el.loadTemplates({ data: this.model });
    this.model.on("change", this.$el.renderItems.bind(this));

    this.render();
  },

  render: function() {
    let option = this.installmentOptions.get(
      this.$("[data-payment-installments-selector]").val()
    );

    let locale = REVELEX.settings.currentLocale.replace("_", "-");

    this.$("[data-payment-installments-payment-terms]").empty();

    var self = this;
    _.each(option.get("payment_terms"), function(term) {
      self.$("[data-payment-installments-payment-terms]").append(
        self.paymentTermsTemplate({
          length: term.length,
          amount: term.amount.toLocaleString(locale, {
            style: "currency",
            currency: self.settings.currency
          })
        })
      );
    });

    this.optionFormatted = {
      annual_percentage_rate_formatted: (
        option.get("annual_percentage_rate") / 100
      ).toLocaleString(locale, {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }),
      fee_amount_formatted: option.get("fee_amount").toLocaleString(locale, {
        style: "currency",
        currency: this.settings.currency
      }),
      funded_amount_formatted: option
        .get("funded_amount")
        .toLocaleString(locale, {
          style: "currency",
          currency: this.settings.currency
        }),
      total_amount_formatted: option
        .get("total_amount")
        .toLocaleString(locale, {
          style: "currency",
          currency: this.settings.currency
        })
    };

    this.$("[data-payment-installments-terms-trigger]")[
      option.get("terms_and_conditions") ? "removeClass" : "addClass"
    ]("is-hidden");

    this.$("[data-payment-installments-apr]")
      .empty()
      .append(this.optionFormatted["annual_percentage_rate_formatted"]);

    this.$("[data-payment-installments-duration]")
      .empty()
      .append(option.get("duration"));

    this.$("[data-payment-installments-amount]")
      .empty()
      .append(this.optionFormatted["amount_formatted"]);

    this.$("[data-payment-installments-total]")
      .empty()
      .append(this.optionFormatted["total_amount_formatted"]);

    this.$("[data-payment-installments-fee]")
      .empty()
      .append(this.optionFormatted["fee_amount_formatted"]);
  },

  renderTerms: function() {
    var option = this.installmentOptions.findWhere({
      id: this.$("[data-payment-installments-selector]").val()
    });

    this.optionFormatted = $.extend(
      true,
      option.attributes,
      this.optionFormatted
    );

    this.model.set(this.optionFormatted);

    var Dialog = this.modal.component();

    Dialog.reflow().open();
  }
});

module.exports = PaymentInstallments;
