/**
 * Templates for use with calendar.js
 * Isolated to individual file for neatness
 * @todo template for timepicker
 * @date 10/09/15
 */
var DateTemplate;
var MonthSelectTemplate;
var YearSelectTemplate;
var TimeTemplate;
DateTemplate =
  '\
    <div class="calendars" data-calendar="{{this.[0].cid}}" tabindex="0" {{#if this.[0].parentAutoclose}}data-parent-autoclose{{/if}}>\
    {{#each this}}\
        <div class="calendar-month-container">\
            <table class="calendar-month">\
                <thead class="calendar-month-header">\
                    <tr>\
                        <th class="{{#if this.has_previous_month}}is-enabled{{/if}} calendar-month-prev" {{#if this.has_previous_month}}data-prev-month tabindex="0" role="button"{{/if}}><div class="sr-only">Go to previous month</div></th>\
                        <th colspan="5" calendar-month-short="{{this.month.short}}" calendar-month-full="{{this.month.full}}" calendar-year="{{this.year}}" class="calendar-month-name {{#if this.show_month_select}}has-select{{/if}}" {{#if this.show_month_select}}data-select-month tabindex="0" role="button"{{/if}}>\
                            {{this.month.full}}, <span>{{this.year}}</span>\
                        </th>\
                        <th class="{{#if this.has_next_month}}is-enabled{{/if}} calendar-month-next" {{#if this.has_next_month}}data-next-month tabindex="0" role="button"{{/if}}><div class="sr-only">Go to next month</div></th>\
                    </tr>\
                    <tr>\
                        {{#each this.weekdays}}\
                            <th class="calendar-month-weekday">{{this}}</th>\
                        {{/each}}\
                    </tr>\
                </thead>\
                <tbody class="calendar-month-body" data-month-body>\
                    {{#each this.weeks}}\
                    <tr class="calendar-month-week">\
                        {{#each this.days}}\
                            {{#if this.is_previous_month}}\
                                <td class="calendar-month-day is-previous-month"></td>\
                            {{else}}\
                              {{#if this.is_next_month}}\
                                <td class="calendar-month-day is-next-month"></td>\
                              {{else}}\
                                <td class="\
                                    {{#if this.is_selectable}}is-selectable{{/if}}\
                                    {{#if this.is_selected}}is-selected{{/if}}\
                                    {{#if this.is_start}}is-start{{/if}}\
                                    {{#if this.is_end}}is-end{{/if}}\
                                    {{#if this.is_range}}is-range{{/if}}\
                                    calendar-month-day"\
                                    {{#if this.is_selectable}}data-day="{{this.date_output}}" tabindex="0" role="button"{{/if}}>\
                                        {{this.date_day}}\
                                </td>\
                              {{/if}}\
                            {{/if}}\
                        {{/each}}\
                    </tr>\
                    {{/each}}\
                </tbody>\
            </table>\
        </div>\
    {{/each}}\
    </div>\
';
MonthSelectTemplate =
  '\
     <div class="calendars" data-calendar="{{this.cid}}" tabindex="0" {{#if this.parentAutoclose}}data-parent-autoclose{{/if}}>\
        <div class="calendar-month-container">\
            <table class="calendar-month">\
                <thead class="calendar-month-header">\
                    <th class="{{#if this.has_previous_year}}is-enabled{{/if}} calendar-month-prev" {{#if this.has_previous_year}}data-prev-year tabindex="0" role="button"{{/if}}><div class="sr-only">Go to previous year</div></th>\
                    <th colspan="5" class="calendar-month-name {{#if this.show_year_select}}has-select{{/if}}" {{#if this.show_year_select}}data-select-year tabindex="0" role="button"{{/if}}>\
                        {{this.year}}\
                    </th>\
                    <th class="{{#if this.has_next_year}}is-enabled{{/if}} calendar-month-next" {{#if this.has_next_year}}data-next-year tabindex="0" role="button"{{/if}}><div class="sr-only">Go to next year</div></th>\
                </thead>\
                <tbody class="calendar-month-body" data-month-body>\
                    <tr>\
                        <td colspan="7">\
                            <div class="calendar-select-wrapper">\
                                {{#each this.months}}\
                                <div class="\
                                    {{#if this.is_selected}}is-selected{{/if}}\
                                    {{#if this.is_selectable}}is-selectable{{/if}}\
                                    calendar-select-month\
                                    calendar-select">\
                                    <span class="calendar-select-value" {{#if this.is_selectable}}data-month="{{this.month_output}}" tabindex="0" role="button"{{/if}}>{{this.full}}</span>\
                                </div>\
                                {{/each}}\
                            </div>\
                        </td>\
                    </tr>\
                </thead>\
            </table>\
        </div>\
    </div>\
';
YearSelectTemplate =
  '\
     <div class="calendars" data-calendar="{{this.cid}}" tabindex="0" {{#if this.parentAutoclose}}data-parent-autoclose{{/if}}>\
        <div class="calendar-month-container">\
            <table class="calendar-month">\
                <thead class="calendar-month-header">\
                    <th class="{{#if this.has_previous_year_range}}is-enabled{{/if}} calendar-month-prev" {{#if this.has_previous_year_range}}data-prev-year-range tabindex="0" role="button"{{/if}}><div class="sr-only">Go to previous year</div></th>\
                    <th colspan="5" class="calendar-month-name">\
                        {{this.start_year}} - {{this.end_year}}\
                    </th>\
                    <th class="{{#if this.has_next_year_range}}is-enabled{{/if}} calendar-month-next" {{#if this.has_next_year_range}}data-next-year-range tabindex="0" role="button"{{/if}}><div class="sr-only">Go to nexr year</div></th>\
                </thead>\
                <tbody class="calendar-month-body" data-month-body>\
                    <tr>\
                        <td colspan="7">\
                            <div class="calendar-select-wrapper">\
                                {{#each this.years}}\
                                <div class="\
                                    {{#if this.is_selected}}is-selected{{/if}}\
                                    {{#if this.is_selectable}}is-selectable{{/if}}\
                                    calendar-select-year\
                                    calendar-select">\
                                    <span class="calendar-select-value" {{#if this.is_selectable}}data-year="{{this.year_output}}" tabindex="0" role="button"{{/if}}>{{this.year}}</span>\
                                </div>\
                                {{/each}}\
                            </div>\
                        </td>\
                    </tr>\
                </thead>\
            </table>\
        </div>\
    </div>\
';
module.exports = {
  date: DateTemplate,
  month_select: MonthSelectTemplate,
  year_select: YearSelectTemplate,
  time: TimeTemplate
};
