var Field = Backbone.Model.extend({
  idAttribute: "id",
  defaults: {
    value: 0
  },

  isApplicableTotal: function(total) {
    return _.indexOf(this.get("totals"), total) !== -1;
  }
});

var Fields = Backbone.Collection.extend({ model: Field });

var Calculator = Backbone.View.extend({
  events: {
    "keyup [data-calculator-field]": "updateField"
  },

  initialize: function() {
    this.fields = new Fields();

    var self = this;

    this.$("[data-calculator-field]").each(function(i, el) {
      self.fields.add(
        new Field({
          id: $(el).data("calculator-field"),
          totals: $(el)
            .data("calculator-field-totals")
            .split("|"),
          value: parseFloat($(el).val() || 0)
        })
      );
    });

    this.$("[data-calculator-total]").each(function(i, el) {
      new TotalView({
        el: el,
        fields: self.fields
      });
    });
  },

  updateField: function(event) {
    var Field = this.fields.get(
      $(event.currentTarget).data("calculator-field")
    );

    if (Field) {
      Field.set("value", parseFloat($(event.currentTarget).val() || 0));
    }
  }
});

var TotalView = Backbone.View.extend({
  initialize: function(options) {
    this.settings = {
      action: "add",
      startValue: 0,
      currency: null,
      precision: 0
    };

    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
    }

    this.fields = options.fields;
    this.fields.on("change", this.update.bind(this));
  },

  update: function() {
    var self = this;
    var locale = REVELEX.settings.currentLocale.replace("_", "-");
    var fieldTotal = 0;
    var total = 0;

    this.fields.each(function(field) {
      if (field.isApplicableTotal(self.settings.id)) {
        fieldTotal = fieldTotal + field.get("value");
      }
    });

    switch (this.settings.action) {
      case "add":
        total = this.settings.startValue + fieldTotal;
        break;

      case "subtract":
        total = this.settings.startValue - fieldTotal;
        break;

      case "multiply":
        total = this.settings.startValue * fieldTotal;
        break;
    }

    if (this.el.localName === "input") {
      this.$el.val(
        this.settings.precision ? total.toFixed(this.settings.precision) : total
      );
      this.$el.change();
    } else {
      if (this.settings.currency) {
        this.$el.html(
          total.toLocaleString(locale, {
            style: "currency",
            currency: this.settings.currency
          })
        );
      } else {
        this.$el.html(
          this.settings.precision
            ? total.toFixed(this.settings.precision)
            : total
        );
      }
    }
  }
});

module.exports = Calculator;
