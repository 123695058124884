var GoogleAnalyticsGTag = Backbone.View.extend({
  initialize: function(options) {
    this.settings = options;

    Backbone.Events.on("publishAnalyticsData", this.publishData.bind(this));

    if (this.settings.send_events) {
      console.log("send event listener");
      $(document).on("click", "[data-analytics-id]", this.sendEvent.bind(this));
    }
  },

  publishData: function(data) {
    if (data.google_analytics_gtag) {
      if (data.google_analytics_gtag.page_data.dataAsDomObj) {
        window[data.google_analytics_gtag.page_data.dataAsDomObj] =
          data.google_analytics_gtag.page_data.data;
      } else {
        gtag(
          "event",
          google_analytics_gtag.pageToken,
          data.google_analytics_gtag.page_data
        );
      }
    }
  },

  sendEvent: function(event) {
    var data = {
      event_category: this.settings.page,
      event_label: $(event.currentTarget).data("analyticsId")
    };

    if (this.settings.event_category) {
      data.event_category = this.settings.event_category;
    }

    if (this.settings.event_label) {
      data.event_label = this.settings.event_label;
    }

    if (this.settings.event_value) {
      data.value = this.settings.event_value;
    }

    gtag("event", this.settings.event_action || "click", data);
  }
});

module.exports = GoogleAnalyticsGTag;
